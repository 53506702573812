import React from 'react';
import { MessageSquare, FileText, Globe, Mail, Search, X } from 'lucide-react';
import styled from 'styled-components';

type ContentType = 'message' | 'file' | 'page' | 'email_template';

interface TabNavigationProps {
  activeTab: ContentType;
  setActiveTab: (tab: ContentType) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  onReset: () => void;
}

const NavContainer = styled.div`
  width: 100%;
  background: white;
  padding: 12px 24px;
  border-bottom: 1px solid #e0e6ed;
  box-shadow: 0 2px 6px rgba(0,0,0,0.04);
`;

const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabList = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 16px;
  background: ${props => props.isActive ? '#e6f2ff' : 'transparent'};
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Inter', 'Gilroy', sans-serif;
  font-weight: ${props => props.isActive ? 600 : 500};
  color: ${props => props.isActive ? '#2c7be5' : '#475569'};
  font-size: 15px;
  transition: all 0.3s ease;
  
  &:hover {
    background: ${props => props.isActive ? '#e6f2ff' : 'rgba(44, 123, 229, 0.06)'};
    color: #2c7be5;
  }

  svg {
    width: 20px;
    height: 20px;
    stroke-width: 1.5;
    color: ${props => props.isActive ? '#2c7be5' : '#94A3B8'};
    transition: color 0.3s ease;
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  margin-left: auto;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #94a3b8;
  display: flex;
  align-items: center;
  pointer-events: none;
  z-index: 10;
`;

const SearchInput = styled.input`
  width: 320px;
  height: 40px;
  padding: 8px 32px 8px 36px;
  font-size: 14px;
  border: 1px solid #e0e6ed;
  border-radius: 8px;
  background: white;
  color: #1f2937;
  outline: none;
  box-shadow: 0 1px 2px rgba(0,0,0,0.04);

  &::placeholder {
    color: #94a3b8;
  }

  &:focus {
    border-color: #2c7be5;
    box-shadow: 0 0 0 3px rgba(44, 123, 229, 0.1);
  }
`;

const ClearButton = styled.button`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #94a3b8;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(44, 123, 229, 0.1);
    color: #2c7be5;
  }
`;

export const TabNavigation: React.FC<TabNavigationProps> = ({ 
  activeTab, 
  setActiveTab,
  searchQuery,
  setSearchQuery,
  onReset 
}) => {
  const tabs = [
    { id: 'message', label: 'Messages', Icon: MessageSquare },
    { id: 'file', label: 'Files', Icon: FileText },
    { id: 'page', label: 'Pages', Icon: Globe },
    { id: 'email_template', label: 'Email Template', Icon: Mail }
  ];

  return (
    <NavContainer>
      <NavContent>
        <TabList>
          {tabs.map(({ id, label, Icon }) => (
            <TabButton 
              key={id}
              isActive={activeTab === id}
              onClick={() => setActiveTab(id as ContentType)}
            >
              <Icon />
              <span>{label}</span>
            </TabButton>
          ))}
        </TabList>

        <SearchWrapper>
          <SearchIconWrapper>
            <Search size={18} />
          </SearchIconWrapper>
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            placeholder="Search content..."
          />
          {searchQuery && (
            <ClearButton onClick={onReset}>
              <X size={16} />
            </ClearButton>
          )}
        </SearchWrapper>
      </NavContent>
    </NavContainer>
  );
};

export default TabNavigation;