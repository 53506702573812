/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import ActivityItem from "../../../components/activityItem";
import LeadNoteItems from "../../../components/leadNoteItems";
import LeadStatus from "../../../components/leadStatus";
import EmptyState from "../EmptyState";
import {
  assignLeadInList,
  copyLeadInList,
  deleteBulkLeads,
  getLeadDetail,
  moveLeadInList,
  sendLeadToMobile,
  shareContentLead,
} from "../../../services/leadService";
import ContactLinksGenerator from "../../../utils/contactlinksgenerator";
import _ from "lodash";
import LeadLabel from "../../../components/leadLabel";
import DrawerComponent from "../../../components/drawer";
import FileUpload from "../FileUpload";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  setActivityList,
  setNote,
  setLeadTaskList,
  leadFileList,
} from "../../../actions/actions";

import {
  getCustomFormResponse,
  getLeadFormResponse,
} from "../../../services/formService";
import FormResponse from "../../userForm/FormResponse";
import TaskDrawer from "../../common/TaskDrawer";

import { deleteTask, taskById } from "../../../services/taskService";

import CreateActivityForm from "../../../components/createActivityForm";
import { activityById, createActivity } from "../../../services/activityService";
import ConfirmationModal from "../../../components/confirmationModal";
import CreateNoteForm from "../../../components/createNoteForm";
import GlobalDrawer from "../../../components/GlobalDrawer";
import { getAllLeadList } from "../../../services/leadListService";
import {
  FaExternalLinkAlt,
  FaPhone,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import {
  MdEdit,
  MdOutlineAttachFile,
  MdOutlineMail,
  MdSave,
} from "react-icons/md";
import { addCountryCode, convertToIST } from "../../../utils/helpers";

import { getAllContent } from "../../../services/contentService";
import { IoIosArrowDown } from "react-icons/io";

import CreateLeadForm from "../createLeadForm";
import DynamicConfirmationModal from "../../../components/DynamicConfirmationModal";
import Information from "./Information";
import NewTaskItem from "../../../components/newTaskItem";
import { MdOutlineEdit } from "react-icons/md";
import LeadOptionsMenu from "./LeadOptionsMenu";
import LeadActionIcons from "./LeadActionIcons";
import { Edit, Pencil } from "lucide-react";
import ActivityTab from "../activitytab/ActivityTab";
import TaskForm from "../../../components/TaskForm";
import { sendLeadToAllDevices } from "../../../services/leadService";
import { Logger } from '../../../utils/logger';


// import InfiniteScroll from "react-infinite-scroll-component";
interface LeadDataI {
  _id: string;
  leadListId?: string;
  name: string;
  integration: string;
  customSource: string;
  createdAt: Date;
  status: Array<string>;
  saleValue: string;
  website: string;
  label: Array<any>;
  deleteLeadFromDrawer: (id: string) => void;
  copyLeadToList: () => void;
  moveLeadToList: () => void;
  onLabelClick: () => void;
  onStatusClick: () => void;
  setFrom: React.Dispatch<React.SetStateAction<string>>;
  handleEditModal: (name: string, id: any, action: string) => void;
  handleLeadDetailsModalOpen: (title: string, name: string) => void;
  onLeadFileUpload: (e: any) => void;
  editLead: (e: any, id: string) => void;
  leadFileDelete: (obj: any) => void;
  refreshData?: () => void;
  onDeleteLead?: () => void;
}

interface SelectedLeadI {
  activities: Array<any>;
  label: Array<any>;
  notes: Array<{
    createdAt: Date;
    createdBy: Date;
    description: string;
    lead: string;
    _id: string;
  }>;
  tasks: Array<any>;
  phone: string;
  email: string;
}

const LeadDetails: React.FC<PropsWithChildren<LeadDataI>> = ({
  _id,
  leadListId,
  name,
  integration,
  customSource,
  createdAt,
  status,
  saleValue,
  website,
  label,
  deleteLeadFromDrawer,
  copyLeadToList,
  moveLeadToList,
  onLabelClick,
  onStatusClick,
  setFrom,
  handleEditModal,
  handleLeadDetailsModalOpen,
  onLeadFileUpload,
  editLead,
  leadFileDelete,
  refreshData,
  onDeleteLead,
}) => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const [selectedLeadData, setSelectedLeadData] = useState<SelectedLeadI>({
    activities: [],
    label: [],
    notes: [],
    tasks: [],
    phone: "",
    email: "",
  });
  const createLeadFormRef = useRef();
  const [disableLeadInfo, setDisableLeadInfo] = useState<boolean>(true);
  const [leadData, setLeadData] = useState<any>({});
  
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  
  const [viewFormResponse, setViewFormResponse] = useState<boolean>(false);
  const [openWithHeader, setOpenWithHeader] = useState<boolean>(false);
  const [openFileDrawer, setOpenFileDrawer] = useState<any>(false);
  const [copyLeadDrawer, setCopyLeadDrawer] = useState(false);
  const [shareLeadDrawer, setShareLeadDrawer] = useState(false);
  const [assignLeadDrawer, setAssignLeadDrawer] = useState(false);

  const [action, setAction] = useState<string>("");
  const [taskData, setTaskData] = useState<any>({});
  const [formData, setFormData] = useState({});
  const [activityData, setActivityData] = useState<any>({});
  const dispatch = useDispatch();
  const StateData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  
  const [activeLeadOption, setActiveLeadOption] = useState("copy");
  const [leadOwnerName, setLeadOwnerName] = useState<any>("");
  const [extraDetails, setExtraDetails] = useState<any>({});

  const [leadListData, setLeadListData] = useState<any>([]);

  const [activeLeadId, setActiveLeadId] = useState("");
  const [contentData, setContentData] = useState<any>([]);
  const [contentType, setContentType] = useState("message");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleSafeClose = () => {
    // React will batch these updates automatically
    setOpenWithHeader(false);
    setAction("");
    setTaskData({});
    setActivityData({});
    
    // Only call refreshData if it exists
    if (refreshData) {
      refreshData();
    }
  };
  

  const selectedLeadContactDetailsGenerator = useMemo(() => 
    new ContactLinksGenerator({
      number:
        selectedLeadData.phone?.length >= 10
          ? "91" + selectedLeadData.phone
          : selectedLeadData.phone,
      email: selectedLeadData?.email || "",
    }),
    [selectedLeadData.phone, selectedLeadData.email]
  );

 // Current code:
const teamMembers = useMemo(() => 
  StateData?.userData?.userDetails?.organizationEmployee
    ?.filter((item: any) => 
      item?._id !== StateData?.userData?.userDetails?._id && 
      item?.isActive === true
    )
    .map((item: any) => ({
      value: item?._id, 
      label: `${item?.firstName} ${item?.lastName}`,
      role: item?.role
    })), 
  [StateData?.userData?.userDetails]
);

 

  const fetchLeadDetails = async () => {
    try {
      const response = await getLeadDetail(_id);
      if (response && response.status) {
        let responseData = response?.data?.data;
        let leadOwnerName =
          StateData?.userData?.userDetails?.organizationEmployee?.find(
            (x: any) => x._id == responseData.createdBy
          );
        let first_name = leadOwnerName?.firstName;
        let last_name = leadOwnerName?.lastName ? leadOwnerName?.lastName : "";
        setLeadData(responseData);
        dispatch(setActivityList(responseData?.activities));
        dispatch(setLeadTaskList(responseData?.tasks));
        dispatch(setNote(responseData?.notes));
        dispatch(leadFileList(responseData?.files));
        setExtraDetails(responseData.extraDetails || {});
        setLeadOwnerName(first_name + " " + last_name);
        setSelectedLeadData({
          ...selectedLeadData,
          activities: responseData?.activities,
          label: responseData?.label,
          notes: responseData?.notes,
          tasks: responseData?.tasks,
          phone: responseData?.phone,
          email: responseData?.email,
        });
      }
    } catch (err) {
      Logger.log(err);
    }
  };

  useEffect(() => {
    fetchLeadDetails();
  }, [_id]);
  
  const getAllCustomFormResponse = async () => {
    const response = await getLeadFormResponse(_id);
    setFormData(response.data.data[0]);
    setViewFormResponse(true);
  };

  const handleDelete = () => {
    if (action === "editTask") {
      onConfirmation(taskData?._id || "", "task");
    }
    if (action === "editActivity") {
      onConfirmation(activityData?._id || "", "activity");
    }
  };

  const handleEditActivityModal = async (
    name: string,
    _id: any,
    action: string
  ) => {
    if (name === "activity") {
      try {
        const response = await activityById(_id);
        if (response && response.status) {
          setActivityData(response.data.data);
          setAction("editActivity");
          setOpenWithHeader(true);
        }
      } catch (err) {
        toast.error("error while fetching activity data.");
      }
    }
    if (name === "task") {
      try {
        const response = await taskById(_id);
        if (response && response.status) {
          setTaskData(response.data.data);
          setAction("editTask");
          setOpenWithHeader(true);
        }
      } catch (err) {
        toast.error("error while fetching activity data.");
      }
    }
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const onConfirmation = async (id: string, name: string) => {
    if (name === "task" && id) {
      try {
        const response = await deleteTask(id);
        if (response && response.status) {
          toast.success(response?.data?.message);
          const tempArray = [...StateData?.leadTask?.leadTask];

          const tempData = tempArray.findIndex((x) => x._id == taskData._id);
          tempArray.splice(tempData, 1);
          dispatch(setLeadTaskList(tempArray));

          setTaskData({});
          toggleConfirmationModal();
        }
      } catch (err) {
        toast.error("Error while deleting task!");
      }
      setOpenWithHeader(false);
      setAction("");
    }
    if (name === "activity" && id) {
      // try {
      //   const response = await deleteTask(id);
      //   if (response && response.status) {
      //     toast.success(response?.data?.message);
      //     handleTaskFollowup(response?.data?.data);
      //     setTaskData({});
      //   }
      // } catch (err) {
      //   toast.error("Error while deleting task!");
      // }
      setOpenWithHeader(false);
      setAction("");
    }
  };

  
  const handleTaskFollowup = useCallback((responseData?: any) => {
    if (responseData) {
      const tempArray = [...StateData?.leadTask?.leadTask];
      if (action === "addTask") {
        tempArray?.unshift(responseData?.data);
        setLeadData(responseData?.lead);
      } else {
        const tempData = tempArray.findIndex((x) => x._id == responseData.data._id);
        tempArray.splice(tempData, 1, responseData.data);
      }
      dispatch(setLeadTaskList(tempArray));
    }
  }, [action, StateData?.leadTask?.leadTask, dispatch]);

  const handleCopyLead = async (leadId: string) => {
    try {
      const data = {
        leadIDs: [leadData._id],
        targetListId: leadId || null,
      };

      if (activeLeadOption == "copy") {
        const response = await copyLeadInList(data);
        toast.success(response?.data?.message);
      } else {
        const response = await moveLeadInList(data);
        toast.success(response?.data?.message);
      }
      setCopyLeadDrawer(false);
    } catch (err) {
      Logger.log();
    }
  };

  const handleAssignLead = async (leadId: string) => {
    try {
      const data = {
        leadIds: [leadData._id],
        assignToUser: leadId,
      };
      const response = await assignLeadInList(data);
      toast.success(response?.data?.message);
      setAssignLeadDrawer(false);
    } catch (err) {}
  };

  const handleGetLeadList = async () => {
    try {
      const response = await getAllLeadList({
        isAscending: false,
        page: 1,
        perPage: 50,
      });
      if (response && response.status) {
        let defaultLead = {
          name: "Default lead list",
          _id: "",
        };
        setLeadListData([defaultLead, ...response.data.data]);
      }
    } catch (err) {
      Logger.log(err, "Error");
    }
  };

  useEffect(() => {
    handleGetLeadList();
  }, []);
  const drawerTitle =
    action === "addTask"
      ? "Add Task"
      : action === "editTask"
      ? "Edit Task"
      : action === "addActivity"
      ? "Add Activity"
      : "Edit Activity";

  // const onScrollLeadMore = () => {
  //   // 20 more records in .5 secs
  //   // setTimeout(() => {
  //   //   setActivityData(StateData.concat(Array.from({ length: 3 })));
  //   // }, 500);
  // };
  // phoneNumber.substring(phoneNumber.length - 10)

  const validateNumber = (number: string) => {
    if (number?.length < 11) {
      return `91${number}`;
    } else {
      return `${number}`;
    }
  };
  //  Share lead
  const shareContent = async (data: any, share: string) => {
    if (data.type == "file" || data.type == "page") {
      try {
        const body = {
          contentId: data._id,
          leadIds: [_id],
          performedAt: createdAt,
          contentType: data.type,
        };
        const response = await shareContentLead(body);
        const message = `Hi ${name || ""},

          Here's the link to view new one:
          ${response.data.data.uniqueLink}`;
        if (share == "whatsapp") {
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
            validateNumber(selectedLeadContactDetailsGenerator?._number)
          )}&text=${encodeURIComponent(message)}`;
          window.open(whatsappUrl, "_blank");
        } else {
          const subject = `Hi ${name || ""}, ${
            StateData?.userData?.userDetails?.firstName
          } ${StateData?.userData?.userDetails?.lastName} has shared ${
            data.type
          } with you`;
          const emailUrl = `mailto:${
            selectedLeadContactDetailsGenerator?.mail
          }?subject=${subject}&body=${encodeURIComponent(message)}`;
          window.open(emailUrl, "_blank");
        }
      } catch (err) {
        Logger.log();
      }
    } else {
      try {
        const body = {
          type: "share",
          // contentId: data._id,
          leadIds: [_id],
          performedAt: createdAt,
          // contentType: data.type
        };

        const response = await createActivity(body);
        Logger.log("response ======>", response.data.data.uniqueLink);

        const message = (
          data.details?.description || data.details?.message
        )?.replace(/@lead name/g, name || "");
        if (share == "whatsapp") {
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
            validateNumber(selectedLeadContactDetailsGenerator?._number)
          )}&text=${encodeURIComponent(message)}`;
          window.open(whatsappUrl, "_blank");
        } else {
          const subject = `Hi ${name || ""}, ${
            StateData?.userData?.userDetails?.firstName
          } ${StateData?.userData?.userDetails?.lastName} has shared ${
            data.type
          } with you`;
          const emailUrl = `mailto:${
            selectedLeadContactDetailsGenerator?.mail
          }?subject=${subject}&body=${encodeURIComponent(message)}`;
          window.open(emailUrl, "_blank");
        }
      } catch (err) {
        Logger.log();
      }
    }
  };

  const handleDeleteLead = async () => {
    try {
      const response = await deleteBulkLeads({
        leadIDs: [_id],
      });
      if (response && response.status) {
        toast.success(response?.data?.message);
        onDeleteLead && onDeleteLead();
      }
    } catch (err) {
      Logger.log(err, "Error");
    }
  };

 /**
 * Send the current lead to all mobile devices
 */
const handleSendToMobile = async () => {
  try {
    // Use the enhanced function to send to all devices
    const response = await sendLeadToAllDevices(_id, name);
    
    if (response && response.status) {
      toast.success("Lead sent to all mobile devices");
    }
  } catch (err) {
    console.error("Error sending lead to mobile:", err);
    toast.error("Failed to send lead to mobile devices");
  }
};
  
  const fetchContent = async () => {
    const data = {
      isAscending: false,
      page: 1,
      perPage: 500,
    };
    const response = await getAllContent(data);
    if (response && response.status) {
      setContentData(response?.data?.data);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const getEmployeeInfoById = useCallback((id: string) => {
    const info = StateData?.userData?.userDetails?.organizationEmployee?.find(
      (e: any) => e._id === id
    );
    return `${info?.firstName ?? "-"}  ${info?.lastName ?? ""}` || "-";
  }, [StateData?.userData?.userDetails]);
  
  const getListInfoById = useCallback((id: string) => {
    const info = StateData?.userData?.userDetails?.organizationLeadList?.find(
      (e: any) => e._id === id
    );
    return info?.name || "-";
  }, [StateData?.userData?.userDetails]);

  const isDeleteable = useMemo(() => 
    StateData?.userData?.userDetails?.userPreference?.screenPermission?.includes(
      "lead_profile_screen > options_modal > delete"
    ),
    [StateData?.userData?.userDetails]
  );
    return (
      <div className="row" style={{ height: "100%" }}>
        <div className="col-md-4" style={{ paddingRight: "0" }}>
          <div className="popup-section-4-left" style={{ padding: "0px", height: "100%" }}>
          <LeadOptionsMenu 
  leadId={_id}
  onCopyToList={() => {
    setCopyLeadDrawer(true);
    setActiveLeadOption("copy");
  }}
  onAssignLead={() => {
    setAssignLeadDrawer(true);
  }}
  onMoveToList={() => {
    setCopyLeadDrawer(true);
    setActiveLeadOption("move");
  }}
  onShare={() => {
    setShareLeadDrawer(true);
  }}
  onSendToMobile={handleSendToMobile}
  onDelete={!isDeleteable ? () => setDeleteModal(true) : undefined}
  isDeleteAllowed={!isDeleteable}
/>
        

          <div style={{ padding: "10px" }}>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <h3
                className="text-capitalize text-black"
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                {name || "No Name"}
              </h3>
              {saleValue && <h5 className="text-black">₹ {saleValue}</h5>}
              {/* <div className="dropdown">
                            <button className="btn btn-sm btn-secondary ms-2 pt-1 pb-0 pe-2 ps-2 mt-1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><p className="dropdown-item">Assign Lead</p></li>
                                <li><p className="dropdown-item" onClick={() => copyLeadToList()}>Copy Lead to List</p></li>
                                <li><p className="dropdown-item" onClick={() => moveLeadToList()}>Move Lead to List</p></li>
                                <li><p className="dropdown-item" onClick={() => deleteLeadFromDrawer(_id)}>Delete Lead</p></li>
                            </ul>
                        </div> */}
            </div>
            <LeadActionIcons
  phone={selectedLeadContactDetailsGenerator?._number}
  email={selectedLeadContactDetailsGenerator?.mail}
  onFileClick={() => setOpenFileDrawer(true)}
  onEditClick={(e) => editLead(e, _id)}
  onFormResponseClick={() => getAllCustomFormResponse()}
  hasCustomForms={leadData?.customForms?.length > 0}
  leadId={_id}
  leadName={name} // Add this line to pass the lead name
/>
<DrawerComponent
  openWithHeader={openFileDrawer}
  setOpenWithHeader={handleSafeClose} 
  drawerTitle={`Files ${StateData?.leadFile?.leadFile?.length}`}
  size="sm"
>
  <FileUpload
    onLeadFileUpload={onLeadFileUpload}
    StateData={StateData}
    leadFileDelete={leadFileDelete}
    leadId={_id}
  />
</DrawerComponent>


            <ul className="popup-section-4-left-2">
              {selectedLeadData?.phone && (
                <li>
                  <span>Phone</span>
                  <br />
                  {selectedLeadData?.phone}
                </li>
              )}

              {selectedLeadData?.email && (
                <li>
                  <span>Email</span>
                  <br />
                  {selectedLeadData?.email}
                </li>
              )}

{status?.length > 0 && (
  <li 
    className="d-flex flex-column cursor-pointer p-2 rounded-md transition-colors active:bg-gray-200"
    onClick={() => {
      onStatusClick();
      setFrom("details");
    }}
  >
    <div className="d-flex justify-content-between align-items-center mb-1">
      <span>Status</span>
      <Edit size={14} className="text-gray-600" />
    </div>

    {!_.isEmpty(_id) && (
      <LeadStatus
        status={status}
        preferences={{
          status: [],
        }}
        isTag={true}
      />
    )}
  </li>
)}

<li 
  className="d-flex flex-column cursor-pointer p-2 rounded-md transition-colors active:bg-gray-200"
  onClick={() => {
    onLabelClick();
    setFrom("details");
  }}
>
  <div className="d-flex justify-content-between align-items-center mb-1">
    <span>Label</span>
    <Edit size={14} className="text-gray-600" />
  </div>
  {!_.isEmpty(_id) && (
    <LeadLabel
      label={label}
      preferences={{
        labels: [],
      }}
      isTag={true}
      showAll={true}
    />
  )}
</li>
              {/* <li><span>Sale Value</span><br />&#8377; {saleValue}</li>
             
                        {website ? <li><span>Webiste</span><br />{website}</li> : ''} */}
              <li>
                <span>Date added </span>
                <br />
                {createdAt && moment(createdAt).format("DD MMM YYYY")}
              </li>

              <li>
                <span>Lead Source</span>
                <br />
                {customSource !== ""
                  ? _.capitalize(customSource?.split("_")?.join(" "))
                  : _.capitalize(integration)}
              </li>

              <li>
                <span>Created by</span>
                <br />
                {getEmployeeInfoById(leadData?.createdBy)}
              </li>

              <li>
                <span>Assigned to</span>
                <br />
                {getEmployeeInfoById(leadData?.assignedTo)}
              </li>

              {leadData?.list ? (
                <li>
                  <span>List</span>
                  <br />
                  {getListInfoById(leadData?.list)}
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div
          className="popup-section-4-right"
          style={{ padding: "14px 25px", height: "100%" }}
        >
          <ul
            className="nav nav-pills d-flex gap-2"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link active"
                id="pills-activites-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-activites"
                type="button"
                role="tab"
                aria-controls="pills-activites"
                aria-selected="true"
              >
                Activities ({leadData?.totalActivities || 0})
              </button>
            </li>
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link"
                id="pills-task-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-task"
                type="button"
                role="tab"
                aria-controls="pills-task"
                aria-selected="false"
              >
                Tasks ({leadData?.totalTasks || 0})
              </button>
            </li>
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link"
                id="pills-notes-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-notes"
                type="button"
                role="tab"
                aria-controls="pills-notes"
                aria-selected="false"
              >
                Notes ({leadData?.totalNotes || leadData?.totalNote || 0})
              </button>
            </li>
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link"
                id="pills-Info-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Info"
                type="button"
                role="tab"
                aria-controls="pills-Info"
                aria-selected="false"
              >
                Info
              </button>
            </li>
          </ul>
          <div className="tab-content pt-2" id="myTabContent">
          <div
  className="tab-pane fade show active"
  id="pills-activites"
  role="tabpanel"
  aria-labelledby="activites-tab"
>
  <ActivityTab
    leadId={_id}
    totalActivities={leadData?.totalActivities || 0}
    onAddActivity={() => {
      setAction("addActivity");
      setOpenWithHeader(true);
    }}
    onEditActivity={(activityId, action) => {
      handleEditActivityModal('activity', activityId, action);
    }}
  />
</div>
            <div
              className="tab-pane fade"
              id="pills-task"
              role="tabpanel"
              aria-labelledby="task-tab"
            >
              <div className="row popup-section-4-add-11">
                <button
                  className="btn align-items-center"
                  onClick={() => {
                    setAction("addTask");
                    setOpenWithHeader(true);
                  }}
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 16.2C5.3105 16.2 1.9 12.969 1.9 9C1.9 5.031 5.3105 1.8 9.5 1.8C13.6895 1.8 17.1 5.031 17.1 9C17.1 12.969 13.6895 16.2 9.5 16.2ZM9.5 0C8.25244 0 7.0171 0.232792 5.86451 0.685084C4.71191 1.13738 3.66464 1.80031 2.78249 2.63604C1.00089 4.32387 0 6.61305 0 9C0 11.3869 1.00089 13.6761 2.78249 15.364C3.66464 16.1997 4.71191 16.8626 5.86451 17.3149C7.0171 17.7672 8.25244 18 9.5 18C12.0196 18 14.4359 17.0518 16.2175 15.364C17.9991 13.6761 19 11.3869 19 9C19 7.8181 18.7543 6.64778 18.2769 5.55585C17.7994 4.46392 17.0997 3.47177 16.2175 2.63604C15.3354 1.80031 14.2881 1.13738 13.1355 0.685084C11.9829 0.232792 10.7476 0 9.5 0ZM10.45 4.5H8.55V8.1H4.75V9.9H8.55V13.5H10.45V9.9H14.25V8.1H10.45V4.5Z"
                      fill="white"
                    />
                  </svg>
                  Add Task
                </button>
              </div>
              <TaskDrawer
  openWithHeader={openWithHeader}
  setOpenWithHeader={setOpenWithHeader}
  onTaskDelete={toggleConfirmationModal}
  showDelete={action.includes("edit")}
  drawerTitle={drawerTitle}
  size="xs"
>
             {(action === "addTask" || action === "editTask") && (
  <TaskForm
    leadId={_id} // This is now required
    updateTaskValue={taskData}
    action={action === "addTask" ? "add" : "edit"}
    status={""}
    
    drawerClose={() => {
      setOpenWithHeader(false);
      setAction("");
    }}
    fetchTaskStatusFollowUps={handleTaskFollowup}
  />
)}

                {(action === "addActivity" || action === "editActivity") && (
                  <CreateActivityForm
                    leadIds={[{ id: _id }]}
                    updateModalValue={activityData}
                    action={action === "addActivity" ? "Add" : "Edit"}
                    formName={"Activity"}
                    handleActivityFormSubmit={(data) => {
                      setOpenWithHeader(false);
                      setAction("");
                      if (data) {
                        setLeadData(data);
                      }
                    }}
                  />
                )}
                {(action === "addNotes" || action === "editNotes") && (
                  <CreateNoteForm
                    leadIds={[{ id: _id }]}
                    handleNotesSubmit={(data) => {
                      setOpenWithHeader(false);
                      setAction("");

                      if (data) {
                        setLeadData(data);
                      }
                    }}
                  />
                )}
              </TaskDrawer>
              <div
                className={
                  StateData?.leadTask?.leadTask?.length > 3
                    ? "row activity-scroll"
                    : "row"
                }
              >
                {StateData?.leadTask?.leadTask?.length > 0 ? (
                  StateData?.leadTask?.leadTask?.map((task: any, i: number) => (
                    <NewTaskItem
                      activity={task}
                      onEditTask={(info) => {
                        setAction("editTask");
                        setTaskData(info);
                        setOpenWithHeader(true);
                      }}
                    />
                  ))
                ) : (
                  <EmptyState type="tasks" />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-notes"
              role="tabpanel"
              aria-labelledby="notes-tab"
            >
              <div className="row popup-section-4-add-11">
                <button
                  className="btn align-items-center"
                  data-bs-toggle="modal"
                  onClick={() => {
                    setAction("addNotes");
                    setOpenWithHeader(true);
                  }}
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 16.2C5.3105 16.2 1.9 12.969 1.9 9C1.9 5.031 5.3105 1.8 9.5 1.8C13.6895 1.8 17.1 5.031 17.1 9C17.1 12.969 13.6895 16.2 9.5 16.2ZM9.5 0C8.25244 0 7.0171 0.232792 5.86451 0.685084C4.71191 1.13738 3.66464 1.80031 2.78249 2.63604C1.00089 4.32387 0 6.61305 0 9C0 11.3869 1.00089 13.6761 2.78249 15.364C3.66464 16.1997 4.71191 16.8626 5.86451 17.3149C7.0171 17.7672 8.25244 18 9.5 18C12.0196 18 14.4359 17.0518 16.2175 15.364C17.9991 13.6761 19 11.3869 19 9C19 7.8181 18.7543 6.64778 18.2769 5.55585C17.7994 4.46392 17.0997 3.47177 16.2175 2.63604C15.3354 1.80031 14.2881 1.13738 13.1355 0.685084C11.9829 0.232792 10.7476 0 9.5 0ZM10.45 4.5H8.55V8.1H4.75V9.9H8.55V13.5H10.45V9.9H14.25V8.1H10.45V4.5Z"
                      fill="white"
                    />
                  </svg>
                  Add Notes
                </button>
              </div>
              <div
                className={
                  StateData?.note?.notes?.length > 3
                    ? "row activity-scroll"
                    : "row"
                }
              >
                {StateData?.note?.notes?.length > 0 ? (
                  StateData?.note?.notes?.map((activity: any, i: number) => (
                    <LeadNoteItems activity={activity} key={i} />
                  ))
                ) : (
                  <EmptyState type="notes" />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-Info"
              role="tabpanel"
              aria-labelledby="Info-tab"
            >
              <div className="d-flex justify-content-between align-items-center">
                {/* <h4 className=" mt-2">Lead Information</h4>
                <div>
                  <Button
                    size="small"
                    onClick={(e) =>
                      setDisableLeadInfo((prevState) => !prevState)
                    }
                  >
                    {disableLeadInfo ? (
                      <MdEdit title="Edit Changes" size={20} />
                    ) : (
                      <MdSave
                        onClick={() =>
                          (createLeadFormRef?.current as any)?.handleSubmit()
                        }
                        title="Save Changes"
                        size={20}
                      />
                    )}
                  </Button>
                </div> */}
              </div>
              {disableLeadInfo ? (
                <Information
                  leadToBeUpdate={_id}
                  onEdit={() => setDisableLeadInfo((prevState) => !prevState)}
                />
              ) : (
                <CreateLeadForm
                  isDetailsBox={true}
                  isEditMode={!disableLeadInfo}
                  disabled={disableLeadInfo}
                  showCreateNewLeadDrawer={() => {}}
                  getLeads={() => refreshData && refreshData()}
                  leadToBeUpdate={_id}
                  handleDrawerClick={() => {}}
                  selectedLeadId={leadListId || "0"}
                  leadList={StoreData.leadList.leadList}
                  ref={createLeadFormRef}
                  onClose={() => setDisableLeadInfo((prevState) => !prevState)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        message="Are you sure you want to delete this task?"
        onConfirmation={handleDelete}
        showModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        title={action === "editTask" ? "Task" : "Activity"}
      />
      {viewFormResponse && (
        <FormResponse
          open={viewFormResponse}
          handleClose={() => {
            setViewFormResponse(false);
          }}
          formDetails={formData}
          formFields={{}}
        />
      )}
      {/* Copy Lead */}
      <GlobalDrawer
        openWithHeader={copyLeadDrawer}
        setOpenWithHeader={() => {
          setCopyLeadDrawer(false);
        }}
        drawerTitle={activeLeadOption === "copy" ? "Copy Lead" : "Move Lead"}
        size="xs"
        customHeader
      >
        <label className="form-label">Select Destination lead list </label>
        <div>
          {leadListData?.length > 0
            ? leadListData?.map((item: any, ix: number) => {
                if (item._id != leadData.list) {
                  return (
                    <button
                      onClick={() => {
                        handleCopyLead(item._id);
                      }}
                      key={ix}
                      className="d-block bg-white w-100 p-3 rounded mb-2"
                      style={{ margin: "8px 0px", textAlign: "left" }}
                    >
                      {item.name}
                    </button>
                  );
                } else {
                }
              })
            : "No Record Found"}
        </div>
      </GlobalDrawer>

      {/* Assign Lead */}
      <GlobalDrawer
        openWithHeader={assignLeadDrawer}
        setOpenWithHeader={() => {
          setAssignLeadDrawer(false);
        }}
        drawerTitle={"Assign Lead"}
        size="xs"
        customHeader
      >
        <label className="form-label">Select team member</label>
        <div>
          <button
            onClick={() =>
              handleAssignLead(StateData?.userData?.userDetails?._id)
            }
            className="d-block bg-white w-100 p-3 rounded mb-2"
            style={{ margin: "8px 0px", textAlign: "left" }}
          >
            Assign to self
          </button>
          {teamMembers?.map((item: any, ix: number) => {
            return (
              <button
                onClick={() => handleAssignLead(item.value)}
                className="d-block bg-white w-100 p-3 rounded mb-2"
                style={{ margin: "8px 0px", textAlign: "left" }}
              >
                {item?.label?.replace("null", "")}
              </button>
            );
          })}
        </div>
      </GlobalDrawer>

      {/* Share Lead Box */}

      <GlobalDrawer
        openWithHeader={shareLeadDrawer}
        setOpenWithHeader={() => {
          setShareLeadDrawer(false);
        }}
        drawerTitle={"Share Content"}
        size="xs"
        customHeader
      >
        <div className="share_lead_box">
          <label className="form-label">Select Content Type</label>
          <select
            className="form-control mb-4"
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
          >
            <option value="message"> Message</option>
            <option value="email_template"> Email</option>
            <option value="page"> Page</option>
            <option value="file"> File</option>
          </select>
          <div>
            {contentData
              .filter((i: any) => i.type == contentType)
              ?.map((item: any, ix: number) => {
                return (
                  <div className="share_card">
                    <button
                      onClick={() =>
                        setActiveLeadId(
                          activeLeadId == item._id ? "" : item._id
                        )
                      }
                      className="action_btn"
                    >
                      {item.details?.title || "Null"}
                      <IoIosArrowDown />
                    </button>
                    {activeLeadId == item._id && (
                      <div className="share_content">
                        <label className="form-label">Message : </label>
                        <p>
                          {item.details?.message?.replace(
                            /@lead name/g,
                            name || ""
                          ) ||
                            item.details?.description?.replace(
                              /@lead name/g,
                              name || ""
                            ) ||
                            ""}
                        </p>
                        <div className="share_buttons">
                          {/* <a href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(validateNumber(selectedLeadContactDetailsGenerator?._number))}&text=${encodeURIComponent(item.details?.description)}`}
                          target="_blank">
                          Share via Whatsapp <FaWhatsapp />
                        </a>
                        <a href={`mailto:${selectedLeadContactDetailsGenerator?.mail}?subject=This%20Subject&body=${encodeURIComponent(item.details?.description)}`}
                        > Share via Email <MdOutlineMail /></a> */}

                          {selectedLeadContactDetailsGenerator?._number
                            ?.length > 4 && (
                            <button
                              onClick={() => shareContent(item, "whatsapp")}
                            >
                              Share via Whatsapp <FaWhatsapp />
                            </button>
                          )}
                          {selectedLeadContactDetailsGenerator?.mail &&
                            selectedLeadContactDetailsGenerator?.mail?.length >
                              8 && (
                              <button
                                onClick={() => shareContent(item, "email")}
                              >
                                Share via Email <MdOutlineMail />
                              </button>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <DynamicConfirmationModal
            onConfirmation={() => {
              setDeleteModal(false);

              handleDeleteLead();

              // setOpenWithHeader(false);
            }}
            showModal={deleteModal}
            toggleModal={() => {
              setDeleteModal(false);
            }}
            title="Are you sure you want delete?"
          />
        </div>
      </GlobalDrawer>
    </div>
  );
};

export default LeadDetails;
