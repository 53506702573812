import { ColDef, GridApi } from "ag-grid-community";
import _, { isEqual } from "lodash";
import LeadExportService from "../../../services/LeadExportService";

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useCallback  
} from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setLeadCount,
  setLeadFilter,
  setLeadIds,
  userPreferences,
} from "../../../actions/actions";
import {
  assignLeadInList,
  copyLeadInList,
  deleteBulkLeads,
  filterLeads,
  getAllLeads,
  moveLeadInList,
  putUserPreferences,
} from "../../../services/leadService";
import { areAllValuesUndefined } from "../../../utils/helpers";
import AgGrid, { IFetchRowsParams } from "../../common/grid/AgGrid";
import { CustomFieldsT } from "../customLeadForm";
import {
  assignedToRenderer,
  dateRenderer,
  labelRenderer,
  lastActivityRenderer,
  locationRenderer,
  nameRenderer,
  dateTimeRenderer,
  phoneRenderer,
  sourceRenderer,
  statusRenderer,
  saleValueRenderer, 
  campaignNameRenderer,
  formNameRenderer,
  phoneRendererTest ,
} from "./leads-renderers";
import { FilterParams, advanceFilterKeys } from "./leads.types";
import ContactLinksGenerator from "../../../utils/contactlinksgenerator";
import TaskDrawer from "../../common/TaskDrawer";
import CreateTaskForm from "../../../components/createTaskForm";
import CreateNoteForm from "../../../components/createNoteForm";
import { MdAddTask } from "react-icons/md";
import { MdOutlineNoteAdd } from "react-icons/md";
import BulkSelector from "../../../components/BulkSelector/bulkSelector";
import BulkLeadOptions from "../../../components/BulkLeadOptions/bulkLeadOptions";
import { useNavigate } from "react-router-dom";
import { setManuallyAutomate } from "../../../services/automationService";
import { Logger } from '../../../utils/logger';
import SelectLeadStatus from "../../../components/status";
import SelectLeadLabel from "../../../components/label";




export interface PreferenceI {
  status: Array<any>;
  labels: Array<any>;
  leadGridPreferences: {
    columnOrders: string[];
    columnWidths: { [colId: string]: number };
  };
  customForm: Array<CustomFieldsT>;
}

export interface ILeadsGridProps {
  handleLeadClick: (rowData: any) => void;
  leadListData: Array<any>;
  setLeadListData: React.Dispatch<React.SetStateAction<any[]>>;
  onEditStatus: () => void;
  onEditLabel: (data?: any) => void; // Make data parameter optional with ?
  selectedItemCount: number;
  setSelectedItemCount: any;
  showBulk: boolean;
  onResetBulk: () => void;
  StoreData?: any; // Add access to StoreData
}

const LeadsGrid = forwardRef(
  (
    {
      handleLeadClick,
      leadListData,
      setLeadListData,
      onEditStatus,
      onEditLabel,
      selectedItemCount,
      setSelectedItemCount,
      showBulk,
      onResetBulk,
    }: ILeadsGridProps,
    ref
  ) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [resetGrid, setResetGrid] = useState<number>(0);
    const [openWithHeader, setOpenWithHeader] = useState<boolean>(false);
    // const [selectedItemCount, setSelectedItemCount] = useState<number>(0);

    const actionColumnClicked = useRef(''); // Store which column was clicked
    const [statusEditLead, setStatusEditLead] = useState<any>(null);
    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const [showStatusPopup, setShowStatusPopup] = useState<boolean>(false);
    const [labelEditLead, setLabelEditLead] = useState<any>(null);
const [selectedLabel, setSelectedLabel] = useState<Array<string>>([]);
const [showLabelPopup, setShowLabelPopup] = useState<boolean>(false);

    // In leads-grid.tsx componentDidMount or useEffect
useEffect(() => {
  Logger.log('Grid Config:', {
    hasColumnDefs: !!columnDefs?.length,
    defaultColDef: defaultColumnDefs,
    rowSelection: gridApi.current?.getSelectedNodes()
  });
}, []);




    

    const { filterParam, leadCount } = useSelector(
      (state: any) => state.rootReducers.leads
    );
    const gridApi = useRef<GridApi | null>(null);
    const preferenceRef = useRef<PreferenceI | null>(null);
    const StoreData = useSelector((state: any) => {
      return state?.rootReducers;
    });

    const preferences: PreferenceI = useSelector(
      (state: { rootReducers: { user: { userPreferences: any } } }) =>
        state?.rootReducers?.user?.userPreferences
    );

    const debouncedColumnMove = useRef(
      _.debounce((newColumnOrder, preferences) => {
        onColumnOrderChanged &&
          onColumnOrderChanged(newColumnOrder, preferences);
      }, 500)
    ).current;

    const debouncedColumnWidth = useRef(
      _.debounce((columnId, newWidth, preferences) => {
        onColumnWidthChanged &&
          onColumnWidthChanged(columnId, newWidth, preferences);
      }, 500)
    ).current;

    const handleExportLeads = async () => {
      try {
        const selectedIds = getSelectedLeadIds();
        await LeadExportService.exportBulkLeads(selectedIds);
      } catch (error) {
        console.error('Export failed:', error);
      }
    };

 
   // Replace the handleStatusUpdate function with this improved version
const handleStatusUpdate = (updatedRows: any[]) => {
  Logger.log('Status update completed:', updatedRows);
  
  if (updatedRows && updatedRows.length > 0) {
    const updatedLead = updatedRows[0]; // Get the first updated lead
    
    // Log the updated lead for debugging
    Logger.log('Refreshing grid with updated lead:', {
      id: updatedLead._id,
      newStatus: updatedLead.status
    });
    
    if (gridApi.current) {
      // Method 1: Try to find and update the specific row
      const rowNode = gridApi.current.getRowNode(updatedLead._id);
      if (rowNode) {
        Logger.log('Found row node, updating directly');
        rowNode.setData(updatedLead);
        gridApi.current.refreshCells({ rowNodes: [rowNode], force: true });
      } else {
        // Method 2: If row node not found, do a more aggressive refresh
        Logger.log('Row node not found, doing full data refresh');
        
        // Get all current data
        const allData: any[] = [];
        gridApi.current.forEachNode(node => {
          if (node.data) allData.push(node.data);
        });
        
        // Update the specific item in the data
        const index = allData.findIndex(item => item._id === updatedLead._id);
        if (index >= 0) {
          allData[index] = updatedLead;
          
          // Set the updated data back to the grid
          gridApi.current.setRowData(allData);
        } else {
          // Method 3: If all else fails, just do a full fetch
          Logger.log('Lead not found in current data, fetching all leads');
          fetchLeadsWrapper();
        }
      }
    }
  }
  
  // Reset state
  setShowStatusPopup(false);
  setStatusEditLead(null);
  setSelectedStatus("");
};
    
    // Add this to handle status popup closing
    const toggleStatusPopup = () => {
      setShowStatusPopup(false);
      setStatusEditLead(null);
      setSelectedStatus("");
    };
  

    const handleLabelUpdate = (updatedRows: any[]) => {
      Logger.log('Label update completed:', updatedRows);
      
      if (updatedRows && updatedRows.length > 0) {
        // Refresh the grid with updated data
        if (gridApi.current) {
          gridApi.current.refreshCells({ force: true });
        }
      }
      
      // Reset state
      setShowLabelPopup(false);
      setLabelEditLead(null);
      setSelectedLabel([]);
    };
    
    // Add this to handle label popup closing
    const toggleLabelPopup = () => {
      setShowLabelPopup(false);
      setLabelEditLead(null);
      setSelectedLabel([]);
    };

const sanitizeFilterParams = (params: FilterParams): FilterParams => {
  const sanitized = {...params};
  
  Object.keys(sanitized).forEach(key => {
      const value = sanitized[key];
      if (value === undefined || value === null || value === '') {
          delete sanitized[key];
      } else if (Array.isArray(value) && value.length === 0) {
          delete sanitized[key];
      } else if (typeof value === 'object' && !Array.isArray(value) && 
                 Object.keys(value).length === 0) {
          delete sanitized[key];
      }
  });

  return sanitized;
};

    
    const defaultColumnDefs: ColDef[] = [
     
      {
        headerName: "Name",
        sortable: false,
        width: 150,
        field: "name",
        colId: "name",
        cellRenderer: nameRenderer,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: "Actions",
        sortable: false,
        width: 500,
        field: "phone",
        colId: "phone",  // This MUST match exactly what we check above
        cellRenderer: phoneRenderer,
        suppressNavigable: true
      },
      {
        headerName: "Status",
        sortable: false,
        width: 150,
        field: "status",
        colId: "status",
        cellRenderer: (params: any) => {
          // Here we're creating a new object for the renderer with the data it needs
          return statusRenderer({ 
            ...params, 
            preferences,
            // Pass direct function references rather than using context
            onStatusClick: (data: any) => {
              // Set up status edit for this specific row
              setStatusEditLead(data);
              setSelectedStatus(data?.status?.[0] || "");
              setShowStatusPopup(true);
            }
          });
        },
      },
      {
        headerName: "Created Date",
        sortable: false,
        width: 160,
        field: "createdAt",
        colId: "createdAt",
       
        cellRenderer: dateTimeRenderer, // Using the existing dateTimeRenderer
      },
      {
        headerName: "FB Campaign",
        sortable: false,
        width: 180,
        field: "extraDetails.campaign_name",
        colId: "campaign_name",
        cellRenderer: campaignNameRenderer,
      },
      {
        headerName: "FB Lead Form",
        sortable: false,
        width: 180,
        field: "extraDetails.formName",
        colId: "formName",
        cellRenderer: formNameRenderer,
      },
      {
        headerName: "Source",
        sortable: false,
        width: 180,
        field: "integration",
        colId: "integration",
        cellRenderer: sourceRenderer,
      },
      {
        headerName: "Last Activity",
        sortable: false,
        width: 250,
        field: "lastActivity",
        colId: "lastActivity",
        cellRenderer: lastActivityRenderer,
      },
      {
        headerName: "Assigned To",
        sortable: false,
        width: 200,
        field: "assignedTo",
        colId: "assignedTo",
        cellRenderer: assignedToRenderer,
      },
     
      {
        headerName: "Label",
        sortable: false,
        width: 220,
        field: "label",
        colId: "label",
        cellRenderer: (params: any) => {
          // Log the params to ensure the data is being passed correctly
          Logger.log('Label renderer params:', {
            paramsAvailable: !!params,
            hasData: !!params?.data,
            dataId: params?.data?._id
          });
          
          return labelRenderer({
            ...params, 
            preferences,
            onLabelClick: (data: any) => {
              Logger.log('Label cell clicked with data:', data?._id);
              // Set the lead data for label update
              setLabelEditLead(data);
              setSelectedLabel(data?.label || []);
              
              // Call the parent's onEditLabel with the data
              onEditLabel(data);
            }
          });
        },
      },
    ];

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(defaultColumnDefs);

   // Update the getCustomColumnDef function in leads-grid.tsx

const getCustomColumnDef = (form: any, columnWidths: any) => {
  let cellRenderer;
  if (form.type === "location") {
    cellRenderer = locationRenderer;
  } else if (form.type === "date") {
    cellRenderer = dateRenderer;
  } else if (form.value === "saleValue") {
    cellRenderer = saleValueRenderer;
  } else if (form.value === "campaign_name") {
    cellRenderer = campaignNameRenderer;
  } else if (form.value === "formName") {
    cellRenderer = formNameRenderer;
  }
  
  // Determine the correct field path
  let field;
  if (["saleValue", "email"].includes(form.value)) {
    field = form.value;
  } else if (["campaign_name", "formName"].includes(form.value)) {
    field = `extraDetails.${form.value}`;
  } else {
    field = `extraDetails.${form.value}`;
  }
  
  return {
    headerName: form.label,
    sortable: false,
    width: columnWidths[form.value] || 150,
    field: field,
    colId: form.value,
    cellRenderer,
  };
};

    
    const fetchLeadListOnScroll = (
      params: IFetchRowsParams,
      leadName?: string,
      search?: string
    ) => {
      console.group('📜 Fetch on Scroll');
      Logger.log('Scroll params:', {
        params,
        leadName,
        search,
        currentFilter: filterParam
      });
    
      let updatedFilterParams: Partial<FilterParams> = {
        sort: { orderBy: params.orderBy, isAscending: params.isAscending },
        ...filterParam,
        paginationParams: { perPage: params.perPage, page: params.page },
      };
    
      Logger.log('Updated filter params:', updatedFilterParams);
      dispatch(setLeadFilter(updatedFilterParams));
    
      let lead_name = leadName || StoreData.leadName.leadName.id;
      Logger.log('Using lead name:', lead_name);
      console.groupEnd();
    
      return fetchLeads(
        updatedFilterParams,
        lead_name !== "0" ? lead_name : undefined
      );
    };

    const handleRowClick = (params: any) => {
      // Detailed debugging log
      console.group('Row Click Debugging');
      Logger.log('Raw Params:', JSON.parse(JSON.stringify(params)));
      Logger.log('Params Type:', typeof params);
      Logger.log('Params Keys:', Object.keys(params));
      Logger.log('Data Property:', params.data);
      Logger.log('Is Data Undefined:', params.data === undefined);
      Logger.log('Stringified Data:', JSON.stringify(params.data));
      
      // Check our flag - if any protected column was clicked, skip processing
      console.log('🔍 Action column flag:', actionColumnClicked.current);
      if (actionColumnClicked.current) {
        console.log(`🛑 Skipping row click - ${actionColumnClicked.current} column was clicked`);
        actionColumnClicked.current = ''; // Reset for next time
        console.groupEnd();
        return;
      }
      
      console.groupEnd();
    
      // Extract data with your existing approach  
      const rowData = 
        params?.data || 
        params?.node?.data || 
        (typeof params === 'object' && !Array.isArray(params) ? params : null);
      
      // Comprehensive logging
      Logger.log('Extracted Row Data:', rowData);
      
      // Robust data extraction and validation
      if (!rowData) {
        Logger.warn('No valid row data could be extracted', {
          originalParams: params,
          extractionAttempts: [
            params?.data,
            params?.node?.data,
            typeof params === 'object' ? params : null
          ]
        });
        return;
      }
      
      // Ensure handleLeadClick is a function before calling
      if (typeof handleLeadClick !== 'function') {
        console.error('handleLeadClick is not a valid function');
        return;
      }
      
      try {
        handleLeadClick(rowData);
      } catch (error) {
        console.error('Error in handleLeadClick:', error);
      }
    };

    const comparePreferencesAreEqual = (newPref: any, oldPref: any) => {
      return isEqual(newPref, oldPref);
    };

    
    const onColumnOrderChanged = (
      orders: string[],
      preferences: PreferenceI
    ) => {
      const uniqueOrders = [...new Set(orders)];
      const leadGridPreferences = {
        ...preferences.leadGridPreferences,
        columnOrders: uniqueOrders,
      };
      if (
        !comparePreferencesAreEqual(
          leadGridPreferences,
          preferences.leadGridPreferences
        )
      ) {
        dispatch(
          userPreferences({
            ...preferences,
            leadGridPreferences,
          })
        );
        handleAddGridPreferences(leadGridPreferences);
      }
    };

    const onColumnWidthChanged = (
      colId: string,
      newWidth: number,
      preferences: PreferenceI
    ) => {
      const updatedColumnWidths = {
        ...(preferences.leadGridPreferences?.columnWidths || {}),
        [colId]: newWidth,
      };

      const leadGridPreferences = {
        ...preferences.leadGridPreferences,
        columnWidths: updatedColumnWidths,
      };

      if (
        !comparePreferencesAreEqual(
          leadGridPreferences,
          preferences.leadGridPreferences
        )
      ) {
        dispatch(
          userPreferences({
            ...preferences,
            leadGridPreferences,
          })
        );
        handleAddGridPreferences(leadGridPreferences);
      }
    };

    const handleAddGridPreferences = async (data: any) => {
      try {
        const response = await putUserPreferences({
          leadGridPreferences: data,
        });
        if (response && response.status) {
        }
      } catch (err: any) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Error while updating Source!");
        }
      }
    };

    const handleLabelClick = (data: any) => {
      if (!data || !data._id) {
        Logger.warn('No valid data for label click');
        return;
      }
      
      Logger.log('Label cell clicked with data:', data?._id);
      
      // Set the lead data for label update
      setLabelEditLead(data);
      setSelectedLabel(data?.label || []);
      
      // Trigger the onEditLabel callback
      onEditLabel(data);
    }

    const fetchLeadsWrapper = async (shouldPurge = false) => {
      console.group('📥 Fetch Wrapper');
      Logger.log('Fetch params:', {
        shouldPurge,
        filterParam,
        isLoading,
        hasGridApi: !!gridApi.current
      });
    
      if (isLoading) {
        Logger.log('🚫 Already loading, skipping fetch');
        console.groupEnd();
        return;
      }
    
      try {
        setIsLoading(true);
        Logger.log('Resetting grid data');
        setResetGrid(prev => prev + 1);
    
        if (gridApi.current) {
          Logger.log('Clearing current grid data');
          gridApi.current.setRowData([]);
        }
    
        // Get current lead name
        const leadName = StoreData.leadName.leadName.id;
        Logger.log('Starting fetch with:', { 
          leadName,
          filterParams: filterParam
        });
    
        await fetchLeads(filterParam, leadName);
    
      } catch (err) {
        console.error('❌ Error in fetch wrapper:', err);
      } finally {
        setIsLoading(false);
        console.groupEnd();
      }
    };

    
   

    
   // In LeadsGrid.tsx
const fetchLeads = async (filterParam: FilterParams, leadName: string) => {
  console.group('🔄 Fetch Leads');
  Logger.log('Request params:', {
    filterParam,
    leadName,
    hasSearch: !!filterParam.search,
    pagination: filterParam.paginationParams
  });

  try {
    setIsLoading(true);
    
    // Don't send empty search
    const cleanFilterParam = {...filterParam};
    if (!cleanFilterParam.search) {
      delete cleanFilterParam.search;
    }
    
    const response = await filterLeads({ 
      ...cleanFilterParam, 
      ...(leadName && leadName !== "0" ? { list: leadName } : {})
    });

    Logger.log('API Response:', {
      status: response?.status,
      totalRows: response?.data?.total,
      dataLength: response?.data?.data?.length
    });

    if (response?.status === 200) {
      let responseData = response.data;
      dispatch(setLeadCount(responseData.total));
      return { 
        data: responseData.data, 
        totalRows: responseData.total 
      };
    }

  } catch (err) {
    console.error('❌ Fetch error:', err);
  } finally {
    setIsLoading(false);
    console.groupEnd();
  }
};
    

    const getColunmmnDefs = () => {
      return columnDefs;
    };

  
    const refreshCurrentPageInGridFn = (updatedRows: any[]) => {
      Logger.log('Page refresh called with:', updatedRows?.length);
      
      if (!gridApi.current) {
        Logger.warn('Grid API not available');
        return;
      }
    
      // Force a clean refresh
      setResetGrid(prev => prev + 1);
    };
    
    const resetGridState = useCallback(() => {
      if (!gridApi.current) return;
      
      // Clear selection
      gridApi.current.deselectAll();
      
      // Reset data
      gridApi.current.setRowData([]);
      
      // Force refresh
      setResetGrid(prev => prev + 1);
    }, []);

    // Update the onGridReady function to add 'label' to the prevented columns
const onGridReady = (params: any) => {
  console.group('🎯 Grid Ready');
  Logger.log('Grid initialization:', {
    hasApi: !!params.api,
    rowCount: params.api?.getDisplayedRowCount(),
    currentFilter: filterParam
  });
  
  gridApi.current = params.api;
  
  // Updated cell clicked handler for multiple columns
  params.api.addEventListener('cellClicked', (event: any) => {
    // Add this detailed debug log
    console.log('📍 Cell clicked debug:', {
      colId: event.column.colId,
      exactColId: `"${event.column.colId}"`, // Shows exact string with quotes
      isLabel: event.column.colId === 'label',
      columnKeys: Object.keys(event.column),
      columnDef: event.column.getDefinition()
    });
    
    // Check for any columns that should prevent row selection
    if (['phone', 'status', 'label'].includes(event.column.colId)) {
      console.log(`🛑 ${event.column.colId} column clicked - setting flag`);
      actionColumnClicked.current = event.column.colId;
      event.node.setSelected(false);
    }
  });
  
  console.groupEnd();
};

    // Fix the column mapping in the useEffect in leads-grid.tsx

useEffect(() => {
  if (!preferences) return;
  
  preferenceRef.current = preferences;
  const columnOrders = preferences.leadGridPreferences?.columnOrders || [];
  const columnWidths = preferences.leadGridPreferences?.columnWidths || {};

  // Create lookup map using colId instead of field for better matching
  const defaultColumnMap = new Map(
    defaultColumnDefs.map(def => [def.colId, def])
  );

  let orderedColumnDefs;

  if (columnOrders.length === 0) {
    // No custom order, use default
    orderedColumnDefs = defaultColumnDefs.map(def => ({
      ...def,
      width: columnWidths[def.colId || ""] || def.width,
    }));
  } else {
    // Process ordered columns with caching
    orderedColumnDefs = columnOrders
      .map(colId => {
        // Check default columns first using colId
        let columnDef = defaultColumnMap.get(colId);
        
        // If not found, check custom forms
        if (!columnDef && preferences.customForm?.length) {
          const form = preferences.customForm.find(f => f.value === colId);
          if (form) {
            columnDef = getCustomColumnDef(form, columnWidths);
          }
        }

        if (columnDef) {
          return {
            ...columnDef,
            width: columnWidths[colId] || columnDef.width,
          };
        }
        return null;
      })
      .filter(Boolean);
  }

  // Add simple debugging to confirm columns are processed
  console.log("Column orders:", columnOrders);
  console.log("Default column IDs:", Array.from(defaultColumnMap.keys()));
 

  setColumnDefs(orderedColumnDefs as ColDef[]);
}, [preferences]);

    // Expose fetchLeadListOnScroll function using useImperativeHandle
    useImperativeHandle(ref, () => ({
      fetchLeadsWrapper,
      refreshCurrentPageInGridFn,
      getColunmmnDefs,
      getSelectedLeadIds,
      handleSelection,
      handleUnSelect,
      resetGridState,
    }));

    const [action, setAction] = useState<string>("");
    const [leadId, setLeadId] = useState<string>("");

    // Function to select the next set of items
    const selectNextItems = (
      startIndex: number,
      total: number,
      allNodes: any[]
    ) => {
      const endIndex = Math.min(startIndex + total, allNodes.length);

      // Select the next rows
      for (let i = startIndex; i <= endIndex; i++) {
        allNodes[i]?.setSelected(true);
      }

      // Scroll to the last selected row
      if (endIndex > startIndex) {
        gridApi?.current?.ensureIndexVisible(endIndex - 1, "bottom");
      }

      return endIndex;
    };

    function isNearby(num1: number, num2: number, tolerance = 3) {
      return Math.abs(num1 - num2) <= tolerance;
    }

    const handleSelection = (total: number) => {
      if (!gridApi?.current) return;

      // const selectedNodes: any = gridApi.current.getSelectedNodes();

      let currentPageRows = gridApi.current.getRenderedNodes();

      let startIndex = 0;

      currentPageRows.forEach((node: any, i: number) => {
        if (node.selected) {
          startIndex = i;
        }
      });

      // Determine the starting index
      // const startIndex =
      //   selectedNodes.length > 0
      //     ? selectedNodes[selectedNodes.length - 1].rowIndex + 1
      //     : 0;

      startIndex = startIndex === -1 ? 0 : startIndex;

      let isAllSelected = gridApi.current
        .getRenderedNodes()
        .every((node: any) => node.selected);

      if (
        isAllSelected ||
        isNearby(startIndex, gridApi.current.getRenderedNodes()?.length)
      ) {
        gridApi.current.paginationGoToNextPage();

        // Retry selection after the next page loads
        setTimeout(() => {
          const newNodes: any = [];
          gridApi?.current
            ?.getRenderedNodes()
            .map((node) => newNodes.push(node));
          selectNextItems(0, total, newNodes); // Start from the beginning of the new page
        }, 500); // Adjust timeout based on page load time

        return;
      }

      // Get all rendered nodes
      const allNodes: any = [];
      gridApi.current.getRenderedNodes()?.map((node) => allNodes.push(node));

      // Select the next set of rows
      const endIndex = selectNextItems(startIndex, total, allNodes);

      // debugger;

      // If there are no more rows on the current page, navigate to the next page
      if (
        startIndex >= endIndex &&
        gridApi?.current?.paginationGoToNextPage
        //  ||
        // endIndex === gridApi.current.getRenderedNodes()?.length
      ) {
        gridApi.current.paginationGoToNextPage();

        // Retry selection after the next page loads
        setTimeout(() => {
          const newNodes: any = [];
          gridApi?.current
            ?.getRenderedNodes()
            .map((node) => newNodes.push(node));
          selectNextItems(0, total, newNodes); // Start from the beginning of the new page
        }, 500); // Adjust timeout based on page load time
      }
    };

    const handleUnSelect = () => {
      if (!gridApi?.current) return;

      gridApi?.current.forEachNode((node) => {
        node.setSelected(false);
      });

      gridApi?.current?.deselectAll();

      setSelectedItemCount(0);
      gridApi?.current?.paginationGoToPage(0);
      onResetBulk();
    };

    const onRowSelected = (event: any) => {
      if (event.node.selected) {
        setSelectedItemCount((prev: number) => prev + 1);
      } else {
        setSelectedItemCount((prev: number) => (prev === 0 ? 0 : prev - 1));
      }
    };

    const getSelectedLeadIds = () => {
      const selectedNodes: any = gridApi?.current?.getSelectedNodes();

      return selectedNodes?.map((e: any) => e.data._id);
    };

    const onDeleteLead = async () => {
      try {
        const leadIDs = getSelectedLeadIds();
        const response = await deleteBulkLeads({ leadIDs });
        reloadLeads();
      } catch (error) {}
    };

    const handleAssignLead = async (assignToUser: string) => {
      try {
        const leadIds = getSelectedLeadIds();
        const { data } = await assignLeadInList({ leadIds, assignToUser });

        toast.success(data?.message);
        reloadLeads();
      } catch (error) {
        Logger.log("err", error);
      }
    };

    const handleCopyLead = async (
      targetListId: string,
      activeLeadOption: string
    ) => {
      try {
        const data = {
          leadIDs: getSelectedLeadIds(),
          targetListId,
        };

        if (activeLeadOption == "copy") {
          const response = await copyLeadInList(data);
          toast.success(response?.data?.message);
        } else {
          const response = await moveLeadInList(data);
          toast.success(response?.data?.message);
        }

        reloadLeads();
      } catch (err) {}
    };
    const onRowClicked = (event: any) => {
      Logger.log('Click Event Details:', {
        hasNode: !!event.node,
        rowIndex: event.rowIndex,
        dataPresent: !!event.data,
        rowID: event.node?.id,
        currentPage: gridApi.current?.paginationGetCurrentPage()
      });
      
      if (onRowClicked && event.data) {
        onRowClicked(event.data);
      }
    };
    
    const handleSelectAutomate = async (automationId: string) => {
      try {
        const data = {
          leadIds: getSelectedLeadIds(),
          automationId,
        };

        const response = await setManuallyAutomate(data);
        toast.success(response?.data?.message);

        reloadLeads();
      } catch (err) {}
    };

    const reloadLeads = () => {
      gridApi.current?.refreshCells({ force: true });
      fetchLeadsWrapper();
    };

    const onCreateCampaign = () => {
      const leadIds = getSelectedLeadIds();
      dispatch(setLeadIds(leadIds));
      navigate("/createWhatsappBroadcast");
      reloadLeads();
    };

    return (
      <>
        {/* Bulk selector - appears when items selected */}
        {(showBulk || selectedItemCount > 0) && (
          <div style={{
            marginTop: 0,
            position: "relative",
            top: "10px",
            marginBottom: 20,
          }}>
            <BulkSelector
              selected={selectedItemCount}
              onSelect={handleSelection}
              onReset={handleUnSelect}
            />
          </div>
        )}
    
      {/* Bulk action options */}
{selectedItemCount ? (
  <BulkLeadOptions
    onDeleteLead={onDeleteLead}
    handleAssignLead={handleAssignLead}
    handleCopyLead={handleCopyLead}
    onEditStatus={onEditStatus}
    onEditLabel={() => onEditLabel()} // Wrap in a function with no parameters
    onBulkTask={() => {
      setOpenWithHeader(true);
      setAction("addTask");
    }}
    onCreateCampaign={onCreateCampaign}
    handleSelectAutomate={handleSelectAutomate}
    onExportLeads={handleExportLeads}
  />
) : null}
    
        {/* Main grid container - scrollable */}
        <div style={{ 
      height: 'calc(100% - 48px)', // Account for pagination height
      width: '100%',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <div style={{
        height: '100%',
        width: '100%',
        overflow: 'auto'
      }}>
<AgGrid
  columnDefs={columnDefs}
  fetchData={fetchLeadListOnScroll}
  onRowClick={handleRowClick}
  defaultData={leadListData}
  totalRows={leadCount}
  isLoading={isLoading}
  onColumnOrderChanged={(newColumnOrder) =>
    debouncedColumnMove(newColumnOrder, preferenceRef.current)
  }
  onColumnWidthChanged={(columnId, newWidth) =>
    debouncedColumnWidth(columnId, newWidth, preferenceRef.current)
  }
  key={resetGrid}
  onGridReady={onGridReady}
  onRowSelected={onRowSelected}
  suppressPaginationPanel={true}
  pagination={true}
  paginationPageSize={50}
  
/>
</div>
</div>
    
       
       
    
        {/* Task drawer */}
        <TaskDrawer
          openWithHeader={openWithHeader}
          setOpenWithHeader={setOpenWithHeader}
          onTaskDelete={() => {}}
          showDelete={false}
          drawerTitle={action === "addTask" ? "Add Task" : "Add Note"}
          size="xs"
        >
          {action === "addTask" && (
            <CreateTaskForm
              leadId={getSelectedLeadIds()}
              updateTaskValue={{}}
              action={action === "addTask" ? "add" : "Add Note"}
              status={""}
              drawerClose={() => {
                setOpenWithHeader(false);
                setAction("");
                handleUnSelect();
              }}
              fetchTaskStatusFollowUps={() => {}}
            />
          )}
    
          {action === "addNotes" && (
            <CreateNoteForm
              leadIds={[{ id: leadId }]}
              handleNotesSubmit={() => {
                setOpenWithHeader(false);
                setAction("");
              }}
            />
          )}
        </TaskDrawer>
        {statusEditLead && (
  <SelectLeadStatus
    showStatusPopup={showStatusPopup}
    toggleLeadStatus={toggleStatusPopup}
    onStatusChange={(e) => setSelectedStatus(e.target.value)}
    selectedStatus={selectedStatus}
    setShowStatusPopup={setShowStatusPopup}
    from="grid"
    id={statusEditLead._id}
    leadList={StoreData?.leadName?.leadName?.id || "0"}
    getLeads={() => {}}
    setOpenWithHeader={() => {}} 
    updateEditedRow={handleStatusUpdate}
  />
)}

{labelEditLead && (
  <SelectLeadLabel
    showLabelPopup={showLabelPopup}
    toggleLeadLabel={toggleLabelPopup}
    onLabelChange={(e) => {
      if (!e?.target?.value) return;
      
      let newLabels = [...selectedLabel];
      const value = e.target.value;
      
      if (selectedLabel.includes(value)) {
        // Remove if already included
        newLabels = newLabels.filter(label => label !== value);
      } else {
        // Add if not included
        newLabels.push(value);
      }
      
      setSelectedLabel(newLabels);
    }}
    selectedLabel={selectedLabel}
    onLeadUpdate={(lead) => {}}
    setShowLabelPopup={setShowLabelPopup}
    from="grid"
    id={labelEditLead._id}
    leadList={StoreData?.leadName?.leadName?.id || "0"}
    getLeads={() => {}}
    setOpenWithHeader={() => {}} 
    LabelData={StoreData?.user?.userPreferences?.labels || []}
    updateEditedRow={handleLabelUpdate}
  />
)}

      </>
    );
  }
);

export default LeadsGrid;
