import React, { PropsWithChildren, useState } from "react";
import { Drawer, Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { ModalSize } from "rsuite/esm/Modal/Modal";
import { RiDeleteBin6Fill, RiCloseFill } from "react-icons/ri";

import styled from "styled-components";

interface DrawerI {
  openWithHeader: boolean;
  customHeader?: boolean; // Make customHeader optional
  onDeleteClick?: (id: string) => void;
  setOpenWithHeader: React.Dispatch<React.SetStateAction<boolean>>;
  drawerTitle: string;
  size: ModalSize;
  id?: string;
  secondOption?: React.ReactNode;
  onClickSecondOption?: () => void;
  className?: string;
}

const DrawerComponent: React.FC<PropsWithChildren<DrawerI>> = ({
  onClickSecondOption,
  secondOption,
  openWithHeader,
  customHeader = false,
  onDeleteClick,
  setOpenWithHeader,
  drawerTitle,
  children,
  size,
  id = "",
  className = "",
}) => {
  return (
    <Root>
      <Drawer
        size={size}
        enforceFocus={false}
        open={openWithHeader}
        onClose={() => setOpenWithHeader(false)}
        className={className}
      >
        {!customHeader ? (
          <Drawer.Header>
            <Drawer.Title className="modal-title">{drawerTitle}</Drawer.Title>
            {secondOption && (
              <Drawer.Actions>
                <Button
                  appearance="subtle"
                  onClick={() => onClickSecondOption && onClickSecondOption()}
                >
                  {secondOption}
                </Button>
              </Drawer.Actions>
            )}
            <Drawer.Actions>
              <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
            </Drawer.Actions>
          </Drawer.Header>
        ) : (
          <Drawer.Header closeButton={false} className="edit-title-header">
            {/* This is a Custom Header for List Edit Drawer*/}
            <Drawer.Title className="modal-title">{drawerTitle}</Drawer.Title>
            {secondOption && (
              <Drawer.Actions>
                <Button
                  appearance="subtle"
                  onClick={() => onClickSecondOption && onClickSecondOption()}
                >
                  {secondOption}
                </Button>
              </Drawer.Actions>
            )}
            <Drawer.Actions>
              {onDeleteClick && (
                <span
                  className="rs-btn rs-btn-default mb-2"
                  onClick={() => {
                    onDeleteClick(id);
                  }}
                >
                  <RiDeleteBin6Fill />
                </span>
              )}
              <span
                className="close-btn"
                onClick={() => {
                  setOpenWithHeader(false);
                }}
              >
                <RiCloseFill />
              </span>
            </Drawer.Actions>
          </Drawer.Header>
        )}
        <Drawer.Body>
          {/* <Placeholder.Paragraph /> */}
          {children}
        </Drawer.Body>
      </Drawer>
    </Root>
  );
};

export default DrawerComponent;
const Root = styled.section`
  .custom_btn:focus {
    background-color: red;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;