import moment from "moment";
import { Logger } from '../utils/logger';

export const getDateRange = (value: string): { from: string; to: string } => {
  Logger.log('INPUT VALUE:', value);  // Debug log
  
  switch(value) {
    case "thisQuarter":
      return {
        from: moment().startOf('quarter').utc().format(),
        to: moment().utc().format()
      };
      
    case "lastQuarter":
      return {
        from: moment().subtract(1, 'quarter').startOf('quarter').utc().format(),
        to: moment().subtract(1, 'quarter').endOf('quarter').utc().format()
      };
      
    case "thisMonth":
      return {
        from: moment().startOf('month').utc().format(),
        to: moment().utc().format()
      };
      
    case "lastMonth":
      return {
        from: moment().subtract(1, 'month').startOf('month').utc().format(),
        to: moment().subtract(1, 'month').endOf('month').utc().format()
      };
      
    case "thisWeek":
      return {
        from: moment().startOf('week').utc().format(),
        to: moment().utc().format()
      };
      
    case "lastWeek":
      return {
        from: moment().subtract(1, 'week').startOf('week').utc().format(),
        to: moment().subtract(1, 'week').endOf('week').utc().format()
      };
      
    case "thisYear":
      return {
        from: moment().startOf('year').utc().format(),
        to: moment().utc().format()
      };
      
    case "7d":
      return {
        from: moment().subtract(7, 'days').startOf('day').utc().format(),
        to: moment().utc().format()
      };

    case "yesterday":
      return {
        from: moment().subtract(1, 'day').startOf('day').utc().format(),
        to: moment().subtract(1, 'day').endOf('day').utc().format()
      };
      
    default:
      Logger.log('NO MATCH FOR:', value);  // Debug log
      return {
        from: moment().utc().format(),
        to: moment().utc().format()
      };
  }
};

export const getDaysDifference = (from: string, to: string): number => {
  return Math.abs(moment(to).diff(moment(from), 'days'));
};