import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import PopupComponent from '../../../../components/PopupComponent';
import type { Tag } from '../../types';
import { logger, LogLevel } from '../../logger';
import { deleteContent } from '../../../../services/contentService';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import GenericConfirmationModal from '../../../../components/GenericConfirmationModal';

interface MessageData {
  title: string;
  tags: string[];
  message: string;
}

interface MessageContentProps {
  openMessageBox: boolean;
  setOpenMessageBox: React.Dispatch<React.SetStateAction<boolean>>;
  activeId: string;
  messageData: MessageData;
  handleMessageForm: (key: keyof MessageData, value: any) => void;
  handleFormSubmit: (data: any) => Promise<any>; // Updated to specify Promise return type
  messageTag: Tag[];
  clearData: () => void;
  onSuccessfulSubmit?: () => Promise<void>; // Make sure this is included in the props
}

const MessageContent: React.FC<MessageContentProps> = ({
  openMessageBox,
  setOpenMessageBox,
  activeId,
  messageData,
  handleMessageForm,
  handleFormSubmit,
  messageTag,
  clearData,
  onSuccessfulSubmit // Add this to the destructured props
}) => {
  const handleClose = useCallback(() => {
    logger('MessageContent', 'Closing message content', LogLevel.INFO);
    clearData();
    setOpenMessageBox(false);
  }, [clearData, setOpenMessageBox]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const addLeadName = useCallback(() => {
    logger('MessageContent', 'Adding lead name to message', LogLevel.INFO);
    handleMessageForm('message', messageData.message + '@lead name');
  }, [messageData.message, handleMessageForm]);

  const handleDelete = useCallback(() => {
    const loadingToast = toast.loading('Deleting message...');
    
    deleteContent(activeId)
      .then((response) => {
        toast.dismiss(loadingToast);
        
        if (response && response.status === 200) {
          toast.success('Message deleted successfully');
          setOpenMessageBox(false);
          clearData();
          
          // Refresh content after deletion
          if (onSuccessfulSubmit) {
            onSuccessfulSubmit();
          }
        } else {
          toast.error('Failed to delete message');
        }
      })
      .catch((error) => {
        toast.dismiss(loadingToast);
        toast.error('Error deleting message');
        console.error('Error deleting message:', error);
      });
  }, [activeId, clearData, onSuccessfulSubmit, setOpenMessageBox]);

  // 4. Add the delete button component
  const DeleteButton = useCallback(() => (
    <button
      onClick={(e) => {
        e.stopPropagation();
        setShowDeleteConfirmation(true); // Show confirmation modal instead of direct delete
      }}
      style={{
        background: 'none',
        border: 'none',
        color: '#ff4d4f',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px',
        borderRadius: '4px',
        transition: 'all 0.2s'
      }}
      title="Delete message"
    >
      <RiDeleteBin6Fill size={20} />
    </button>
  ), []);
  const renderFooter = () => (
    <FooterContainer>
      <SaveButton
        onClick={() => {
          if (!messageData.title.trim()) {
            toast.error('Title is required');
            return;
          }
          
          if (!messageData.tags?.length) {
            toast.error('Please select at least one tag');
            return;
          }

          if (!messageData.message.trim()) {
            toast.error('Message is required');
            return;
          }

          // Show loading indicator
          const loadingToast = toast.loading('Saving message...');
          
          // Call handleFormSubmit and handle the Promise
          handleFormSubmit({
            details: messageData,
            type: "message",
            _id: activeId
          })
            .then((response) => {
              // Dismiss loading toast
              toast.dismiss(loadingToast);
              
              // Check if response is successful
              if (response && response.status === 200) {
                // Close the popup
                setOpenMessageBox(false);
                
                // Clear the form data
                clearData();
                
                // Refresh the content grid data
                if (onSuccessfulSubmit) {
                  onSuccessfulSubmit();
                }
              } else {
                // If response is not successful, keep popup open and show error
                toast.error('Failed to save message');
              }
            })
            .catch((error) => {
              // Dismiss loading toast
              toast.dismiss(loadingToast);
              
              // Keep popup open and show error
              toast.error('Error saving message');
              console.error('Error saving message:', error);
            });
        }}
      >
        Save
      </SaveButton>
    </FooterContainer>
  );

  return (
    <PopupComponent
        isOpen={openMessageBox}
        onClose={handleClose}
        title={activeId.length > 1 ? 'Edit Message' : 'Add Message'}
        size="lg"
        footer={renderFooter()}
        headerActions={activeId.length > 1 ? <DeleteButton /> : undefined}
      >
       
  
      <FormContainer>
        <div className="user_form_box">
          <label>Title</label>
          <input
            type="text"
            value={messageData.title}
            onChange={(e) => handleMessageForm("title", e.target.value)}
            placeholder="Enter title"
          />
        </div>

        <div className="user_form_box">
          <label>Tags</label>
          <div className="all_tags">
            {messageTag.map((tag) => (
              <span
                key={tag.value}
                onClick={() => handleMessageForm("tags", tag.value)}
                className={messageData.tags.includes(tag.value) ? "active" : ""}
              >
                {tag.name}
              </span>
            ))}
          </div>
        </div>

        <div className="user_form_box">
          <div className="label-wrapper">
            <label>Message</label>
            <button className="add_lead_name" onClick={addLeadName}>
              Add @lead name
            </button>
          </div>
          <textarea
            rows={8}
            cols={50}
            value={messageData.message}
            onChange={(e) => handleMessageForm("message", e.target.value)}
            placeholder="Enter your message"
          />
        </div>
      </FormContainer>
      <GenericConfirmationModal
        showModal={showDeleteConfirmation}
        toggleModal={() => setShowDeleteConfirmation(false)}
        onConfirmation={handleDelete}
        title="Delete Message"
        message="Are you sure you want to delete this message? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        isWarning={true}
      />
    </PopupComponent>
    
  );
};

const FormContainer = styled.div`
  padding: 24px 32px;
  overflow-y: auto;
  max-height: calc(90vh - 140px);
  background: #fff;

  .user_form_box {
    margin-bottom: 24px;
    
    .label-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }

    label {
      display: block;
      font-weight: 500;
      color: #4A4A4A;
      font-size: 14px;
    }

    input[type="text"], textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #E5E7EB;
      border-radius: 8px;
      font-size: 14px;
      color: #374151;
      background: #fff;
      transition: all 0.2s ease;
      
      &::placeholder {
        color: #9CA3AF;
      }
      
      &:focus {
        outline: none;
        border-color: #3FAEFB;
        box-shadow: 0 0 0 2px rgba(63, 174, 251, 0.1);
      }
    }

    textarea {
      resize: vertical;
      min-height: 200px;
      line-height: 1.5;
    }

    .all_tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;

      span {
        padding: 8px 16px;
        border-radius: 6px;
        background: #F3F4F6;
        cursor: pointer;
        font-size: 14px;
        color: #4B5563;
        transition: all 0.2s ease;
        border: 1px solid transparent;
        
        &:hover {
          background: #E5E7EB;
        }
        
        &.active {
          background: #EBF5FF;
          color: #3FAEFB;
          border-color: #3FAEFB;
        }
      }
    }
  }

  .add_lead_name {
    padding: 6px 12px;
    background: #F3F4F6;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    font-size: 13px;
    color: #374151;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      background: #E5E7EB;
    }
  }
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  color: #ff4d4f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 4px;
  transition: all 0.2s;
  
  &:hover {
    background-color: rgba(255, 77, 79, 0.1);
  }
  
  &:active {
    background-color: rgba(255, 77, 79, 0.2);
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid #E5E7EB;
`;

const SaveButton = styled.button`
  padding: 10px 24px;
  background-color: #3FAEFB;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  height: 42px;
  min-width: 120px;
  max-width: 240px;
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #2E9FEA;
  }
  
  &:active {
    background-color: #2B96DE;
  }
`;

export default MessageContent;