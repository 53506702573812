import { Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import RangeDatePicker from "../../components/RangeDatePicker";
import { DateRange } from "./dashboard";
import styled from "styled-components";
import { useEffect, useState } from "react";

// Keep all the existing interfaces
interface IDashboardTaskStatusPieChart {
  title: string;
  pieChartData: PieChartData[];
  legendPosition?: "top" | "right" | "bottom" | "left";
  chartHeight?: number;
  callBackFun: Function;
  dateRangeValue: DateRange;
  taskCount: TaskCount[];
}

export interface PieChartData {
  label: string;
  value: number;
}

interface TaskCount {
  type: string;
  total: number;
  conversionRate: number;
}

// Keep all your existing styled components
const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-height: 240px;
  min-height: 120px;
  overflow-y: auto;
  width: 100%;
  padding: 1.5rem;
  margin: 0.5rem auto;
  background: #f8f9fa;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6;
    border-radius: 3px;
  }

  & > div {
    flex: 1;
    min-width: 160px;
  }
`;

const Heading = styled.h4`
  color: #333;
  font-size: 12px;
  font-family: "Gilroy-Bold", sans-serif;
  margin-bottom: 1rem;
`;

const ConversionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  transition: all 0.2s ease;

  &:hover {
    background: #f8f9fa;
  }

  span {
    color: #333;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    text-transform: capitalize;
  }
`;

const DashboardTaskStatusChart: React.FC<IDashboardTaskStatusPieChart> = ({
  title,
  pieChartData,
  chartHeight = 210,
  callBackFun,
  dateRangeValue,
  taskCount,
}) => {
  const [isChartReady, setIsChartReady] = useState(false);

  useEffect(() => {
    if (pieChartData?.length && pieChartData.every(item => item.value !== undefined)) {
      setIsChartReady(true);
    } else {
      setIsChartReady(false);
    }
  }, [pieChartData]);

 
  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar',
      background: 'transparent',
      fontFamily: 'Gilroy, sans-serif',
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        speed: 500
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        borderRadius: 6,
        distributed: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    colors: ['#6366F1', '#4F46E5', '#7C3AED', '#6D28D9', '#5B21B6'],
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        fontFamily: 'Gilroy-Medium, sans-serif',
        fontWeight: 500,
        colors: ['#64748B']
      },
      formatter: function(val: number) {
        return val.toString();
      }
    },
    grid: {
      borderColor: '#F1F5F9',
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    },
    xaxis: {
      categories: pieChartData.map(data => data.label),
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Gilroy-Medium, sans-serif',
          colors: '#64748B'
        },
        rotate: -45,
        offsetY: 0,
        trim: true
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Gilroy-Medium, sans-serif',
          colors: '#64748B'
        }
      }
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.9
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: '12px',
        fontFamily: 'Gilroy-Medium, sans-serif'
      },
      y: {
        formatter: function(val: number) {
          return val.toString();
        }
      }
    }
  };

  const hasData = pieChartData.reduce((total, current) => total + current.value, 0) > 0;

  return (
    <Card>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="fw-bold text-dark d-flex flex-column align-items-center fm-gilroy">
            <span>{title}</span>
          </div>
        </div>
        <RangeDatePicker
          dateRangeValue={dateRangeValue}
          callBackFun={callBackFun}
        />
      </CardHeader>
      
      <CardBody className="p-0">
        {!hasData ? (
          <h2 className="no_data_found">No data yet.</h2>
        ) : isChartReady ? (
          <ReactApexChart
            options={chartOptions}
            series={[{
              name: 'Tasks',
              data: pieChartData.map(data => data.value)
            }]}
            type="bar"
            height={chartHeight}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center" style={{height: chartHeight}}>
            <span>Loading...</span>
          </div>
        )}
      </CardBody>

      <CardBody className="p-0">
        <div className="mb-4">
        <h6 className="text-sm font-medium text-gray-500 mb-1">Task by type</h6>
        </div>
        <ReactApexChart
          options={{
            chart: {
              type: 'bar',
              background: 'transparent',
              fontFamily: 'Inter, sans-serif',
              toolbar: { show: false },
              stacked: false
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '60%',
                borderRadius: 4,
                distributed: true,
                dataLabels: {
                  position: 'top'
                }
              }
            },
            colors: ['#818CF8'], // Subtle indigo color
            dataLabels: {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: '13px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: '500',
                colors: ['#1F2937']
              },
              formatter: function(val: number): string {
                return val.toString();
              }
            },
            xaxis: {
              categories: taskCount?.map(t => t.type.toLowerCase().replaceAll("_", "-")),
              labels: {
                style: {
                  fontSize: '12px',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: '400',
                  colors: '#4B5563'
                },
                rotate: -45,
                offsetY: 0
              },
              axisBorder: { show: false },
              axisTicks: { show: false }
            },
            yaxis: {
              labels: {
                style: {
                  fontSize: '12px',
                  fontFamily: 'Inter, sans-serif',
                  colors: '#4B5563'
                },
                formatter: function(val: number): string {
                  return val.toString();
                }
              },
              axisBorder: { show: false },
              axisTicks: { show: false }
            },
            grid: {
              show: false
            },
            tooltip: {
              enabled: true,
              shared: false,
              intersect: true,
              style: {
                fontSize: '12px'
              },
              y: {
                formatter: function(val: number): string {
                  return val.toString();
                }
              },
              custom: function({ seriesIndex, dataPointIndex, w }) {
                const task = taskCount[dataPointIndex];
                return '<div class="px-3 py-2 bg-white shadow-lg rounded-lg border border-gray-100">' +
                  `<div class="text-sm text-gray-600">${task.type.replaceAll("_", " ")}</div>` +
                  `<div class="font-medium text-gray-900">Count: ${task.total}</div>` +
                  `<div class="text-xs text-indigo-600">Completion: ${task.conversionRate}%</div>` +
                  '</div>';
              }
            },
            states: {
              hover: {
                filter: {
                  type: 'darken',
                  value: 0.08
                }
              }
            }
          }}
          series={[{
            name: 'Tasks',
            data: taskCount?.map(t => t.total) || []
          }]}
          type="bar"
          height={350}
        />
      </CardBody>
    </Card>
  );
};

export default DashboardTaskStatusChart;