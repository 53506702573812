// src/views/whatsappInbox/incomingMsg.tsx
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./whatsapp.css";
import {
  downloadChatMedia,
  getConversationsFromId,
} from "../../services/whatsapp";
import moment from "moment";
import { Spin } from "antd";
import { DateDivider, MessageBubble, MessageMedia } from "./components"; // Import from index
import { Anchorme } from "react-anchorme";
import { MediaCache } from "./utils/mediaCache";
import { Logger } from '../../utils/logger';

const mediaCache = new MediaCache();

export default function IncomingMsg(props: any) {
  const [responseData, setResponseData] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [integrationInfo, setIntegrationInfo] = useState<any>(null);
  const [downloadingMedia, setDownloadingMedia] = useState<{
    [key: string]: boolean;
  }>({});
  const [downloadErrors, setDownloadErrors] = useState<{
    [key: string]: string;
  }>({});

  // Existing functions and effects...
  const getConversation = async (convoId: string) => {
    const response = await getConversationsFromId(convoId);
    const reversedResponse: any = [];
    if (response?.data?.data?.[0]?.lead) {
      props.setLead(response.data.data[0].lead);
    }
    response.data.data.map((e: any) => {
      reversedResponse.unshift(e);
    });
    setResponseData(reversedResponse);
    props.setMsgList(reversedResponse);
    setLoader(false);
  };

  useEffect(() => {
    props?.scrollToBottom();
  }, [responseData?.length]);

  useEffect(() => {
    getConversation(props?.users?.convoId);
    setLoader(true);
  }, [props?.users?.convoId]);

  useEffect(() => {
    const userIntigrationsList = JSON.parse(
      localStorage.getItem("userData") || ""
    ).userIntegrations;

    const findData = userIntigrationsList.find(
      (item: any) =>
        item?.integration?._id === "646b9286ae8ef73b33caec2e" && item?.isActive
    );

    setIntegrationInfo(findData);
  }, []);

  // useEffect(() => {
  //   Logger.log("props?.socket:", props?.socket);
  // }, [props?.socket]);

  useEffect(() => {
    const obj = {
      message: { body: props.inputText },
      sender: "644e1003b5160d2d06549130",
      messageDelivery: "sent",
    };
    const data = [...responseData, obj];
    setResponseData(data);
  }, [props.inputText]);

  const chats = useMemo(() => {
    if (!responseData) {
      return [];
    }
    return responseData.reduce((prev: any, current: any) => {
      const date = moment(current.createdAt).format("L");
      prev[date] = [...(prev[date] || []), current];
      return prev;
    }, {});
  }, [responseData]);

  const getFormattedTime = (date: string) => {
    const time = moment(chats[date][0].createdAt);
    const now = moment();
    if (time.format("ll") == now.format("ll")) {
      return "Today";
    }
    return time.format("ll");
  };

  const currentUser = useMemo(() => {
    return JSON.parse(
      localStorage.getItem("userData") || JSON.stringify({ firstName: "You" })
    );
  }, []);

  const renderMessage = (msg: any) => {
    return <Anchorme target="_blank">{msg}</Anchorme>;
  };

  const getMedia = async (info: any) => {
    if (!integrationInfo) return;

    const cachedMedia = mediaCache.get(info._id);
    if (cachedMedia) {
      let tempArr = [...responseData];
      let idx = tempArr.findIndex((e: any) => e._id === info._id);
      if (idx > -1) {
        tempArr[idx] = {
          ...info,
          downloadUrl: cachedMedia.url,
        };
        setResponseData(tempArr);
      }
      return;
    }

    setDownloadingMedia((prev) => ({ ...prev, [info._id]: true }));
    setDownloadErrors((prev) => ({ ...prev, [info._id]: "" }));

    try {
      let mediaId = info?.message?.id;
      let formdata = new FormData();
      formdata.append("mediaId", mediaId);

      const response = await downloadChatMedia(formdata);

      if (response.data) {
        const blob = new Blob([response.data], {
          type:
            info?.messageType === "document"
              ? "application/pdf"
              : info.message.mime_type,
        });

        const url = URL.createObjectURL(blob);

        mediaCache.set(info._id, {
          url,
          type: info.messageType,
          mimeType: info.message.mime_type,
        });

        let tempArr = [...responseData];
        let idx = tempArr.findIndex((e: any) => e._id === info._id);

        if (idx > -1) {
          tempArr[idx] = {
            ...info,
            downloadUrl: url,
          };
          setResponseData(tempArr);
        }
      }
    } catch (error) {
      console.error("Media download failed:", error);
      setDownloadErrors((prev) => ({
        ...prev,
        [info._id]: "Failed to download media. Click to retry.",
      }));
    } finally {
      setDownloadingMedia((prev) => ({ ...prev, [info._id]: false }));
    }
  };

  const groupedByTime = (data: any) => {
    try {
      return data.reduce((acc: any, item: any) => {
        const time = moment(item.createdAt).format("hh:mm a");
        if (!acc[time]) {
          acc[time] = [];
        }
        acc[time].push(item);
        return acc;
      }, {});
    } catch (error) {
      console.error("Error grouping messages by time:", error);
      return {};
    }
  };

  return (
    <div className="messages-container">
      {JSON.stringify(chats) !== "{}" ? (
        Object.keys(chats).map((date: string, dateIndex: number) => {
          const time = groupedByTime(chats[date]);

          return (
            <div key={`date-${dateIndex}`}>
              <Spin spinning={loader} size="large" tip="Loading...">
                <DateDivider date={date} getFormattedTime={getFormattedTime} />

                {Object.keys(time).map((val, timeIndex) => (
                  <div
                    key={`time-${dateIndex}-${timeIndex}`}
                    className="message-group"
                  >
                    {(time[val] || []).map((message: any, msgIndex: number) => {
                      const cachedMedia = mediaCache.get(message._id);
                      const uniqueKey = `${
                        message._id ||
                        `${Date.now()}-${message.createdAt}-${msgIndex}`
                      }`;

                      return (
                        <MessageBubble
                          key={uniqueKey}
                          message={{
                            ...message,
                            downloadUrl:
                              cachedMedia?.url || message.downloadUrl,
                          }}
                          currentUser={currentUser}
                          onMediaDownload={getMedia}
                          integrationInfo={integrationInfo}
                          isFirstInGroup={msgIndex === 0}
                          isLastInGroup={msgIndex === time[val].length - 1}
                          showTimestamp={true}
                          isLoading={downloadingMedia[message._id]}
                          downloadError={downloadErrors[message._id]}
                        />
                      );
                    })}
                  </div>
                ))}
              </Spin>
            </div>
          );
        })
      ) : (
        <div className="empty-chat">
          <div className="empty-chat-content">
            <h3>No conversation to show</h3>
            <p>
              Your inbox is empty. Messages will appear here when they arrive.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
