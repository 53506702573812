import React, { useEffect, useState, useRef } from "react";
import { MapPin, Navigation } from "lucide-react";

const InputMaps = (props) => {
    // Generate unique IDs for this instance to prevent conflicts with multiple maps
    const uniqueId = useRef(`map-${props.name}-${Math.random().toString(36).substr(2, 9)}`).current;
    const autocompleteId = useRef(`autocomplete-${props.name}-${Math.random().toString(36).substr(2, 9)}`).current;

    // Get coordinates from props without any default location
    const getInitialCoords = () => {
        if (props.locationData?.coords) {
            return {
                lat: props.locationData.coords.latitude,
                lng: props.locationData.coords.longitude
            };
        } else if (typeof props.value === 'object' && props.value?.coords) {
            return {
                lat: props.value.coords.latitude,
                lng: props.value.coords.longitude
            };
        }
        // No default location - return null
        return null;
    };

    const [location, setLocation] = useState(getInitialCoords());
    
    // Handle address input value - extract address string if an object is passed
    const getInitialInput = () => {
        if (typeof props.value === 'string') {
            return props.value;
        } else if (typeof props.value === 'object' && props.value?.address) {
            return props.value.address;
        } else if (props.locationData?.address) {
            return props.locationData.address;
        }
        return "";
    };

    const [input, setInput] = useState(getInitialInput());
    const [marker, setMarker] = useState(null);
    const mapRef = useRef(null);
    const autocompleteRef = useRef(null);
    const [mapInitialized, setMapInitialized] = useState(false);

    // Initialize map after component is mounted
    useEffect(() => {
        if (props.disabled) return;

        // Initialize map
        const initMap = () => {
            // Make sure the map container element exists
            const mapContainer = document.getElementById(uniqueId);
            if (!mapContainer) {
                console.error(`Map container element with ID ${uniqueId} not found`);
                // Try again after a short delay to allow DOM to render
                setTimeout(() => initMap(), 100);
                return;
            }

            try {
                mapRef.current = new window.google.maps.Map(
                    mapContainer,
                    {
                        center: location || { lat: 0, lng: 0 }, // Fallback if location is null
                        zoom: location ? 13 : 2, // Zoom out if no location
                    }
                );
                
                // Add a marker only if we have a valid location
                if (location) {
                    setMarker(
                        new window.google.maps.Marker({
                            position: location,
                            map: mapRef.current,
                        })
                    );
                }
                
                setMapInitialized(true);
            } catch (error) {
                console.error("Error initializing map:", error);
            }
        };

        // Initialize autocomplete
        const initAutocomplete = () => {
            // Make sure the input element exists
            const inputElement = document.getElementById(autocompleteId);
            if (!inputElement) {
                console.error(`Autocomplete input element with ID ${autocompleteId} not found`);
                // Try again after a short delay
                setTimeout(() => initAutocomplete(), 100);
                return;
            }

            try {
                autocompleteRef.current = new window.google.maps.places.Autocomplete(
                    inputElement,
                    { types: ["geocode"] }
                );
                autocompleteRef.current.addListener(
                    "place_changed",
                    handlePlaceChanged
                );
            } catch (error) {
                console.error("Error initializing autocomplete:", error);
            }
        };

        // Wait for Google Maps to load
        if (window.google && window.google.maps) {
            initMap();
            initAutocomplete();
        } else {
            // If Google Maps not loaded yet, wait for it
            const checkGoogleMapsLoaded = setInterval(() => {
                if (window.google && window.google.maps) {
                    clearInterval(checkGoogleMapsLoaded);
                    initMap();
                    initAutocomplete();
                }
            }, 100);
            
            // Clear interval on component unmount
            return () => clearInterval(checkGoogleMapsLoaded);
        }
    }, [props.disabled, location, uniqueId, autocompleteId]);

    // Update state when props change
    useEffect(() => {
        // Update location and input when props change
        const newCoords = getInitialCoords();
        setLocation(newCoords);
        setInput(getInitialInput());
        
        // Update marker position if map is initialized
        if (mapInitialized && mapRef.current && newCoords) {
            if (marker) {
                marker.setPosition(newCoords);
            } else {
                setMarker(
                    new window.google.maps.Marker({
                        position: newCoords,
                        map: mapRef.current,
                    })
                );
            }
            mapRef.current.panTo(newCoords);
            mapRef.current.setZoom(13);
        }
    }, [props.value, props.locationData]);

    const handlePlaceChanged = () => {
        if (!autocompleteRef.current) return;
        
        const place = autocompleteRef.current.getPlace();
        if (place?.geometry?.location) {
            const coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            setLocation(coordinates);
            setInput(place.formatted_address);
            
            if (mapRef.current) {
                mapRef.current.panTo(coordinates);
                mapRef.current.setZoom(13);
            }
            
            if (marker) {
                marker.setPosition(coordinates);
            } else if (mapRef.current) {
                setMarker(new window.google.maps.Marker({
                    position: coordinates,
                    map: mapRef.current,
                }));
            }
            
            // Call the parent component's onChange handler
            if (props.onChange) {
                props.onChange(props.name, place.formatted_address, coordinates);
            }
        }
    };

    const handleCurrentLocationClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setLocation(currentPosition);
                    
                    if (mapRef.current) {
                        mapRef.current.panTo(currentPosition);
                        mapRef.current.setZoom(13);
                    }
                    
                    if (marker) {
                        marker.setPosition(currentPosition);
                    } else if (mapRef.current) {
                        setMarker(new window.google.maps.Marker({
                            position: currentPosition,
                            map: mapRef.current,
                        }));
                    }
                    
                    // Reverse geocode to get address
                    if (window.google && window.google.maps) {
                        const geocoder = new window.google.maps.Geocoder();
                        geocoder.geocode({ location: currentPosition }, (results, status) => {
                            if (status === "OK" && results[0]) {
                                setInput(results[0].formatted_address);
                                // Call the parent component's onChange handler
                                if (props.onChange) {
                                    props.onChange(props.name, results[0].formatted_address, currentPosition);
                                }
                            } else {
                                console.error('Geocoder failed due to: ' + status);
                                // Still update with coordinates even if address lookup fails
                                const coordsText = `Lat: ${currentPosition.lat.toFixed(6)}, Lng: ${currentPosition.lng.toFixed(6)}`;
                                setInput(coordsText);
                                if (props.onChange) {
                                    props.onChange(props.name, coordsText, currentPosition);
                                }
                            }
                        });
                    } else {
                        console.error('Google Maps Geocoder not available');
                        // Update with coordinates as fallback
                        const coordsText = `Lat: ${currentPosition.lat.toFixed(6)}, Lng: ${currentPosition.lng.toFixed(6)}`;
                        setInput(coordsText);
                        if (props.onChange) {
                            props.onChange(props.name, coordsText, currentPosition);
                        }
                    }
                },
                (error) => {
                    console.error('Error getting current location:', error);
                    // Show error message to user
                    alert(`Could not get your location: ${error.message}`);
                },
                { 
                    enableHighAccuracy: true, 
                    timeout: 10000, 
                    maximumAge: 0 
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            alert('Geolocation is not supported by this browser.');
        }
    };

    const handleChange = (event) => {
        setInput(event.target.value);
    };

    return (
        <div className="map_inputs input-field-container">
            <label className="form-label">
                {props.label}{" "}
                <span className="text-danger">{props.isRequired && "*"}</span>
            </label>
            <div className="input-with-icon-wrapper">
                <MapPin size={18} className="field-icon" />
                <input
                    {...props}
                    className={`form-control input-with-icon ${props.className || ""}`}
                    placeholder="Search for an address or use current location"
                    type="text"
                    onChange={handleChange}
                    value={input}
                    id={autocompleteId}
                />
                <button 
                    type="button" 
                    className="location-button" 
                    onClick={handleCurrentLocationClick}
                    title="Use current location"
                >
                    <Navigation size={18} />
                </button>
            </div>
            {!props.disabled && (
                <div
                    id={uniqueId}
                    style={{ height: "160px", width: "100%", marginTop: "10px", borderRadius: "4px" }}
                ></div>
            )}
        </div>
    );
};

export default InputMaps;