import React, { PropsWithChildren } from "react";
import moment from "moment";
import copy from "copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import ReactAudioPlayer from 'react-audio-player';
import { useSelector } from "react-redux";

interface leadNoteItemI {
  activity: any;
  key: any;
}

const LeadNoteItems: React.FC<PropsWithChildren<leadNoteItemI>> = ({
  activity,
  key,
}) => {
  // Access Redux store to get bucketUrl
  const StoreData = useSelector((state: any) => state?.rootReducers);
  const bucketUrl = StoreData?.userData?.userDetails?.bucketUrl;

  const full_name =
    activity?.createdBy?.firstName && activity?.createdBy?.lastName
      ? activity?.createdBy?.firstName + " " + activity?.createdBy?.lastName
      : activity?.createdBy?.firstName;

  const timeFormat = moment.utc(activity.createdAt).toDate();
  
  const copyData = () => {
    copy(activity?.description);
    toast.success("Notes Copied to Clipboard");
  };
  
  return (
    <>
      <div className="popup-section-4-right-1 d-flex align-items-center">
        <h6 className="ms-4-notes" key={key}>
          {activity?.description && (
            <h6 className="my-1 text-dark">{activity?.description ?? ""}</h6>
          )}
          
          {activity?.voiceNote && (
            <div className="audio-note-container my-2">
              <ReactAudioPlayer
                src={`${bucketUrl}${activity.voiceNote}`}
                controls
                className="lead-audio-player w-100"
              />
            </div>
          )}

          {activity?.description && (
            <div className="icon_div_copy">
              <img
                alt="email"
                src="assets/img/copy.png"
                height={15}
                width={15}
                onClick={copyData}
              />
            </div>
          )}
        </h6>
        <Toaster position="top-right" reverseOrder={false} />
      </div>
      <div className="lead_note_name_date mb-3 mt-1">
        <h6 className="fs-8">
          {" " + moment(activity.createdAt).format("DD MMM YYYY")} {" - "}
          {moment(timeFormat).format("hh:mm A")} by{" "}
          {full_name ? (
            <>{" " + full_name}</>
          ) : (
            <>{" " + activity?.createdBy}</>
          )}
        </h6>
      </div>
    </>
  );
};

export default LeadNoteItems;