import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import styled from 'styled-components';
import ColumnChart from './ColumnChart';
import RangeDatePicker from '../../components/RangeDatePicker';
import { DateRange } from './dashboard';

interface LeadSourceData {
  label: string;
  value: number;
  rate?: number;
}

interface LeadsBySourceWithTableProps {
  title: string;
  data: LeadSourceData[];
  dateRangeValue: DateRange;
  callBackFun: (date?: DateRange) => void;
  height?: number;
}

const TableContainer = styled.div`
  margin-top: 1rem;
  overflow-x: auto;
  max-height: 400px;
  overflow-y: auto;
  
  /* Sticky header */
  thead {
    position: sticky;
    top: 0;
    background: #F9FAFB;
    z-index: 1;
  }
  
  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Gilroy', sans-serif;
  
  th, td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #E5E7EB;
  }

  th {
    background-color: #F9FAFB;
    color: #4B5563;
    font-weight: 600;
    font-size: 0.875rem;
  }

  td {
    color: #1F2937;
    font-size: 0.875rem;
  }

  tbody tr:hover {
    background-color: #F9FAFB;
  }
`;

const LeadsBySourceWithTable: React.FC<LeadsBySourceWithTableProps> = ({ 
  title, 
  data, 
  dateRangeValue, 
  callBackFun,
  height = 350 
}) => {
  // Transform data for the column chart
  // Filter out zero values and sort by value descending
const chartData = data
  .filter((item: LeadSourceData) => item.value > 0)
  .sort((a: LeadSourceData, b: LeadSourceData) => b.value - a.value)
  .map((item: LeadSourceData) => ({
    label: item.label,
    value: item.value
  }));

  return (
    <Card className="shadow-sm border-0">
      <CardHeader className="bg-white border-bottom">
        <h5 className="mb-0">{title}</h5>
      </CardHeader>
      <CardBody>
        {chartData.length > 0 ? (
          <ColumnChart
          title=""
          data={chartData}
          height={height}
          dateRangeValue={dateRangeValue}
          callBackFun={callBackFun}
        />
        ) : (
          <div className="text-center py-4 text-muted">
            No active sources found
          </div>
        )}
        
        <TableContainer>
          <StyledTable>
            <thead>
              <tr>
                <th>Source</th>
                <th>Count</th>
                <th>Conversion Rate</th>
              </tr>
            </thead>
            <tbody>
              {data
                .sort((a: LeadSourceData, b: LeadSourceData) => b.value - a.value)
                .map((item: LeadSourceData, index: number) => (
                <tr key={index}>
                  <td>{item.label}</td>
                  <td>{new Intl.NumberFormat().format(item.value)}</td>
                  <td>{typeof item.rate === 'number' ? `${item.rate.toFixed(1)}%` : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        </TableContainer>
      </CardBody>
    </Card>
  );
};

export default LeadsBySourceWithTable;