import React, { PropsWithChildren, useEffect, useState } from "react";
// import { Typeahead } from "react-bootstrap-typeahead";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import moment from "moment";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getAllTeams } from "../../services/teamsService";
import { useDispatch } from "react-redux";
import { resetLeadFilter } from "../../actions/actions";
import Switch from "react-switch";
import "./leads.css";
import { CUSTOM_ACTIVITY_TYPE } from "../../utils/constants";
import { Logger } from '../../utils/logger';

interface PropsT {
  onLabelClick: () => void;
  onStatusClick: () => void;
  selectedStatus: string;
  selectedLabel: Array<string>;
  setShowFilterDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  LeadFilterDataList: (objData: any) => void;
}

const getDateRange = (
  dateType: string
): { startDate?: string; endDate?: string } => {
  const today = new Date();
  const startDate = new Date();
  const endDate = new Date();

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  switch (dateType) {
    case "today":
      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    case "yesterday":
      startDate.setDate(today.getDate() - 1);
      endDate.setDate(today.getDate() - 1);
      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    case "3DayBefore":
      startDate.setDate(today.getDate() - 3);
      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    case "1WeekBefore":
      startDate.setDate(today.getDate() - 7);
      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    case "1MonthBefore":
      startDate.setMonth(today.getMonth() - 1);
      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };
    default:
      return {};
  }
};

const convertDateRangeToLabel = (startDate: string, endDate: string) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const threeDaysBefore = new Date(today);
  threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);
  const oneWeekBefore = new Date(today);
  oneWeekBefore.setDate(oneWeekBefore.getDate() - 7);
  const oneMonthBefore = new Date(today);
  oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

  const startDateObj = new Date(startDate);
  startDateObj.setHours(0, 0, 0, 0);
  const endDateObj = new Date(endDate);
  endDateObj.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);
  threeDaysBefore.setHours(0, 0, 0, 0);
  oneWeekBefore.setHours(0, 0, 0, 0);
  oneMonthBefore.setHours(0, 0, 0, 0);

  if (
    startDateObj.toDateString() === today.toDateString() &&
    endDateObj.toDateString() === today.toDateString()
  ) {
    return dateLeadAdded.find((item) => item.value === "today");
  } else if (startDateObj >= yesterday && endDateObj <= today) {
    return dateLeadAdded.find((item) => item.value === "yesterday");
  } else if (startDateObj >= threeDaysBefore && endDateObj <= today) {
    return dateLeadAdded.find((item) => item.value === "3DayBefore");
  } else if (startDateObj >= oneWeekBefore && endDateObj <= today) {
    return dateLeadAdded.find((item) => item.value === "1WeekBefore");
  } else if (startDateObj >= oneMonthBefore && endDateObj <= today) {
    return dateLeadAdded.find((item) => item.value === "1MonthBefore");
  } else {
    return dateLeadAdded.find((item) => item.value === "custom");
  }
};

const sortLeadBy = [
  { value: "createdDate", label: "Created Date" },
  { value: "name", label: "Name" },
  { value: "saleValue", label: "Sale Value" },
  { value: "followupDate", label: "Followup Date" },
  { value: "activityDate", label: "Activity Date" },
];

const dateLeadAdded = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "3DayBefore", label: "3 Days Before Now" },
  { value: "1WeekBefore", label: "1 Week Before Now" },
  { value: "1MonthBefore", label: "1 Month Before Now" },
  { value: "custom", label: "Custom Date" },
];

const LeadFilter: React.FC<PropsWithChildren<any>> = ({
  onLabelClick,
  onStatusClick,
  selectedLabel,
  setShowFilterDrawer,
  LeadFilterDataList,
}) => {
  const dispatch = useDispatch();
  const { filterParam } = useSelector((state: any) => state.rootReducers.leads);
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const taskOptions =
    StoreData?.userData?.userDetails?.userPreference?.taskType?.map(
      (e: any) => ({
        ...e,
        label: e?.name,
      })
    );

  const customLeadOptions =
    StoreData?.userData?.userDetails?.userPreference?.customForm
      ?.map((e: any) => ({
        ...e,
        label: e?.name,
      }))
      ?.filter((e: any) => !e.value?.includes("address")) || null;

  const activityTypes =
    StoreData?.userData?.userDetails?.userPreference?.activityType;

  const [teamMembers, setTeamMembers] = useState<Array<any>>([]);
  const [startDate, setStartDate] = useState<any>(undefined);
  const [endDate, setEndDate] = useState<any>(undefined);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [multiSelections, setMultiSelections] = useState([]);
  const [isAscending, setIsAscending] = useState<boolean>(
    filterParam?.sort?.isAscending ?? false
  );
  const [orderBy, setOrderBy] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [leadSource, setLeadSource] = useState<any>([]);
  const [employee, setEmployee] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({});
  const [isOrganization, setIsOrganization] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [datePickerKey, setDatePickerKey] = useState("");
  const [taskFromDate, setTaskFromDate] = useState<any>(undefined);
  const [taskToDate, setTaskToDate] = useState<any>(undefined);
  const [activityFromDate, setActivityFromDate] = useState<any>(undefined);
  const [activityToDate, setActivityToDate] = useState<any>(undefined);

  // Logger.log("filterData>>", filterData);

  useEffect(() => {
    let local: any = localStorage.getItem("user_preferences");
    let userData: any = localStorage.getItem("userData");

    let systemItegration: any = [];

    if (JSON.parse(userData)?.systemIntegration) {
      systemItegration = JSON.parse(userData)?.systemIntegration?.map(
        (e: any) => ({
          ...e,
          value: e._id,
        })
      );
    }

    setLabels(JSON.parse(local)?.labels);
    setStatus(JSON.parse(local)?.status);
    setLeadSource([...JSON.parse(local)?.customSource, ...systemItegration]);
    setEmployee(JSON.parse(userData)?.organizationEmployee);
  }, []);

  useEffect(() => {
    setFilterData({
      ...filterData,
      sortLeadBy: sortLeadBy.find((s) => s.value === filterParam.sort?.orderBy),
      dateLead:
        filterParam.date?.startedAt && filterParam.date?.endedAt
          ? convertDateRangeToLabel(
              filterParam.date.startedAt,
              filterParam.date.endedAt
            )
          : undefined,
      label: filterParam?.label
        ? filterParam.label.map((item: string) => {
            const index = labels.findIndex((l: any) => l.value === item);
            return {
              value: item,
              label: index !== -1 ? labels[index].name : "",
            };
          })
        : [],
      status: filterParam?.status
        ? filterParam.status.map((item: string) => {
            const index = status.findIndex(
              (statusItem: any) => statusItem.value === item
            );
            return {
              value: item,
              label: index !== -1 ? status[index].name : "",
            };
          })
        : [],
      sources: filterParam?.source
        ? filterParam.source.map((item: string) => {
            const index = leadSource.findIndex(
              (sourceItem: any) => sourceItem.value === item
            );
            return {
              value: item,
              label: index !== -1 ? leadSource[index].name : "",
            };
          })
        : [],
      team: filterParam?.teams
        ? filterParam.teams.map((item: string) => {
            const index = teamMembers.findIndex(
              (teamMember: any) => teamMember._id === item
            );
            return {
              value: item,
              label: index !== -1 ? teamMembers[index].name : "",
            };
          })
        : [],
      employee: filterParam?.teamMembers
        ? filterParam.teamMembers.map((item: string) => {
            const index = employee.findIndex((emp: any) => emp._id === item);
            return {
              value: item,
              label:
                index !== -1
                  ? employee[index].firstName + " " + employee[index].lastName
                  : "",
            };
          })
        : [],
      selectCustomLead: customLeadOptions.find(
        (e: any) => e.value === filterParam?.customFieldFilter?.fieldName
      ),
      leadformValue: filterParam?.customFieldFilter?.single?.value,
      taskType: taskOptions?.filter((e: any) =>
        filterParam?.taskFilter?.types?.includes(e.value)
      ),
      taskSelectedDate:
        filterParam?.taskFilter?.range?.start &&
        filterParam?.taskFilter?.range?.end
          ? convertDateRangeToLabel(
              filterParam?.taskFilter?.range?.start,
              filterParam?.taskFilter?.range?.end
            )
          : undefined,
      customActivityName: activityTypes
        ?.filter((e: any) =>
          filterParam?.activityFilter?.types?.includes(e?.value)
        )
        ?.map((e: any) => ({ ...e, label: e.name })),
      activitySelectedDate:
        filterParam?.activityFilter?.range?.start &&
        filterParam?.activityFilter?.range?.end
          ? convertDateRangeToLabel(
              filterParam?.activityFilter.range.start,
              filterParam?.activityFilter.range.end
            )
          : undefined,

      assignedLead: employee
        ?.filter((e: any) => filterParam?.teamMembers?.includes(e?._id))
        ?.map((v: any) => {
          return {
            value: v?._id,
            label:
              v?.firstName && v?.lastName
                ? v?.firstName + " " + v?.lastName
                : v?.firstName,
          };
        }),
    });
  }, [labels, leadSource, status, teamMembers, employee, filterParam]);

  const fetchTeams = async () => {
    const response = await getAllTeams();
    if (response && response.status) {
      let tempArray = [...response.data.data];
      tempArray.forEach((el: any) => {
        el.showName = el.name + "-" + "Team";
      });

      let tempOrgData = {
        showName:
          StoreData.userData.userDetails.organization.name +
          "-" +
          "Organization",
        _id: "organization",
      };
      tempArray.push(tempOrgData);
      setTeamMembers(tempArray);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleFilter = (e: any, name: any) => {
    if (name === "team") {
      if (e[0]?.value === "organization") {
        setIsOrganization(true);
      } else {
        setIsOrganization(false);
      }
    }
    setFilterData({ ...filterData, [name]: e });
  };
  const dateFilteronChange = (e: any, name: string) => {
    setFilterData({ ...filterData, [name]: e });
    switch (e?.value) {
      case "today":
        setStartDate(moment().subtract(0, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "yesterday":
        setStartDate(moment().subtract(1, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "3DayBefore":
        setStartDate(moment().subtract(3, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "1WeekBefore":
        setStartDate(moment().subtract(1, "w").utc().format());
        setShowDatePicker(false);
        break;
      case "1MonthBefore":
        setStartDate(moment().subtract(30, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "custom":
        setShowDatePicker(true);
        break;
      default:
        break;
    }
  };

  const selectTeamMember = (val: any) => {
    setMultiSelections(val);
  };

  const selectOrderBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderBy(e?.currentTarget?.value);
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isAsc = e?.currentTarget?.value === "true" ? true : false;
    setIsAscending(isAsc);
  };

  // should take selected value
  // isAscending to false
  // assigned to not showing(read only)

  const resetFilter = () => {
    const resetFilter = () => {
      setFilterData({});  // Reset all filter data
      setIsAscending(false);  // Reset ascending/descending toggle
      setStartDate(undefined);  // Reset start date
      setEndDate(undefined);  // Reset end date
      setShowDatePicker(false);  // Hide date picker
      setTaskFromDate(undefined);  // Reset task from date
      setTaskToDate(undefined);  // Reset task to date
      setActivityFromDate(undefined);  // Reset activity from date
      setActivityToDate(undefined);  // Reset activity to date
      dispatch(resetLeadFilter());  // Dispatch action to reset filter in Redux store
    };
  };

  const applyFilter = async () => {
    var tempLabel = filterData?.label;
    var filterLabel = [];
    for (let i = 0; i < tempLabel?.length; i++) {
      filterLabel.push(tempLabel[i].value);
    }
    var tempStatus = filterData?.status;
    var filterStatus = [];
    for (let i = 0; i < tempStatus?.length; i++) {
      filterStatus.push(tempStatus[i].value);
    }
    var tempSource = filterData?.sources;
    var filterSource = [];
    for (let i = 0; i < tempSource?.length; i++) {
      filterSource.push(tempSource[i].value);
    }
    var tempEmp = filterData?.employee;
    var filterEmp = [];
    for (let i = 0; i < tempEmp?.length; i++) {
      filterEmp.push(tempEmp[i].value);
    }

    var tempTeam = filterData?.team;
    var filterTeame = [];
    if (filterData?.team) {
      if (tempTeam[0]?.value !== "organization") {
        for (let i = 0; i < tempTeam?.length; i++) {
          filterTeame.push(tempTeam[i].value);
        }
      }
    }
    let dateLeadSD = startDate;
    let dateLeadED = endDate;

    if (filterData.dateLead && filterData.dateLead.value !== "custom") {
      const { startDate, endDate } =
        getDateRange(filterData.dateLead.value) || {};
      dateLeadSD = startDate;
      dateLeadED = endDate;
    }

    let taskTypes: string[] = [];

    if (filterData?.taskType?.length) {
      filterData?.taskType?.map((e: any) => {
        taskTypes.push(e.value);
      });
    }

    let taskStartDate = taskFromDate,
      taskEndDate = taskToDate;

    if (
      filterData?.taskSelectedDate &&
      filterData?.taskSelectedDate?.value !== "custom"
    ) {
      const { startDate, endDate } =
        getDateRange(filterData?.taskSelectedDate?.value) || {};
      taskStartDate = startDate;
      taskEndDate = endDate;
    }

    let assignLeadData: string[] = [];

    if (filterData?.assignedLead && filterData?.assignedLead?.length) {
      filterData?.assignedLead?.map((e: any) => {
        assignLeadData.push(e.value);
      });
    }

    let activityTypes: string[] = [];

    if (
      filterData?.customActivityName &&
      filterData?.customActivityName?.length
    ) {
      filterData?.customActivityName?.map((e: any) => {
        activityTypes.push(e.value);
      });
    }

    let activityStartDate = activityFromDate,
      activityEndDate = activityToDate;

    if (
      filterData?.activitySelectedDate &&
      filterData?.activitySelectedDate?.value !== "custom"
    ) {
      const { startDate, endDate } =
        getDateRange(filterData?.activitySelectedDate?.value) || {};
      activityStartDate = startDate;
      activityEndDate = endDate;
    }

    let customLeadObj: any = {};

    if (
      filterData?.selectCustomLead &&
      filterData?.operator &&
      filterData?.leadformValue
    ) {
      customLeadObj.customFieldFilter = {
        fieldName: filterData?.selectCustomLead?.value,
      };

      if (filterData?.operator === "between") {
        customLeadObj.customFieldFilter.range = {
          start: {
            operator: "gte",
            value: filterData?.leadformValue,
          },
          end: {
            operator: "lte",
            value: filterData?.endLeadformValue,
          },
        };
      } else {
        customLeadObj.customFieldFilter.single = {
          operator: filterData?.operator?.value,
          value: filterData?.leadformValue,
        };
      }
    }

    let taskFilter: any = {};

    if (filterData?.taskType?.length) {
      taskFilter = {
        types: taskTypes,
      };
    }

    if (filterData?.taskSelectedDate) {
      taskFilter = {
        ...taskFilter,
        range: {
          start: taskStartDate,
          end: taskEndDate,
        },
      };
    }

    let activityFilter: any = {};

    if (activityTypes?.length) {
      activityFilter.activityFilter = {
        types: activityTypes,
      };
    }

    if (filterData?.activitySelectedDate) {
      activityFilter.activityFilter = {
        ...activityFilter?.activityFilter,
        range: {
          start: activityStartDate,
          end: activityEndDate,
        },
      };
    }

    let obj = {
      ...(filterData.sortLeadBy
        ? {
            sort: {
              orderBy: filterData?.sortLeadBy?.value,
              isAscending: false,
            },
          }
        : {
            sort: {
              isAscending: false,
            },
          }),
      ...(dateLeadSD &&
        dateLeadED && {
          date: {
            startedAt: dateLeadSD,
            endedAt: dateLeadED,
          },
        }),
      ...(filterLabel.length && { label: filterLabel }),
      ...(isOrganization && { byOrganization: isOrganization }),
      ...(filterStatus.length && { status: filterStatus }),
      ...(filterSource.length && { source: filterSource }),
      ...(filterEmp.length && { teamMembers: filterEmp }),
      ...(filterData?.assignedLead?.length && { teamMembers: assignLeadData }),
      ...(filterTeame.length && { teams: filterTeame }),

      ...(Object.keys(taskFilter || {}).length && { taskFilter }),

      ...(activityFilter && {
        ...activityFilter,
      }),

      ...(customLeadObj && {
        ...customLeadObj,
      }),

      ...(filterData?.assignedLead?.length && {
        assign: {
          isAssign: true,
          // teamMembers: assignLeadData,
        },
      }),
    };

    LeadFilterDataList(obj || {});
  };

  const getOperatorOptions = () => {
    if (!filterData?.selectCustomLead) return [];

    let isExist = customLeadOptions.find(
      (e: any) => e.value === filterData?.selectCustomLead?.value
    );

    switch (isExist?.type) {
      case "name":
      case "email":
      case "text":
        return [
          { label: "is", value: "eq" },
          { label: "contain", value: "contain" },
          { label: "does not contain", value: "doesNotContain" },
          { label: "is not", value: "ne" },
        ];

      case "number":
        return [
          { label: "greather than", value: "gt" },
          { label: "less than", value: "lt" },
          { label: "is", value: "eq" },
          { label: "between", value: "between" },
        ];

      case "phone":
        return [
          { label: "contain", value: "contain" },
          { label: "does not contain", value: "doesNotContain" },
        ];

      case "amount":
        return [
          { label: "greather than", value: "gt" },
          { label: "less than", value: "lt" },
          { label: "is", value: "eq" },
          { label: "is not", value: "ne" },
          { label: "between", value: "between" },
        ];

      case "selection":
        return [
          { label: "is", value: "eq" },
          { label: "is not", value: "ne" },
        ];

      default:
        return [];
    }
  };

  const isOptionsExist = () => {
    if (!filterData?.selectCustomLead) return [];

    let leadOptions =
      StoreData?.userData?.userDetails?.userPreference?.customForm;

    let isExist = leadOptions?.find(
      (e: any) => e.value === filterData?.selectCustomLead?.value
    );

    if (isExist?.type !== "selection") return [];

    return isExist?.options?.map((e: string) => ({ label: e, value: e }));
  };

  const advanceDateFilteronChange = (
    e: any,
    name: string,
    callback: (e: any) => any
  ) => {
    setFilterData({ ...filterData, [name]: e });
    switch (e?.value) {
      case "today":
        callback(moment().subtract(0, "d").utc().format());
        setDatePickerKey("");
        break;
      case "yesterday":
        callback(moment().subtract(1, "d").utc().format());
        setDatePickerKey("");
        break;
      case "3DayBefore":
        callback(moment().subtract(3, "d").utc().format());
        setDatePickerKey("");
        break;
      case "1WeekBefore":
        callback(moment().subtract(1, "w").utc().format());
        setDatePickerKey("");
        break;
      case "1MonthBefore":
        callback(moment().subtract(30, "d").utc().format());
        setDatePickerKey("");
        break;
      case "custom":
        setDatePickerKey(name);
        break;
      default:
        break;
    }
  };

  return (
    <form id="LeadsFilterForm" className="leadsform">
      <div className="form-container">
        <div className="product_group pb-5">
          {/*  */}
          <label className="d-flex justify-content-end gap-3">
  <span className="form-label flex-item text-center" style={{ marginTop: 2 }}>
  <span role="img" aria-label="ascending">🔼</span> Ascending
  </span>
  <div 
    className={`custom-toggle ${isAscending ? 'active' : ''}`} 
    onClick={() => setIsAscending(!isAscending)}
  >
    <div className="toggle-handle"></div>
  </div>
</label>

          <div className="form-group">
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="row mb-2 mt-3 pointer-event"
            >
              <span
                className="col fw-bold pointer-event"
                style={{ fontSize: 18, fontWeight: 'bold' }}
              >
                <span role="img" aria-label="advanced">🔍</span> Advanced Filters
              </span>
              <span className="col text-end pointer-event">
                {isOpen ? (
                  <i className="bi bi-chevron-up"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </span>
            </div>
            <p className="text-muted mb-3" style={{ fontSize: 14 }}>
    Use these filters for more specific lead searches
  </p>
            {isOpen ? (
              <>
                <label className="form-label">Task Type</label>
                <Select
                  isMulti
                  value={filterData.taskType}
                  className="basic-single mb-2"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="taskType"
                  options={taskOptions}
                  onChange={(e: any) => handleFilter(e, "taskType")}
                />

                <label className="form-label">Task due date range</label>
                <Select
                  value={filterData.taskSelectedDate}
                  className="basic-single mb-2"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="dateLead"
                  options={dateLeadAdded}
                  onChange={(e: any) =>
                    advanceDateFilteronChange(
                      e,
                      "taskSelectedDate",
                      setTaskFromDate
                    )
                  }
                />

                {datePickerKey === "taskSelectedDate" && (
                  <div className="form-group">
                    <label className="form-label">Custom Dates</label>
                    <div className="custom_date_picker">
                      <DatePicker
                        format={"DD MMM YYYY"}
                        showToday={false}
                        // value={startDate}
                        onChange={(date: any) => setTaskFromDate(date)}
                      />
                      <DatePicker
                        format={"DD MMM YYYY"}
                        showToday={false}
                        // value={endDate}
                        onChange={(date: any) => setTaskToDate(date)}
                      />
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="lead-date" className="form-label">
                  <span role="img" aria-label="assigned">🎯</span> Lead assigned to
                  </label>
                  <Select
                    isMulti
                    value={filterData.assignedLead}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    options={employee?.map((v: any) => {
                      return {
                        value: v?._id,
                        label:
                          v?.firstName && v?.lastName
                            ? v?.firstName + " " + v?.lastName
                            : v?.firstName,
                      };
                    })}
                    onChange={(e: any) => handleFilter(e, "assignedLead")}
                  />
                </div>

                {/* Custom Lead Form Field */}

                <span className="fw-bold d-block mt-2" style={{ fontSize: 14 }}>
                  Custom leadform field
                </span>

                <div className="form-group mt-2">
                  <Select
                    placeholder="Select Custom field"
                    value={filterData.selectCustomLead}
                    isClearable={true}
                    name="selectCustomLead"
                    options={customLeadOptions?.map((e: any) => {
                      delete e.options;
                      return e;
                    })}
                    onChange={(e: any) => handleFilter(e, "selectCustomLead")}
                  />
                </div>

                <div className="form-group">
                  <Select
                    placeholder="Select condition"
                    value={filterData.operator}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="selectCustomLead"
                    options={getOperatorOptions()}
                    onChange={(e: any) => handleFilter(e, "operator")}
                  />
                </div>

                {isOptionsExist()?.length ? (
                  <div>
                    <Select
                      placeholder="Value"
                      value={filterData.leadformValue}
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={true}
                      options={isOptionsExist()}
                      onChange={(e: any) => handleFilter(e, "leadformValue")}
                    />
                  </div>
                ) : (
                  <input
                    type="text"
                    placeholder={
                      filterData?.operator === "between"
                        ? "Enter Start Value"
                        : "Enter value"
                    }
                    className={`${
                      filterData?.operator !== "between" && "d-block"
                    } mb-3 mt-1 lead_input`}
                    value={filterData.leadformValue}
                    onChange={(e: any) =>
                      handleFilter(e.target.value, "leadformValue")
                    }
                  />
                )}

                {filterData?.operator === "between" && (
                  <input
                    type="text"
                    placeholder="Enter End Value"
                    className="mb-3 lead_input"
                    style={{ marginLeft: 10 }}
                    value={filterData.endLeadformValue}
                    onChange={(e: any) =>
                      handleFilter(e.target.value, "endLeadformValue")
                    }
                  />
                )}

                {/* Custom activity */}

                <span className="fw-bold d-block mt-2" style={{ fontSize: 14 }}>
                  Custom activity
                </span>
                <span className="d-block mb-2" style={{ fontSize: 12 }}>
                  Eg- Show leads who were called in last 7 days
                </span>

                <Select
                  isMulti
                  value={filterData.customActivityName}
                  className="basic-single"
                  classNamePrefix="select"
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name="sortLeadBy"
                  options={[
                    ...activityTypes?.map((e: any) => ({
                      ...e,
                      label: e.name,
                    })),
                    ...CUSTOM_ACTIVITY_TYPE,
                  ]}
                  onChange={(e: any) => handleFilter(e, "customActivityName")}
                />

                <span className="d-block mt-2 mb-2" style={{ fontSize: 14 }}>
                  Was perfomed in last
                </span>

                <Select
                  value={filterData.activitySelectedDate}
                  className="basic-single mb-4"
                  classNamePrefix="select"
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  options={dateLeadAdded}
                  onChange={(e: any) =>
                    advanceDateFilteronChange(
                      e,
                      "activitySelectedDate",
                      setActivityFromDate
                    )
                  }
                />

                {datePickerKey === "activitySelectedDate" && (
                  <div className="form-group">
                    <label className="form-label">Custom Dates</label>
                    <div className="custom_date_picker">
                      <DatePicker
                        format={"DD MMM YYYY"}
                        showToday={false}
                        // value={startDate}
                        onChange={(date: any) => setActivityFromDate(date)}
                      />
                      <DatePicker
                        format={"DD MMM YYYY"}
                        showToday={false}
                        // value={endDate}
                        onChange={(date: any) => setActivityToDate(date)}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : null}

            {/*  */}

            <label htmlFor="lead-date" className="form-label">
            <span role="img" aria-label="sort">↕️</span> Sort lead by
            </label>
            <Select
              value={filterData.sortLeadBy}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="sortLeadBy"
              options={sortLeadBy}
              onChange={(e: any) => handleFilter(e, "sortLeadBy")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
            <span role="img" aria-label="calendar">📅</span> Date lead added
            </label>
            <Select
              value={filterData.dateLead}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="dateLead"
              options={dateLeadAdded}
              onChange={(e: any) => dateFilteronChange(e, "dateLead")}
            />
          </div>
          {showDatePicker && (
            <div className="form-group">
              <label className="form-label">Custom Dates</label>
              <div className="custom_date_picker">
                <DatePicker
                  format={"DD MMM YYYY"}
                  showToday={false}
                  // value={startDate}
                  onChange={(date: any) => setStartDate(date)}
                />
                <DatePicker
                  format={"DD MMM YYYY"}
                  showToday={false}
                  // value={endDate}
                  onChange={(date: any) => setEndDate(date)}
                />
              </div>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
            <span role="img" aria-label="label">🏷️</span> Lead Labels
            </label>
            <Select
              value={filterData.label}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isMulti
              isSearchable={true}
              name="color"
              options={labels?.map((v: any) => {
                return { value: v?.value, label: v?.name };
              })}
              onChange={(e: any) => handleFilter(e, "label")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
            <span role="img" aria-label="status">🚦</span> Lead Status  
            <span 
      className="ms-1" 
      title="Select the current status of the lead"
      style={{ cursor: 'help' }}
    >
      ℹ️
    </span>
              </label>
            <Select
              value={filterData.status}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isMulti
              isSearchable={true}
              name="color"
              options={status?.map((v: any) => {
                return { value: v?.value, label: v?.name };
              })}
              onChange={(e: any) => handleFilter(e, "status")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
            <span role="img" aria-label="source">📊</span> Lead Sources
            </label>
            <Select
              value={filterData.sources}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isMulti
              isSearchable={true}
              name="color"
              options={leadSource?.map((v: any) => {
                return { value: v?.value, label: v?.name };
              })}
              onChange={(e: any) => handleFilter(e, "sources")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
            <span role="img" aria-label="team">👥</span> Team
            </label>
            <Select
              value={filterData.team}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isMulti
              isSearchable={true}
              name="color"
              options={teamMembers?.map((v: any) => {
                return { value: v?._id, label: v?.showName };
              })}
              onChange={(e: any) => handleFilter(e, "team")}
            />
          </div>
          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
            <span role="img" aria-label="team-member">👤</span> Team Member
            </label>
            <Select
              value={filterData.employee}
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isMulti
              isSearchable={true}
              name="color"
              options={employee?.map((v: any) => {
                return {
                  value: v?._id,
                  label:
                    v?.firstName && v?.lastName
                      ? v?.firstName + " " + v?.lastName
                      : v?.firstName,
                };
              })}
              onChange={(e: any) => handleFilter(e, "employee")}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center w-100 fixed-bottom lead_filter_bottom_button">
        <button
    type="button"
    className="btn btn-outline-secondary LeadsFilterReset me-3"
    onClick={resetFilter}
    style={{ fontSize: '16px', padding: '10px 20px' }}
  >
    Reset
  </button>
  <button
    type="button"
    className="btn btn-primary LeadsFilterApply"
    onClick={applyFilter}
    style={{ fontSize: '16px', padding: '10px 20px' }}
  >
    Apply Filters
  </button>
        </div>
      </div>
    </form>
  );
};

export default LeadFilter;
