import React, { useState, useCallback, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Fill } from "react-icons/ri";
import styled from 'styled-components';
import toast from "react-hot-toast";
import { uploadFiles } from '../../services/utilityService';
import { FileWithProgress, LogLevel } from './types';
import { 
  FileText, 
  Image as ImageIcon, 
  Video, 
  File as FileIcon, 
  Upload, 
  Trash2 
} from 'lucide-react';
import _ from 'lodash';
import { Logger } from '../../utils/logger';

// Define the ref interface for imperative access
export interface FileUploadHandlerRef {
  uploadPendingFiles: (contentId: string) => Promise<FileWithProgress[]>;
  resetFiles: () => void;
}

interface FileUploadHandlerProps {
  files: Array<{fileName?: string; filePath?: string; name?: string; size?: number}>;
  onFileChange: (files: FileWithProgress[]) => void;
  onDeleteServerFile: (path: string) => Promise<void>;
  maxSize?: number;
  acceptedTypes?: string[];
  activeId?: string;
}

// Enhanced component with proper ref forwarding
const FileUploadHandler = forwardRef<FileUploadHandlerRef, FileUploadHandlerProps>(({ 
  files = [],
  onFileChange,
  onDeleteServerFile,
  maxSize = 100,
  acceptedTypes = ['.mp4', '.wmv', '.pdf', '.jpeg', '.jpg', '.png', '.gif', '.webp'],
  activeId
}, ref) => {
  const [localFiles, setLocalFiles] = useState<FileWithProgress[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  const [filesWithData, setFilesWithData] = useState<Map<string, File>>(new Map());
  const fileObjectsRef = useRef<Map<string, File>>(new Map());
  // Logger function
  const log = (message: string, level: LogLevel = LogLevel.INFO) => {
    Logger.log(`[FileUploadHandler] [${level}] ${message}`);
  };

  // Convert existing files to our format
 // In FileUploadHandler.tsx
useEffect(() => {
  // Only run this effect when the 'files' prop changes
  const formattedFiles = files.map(file => ({
    name: file.name || file.fileName || '',
    fileName: file.fileName || file.name || '',
    filePath: file.filePath || '',
    size: file.size || 0,
    uploaded: !!file.filePath
  }));
  
  log(`Initialized with ${formattedFiles.length} files`, LogLevel.DEBUG);
  
  // Add a check to prevent unnecessary state updates
  if (!_.isEqual(formattedFiles, localFiles)) {
    setLocalFiles(formattedFiles);
  }
}, [files]); // Only depend on the 'files' prop
  
  useEffect(() => {
    return () => {
      log('Component unmounting', LogLevel.DEBUG);
      // Clean up any resources if needed
    };
  }, []);

  // Expose methods via ref
  useImperativeHandle(ref, () => ({
    
    // Upload any pending files
   // Replace the uploadPendingFiles method in FileUploadHandler.tsx
uploadPendingFiles: async (contentId: string) => {
  log(`Uploading pending files for content ID: ${contentId}`, LogLevel.INFO);
  
  if (!contentId) {
    const errorMsg = 'Cannot upload files - missing content ID';
    log(errorMsg, LogLevel.ERROR);
    toast.error(errorMsg);
    throw new Error(errorMsg);
  }
  
  const pendingFiles = localFiles.filter(file => !file.uploaded && !file.filePath);
  log(`Found ${pendingFiles.length} pending files to upload`, LogLevel.INFO);
  
  if (pendingFiles.length === 0) {
    log('No pending files to upload', LogLevel.INFO);
    return [];
  }
  
  setIsUploading(true);
  const uploadedFiles: FileWithProgress[] = [];
  
  try {
    for (const file of pendingFiles) {
      // Get the actual File object from our ref Map
      const actualFile = fileObjectsRef.current.get(file.name);
      
      if (!actualFile) {
        log(`No file data found for ${file.name}`, LogLevel.WARNING);
        continue;
      }
      
      const formData = new FormData();
      formData.append("files", actualFile); // Use the actual File object
      formData.append("type", "content");
      formData.append("content", contentId);
      
      log(`Sending upload request for file: ${file.name}`, LogLevel.INFO);
      const response = await uploadFiles(formData);
      
      log(`Upload response received: status ${response.status}`, LogLevel.DEBUG);
      
      if (response.status === 200 && response.data) {
        // The file data is inside the data array
        if (Array.isArray(response.data.data) && response.data.data.length > 0) {
          const fileData = response.data.data[0]; // Get the first file from the array
          
          log(`File upload successful: ${file.name} → ${fileData.fileName}`, LogLevel.INFO);
          
          const uploadedFile = {
            ...file,
            filePath: fileData.filePath,
            fileName: fileData.fileName,
            uploaded: true,
            progress: 100
          };
          
          log(`Created uploaded file object: ${uploadedFile.fileName}, path: ${uploadedFile.filePath}`, LogLevel.DEBUG);
          uploadedFiles.push(uploadedFile);
        } else {
          log(`Upload response missing file data array for file: ${file.name}`, LogLevel.WARNING);
        }
      
      } else {
        log(`Upload response not successful for file: ${file.name}`, LogLevel.WARNING);
      }
    }
    
    // Update local state with uploaded files
    setLocalFiles(prev => {
      const updated = [...prev];
      
      for (const uploaded of uploadedFiles) {
        const index = updated.findIndex(f => f.name === uploaded.name);
        if (index !== -1) {
          log(`Updating file at index ${index} with uploaded data`, LogLevel.DEBUG);
          updated[index] = uploaded;
        }
      }
      
      log(`Updated local files state with ${uploadedFiles.length} uploaded files`, LogLevel.INFO);
      return updated;
    });
    
    // Notify parent of changes
    onFileChange([...localFiles, ...uploadedFiles]);
    
    return uploadedFiles;
  } catch (exception) {
    const error = exception as Error;
    log(`Error uploading files: ${error.message || String(error)}`, LogLevel.ERROR);
    console.error('Upload exception details:', exception);
    toast.error('Failed to upload files');
    throw error;
  } finally {
    setIsUploading(false);
  }
},
    
    // Reset files state
    resetFiles: () => {
      log('Resetting files state', LogLevel.INFO);
      setLocalFiles([]);
      onFileChange([]);
      
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }));

  // Validate a single file
  const validateFile = useCallback((file: File): string | null => {
    log(`Validating file: ${file.name}`, LogLevel.DEBUG);
    
    if (file.size > maxSize * 1024 * 1024) {
      return `File size exceeds ${maxSize}MB limit`;
    }

    const fileType = '.' + file.name.split('.').pop()?.toLowerCase();
    if (!acceptedTypes.includes(fileType)) {
      return `Invalid file type. Accepted: ${acceptedTypes.join(', ')}`;
    }

    return null;
  }, [maxSize, acceptedTypes]);

  // Handle file selection
  const handleFileSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    
    // Create file metadata objects for state management
    const fileMetadata: FileWithProgress[] = newFiles.map(file => {
      // Store the actual File object in our ref Map
      fileObjectsRef.current.set(file.name, file);
      
      return {
        name: file.name,
        size: file.size,
        progress: 0,
        uploaded: false,
        // Don't include uploadFile in the state object
      };
    });
    
    // Update local state and notify parent
    setLocalFiles(prev => [...prev, ...fileMetadata]);
    onFileChange([...localFiles, ...fileMetadata]);
    
    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [localFiles, onFileChange]);

  // Handle local file deletion
  const handleLocalFileDelete = useCallback((index: number) => {
    log(`Deleting local file at index: ${index}`, LogLevel.INFO);
    
    setLocalFiles(prev => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      onFileChange(newFiles);
      return newFiles;
    });
  }, [onFileChange]);

  return (
    <div className="user_form_box">
      <label>Upload File</label>
      <div className="file_upload position-relative mb-3" style={{ width: '58px' }}>
        <input
          ref={fileInputRef}
          type="file"
          accept={acceptedTypes.join(',')}
          className="position-relative"
          style={{ zIndex: "9", opacity: 0 }}
          onChange={handleFileSelect}
          multiple
          disabled={isUploading}
        />
      
      </div>

      <FilesSection>
  <UploadButton onClick={() => fileInputRef.current?.click()}>
    <input
      ref={fileInputRef}
      type="file"
      accept={acceptedTypes.join(',')}
      style={{ display: 'none' }}
      onChange={handleFileSelect}
      multiple
      disabled={isUploading}
    />
    <Upload size={20} />
    <span>Upload Files</span>
  </UploadButton>
  
  <FilesGrid>
  {localFiles.map((file, index) => {
  // Get file extension from either fileName or name
  const fileExt = ((file.fileName || file.name || '').split('.').pop()?.toLowerCase() || '');
  const displayName = file.fileName || file.name || 'Unknown file';
  
  Logger.log(`FileUploadHandler - Rendering file ${index}:`, { name: file.name, fileName: file.fileName, filePath: file.filePath });
  
  return (
    <FileCard key={`file-${index}`}>
      <FileIconWrapper>
        {['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExt) ? (
          <ImageIcon size={24} />
        ) : ['mp4', 'wmv', 'avi', 'mov'].includes(fileExt) ? (
          <Video size={24} />
        ) : ['pdf', 'doc', 'docx', 'txt'].includes(fileExt) ? (
          <FileText size={24} />
        ) : (
          <FileIcon size={24} />
        )}
      </FileIconWrapper>
      <FileDetails>
        <FileName>{displayName}</FileName>
        {file.progress !== undefined && file.progress > 0 && file.progress < 100 && (
          <ProgressBar>
            <ProgressFill style={{width: `${file.progress}%`}} />
          </ProgressBar>
        )}
      </FileDetails>
      <DeleteButton 
        onClick={() => {
          Logger.log('FileUploadHandler - Delete clicked for file:', file);
          if (file.filePath) {
            onDeleteServerFile(file.filePath);
          } else {
            handleLocalFileDelete(index);
          }
        }}
        disabled={isUploading}
        aria-label="Delete file"
      >
        <Trash2 size={16} />
      </DeleteButton>
    </FileCard>
  );
})}
  </FilesGrid>
</FilesSection>
      
      {isUploading && (
        <p className="upload-status">Uploading files, please wait...</p>
      )}
    </div>
  );
});
const FilesSection = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const UploadButton = styled.button`
  display: flex;
  align-items: center;
  background: #f0f9ff;
  border: 1px dashed #3FAEFB;
  border-radius: 6px;
  padding: 8px 16px;
  color: #3FAEFB;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 16px;
  
  span {
    margin-left: 8px;
  }
  
  &:hover {
    background: #e0f2fe;
  }
`;

const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 12px;
`;

const FileCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e4e9f0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
`;

const FileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: #f8fafc;
  color: #64748b;
`;

const FileDetails = styled.div`
  padding: 12px;
  background: white;
`;

const FileName = styled.div`
  font-size: 13px;
  color: #334155;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProgressBar = styled.div`
  height: 3px;
  background: #e2e8f0;
  border-radius: 3px;
  margin-top: 8px;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  height: 100%;
  background: #3FAEFB;
  transition: width 0.3s ease;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ef4444;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
  &:hover {
    background: #fee2e2;
    color: #dc2626;
    transform: scale(1.05);
  }
  
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export default FileUploadHandler;