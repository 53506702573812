import React, { useCallback } from 'react';
import { Form, Button, Input, InputNumber } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import {
  TaxContainer,
  SectionHeader,
  TitleContainer,
  SectionTitle,
  InfoIcon,
  ActionButton,
  TaxFieldsContainer,
  StyledIconButton,
  AddButton,
  StyledFormItem
} from './QuotationAdjustmentsStyles';
import { DeleteOutlined } from '@ant-design/icons';

interface TaxRate {
  title: string;
  rate: number;
}

interface TaxSectionProps {
  show: boolean;
  onToggle: () => void;
  form: FormInstance;
  onValuesChange: (values: any) => void;
  baseAmount: number;
  disabled?: boolean;
}

export const TaxSection: React.FC<TaxSectionProps> = ({
  show,
  onToggle,
  form,
  onValuesChange,
  baseAmount,
  disabled = false
}) => {
  // Calculate tax amount for a single rate
  const calculateTaxAmount = (rate: number): number => {
    return (baseAmount * rate) / 100;
  };

  // Calculate total tax amount
  const calculateTotalTax = useCallback((taxes: TaxRate[]): number => {
    return taxes.reduce((total, tax) => {
      return total + calculateTaxAmount(tax.rate);
    }, 0);
  }, [baseAmount]);

  // Handler for rate change to recalculate totals
  const handleRateChange = useCallback(() => {
    const values = form.getFieldsValue();
    const taxes = values.taxes || [];
    const totalTax = calculateTotalTax(taxes);
    onValuesChange({ ...values, totalTax });
  }, [form, onValuesChange, calculateTotalTax]);

  // Handle click on initial "Add Tax" button
  const handleAddTaxClick = () => {
    if (!show) {
      onToggle();
      // Add first tax field automatically
      setTimeout(() => {
        form.setFieldsValue({
          taxes: [{ title: '', rate: 0 }]
        });
      }, 0);
    }
  };

  const validateTaxRate = (_: any, value: number) => {
    if (value === null || value === undefined) {
      return Promise.reject('Tax rate is required');
    }
    if (value < 0) {
      return Promise.reject('Tax rate cannot be negative');
    }
    if (value > 100) {
      return Promise.reject('Tax rate cannot exceed 100%');
    }
    return Promise.resolve();
  };

  const validateUniqueTaxTitle = (_: any, value: string) => {
    if (!value) return Promise.resolve();
    
    const { taxes = [] } = form.getFieldsValue();
    const taxCount = taxes.filter(
      (tax: TaxRate) => tax?.title?.toLowerCase() === value.toLowerCase()
    ).length;
    
    if (taxCount > 1) {
      return Promise.reject('Tax title must be unique');
    }
    return Promise.resolve();
  };

  // Get current taxes from form
  const currentTaxes = Form.useWatch('taxes', form) || [];

  return (
    <TaxContainer>
      <SectionHeader>
        <TitleContainer>
          <SectionTitle>Tax</SectionTitle>
          <InfoIcon 
            title="Add tax rates that will be calculated on the amount after discount"
          />
        </TitleContainer>
        {show && currentTaxes.length > 0 ? (
          <ActionButton 
            type="link" 
            onClick={() => {
              form.setFieldsValue({ taxes: [] });
              onToggle();
              handleRateChange();
            }}
            disabled={disabled}
          >
            Remove All
          </ActionButton>
        ) : (
          <ActionButton 
            type="link" 
            onClick={handleAddTaxClick}
            disabled={disabled}
          >
            Add Tax
          </ActionButton>
        )}
      </SectionHeader>

      {show && (
        <Form.List name="taxes">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <TaxFieldsContainer key={field.key}>
                  <StyledFormItem
                    {...field}
                    name={[field.name, 'title']}
                    label="Tax Title"
                    rules={[
                      { required: true, message: 'Tax title is required' },
                      { max: 50, message: 'Title too long' },
                      { validator: validateUniqueTaxTitle }
                    ]}
                  >
                    <Input 
                      placeholder="Enter title" 
                      disabled={disabled}
                    />
                  </StyledFormItem>

                  <StyledFormItem
                    {...field}
                    name={[field.name, 'rate']}
                    label="Rate (%)"
                    rules={[
                      { required: true, message: 'Tax rate is required' },
                      { validator: validateTaxRate }
                    ]}
                  >
                     <InputNumber
                      min={0}
                      max={100}
                      disabled={disabled}
                      formatter={(value) => {
                        if (value === undefined || value === null) return '';
                        return `${value}%`;
                      }}
                      parser={(value): 0 | 100 => {
                        if (!value) return 0;
                        const parsed = parseFloat(value.replace(/[^\d.-]/g, ''));
                        if (isNaN(parsed) || parsed < 0) return 0;
                        if (parsed > 100) return 100;
                        // Since we need to return either 0 or 100 for type safety
                        return parsed as 0 | 100;
                      }}
                      onChange={handleRateChange}
                      onStep={handleRateChange}
                    />
                  </StyledFormItem>

                  {!disabled && (
                    <StyledIconButton 
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        remove(field.name);
                        handleRateChange();
                      }}
                    />
                  )}
                </TaxFieldsContainer>
              ))}

              {!disabled && fields.length > 0 && (
                <AddButton 
                  type="dashed" 
                  onClick={() => add()} 
                >
                  <span>+</span> Add Another Tax
                </AddButton>
              )}
            </>
          )}
        </Form.List>
      )}
    </TaxContainer>
  );
};