import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";
import { message } from 'antd';
import { Logger } from '../utils/logger';

const API_ENDPOINT = API_URL + "api/v1";

class LeadExportService {
  private static getAuthHeaders() {
    return {
      ...getHeaderOptions,
      Authorization: localStorage.getItem("auth_token") || "",
    };
  }

  public static async exportBulkLeads(
    leadIds: string[],
  ): Promise<void> {
    try {
      Logger.log('Starting bulk export with leadCount:', leadIds.length);
      message.loading({ content: 'Initiating export...', key: 'exportLoading' });
      
      const exportResponse = await axios.post(
        `${API_ENDPOINT}/user/export-leads`,
        { leadIds },
        { headers: this.getAuthHeaders() }
      );
      
      Logger.log('Export response:', exportResponse.data);
  
      if (exportResponse.data.status) {
        message.destroy('exportLoading');
        message.success('Export initiated. You will receive the file in your email shortly.');
      } else {
        throw new Error('Export failed');
      }
    } catch (error) {
      console.error('Bulk export error:', error);
      message.destroy('exportLoading');
      message.error('Export failed. Please try again.');
      throw error;
    }
  }

  public static async exportLeads(
    campaignId: string,
    filters?: {
      status?: string;
      searchTerm?: string;
    }
  ): Promise<void> {
    Logger.log('Starting campaign export:', { campaignId, filters });
    message.loading({ content: 'Processing export...', key: 'exportLoading' });
    
    try {
      // Get campaign leads with filters
      const params: Record<string, string> = { 
        page: '1', 
        perPage: '2000'
      };
      
      if (filters?.status) {
        params.status = filters.status;
      }
      
      if (filters?.searchTerm) {
        params.search = filters.searchTerm;
      }
      
      const leadsResponse = await axios.get(
        `${API_ENDPOINT}/campaign/${campaignId}`,
        { 
          headers: this.getAuthHeaders(),
          params: params
        }
      );

      Logger.log('Campaign leads fetched:', leadsResponse.data);
      const leadIds = leadsResponse.data.data.leads.map((lead: any) => lead._id);
      
      // Send to bulk export
      await this.exportBulkLeads(leadIds);
    } catch (error) {
      console.error('Campaign export error:', error);
      message.destroy('exportLoading');
      message.error('Export failed. Please try again.');
      throw error;
    }
  }
}

export default LeadExportService;