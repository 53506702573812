import axios, { AxiosResponse } from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";


interface TemplateParams {
  search?: string;
  page?: number;
  perPage?: number;
  orderBy?: string;
  isAscending?: boolean;
}

const API_ENDPOINT = API_URL + "api/v1";

export const getHeader = () => {
  return {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
};

export async function getTemplates(params: TemplateParams = {}): Promise<AxiosResponse<any, any>> {
  return await axios.get(`${API_ENDPOINT}/whatsapp/template`, {
    headers: getHeader(),
    params,
  });
}

export async function deleteTemplate(
  id: string
): Promise<AxiosResponse<any, any>> {
  return await axios.delete(`${API_ENDPOINT}/whatsapp/template/${id}`, {
    headers: getHeader(),
  });
}
export async function updateTemplate(
  id: string,
  data: any
): Promise<AxiosResponse<any, any>> {
  return await axios.put(`${API_ENDPOINT}/whatsapp/template/${id}`, data, {
    headers: getHeader(),
  });
}

export async function createTemplate(
  data: any
): Promise<AxiosResponse<any, any>> {
  return await axios.post(`${API_ENDPOINT}/whatsapp/template`, data, {
    headers: getHeader(),
  });
}

export async function getTemplateById(id: string): Promise<AxiosResponse<any, any>> {
  return await axios.get(`${API_ENDPOINT}/whatsapp/template/${id}`, {
    headers: getHeader(),
  });
}
