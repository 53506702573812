import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { Logger } from '../../../utils/logger';

interface TaskFilterProps {
  toggle: () => void;
  modal: boolean;
  filters: any;
  applyFilter: (filterData: any) => void;
}

interface FilterState {
  sortTeamsBy: string;
  sortTeamMemberBy: string;
  sortTaskAssignBy: string;
  orderBy: string;
  taskType: string;
  dateTask: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

const TaskFilter = ({
  toggle,
  modal,
  filters,
  applyFilter,
  ...args
}: TaskFilterProps) => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<any>({});
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [filterState, setFilterState] = useState<FilterState>({
    sortTeamsBy: "",
    sortTeamMemberBy: "",
    sortTaskAssignBy: "",
    orderBy: "",
    taskType: "",
    dateTask: "",
    startDate: null,
    endDate: null
  });
  const storeState = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const dateCollection = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "3DayBefore", label: "3 Days Before Now" },
    { value: "1WeekBefore", label: "1 Week Before Now" },
    { value: "1MonthBefore", label: "1 Month Before Now" },
    { value: "custom", label: "Custom Date" },
  ];
  const teams = [
    ...(storeState?.userData?.userDetails?.organizationTeams || []).map(
      (item: any) => {
        return { value: item?._id, label: item?.name };
      }
    ),
    {
      value: storeState?.userData?.userDetails?.organization?._id,
      label: storeState?.userData?.userDetails?.organization?.name,
    },
  ];
  const formatName = (firstName: string, lastName: string | null) => {
    if (!firstName) return '';
    return lastName ? `${firstName} ${lastName}`.trim() : firstName.trim();
  };
  const teamMembers = storeState?.userData?.userDetails?.organizationEmployee?.map(
    (item: any) => {
      return {
        value: item?._id,
        label: formatName(item?.firstName, item?.lastName)
      };
    }
  );

  const taskType = storeState?.user?.userPreferences?.taskType?.map(
    (item: any) => {
      return { value: item?.value, label: item?.name };
    }
  );
  const sortObject = [
    { id: 1, value: "type", label: "Type" },
    { id: 2, value: "toBePerformAt", label: "Due Date" },
    { id: 3, value: "createdBy", label: "Created By" },
  ];

  // Add after state declarations, before dateFilterOnChange:
  const handleFilter = (value: any, name: keyof FilterState) => {
    setFilterState(prev => ({
      ...prev,
      [name]: value
    }));
  };

const getDateRange = (preset: string): { start: moment.Moment, end: moment.Moment } => {
  const end = moment().endOf('day');
  switch (preset) {
    case "today":
      return { 
        start: moment().startOf('day'), 
        end 
      };
    case "yesterday":
      return { 
        start: moment().subtract(1, 'day').startOf('day'),
        end: moment().subtract(1, 'day').endOf('day')
      };
    case "3DayBefore":
      return { 
        start: moment().subtract(3, 'days').startOf('day'),
        end
      };
    case "1WeekBefore":
      return { 
        start: moment().subtract(1, 'week').startOf('day'),
        end
      };
    case "1MonthBefore":
      return { 
        start: moment().subtract(1, 'month').startOf('day'),
        end
      };
    default:
      return { start: moment().startOf('day'), end };
  }
};



const dateFilterOnChange = (preset: string) => {
  if (preset === "custom") {
    setShowDatePicker(true);
    setFilterState(prev => ({
      ...prev,
      dateTask: preset,
      startDate: null,
      endDate: null
    }));
    return;
  }

  const { start, end } = getDateRange(preset);
  setShowDatePicker(false);
  setFilterState(prev => ({
    ...prev,
    dateTask: preset,
    startDate: start,
    endDate: end
  }));
};

const handleResetFilter = () => {
  setFilterState({
    sortTeamsBy: "",
    sortTeamMemberBy: "",
    sortTaskAssignBy: "",
    orderBy: "",
    taskType: "",
    dateTask: "",
    startDate: null,
    endDate: null
  });
  setShowDatePicker(false);
  
  applyFilter({
    startDate: null,
    endDate: null,
    byOrganization: false
  });
};


 
  
const handleApplyClick = () => {
  const { startDate, endDate, sortTeamsBy } = filterState;
  
  if (filterState.dateTask === 'custom' && (!startDate || !endDate)) {
    return;
  }

  // Format dates with consistent format
  const formattedStartDate = startDate ? 
    moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null;
  const formattedEndDate = endDate ? 
    moment(endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null;

  const filterData = {
    ...filterState,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    startedAt: formattedStartDate,  // Add these explicitly
    endedAt: formattedEndDate,      // Add these explicitly
    byOrganization: sortTeamsBy === storeState?.userData?.userDetails?.organization?._id
  };

  applyFilter(filterData);
};

  const handleStartDateChange = (date: moment.Moment | null) => {
    setFilterState(prev => ({
      ...prev,
      startDate: date
    }));
  };
  
  const handleEndDateChange = (date: moment.Moment | null) => {
    setFilterState(prev => ({
      ...prev,
      endDate: date
    }));
  };

  const checkTimeDifference = (startD: any = "", endD: any = "") => {
    if (startD && endD) {
      const startDate: Date = new Date(startD);
      const endDate: Date = new Date(endD);
      const timeDifference: number = Math.floor(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
      );
      Logger.log(`${timeDifference} days`);
      Logger.log("filterData days  ====>", timeDifference);
      switch (timeDifference) {
        case 1:
          return "today";
        case 2:
          return "yesterday";
        case 4:
          return "3DayBefore";
        case 8:
          return "1WeekBefore";
        case 32:
          return "1MonthBefore";
        default:
          setShowDatePicker(true);
          // setStartDate(startD)
          // setEndDate(endD)
          return "custom";
      }
    } else {
      Logger.log("filterData days return noo ====>");
      return "";
    }
  };

  useEffect(() => {
    if (filters) {
      setFilterState(prev => ({
        ...prev,
        sortTeamsBy: filters?.team,
        sortTeamMemberBy: filters?.userId,
        sortTaskAssignBy: filters?.taskAssign,
        orderBy: filters?.orderBy,
        taskType: filters?.taskType,
        dateTask: checkTimeDifference(filters?.startedAt, filters?.endedAt)
      }));
    }
  }, [filters]);

  return (
    <DrawerBox>
      <div>
        <div className="form-group">
          <label className="form-label">Teams</label>
          <select
  id="inputState"
  value={filterState.sortTeamsBy}  // Changed from filterData
  onChange={(e: any) => handleFilter(e.target.value, "sortTeamsBy")}
  name="type"
  className="form-select"
>
            <option value="" disabled={filterData?.sortTeamsBy?.length > 1}>
              Select Team
            </option>
            {teams?.map((task: any, ix: number) => (
              <option value={task?.value} key={ix}>
                {task?.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="form-label">Team member</label>
          
<select
  id="inputState"
  value={filterState.sortTeamMemberBy}  // Changed from filterData
  onChange={(e: any) => handleFilter(e.target.value, "sortTeamMemberBy")}
  name="type"
  className="form-select"
>
            <option
              value=""
              disabled={filterData?.sortTeamMemberBy?.length > 1}
            >
              Select Team Member
            </option>
            {teamMembers?.map((task: any, ix: number) => (
              <option value={task?.value} key={ix}>
                {task?.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="form-label">Due Date</label>
          <select
  id="inputState"
  value={filterState.dateTask}  // Changed from filterData
  onChange={(e: any) => dateFilterOnChange(e.target.value)}  // Removed second parameter
  name="type"
  className="form-select"
>
            <option value="" disabled={filterData?.dateTask?.length > 1}>
              Select Date
            </option>
            {dateCollection?.map((data: any, ix: number) => (
              <option value={data?.value} key={ix}>
                {data?.label}
              </option>
            ))}
          </select>
        </div>

     

        {showDatePicker && (
  <div className="form-group">
    <label className="form-label">Custom Dates</label>
    <div className="custom_date_picker">
      <DatePicker
        format="DD MMM YYYY"
        value={filterState.startDate}
        onChange={handleStartDateChange}
        disabledDate={(current: moment.Moment): boolean => {
          return current.isAfter(moment());
        }}
      />
      <DatePicker
        format="DD MMM YYYY"
        value={filterState.endDate}
        onChange={handleEndDateChange}
        disabledDate={(current: moment.Moment): boolean => {
          const startDate = filterState.startDate;
          // Ensure we return a boolean by using !! or explicit comparison
          return current.isAfter(moment()) || (!!startDate && current.isBefore(startDate));
          // Alternative approach:
          // return current.isAfter(moment()) || (startDate ? current.isBefore(startDate) : false);
        }}
      />
    </div>
  </div>
)}

        <div className="form-group">
          <label className="form-label"> Task assign</label>
          <select
  id="inputState"
  value={filterState.sortTaskAssignBy}  // Changed from filterData
  onChange={(e: any) => handleFilter(e.target.value, "sortTaskAssignBy")}
  name="type"
  className="form-select"
>
            <option
              value=""
              disabled={filterData?.sortTaskAssignBy?.length > 1}
            >
              Select Task assign
            </option>
            {teamMembers?.map((task: any, ix: number) => (
              <option value={task?.value} key={ix}>
                {task?.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="form-label">Sorting</label>
          <select
  id="inputState"
  value={filterState.orderBy}  // Changed from filterData
  onChange={(e: any) => handleFilter(e.target.value, "orderBy")}
  name="type"
  className="form-select"
>
            <option value="" disabled={filterData?.orderBy?.length > 1}>
              Select Sorting
            </option>
            {sortObject?.map((task: any, key: number) => (
              <option key={key} value={task?.value}>
                {task?.label}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label className="form-label"> Task type</label>
          <select
    id="inputState"
    value={filterState.taskType}  // Corrected to taskType
    onChange={(e: any) => handleFilter(e.target.value, "taskType")}  // Corrected to taskType
    name="type"
    className="form-select"
  >
            <option value="" disabled={filterData?.taskType?.length > 1}>
              Select Task type
            </option>
            {taskType?.map((task: any, ix: number) => (
              <option value={task?.value} key={ix}>
                {task?.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="auto_form_btn">
        <button
          type="button"
          className="btn btn-primary-save"
          onClick={() => {
            handleResetFilter();
          }}
        >
          Reset
        </button>
        <button
          type="button"
          onClick={() => handleApplyClick()}
          className="btn btn-primary-save"
        >
          Apply
        </button>
      </div>
    </DrawerBox>
  );
};

export default TaskFilter;
const DrawerBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .form-group {
    margin: 14px 0px;
  }
  .auto_form_btn {
    position: sticky;
    margin-top: auto;
    bottom: 0px;
    display: flex;
    gap: 10px;
  }
`;
