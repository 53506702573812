import React, { useState, useEffect } from 'react';
  import { createPortal } from 'react-dom';
  import { 
    User, 
    FileText, 
    Calendar,
    MessageCircle, 
    Mail, 
    Receipt,
    ScrollText,
    Eye,
    Tag,
    ClipboardList,
    X as CloseIcon,
    Search,
    ListChecks,
    PhoneIncoming,
    PhoneOutgoing,
    PhoneMissed,
    PhoneCall,
    Edit
  } from 'lucide-react';
  import './TriggerSelectionPopup.css';
  
  // Define trigger option interface
  interface TriggerOption {
    value: string;
    name: string;
    description: string;
    category: string;
    icon: React.ReactNode;
  }
  
  // Define categories with user-friendly names
  const CATEGORIES = {
    LEAD: 'Lead Management',
    SALES: 'Sales & Quotes',
    ACTIVITY: 'Activities & Tasks',
    COMMUNICATION: 'Communication'
  };
  
  interface TriggerSelectionPopupProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (value: string) => void;
    currentValue: string;
    isEditMode: boolean;
    triggerOptions?: TriggerOption[];
  }
  
  const TriggerSelectionPopup: React.FC<TriggerSelectionPopupProps> = ({
    isOpen,
    onClose,
    onSelect,
    currentValue,
    isEditMode,
    triggerOptions = DEFAULT_TRIGGERS
  }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTriggers, setFilteredTriggers] = useState<TriggerOption[]>(triggerOptions);
  
    // Filter triggers based on search query
    useEffect(() => {
      if (!searchQuery.trim()) {
        setFilteredTriggers(triggerOptions);
        return;
      }
  
      const query = searchQuery.toLowerCase();
      const filtered = triggerOptions.filter(
        trigger => 
          trigger.name.toLowerCase().includes(query) || 
          trigger.description.toLowerCase().includes(query)
      );
      
      setFilteredTriggers(filtered);
    }, [searchQuery, triggerOptions]);
  
    // Group triggers by category
    const groupedTriggers = filteredTriggers
      // Filter out call_duration and call_count triggers
      .filter(trigger => trigger.value !== 'call_duration' && trigger.value !== 'call_count')
      .reduce((acc, trigger) => {
        if (!acc[trigger.category]) {
          acc[trigger.category] = [];
        }
        acc[trigger.category].push(trigger);
        return acc;
      }, {} as Record<string, TriggerOption[]>);
  
    // Handle trigger selection
    const handleTriggerSelect = (value: string) => {
      onSelect(value);
      onClose();
    };
  
    if (!isOpen) return null;
  
    return createPortal(
      <div className="trigger-popup-backdrop" onClick={(e) => {
        if (e.target === e.currentTarget) onClose();
      }}>
        <div className="trigger-popup-content">
          {/* Header */}
          <div className="trigger-popup-header">
            <h2>Choose Automation Trigger</h2>
            <button className="trigger-popup-close" onClick={onClose}>
              <CloseIcon size={20} />
            </button>
          </div>
  
          {/* Edit mode warning */}
          {isEditMode && (
            <div className="trigger-edit-warning">
              <p>The trigger cannot be changed after an automation is created. To use a different trigger, create a new automation rule.</p>
            </div>
          )}
  
          {/* Introduction text */}
          <div className="trigger-intro">
            <p>
              An automation trigger is the event that starts your workflow. When this event happens, 
              the automation will run and perform the actions you've set up.
            </p>
          </div>
          
          {/* Search */}
          <div className="trigger-search-container">
            <div className="trigger-search-input">
              <Search className="trigger-search-icon" size={18} />
              <input
                type="text"
                placeholder="Search for triggers..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                disabled={isEditMode}
              />
              {searchQuery && (
                <button 
                  className="trigger-search-clear" 
                  onClick={() => setSearchQuery('')}
                >
                  <CloseIcon size={16} />
                </button>
              )}
            </div>
          </div>
  
          {/* Body with categories */}
          <div className="trigger-popup-body">
            {Object.keys(groupedTriggers).length > 0 ? (
              Object.entries(groupedTriggers).map(([category, triggers], categoryIndex) => (
                <div key={`category-${categoryIndex}-${category}`} className="trigger-category-section">
                  <h3 className="trigger-category-title">{category}</h3>
                  <div className="trigger-grid">
                    {triggers.map((trigger, triggerIndex) => (
                      <div
                        key={`trigger-${categoryIndex}-${triggerIndex}-${trigger.value}`}
                        className={`trigger-card ${currentValue === trigger.value ? 'selected' : ''} ${isEditMode && currentValue !== trigger.value ? 'disabled' : ''}`}
                        onClick={() => {
                          // Only allow selection if not in edit mode or if this is the current value
                          if (!isEditMode || currentValue === trigger.value) {
                            handleTriggerSelect(trigger.value);
                          }
                        }}
                      >
                        <div className="trigger-card-icon">
                          {trigger.icon}
                        </div>
                        <h4 className="trigger-card-title">{trigger.name}</h4>
                        <p className="trigger-card-description">{trigger.description}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="trigger-empty-state">
                <p>No matching triggers found</p>
                <button onClick={() => setSearchQuery('')}>Clear search</button>
              </div>
            )}
          </div>
  
          {/* Footer */}
          <div className="trigger-popup-footer">
            <button 
              className="trigger-btn-secondary" 
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="trigger-btn-primary"
              onClick={() => onClose()}
              disabled={!currentValue}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>,
      document.body
    );
  };
  
  // Icons for call activities
  const IncomingCallIcon = () => (
    <div className="icon-placeholder">
      <PhoneIncoming size={22} />
    </div>
  );
  
  const OutgoingCallIcon = () => (
    <div className="icon-placeholder">
      <PhoneOutgoing size={22} />
    </div>
  );
  
  const MissedCallIcon = () => (
    <div className="icon-placeholder">
      <PhoneMissed size={22} />
    </div>
  );
  
  // Task updated icon
  const TaskUpdatedIcon = () => (
    <div className="icon-placeholder">
      <Edit size={22} />
    </div>
  );
  
  // Icons for each trigger type using Lucide icons
const LeadIcon = () => (
    <div className="icon-placeholder">
      <User size={22} />
    </div>
  );
  
  const QuotationIcon = () => (
    <div className="icon-placeholder">
      <ScrollText size={22} />
    </div>
  );
  
  const InvoiceIcon = () => (
    <div className="icon-placeholder">
      <Receipt size={22} />
    </div>
  );
  
  const OrderIcon = () => (
    <div className="icon-placeholder">
      <ClipboardList size={22} />
    </div>
  );
  
  const StatusIcon = () => (
    <div className="icon-placeholder">
      <ListChecks size={22} />
    </div>
  );
  
  const LabelIcon = () => (
    <div className="icon-placeholder">
      <Tag size={22} />
    </div>
  );
  
  const TaskIcon = () => (
    <div className="icon-placeholder">
      <Calendar size={22} />
    </div>
  );
  
  const ActivityIcon = () => (
    <div className="icon-placeholder">
      <FileText size={22} />
    </div>
  );
  
  const EmailIcon = () => (
    <div className="icon-placeholder">
      <Mail size={22} />
    </div>
  );
  
  const WhatsappIcon = () => (
    <div className="icon-placeholder">
      <MessageCircle size={22} />
    </div>
  );
  
  const PageViewIcon = () => (
    <div className="icon-placeholder">
      <Eye size={22} />
    </div>
  );
  
  // Default trigger options with business-friendly language
  const DEFAULT_TRIGGERS: TriggerOption[] = [
    // Lead Management
    {
      value: 'create_lead',
      name: 'New Lead Added',
      description: 'Triggers when a new lead is created in your CRM',
      category: CATEGORIES.LEAD,
      icon: <LeadIcon />
    },
    {
      value: 'edit_lead',
      name: 'Lead Information Updated',
      description: 'Triggers when any lead information is changed',
      category: CATEGORIES.LEAD,
      icon: <LeadIcon />
    },
    {
      value: 'status_change',
      name: 'Lead Status Changed',
      description: 'Triggers when a lead moves to a different status',
      category: CATEGORIES.LEAD,
      icon: <StatusIcon />
    },
    {
      value: 'label_change',
      name: 'Lead Label Changed',
      description: 'Triggers when a lead\'s label is updated',
      category: CATEGORIES.LEAD,
      icon: <LabelIcon />
    },
    {
      value: 'new_lead_added',
      name: 'Lead Added to List',
      description: 'Triggers when a lead is added to a specific list',
      category: CATEGORIES.LEAD,
      icon: <LeadIcon />
    },
    
    // Sales Documents
    {
      value: 'create_quotation',
      name: 'New Quotation Created',
      description: 'Triggers when you create a new quotation for a lead',
      category: CATEGORIES.SALES,
      icon: <QuotationIcon />
    },
    {
      value: 'edit_quotation',
      name: 'Quotation Updated',
      description: 'Triggers when a quotation is edited or status changes',
      category: CATEGORIES.SALES,
      icon: <QuotationIcon />
    },
    {
      value: 'create_invoice',
      name: 'New Invoice Created',
      description: 'Triggers when you create a new invoice for a lead',
      category: CATEGORIES.SALES,
      icon: <InvoiceIcon />
    },
    {
      value: 'edit_invoice',
      name: 'Invoice Updated',
      description: 'Triggers when an invoice is edited or status changes',
      category: CATEGORIES.SALES,
      icon: <InvoiceIcon />
    },
    {
      value: 'create_order',
      name: 'New Order Created',
      description: 'Triggers when you create a new order for a lead',
      category: CATEGORIES.SALES,
      icon: <OrderIcon />
    },
    {
      value: 'edit_order',
      name: 'Order Updated',
      description: 'Triggers when an order is edited or status changes',
      category: CATEGORIES.SALES,
      icon: <OrderIcon />
    },
    
    // Activities and Tasks
    {
      value: 'create_task',
      name: 'New Task Created',
      description: 'Triggers when you create a new follow-up task',
      category: CATEGORIES.ACTIVITY,
      icon: <TaskIcon />
    },
    {
      value: 'edit_task',
      name: 'Task Updated/Completed',
      description: 'Triggers when a task is updated or marked as complete',
      category: CATEGORIES.ACTIVITY,
      icon: <TaskUpdatedIcon />
    },
    {
      value: 'create_activity',
      name: 'New Activity Logged',
      description: 'Triggers when you log a new activity with a lead',
      category: CATEGORIES.ACTIVITY,
      icon: <ActivityIcon />
    },
    {
      value: 'incoming_call',
      name: 'Incoming Call',
      description: 'Triggers when someone calls you',
      category: CATEGORIES.ACTIVITY,
      icon: <IncomingCallIcon />
    },
    {
      value: 'outgoing_call',
      name: 'Outgoing Call',
      description: 'Triggers when you call a lead',
      category: CATEGORIES.ACTIVITY,
      icon: <OutgoingCallIcon />
    },
    {
      value: 'missed_call',
      name: 'Missed Call',
      description: 'Triggers when you miss a call from a lead',
      category: CATEGORIES.ACTIVITY,
      icon: <MissedCallIcon />
    },
    {
      value: 'view_page',
      name: 'Lead Views Page',
      description: 'Triggers when a lead views a specific page on your website',
      category: CATEGORIES.ACTIVITY,
      icon: <PageViewIcon />
    },
    {
      value: 'view_file',
      name: 'Lead Views Document',
      description: 'Triggers when a lead opens a file you\'ve shared',
      category: CATEGORIES.ACTIVITY,
      icon: <PageViewIcon />
    },
    
    // Communication
    {
      value: 'send_email',
      name: 'Email Sent',
      description: 'Triggers when an email is sent to a lead',
      category: CATEGORIES.COMMUNICATION,
      icon: <EmailIcon />
    },
    {
      value: 'send_whatsapp',
      name: 'WhatsApp Message Sent',
      description: 'Triggers when a WhatsApp message is sent to a lead',
      category: CATEGORIES.COMMUNICATION,
      icon: <WhatsappIcon />
    }
  ];
  
  export default TriggerSelectionPopup;