import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Trash2,HelpCircle  } from 'lucide-react';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import { mergeDuplicateLeads, deleteBulkLeads } from '../../../services/leadService';
import GenericConfirmationModal from '../../../components/GenericConfirmationModal';
import { styled } from 'styled-components';
import { Logger } from '../../../utils/logger';

interface LeadDataI {
  _id: string;
  name: string;
  integration: string;
  customSource: string;
  createdAt: Date;
  status: Array<string>;
  phone: string;
  email: string;
  label: Array<any>;
  duplicateLeads: string[];
  assignedTo?: {
    _id: string;
    firstName: string;
    lastName: string;
  };
}

interface DuplicateGroup {
  id: string;
  leads: LeadDataI[];
  isSelected: boolean;
}

interface LoadingState {
  isLoading: boolean;
  isProcessing: boolean;
  processingMessage: string;
}


  // Handle merge action with batching
  const MERGE_DELAY = 2000; // 5 seconds

const DuplicateManager: React.FC<{
  leads: LeadDataI[];
  onClose: () => void;
  onLoadMore?: () => void;
  hasMore?: boolean;
  isLoading?: boolean;
}> = ({ 
  leads, 
  onClose, 
  onLoadMore, 
  hasMore = false, 
  isLoading = false 
}) => {
  const [duplicateGroups, setDuplicateGroups] = useState<DuplicateGroup[]>([]);
  const [selectedGroups] = useState(new Set<string>());
  const [loadingState, setLoadingState] = useState<LoadingState>({
    isLoading: false,
    isProcessing: false,
    processingMessage: ''
  });
  Logger.log('[DuplicateManager] Pre-render check:', {
    hasGroups: duplicateGroups.length > 0,
    duplicateGroups,
    renderedTableHeader: !!document.querySelector('.TableHeader'),
    renderedGroupWrappers: document.querySelectorAll('.GroupWrapper').length
  });

  // Confirmation modal state
  const [confirmationState, setConfirmationState] = useState<{
    isOpen: boolean;
    action: 'merge' | 'delete' | null;
    message: string;
  }>({
    isOpen: false,
    action: null,
    message: ''
  });

  useEffect(() => {
    Logger.log('[DuplicateManager] State update:', {
      hasMore,
      duplicateGroupsLength: duplicateGroups.length,
      isLoading
    });
  }, [hasMore, duplicateGroups.length, isLoading]);
  
  // Then in the render section, just have the conditional:
  {!hasMore && duplicateGroups.length > 0 && !isLoading && (
    <EndListMessage>
      ✨ You've reached the end of the list
    </EndListMessage>
  )}
  
  useEffect(() => {
    // Find the popup-body element that contains this component
    const popupBody = document.querySelector('.popup-body');
    if (popupBody) {
      // Cast to HTMLElement to access style property
      const popupBodyElement = popupBody as HTMLElement;
      
      // Save original style to restore later
      const originalOverflow = popupBodyElement.style.overflow;
      
      // Override the overflow style
      popupBodyElement.style.overflow = 'auto'; // Change back to auto
      popupBodyElement.style.maxHeight = '80vh'; // Set max height
      
      Logger.log('[DuplicateManager] Overriding popup-body style: setting overflow to auto');
      
      // Clean up function to restore original style
      return () => {
        popupBodyElement.style.overflow = originalOverflow;
        Logger.log('[DuplicateManager] Restoring popup-body style');
      };
    }
  }, []);

useEffect(() => {
  // Simple safety check before processing
  if (!Array.isArray(leads)) {
    Logger.warn('[DuplicateManager] Invalid leads data received');
    setDuplicateGroups([]);
    return;
  }

  Logger.log('[DuplicateManager] Processing leads:', {
    leadCount: leads.length,
    sampleLead: leads[0]
  });


  const groups = _.chain(leads)
    .filter(lead => {
      try {
        // Safe check for duplicateLeads
        if (!lead || !Array.isArray(lead.duplicateLeads)) return false;
        
        const hasDuplicates = lead.duplicateLeads.some(id => 
          leads.some(l => l?._id === id)
        );
        return hasDuplicates;
      } catch (err) {
        Logger.warn('Error processing lead:', lead);
        return false;
      }
    })
    .groupBy(lead => {
      try {
        // Defensive array access
        const allIds = [lead._id, ...(lead.duplicateLeads || [])].filter(Boolean).sort();
        return allIds[0] || lead._id; // Fallback to lead._id if sort fails
      } catch (err) {
        return lead._id; // Safe fallback
      }
    })
    .values()
    .filter(group => Array.isArray(group) && group.length > 1)
    .map(group => ({
      id: group[0]?._id || '',
      leads: group,
      isSelected: selectedGroups.has(group[0]?._id)
    }))
    .value();
    Logger.log('[DuplicateManager] Processed groups:', {
      groupCount: groups.length,
      sampleGroup: groups[0]
    });
    

  setDuplicateGroups(groups);
}, [leads, selectedGroups]);


// Add this at the beginning of your render function in DuplicateManager
useEffect(() => {
  if (duplicateGroups.length > 0) {
    Logger.log('[DuplicateManager] Container structure check:');
    const popupBody = document.querySelector('.popup-body');
    if (popupBody) {
      Logger.log('Popup body dimensions:', {
        clientHeight: popupBody.clientHeight,
        scrollHeight: popupBody.scrollHeight,
        
        style: window.getComputedStyle(popupBody),
        overflow: window.getComputedStyle(popupBody).overflow
      });
    }

    const contentWrapper = document.querySelector('.popup-body > div'); // Adjust this selector if needed
    if (contentWrapper) {
      Logger.log('Content wrapper dimensions:', {
        clientHeight: contentWrapper.clientHeight,
        scrollHeight: contentWrapper.scrollHeight,
       
        style: window.getComputedStyle(contentWrapper),
        overflow: window.getComputedStyle(contentWrapper).overflow
      });
    }
  }
}, [duplicateGroups.length]);



const MAX_SELECTED_GROUPS = 10;

const toggleGroupSelection = (groupId: string) => {
  const selectedCount = duplicateGroups.filter(g => g.isSelected).length;
  const group = duplicateGroups.find(g => g.id === groupId);
  
  // If trying to select more than limit
  if (!group?.isSelected && selectedCount >= MAX_SELECTED_GROUPS) {
    toast.error(`You can only select up to ${MAX_SELECTED_GROUPS} groups at a time`);
    return;
  }
  
  setDuplicateGroups(prev => prev.map(group => ({
    ...group,
    isSelected: group.id === groupId ? !group.isSelected : group.isSelected
  })));
};

  const getActionTitle = (action: 'merge' | 'delete' | null) => {
    if (!action) return '';
    return action.charAt(0).toUpperCase() + action.slice(1);
  };

  const getSelectedGroups = () => duplicateGroups.filter(group => group.isSelected);

  const handleConfirmationClose = () => {
    setConfirmationState(prev => ({ ...prev, isOpen: false }));
  };

  const handleActionClick = (action: 'merge' | 'delete') => {
    const selectedCount = getSelectedGroups().length;
    if (selectedCount === 0) {
      toast.error(`No groups selected for ${action}`);
      return;
    }

    setConfirmationState({
      isOpen: true,
      action,
      message: action === 'merge' 
        ? `Merge ${selectedCount} lead groups? This operation will combine duplicate leads and cannot be undone.`
        : `Delete ${selectedCount} duplicate leads? This operation cannot be undone.`
    });
  };



  const handleMerge = async () => {
    try {
      const selectedGroups = getSelectedGroups();
      if (!selectedGroups?.length) {
        toast.error('No groups selected for merge');
        return;
      }
  
      for (const [index, group] of selectedGroups.entries()) {
        // Safe check for valid lead ID
        if (!group?.leads?.[0]?._id) {
          Logger.warn('Invalid lead data in group, skipping');
          continue;
        }
        
        try {
          await mergeDuplicateLeads(group.leads[0]._id);
        } catch (err) {
          console.error('Error merging group:', err);
          // Continue with next group even if one fails
        }
      }
      
      toast.success('Successfully merged leads');
      onClose();
    } catch (error) {
      console.error('Merge operation failed:', error);
      toast.error('Failed to merge leads');
    }
  };

  // Handle delete action
  const handleDelete = async () => {
    try {
      const selectedGroups = getSelectedGroups();
      if (!selectedGroups?.length) {
        toast.error('No groups selected for deletion');
        return;
      }
  
      const leadIdsToDelete = selectedGroups.flatMap(group => {
        // Safe array operations
        if (!Array.isArray(group?.leads)) return [];
        
        const duplicateLeads = [...group.leads];
        duplicateLeads.shift(); // Remove first lead as in original
        
        // Filter out any invalid IDs
        return duplicateLeads
          .map(lead => lead?._id)
          .filter(Boolean); // Remove any undefined/null IDs
      });
  
      if (!leadIdsToDelete.length) {
        toast.error('No duplicate leads to delete');
        return;
      }
  
      await deleteBulkLeads({ leadIDs: leadIdsToDelete });
      toast.success('Successfully deleted duplicate leads');
      onClose();
    } catch (error) {
      console.error('Delete operation failed:', error);
      toast.error('Failed to delete leads');
    }
  };

  const handleConfirmation = async () => {
    if (!confirmationState.action) return;
    
    setConfirmationState(prev => ({ ...prev, isOpen: false }));
    
    if (confirmationState.action === 'merge') {
      await handleMerge();
    } else {
      await handleDelete();
    }
  };

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  useEffect(() => {
    return () => {
      Logger.log('[DuplicateManager] Cleaning up...');
      setDuplicateGroups([]);
    };
  }, []);

  return (
    <Container>
      {(loadingState.isLoading || loadingState.isProcessing) && (
        <div className="absolute inset-0 bg-white/60 flex items-center justify-center z-50">
          <div className="flex flex-col items-center gap-3">
            <div className="spinner-border text-primary" role="status" />
            <p className="text-sm text-gray-600">{loadingState.processingMessage || 'Loading...'}</p>
          </div>
        </div>
      )}
  
      <ActionBarContainer>
        <ActionBar>
          <div className="text-sm text-gray-600">
            {getSelectedGroups().length} groups selected
          </div>
          <ButtonGroup>
          <ActionButton
  variant="primary"
  onClick={() => handleActionClick('merge')}
  disabled={
    getSelectedGroups().length === 0 || 
    getSelectedGroups().length > MAX_SELECTED_GROUPS ||
    loadingState.isProcessing
  }
>
  Merge Selected ({getSelectedGroups().length}/10)
</ActionButton>
            <ActionButton
              variant="danger"
              onClick={() => handleActionClick('delete')}
              disabled={getSelectedGroups().length === 0 || loadingState.isProcessing}
            >
              <Trash2 size={16} />
              Delete
            </ActionButton>
          </ButtonGroup>
        </ActionBar>
      </ActionBarContainer>
  
      <ContentWrapper>
      <div className="flex items-center justify-between">
        <IntroText>
          Welcome to the Duplicate Leads Manager! Here you can easily manage your duplicate leads 
          by selecting and merging or deleting them.
        </IntroText>
        <HelpLink 
          href="https://help.3sigmacrm.com/" 
          target="_blank"
          rel="noopener noreferrer"
        >
          <HelpCircle size={16} />
          <span>Learn more about managing duplicates</span>
        </HelpLink>
      </div>
  
      <TableHeader className="TableHeader">
          <div>Name</div>
          <div>Source</div>
          <div>Phone</div>
          <div>Email</div>
          <div>Created</div>
          <div>Status</div>
          <div>Assigned To</div>
        </TableHeader>
  
     

        {duplicateGroups.map((group, groupIndex) => (
          <GroupWrapper className="GroupWrapper" key={group.id} isSelected={group.isSelected}>
            <GroupHeader>
  <input
    type="checkbox"
    checked={group.isSelected || false}
    onChange={() => toggleGroupSelection(group.id)}
    className="form-check-input"
    aria-label={`Select group ${groupIndex + 1}`}
  />
  <span className="h6 mb-0">
    Duplicate leads group {groupIndex + 1} ({group.leads.length} leads)
  </span>
</GroupHeader>
  
            {group.leads.map((lead, leadIndex) => (
              <LeadRow key={`${group.id}-${lead._id}`} isMain={leadIndex === 0}>
                <div>
                  {lead.name}
                  {leadIndex === 0 && (
                    <MainBadge>Main</MainBadge>
                  )}
                </div>
                <div>
                  <SourceBadge source={lead.integration || lead.customSource}>
                    {lead.integration || lead.customSource}
                  </SourceBadge>
                </div>
                <div>{lead.phone}</div>
                <div>{lead.email}</div>
                <div>{formatDate(lead.createdAt)}</div>
                <div>
                  {lead.status.map((status, idx) => (
                    <StatusBadge key={idx} type={status}>
                      {status}
                    </StatusBadge>
                  ))}
                </div>
                <div>
                  {lead.assignedTo ? `${lead.assignedTo.firstName} ${lead.assignedTo.lastName}` : '-'}
                </div>
              </LeadRow>
            ))}
          </GroupWrapper>
        ))}
  
        {duplicateGroups.length === 0 && !isLoading && (
          <EndListMessage>
            No duplicate leads found
          </EndListMessage>
        )}
  

{duplicateGroups.length > 0 && !isLoading && hasMore && (
  <div className="d-flex justify-content-center my-4">
    <button 
      className="btn btn-outline-primary" 
      onClick={onLoadMore}
      disabled={isLoading}
    >
      Load More
    </button>
  </div>
)}

{!hasMore && duplicateGroups.length > 0 && !isLoading && (
  <EndListMessage>
    ✨ You've reached the end of the list
  </EndListMessage>
)}

{isLoading && (
  <LoadingSpinner>
    <div className="spinner-border text-primary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </LoadingSpinner>
)}
        
      </ContentWrapper> 
  
      <GenericConfirmationModal
        showModal={confirmationState.isOpen}
        toggleModal={handleConfirmationClose}
        onConfirmation={handleConfirmation}
        title={`Confirm ${getActionTitle(confirmationState.action)}`}
        message={confirmationState.message}
        confirmText={getActionTitle(confirmationState.action)}
        cancelText="Cancel"
        isWarning={confirmationState.action === 'delete'}
      />
    </Container>
  );
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px); /* Calculate a good height */
  background: white;
  position: relative;
  /* Make sure scrolling works */
  max-height: 80vh;
`;

// Content wrapper - scrollable with proper overflow
const ContentWrapper = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 60px); /* Account for the action bar height */
  overflow-y: auto !important; /* Explicitly make this scrollable */
  overflow-x: hidden;
  /* Add this to ensure content grows properly */
  min-height: 500px;
`;

const ActionBarContainer = styled.div`
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid #e2e8f0;
  z-index: 50;
  width: 100%;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background: white;
`;

const MainBadge = styled.span`
  background: #0d6efd;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
`;

const HelpLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  color: #3b82f6;
  font-size: 14px;
  text-decoration: none;
  margin-left: 16px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const IntroText = styled.div`
  padding: 16px 24px;
  background: #f8fafc;
  border-radius: 8px;
  margin-bottom: 24px;
  color: #475569;
  font-size: 14px;
  line-height: 1.5;
`;

const ButtonGroup = styled.div`
  display: flex;
    gap: 12px;
  align-items: center;
`;

const ActionButton = styled.button<{ variant?: 'primary' | 'danger' }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 36px;  // Fixed height for all buttons
  padding: 0 16px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  
  ${props => props.variant === 'primary' && `
    background: #0d6efd;
    color: white;
    &:hover:not(:disabled) {
      background: #0b5ed7;
    }
  `}
  
  ${props => props.variant === 'danger' && `
    background: #dc3545;
    color: white;
    &:hover:not(:disabled) {
      background: #bb2d3b;
    }
  `}
  
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
`;

const ScrollArea = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TableHeader = styled.div`
  position: sticky;
  top: 0;
  background: #f8f9fa;
  border-bottom: 2px solid #e2e8f0;
  z-index: 10;
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1.5fr 1fr 1.5fr 1.5fr;
  gap: 1rem;
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: #4a5568;
`;

const GroupWrapper = styled.div<{ isSelected: boolean }>`
  margin-bottom: 1rem;
  border: ${props => props.isSelected ? '2px solid #0d6efd' : '1px solid #e2e8f0'};
  border-radius: 0.5rem;
  overflow: visible; /* Change from hidden to visible */
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 1;
`;


const EndListMessage = styled.div`
  text-align: center;
  padding: 16px;
  margin: 16px 0;
  background: #f8fafc;
  border-radius: 8px;
  color: #64748b;
  font-size: 14px;
  border: 1px dashed #cbd5e1;
`;


const GroupHeader = styled.div`
  padding: 12px 16px;
  background: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 2;

  input[type="checkbox"] {
    min-width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
  }
`;

const LeadRow = styled.div<{ isMain?: boolean }>`
  display: grid;
  grid-template-columns: minmax(200px, 2.5fr) minmax(150px, 1fr) minmax(120px, 1fr) minmax(150px, 1.5fr) minmax(100px, 1fr) minmax(150px, 1.5fr) minmax(150px, 1.5fr);
  
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px;
  }
  gap: 1rem;
  padding: 0.75rem 1rem;
  background: ${props => props.isMain ? '#e7f1ff' : 'white'};
  border-bottom: 1px solid #e2e8f0;
  align-items: center;
  min-height: 40px; /* Ensure minimum height */
  
  &:last-child {
    border-bottom: none;
  }
`;

const SourceBadge = styled.span<{ source?: string }>`
  background: ${props => {
    switch (props.source?.toLowerCase()) {
      case 'lead_form': return '#e9ecef';
      case 'whatsapp': return '#25D366';
      default: return '#e9ecef';
    }
  }};
  color: ${props => props.source?.toLowerCase() === 'whatsapp' ? 'white' : '#4a5568'};
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
`;

const StatusBadge = styled.span<{ type?: string }>`
  background: ${props => {
    switch (props.type?.toLowerCase()) {
      case 'new': return '#0d6efd';
      case 'follow_up': return '#198754';
      case 'closed': return '#6c757d';
      default: return '#0d6efd';
    }
  }};
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  display: inline-block;
  margin-right: 0.25rem;
`;

const EndMessage = styled.div`
  text-align: center;
  padding: 1rem;
  color: #6c757d;
  background: #f8f9fa;
  border-radius: 0.5rem;
  margin: 1rem 0;
`;

const LoadingSpinner = styled.div`
  text-align: center;
  padding: 2rem;
  color: #6c757d;
  width: 100%;
  position: relative;
`;

export default DuplicateManager;