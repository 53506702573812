import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';
import { API_URL } from '../config/config';
import { Button, Form, Input } from 'antd';
import { Search, User } from 'lucide-react';
import PopupComponent from './PopupComponent';
import toast from 'react-hot-toast';

// Define interface for Lead
interface Lead {
  _id: string;
  name: string;
  phone?: string;
}

// Props for the LeadSelector component
interface LeadSelectorProps {
  value?: Lead | null;
  onChange?: (lead: Lead) => void;
  placeholder?: string;
  disabled?: boolean;
  formInstance?: any; // Optional form instance if used within a form
  formFieldName?: string; // Optional form field name
}

const LeadSelector: React.FC<LeadSelectorProps> = ({
  value,
  onChange,
  placeholder = "Select Lead",
  disabled = false,
  formInstance,
  formFieldName
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Lead[]>([]);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(value || null);

  // Reset state when popup opens
  useEffect(() => {
    if (isOpen) {
      setSearchTerm('');
      setSearchResults([]);
    }
  }, [isOpen]);

  // Update selected lead when value changes
  useEffect(() => {
    if (value && (!selectedLead || value._id !== selectedLead._id)) {
      setSelectedLead(value);
    }
  }, [value]);

  // Debounced search function
  const debouncedSearch = _.debounce(async (value: string) => {
    if (!value?.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}api/v1/lead/search`, {
        headers: {
          Authorization: localStorage.getItem("auth_token") || "",
        },
        params: {
          search: value,
          orderBy: "_id",
          isAscending: false,
          page: 1,
          perPage: 50,
        },
      });

      if (response.data?.data) {
        setSearchResults(response.data.data);
      }
    } catch (err) {
      console.error('Search failed:', err);
      toast.error("Search failed");
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  }, 300);

  // Handle search input change
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  // Handle lead selection
  const handleSelect = (lead: Lead) => {
    setSelectedLead(lead);
    
    // Update form if form instance is provided
    if (formInstance && formFieldName) {
      formInstance.setFieldValue(formFieldName, lead);
    }
    
    // Call onChange callback
    if (onChange) {
      onChange(lead);
    }
    
    setIsOpen(false);
  };

  // Open the lead selector popup
  const openSelector = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  };

  return (
    <>
      {/* Lead Display/Selector Button */}
      {selectedLead ? (
        <LeadDisplay onClick={openSelector} disabled={disabled}>
          <User size={16} className="icon" />
          <span className="lead-name">{selectedLead.name}</span>
          {!disabled && <span className="change-text">Change</span>}
        </LeadDisplay>
      ) : (
        <SelectButton onClick={openSelector} disabled={disabled}>
          <User size={16} className="icon" />
          <span>{placeholder}</span>
        </SelectButton>
      )}

      {/* Lead Selector Popup */}
      <PopupComponent
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Select Lead"
        size="sm"
        loading={loading}
      >
        <StyledContent>
          <div className="search-container">
            <Search size={20} className="search-icon" />
            <input
              type="text"
              className="search-input"
              placeholder="Search leads by name or phone..."
              value={searchTerm}
              onChange={handleSearch}
              autoFocus
            />
          </div>
          
          <div className="leads-container">
            {searchResults.length > 0 ? (
              <div className="lead-list">
                {searchResults.map((lead: Lead) => (
                  <div
                    key={lead._id}
                    className="lead-item"
                    onClick={() => handleSelect(lead)}
                  >
                    <div className="lead-info">
                      <span className="lead-name">{lead.name || 'Unknown'}</span>
                      {lead.phone && <span className="lead-phone">{lead.phone}</span>}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-state">
                <p>{searchTerm ? 'No leads found' : 'Start typing to search leads'}</p>
              </div>
            )}
          </div>
        </StyledContent>
      </PopupComponent>
    </>
  );
};

// Styled components
const LeadDisplay = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  background-color: ${props => props.disabled ? '#f5f5f5' : 'white'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s;
  
  &:hover {
    border-color: ${props => props.disabled ? '#d9d9d9' : '#40a9ff'};
  }

  .icon {
    color: #666;
    margin-right: 8px;
  }
  
  .lead-name {
    flex: 1;
    font-size: 14px;
  }
  
  .change-text {
    font-size: 12px;
    color: #1890ff;
    margin-left: 8px;
    display: ${props => props.disabled ? 'none' : 'block'};
  }
`;

const SelectButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  background-color: ${props => props.disabled ? '#f5f5f5' : 'white'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.2s;
  
  &:hover {
    border-color: ${props => props.disabled ? '#d9d9d9' : '#40a9ff'};
  }

  .icon {
    color: #666;
    margin-right: 8px;
  }
  
  span {
    color: #888;
    font-size: 14px;
  }
`;

const StyledContent = styled.div`
  .search-container {
    position: relative;
    margin-bottom: 16px;
  }

  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }

  .search-input {
    width: 100%;
    padding: 10px 12px 10px 40px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: #2563eb;
      box-shadow: 0 0 0 2px rgba(37,99,235,0.1);
    }

    &::placeholder {
      color: #999;
    }
  }

  .leads-container {
    max-height: 400px;
    overflow-y: auto;
  }

  .lead-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .lead-item {
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: white;

    &:hover {
      background-color: #f8f9fa;
      border-color: #2563eb;
      transform: translateY(-1px);
    }
  }

  .lead-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .lead-name {
    font-weight: 500;
    color: #1a1a1a;
    font-size: 14px;
  }

  .lead-phone {
    color: #666;
    font-size: 13px;
  }

  .empty-state {
    text-align: center;
    color: #666;
    padding: 20px;
  }
`;

// Form item component that integrates LeadSelector with Ant Design forms
export const LeadFormItem: React.FC<{
  name: string;
  label: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  form: any;
}> = ({
  name,
  label,
  required = false,
  placeholder,
  disabled = false,
  form
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={required ? [{ required: true, message: `${label} is required` }] : []}
    >
      <LeadSelector 
        formInstance={form}
        formFieldName={name}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default LeadSelector;