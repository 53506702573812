// Enhanced validation system for invoices

import { FormValues, Invoice, ProductSummary, AdditionalCharge, Tax } from './Invoice';
import { calculateTotals } from './InvoiceUtils';

export enum ValidationSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export interface ValidationResult {
  field?: string;
  message: string;
  severity: ValidationSeverity;
}

/**
 * Comprehensive invoice validation that ensures data integrity
 * and business rule compliance
 */
export const validateInvoice = (values: Partial<FormValues | Invoice>): ValidationResult[] => {
  const validationResults: ValidationResult[] = [];
  const totals = calculateTotals(values as FormValues);

  // ======== PRODUCT VALIDATIONS ========
  if (!values.products?.length) {
    validationResults.push({
      field: 'products',
      message: 'Please add at least one product',
      severity: ValidationSeverity.ERROR
    });
  } else {
    // Validate each product
    values.products.forEach((product: ProductSummary, index: number) => {
      // Quantity validation
      if (!product.quantity || product.quantity <= 0) {
        validationResults.push({
          field: `products[${index}].quantity`,
          message: 'Quantity must be greater than zero',
          severity: ValidationSeverity.ERROR
        });
      }

      // Price validation
      if (!product.unitPrice || product.unitPrice < 0) {
        validationResults.push({
          field: `products[${index}].unitPrice`,
          message: 'Price cannot be negative',
          severity: ValidationSeverity.ERROR
        });
      }

      // Product reference validation
      if (!product.id && !product.product?._id) {
        validationResults.push({
          field: `products[${index}]`,
          message: 'Invalid product reference',
          severity: ValidationSeverity.ERROR
        });
      }
    });
  }

  // ======== LEAD VALIDATION ========
  if (!values.lead) {
    validationResults.push({
      field: 'lead',
      message: 'Lead is required',
      severity: ValidationSeverity.ERROR
    });
  } else if (typeof values.lead === 'object' && !values.lead._id) {
    validationResults.push({
      field: 'lead',
      message: 'Invalid lead reference',
      severity: ValidationSeverity.ERROR
    });
  }

  // ======== ADDITIONAL CHARGES VALIDATION ========
  if (values.additionalCharges?.length) {
    // Check for duplicate titles
    const titleCounts: Record<string, number> = {};
    
    values.additionalCharges.forEach((charge: AdditionalCharge, index: number) => {
      // Validate title
      if (!charge.title?.trim()) {
        validationResults.push({
          field: `additionalCharges[${index}].title`,
          message: 'Charge title is required',
          severity: ValidationSeverity.ERROR
        });
      } else {
        const normalizedTitle = charge.title.toLowerCase().trim();
        titleCounts[normalizedTitle] = (titleCounts[normalizedTitle] || 0) + 1;
        
        if (titleCounts[normalizedTitle] > 1) {
          validationResults.push({
            field: `additionalCharges[${index}].title`,
            message: 'Duplicate charge title',
            severity: ValidationSeverity.ERROR
          });
        }
      }

      // Validate type
      if (!charge.type || !['fixed', 'rate'].includes(charge.type)) {
        validationResults.push({
          field: `additionalCharges[${index}].type`,
          message: 'Invalid charge type',
          severity: ValidationSeverity.ERROR
        });
      }

      // Validate rate
      if (charge.rate === null || charge.rate === undefined || isNaN(Number(charge.rate))) {
        validationResults.push({
          field: `additionalCharges[${index}].rate`,
          message: 'Rate is required and must be a number',
          severity: ValidationSeverity.ERROR
        });
      } else if (Number(charge.rate) < 0) {
        validationResults.push({
          field: `additionalCharges[${index}].rate`,
          message: 'Rate cannot be negative',
          severity: ValidationSeverity.ERROR
        });
      } else if (charge.type === 'rate' && Number(charge.rate) > 100) {
        validationResults.push({
          field: `additionalCharges[${index}].rate`,
          message: 'Percentage rate cannot exceed 100%',
          severity: ValidationSeverity.ERROR
        });
      }
    });
  }

  // ======== DISCOUNT VALIDATION ========
  if (values.discount?.rate !== null && values.discount?.rate !== undefined) {
    // Validate discount type
    if (!values.discount.type || !['fixed', 'rate'].includes(values.discount.type)) {
      validationResults.push({
        field: 'discount.type',
        message: 'Invalid discount type',
        severity: ValidationSeverity.ERROR
      });
    }

    const discountRate = Number(values.discount.rate);
    
    // Validate discount rate format
    if (isNaN(discountRate)) {
      validationResults.push({
        field: 'discount.rate',
        message: 'Discount rate must be a number',
        severity: ValidationSeverity.ERROR
      });
    } else if (discountRate < 0) {
      validationResults.push({
        field: 'discount.rate',
        message: 'Discount rate cannot be negative',
        severity: ValidationSeverity.ERROR
      });
    } else {
      // Validate percentage discount
      if (values.discount.type === 'rate' && discountRate > 100) {
        validationResults.push({
          field: 'discount.rate',
          message: 'Discount percentage cannot exceed 100%',
          severity: ValidationSeverity.ERROR
        });
      }
      
      // Validate fixed discount
      if (values.discount.type === 'fixed' && discountRate > totals.total) {
        validationResults.push({
          field: 'discount.rate',
          message: 'Discount amount cannot exceed total amount',
          severity: ValidationSeverity.ERROR
        });
      }
    }
  }


 // ======== TAX VALIDATION ========
 if (values.taxes?.length) {
  // Check for duplicate tax titles
  const taxTitlesToCheck: Record<string, number> = {};
  
  values.taxes.forEach((tax: Tax, index: number) => {
    // Validate title
    if (!tax.title?.trim()) {
      validationResults.push({
        field: `taxes[${index}].title`,
        message: 'Tax title is required',
        severity: ValidationSeverity.ERROR
      });
    } else {
      const normalizedTitle = tax.title.toLowerCase().trim();
      taxTitlesToCheck[normalizedTitle] = (taxTitlesToCheck[normalizedTitle] || 0) + 1;
      
      if (taxTitlesToCheck[normalizedTitle] > 1) {
        validationResults.push({
          field: `taxes[${index}].title`,
          message: 'Duplicate tax title',
          severity: ValidationSeverity.ERROR
        });
      }
    }

    // Validate rate
    if (tax.rate === null || tax.rate === undefined || isNaN(Number(tax.rate))) {
      validationResults.push({
        field: `taxes[${index}].rate`,
        message: 'Tax rate is required and must be a number',
        severity: ValidationSeverity.ERROR
      });
    } else if (Number(tax.rate) < 0) {
      validationResults.push({
        field: `taxes[${index}].rate`,
        message: 'Tax rate cannot be negative',
        severity: ValidationSeverity.ERROR
      });
    } else if (Number(tax.rate) > 100) {
      validationResults.push({
        field: `taxes[${index}].rate`,
        message: 'Tax percentage cannot exceed 100%',
        severity: ValidationSeverity.ERROR
      });
    }
  });
}

  // ======== ADVANCED PAYMENT VALIDATION ========
  if (values.receivedAmount !== undefined) {
    const numReceivedAmount = Number(values.receivedAmount);
    
    if (isNaN(numReceivedAmount)) {
      validationResults.push({
        field: 'receivedAmount',
        message: 'Advanced payment must be a valid number',
        severity: ValidationSeverity.ERROR
      });
    } else if (numReceivedAmount < 0) {
      validationResults.push({
        field: 'receivedAmount',
        message: 'Advanced payment cannot be negative',
        severity: ValidationSeverity.ERROR
      });
    } else if (numReceivedAmount > totals.total) {
      validationResults.push({
        field: 'receivedAmount',
        message: 'Advanced payment cannot exceed total amount',
        severity: ValidationSeverity.ERROR
      });
    }
    
    // Validate payment mode when amount is specified
    if (numReceivedAmount > 0 && !values.receivedPaymentMode) {
      validationResults.push({
        field: 'receivedPaymentMode',
        message: 'Payment mode is required when advanced payment is provided',
        severity: ValidationSeverity.WARNING
      });
    }
  }

  // Return only error-level validations when checking for form submission validity
  return validationResults;
};

/**
 * Utility function to check if there are any error-level validation issues
 */
export const hasValidationErrors = (validationResults: ValidationResult[]): boolean => {
  return validationResults.some(result => result.severity === ValidationSeverity.ERROR);
};

/**
 * Converts validation results to Ant Design form-compatible error messages
 */
export const getFormValidationErrors = (validationResults: ValidationResult[]): Record<string, string[]> => {
  const formErrors: Record<string, string[]> = {};
  
  validationResults
    .filter(result => result.severity === ValidationSeverity.ERROR && result.field)
    .forEach(error => {
      if (error.field) {
        if (!formErrors[error.field]) {
          formErrors[error.field] = [];
        }
        formErrors[error.field].push(error.message);
      }
    });
    
  return formErrors;
};