import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { Input, Button, Spin, Row, Col, Card, Checkbox, Badge, Pagination, Empty, Divider, Select } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Search, Package, Plus, Minus, ShoppingCart, X, ChevronDown } from 'lucide-react';
import { getAllProducts } from '../services/productService';
import PopupComponent from './PopupComponent';
import debounce from 'lodash/debounce';
import './ProductPickerPopup.css';
import { BUCKET_URL } from '../config/config';
import { Logger } from '../utils/logger';

// Type definitions
export interface Product {
  quantity: number;
  _id: string;
  name: string;
  description?: string;
  price: number;
  category?: {
    _id: string;
    name: string;
  };
  extraDetails?: {
    unit?: string;
    [key: string]: any;
  };
  images?: Array<{
    filePath: string;
    fileName: string;
  }>;
}

interface ProductWithQuantity extends Product {
  quantity: number; // Can be decimal
}

interface ProductPickerPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onProductsSelect: (products: ProductWithQuantity[]) => void;
  initialSelectedProducts?: ProductWithQuantity[];
  excludeProductIds?: string[];
}

// Memoized Product Card component to prevent unnecessary re-renders
const ProductCard = memo(({ 
  product, 
  isSelected, 
  selectedProduct, 
  onToggle, 
  onQuantityChange 
}: { 
  product: Product; 
  isSelected: boolean; 
  selectedProduct?: ProductWithQuantity; 
  onToggle: (product: Product, event?: React.MouseEvent) => void;
  onQuantityChange: (productId: string, change: number) => void;
}) => {
  const handleCardClick = useCallback((e: React.MouseEvent) => {
    // Prevent bubbling if clicked on controls
    if ((e.target as HTMLElement).closest('.quantity-controls')) {
      e.stopPropagation();
      return;
    }
    onToggle(product, e);
  }, [product, onToggle]);

  const handleCheckboxChange = useCallback((e: CheckboxChangeEvent) => {
    e.stopPropagation();
    onToggle(product);
  }, [product, onToggle]);

  const handleQuantityButtonClick = useCallback((e: React.MouseEvent, change: number) => {
    e.stopPropagation();
    // For button clicks, always use integer increments
    onQuantityChange(product._id, product.quantity + change);
  }, [product._id, product.quantity, onQuantityChange]);

  return (
    <Card 
      className={`product-card ${isSelected ? 'selected' : ''}`}
      onClick={handleCardClick}
      hoverable
    >
      {/* Product Image or Placeholder */}
      <div className="product-image">
  {product.images?.[0]?.filePath ? (
    <img 
      src={`${BUCKET_URL}${product.images[0].filePath}`}
      alt={product.name}
      onError={(e) => {
        // On error, replace with placeholder
        e.currentTarget.onerror = null; // Prevent infinite error loops
        e.currentTarget.style.display = 'none';
        
        // Add null check before accessing parentElement.innerHTML
        if (e.currentTarget.parentElement) {
          e.currentTarget.parentElement.innerHTML = `
            <div class="image-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                <line x1="12" y1="22.08" x2="12" y2="12"></line>
              </svg>
            </div>`;
        }
      }}
      className="product-image-element"
    />
  ) : (
    <div className="image-placeholder">
      <Package size={24} />
    </div>
  )}
  
  {/* Selection Checkbox */}
  <Checkbox 
    className="product-checkbox"
    checked={isSelected}
    onChange={handleCheckboxChange}
    onClick={e => e.stopPropagation()}
  />
</div>
      
      {/* Product Details */}
      <div className="product-details">
        <h3 className="product-name">{product.name}</h3>
        {product.category && (
          <Badge className="product-category" count={product.category.name} />
        )}
        <div className="product-price">
          <span>₹ {product.price.toLocaleString()}</span>
          {product.extraDetails?.unit && (
            <span className="product-unit">per {product.extraDetails.unit}</span>
          )}
        </div>
        
        {/* Quantity Controls (only for selected products) */}
        {isSelected && selectedProduct && (
  <div className="quantity-controls">
    <Button 
      icon={<Minus size={14} />}
      onClick={(e) => handleQuantityButtonClick(e, -1)}
      disabled={selectedProduct.quantity <= 1}
    />
    {/* Replace span with Input that allows direct editing */}
    <Input
      className="quantity-value"
      value={selectedProduct.quantity}
      onChange={(e) => {
        e.stopPropagation();
        const value = parseFloat(e.target.value);
        if (!isNaN(value) && value > 0) {
          // This allows direct decimal input
          onQuantityChange(product._id, value);
        }
      }}
      onClick={(e) => e.stopPropagation()}
      style={{ width: '50px', textAlign: 'center' }}
    />
    <Button 
      icon={<Plus size={14} />}
      onClick={(e) => handleQuantityButtonClick(e, 1)}
    />
  </div>
)}
      </div>
    </Card>
  );
});

// Memoize the selected product item component
const SelectedProductItem = memo(({ 
  product, 
  onToggle, 
  onQuantityChange 
}: { 
  product: ProductWithQuantity;
  onToggle: (product: Product) => void;
  onQuantityChange: (productId: string, change: number) => void;
}) => {
  return (
    <div className="selected-product-item">
      <div className="selected-product-info">
        <div className="selected-product-name">{product.name}</div>
        <div className="selected-product-price">
          ₹ {product.price.toLocaleString()}
          {product.extraDetails?.unit && <span> / {product.extraDetails.unit}</span>}
        </div>
      </div>
      <div className="selected-product-quantity">
  <Button
    icon={<Minus size={14} />}
    onClick={() => onQuantityChange(product._id, -1)}
    disabled={product.quantity <= 0.1}
    size="small"
  />
        <span className="quantity-value">{product.quantity}</span>
        <Button
          icon={<Plus size={14} />}
          onClick={() => onQuantityChange(product._id, 1)}
          size="small"
        />
      </div>
      <div className="selected-product-total">
        ₹ {(product.price * product.quantity).toLocaleString()}
      </div>
      <Button
        type="text"
        icon={<X size={14} />}
        onClick={() => onToggle(product)}
        className="selected-product-remove"
        size="small"
      />
    </div>
  );
});

const ProductPickerPopup: React.FC<ProductPickerPopupProps> = ({
  isOpen,
  onClose,
  onProductsSelect,
  initialSelectedProducts = [],
  excludeProductIds = []
}) => {
  // State management
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<Map<string, ProductWithQuantity>>(new Map());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const [pageSize, setPageSize] = useState(16);
  const [paginationMode, setPaginationMode] = useState<'paged' | 'infinite'>('paged');
  const searchInputRef = useRef<any>(null);
  const productListRef = useRef<HTMLDivElement>(null);
  const loadingMoreRef = useRef<boolean>(false);

  // Removed render logging that was causing excessive console output

  // Setup debounced search only once - created outside component to prevent recreation
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setDebouncedSearchTerm(value);
    }, 300),
    [] // Empty dependency array ensures this is only created once
  );

  // Update search term
  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    Logger.log('ProductPickerPopup: Search changed', value);
    setSearchTerm(value);
    debouncedSearch(value);
  }, [debouncedSearch]);

  // Reset all state when popup opens/closes
  useEffect(() => {
    if (isOpen) {
      // Reset search when opening the popup
      setSearchTerm('');
      setDebouncedSearchTerm('');
      setCurrentPage(1);
      
      // Focus the search input
      const timerId = setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 100);
      
      // Set initial selected products once
      const initialProductsMap = new Map<string, ProductWithQuantity>();
      if (initialSelectedProducts && initialSelectedProducts.length > 0) {
        initialSelectedProducts.forEach(product => {
          initialProductsMap.set(product._id, { ...product });
        });
      }
      setSelectedProducts(initialProductsMap);
      
      return () => clearTimeout(timerId);
    }
  }, [isOpen]); // Only depend on isOpen, not initialSelectedProducts

  // Fetch products when search term or page changes
  useEffect(() => {
    if (!isOpen) return;
    
    let isMounted = true;
    
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const params = {
          page: currentPage,
          perPage: pageSize,
          ...(debouncedSearchTerm && { search: debouncedSearchTerm.trim() }),
          orderBy: 'name',
          isAscending: true
        };
        
        const response = await getAllProducts(params);
        
        // Only update state if component is still mounted
        if (isMounted && response?.data?.data) {
          // Filter out excluded product IDs
          const filteredProducts = response.data.data.filter(
            (product: Product) => !excludeProductIds.includes(product._id)
          );

          if (paginationMode === 'infinite' && currentPage > 1) {
            // Append products for infinite scroll
            setProducts(prev => [...prev, ...filteredProducts]);
          } else {
            // Replace products for regular pagination
            setProducts(filteredProducts);
          }
          
          setTotalProducts(response.data.total - excludeProductIds.length);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching products:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          loadingMoreRef.current = false;
        }
      }
    };

    fetchProducts();
    
    // Cleanup function to prevent updates after unmount
    return () => {
      isMounted = false;
    };
  }, [isOpen, currentPage, debouncedSearchTerm, excludeProductIds, pageSize, paginationMode]);

  // Handle pagination mode change
  const handlePaginationModeChange = useCallback((mode: 'paged' | 'infinite') => {
    setPaginationMode(mode);
    setCurrentPage(1);
    setProducts([]);
  }, []);

  // Handle infinite scroll
  const handleScroll = useCallback(() => {
    if (paginationMode !== 'infinite' || loading || loadingMoreRef.current) return;
    
    const container = productListRef.current;
    if (!container) return;
    
    const { scrollTop, scrollHeight, clientHeight } = container;
    
    // Check if user has scrolled to the bottom (with a threshold of 200px)
    if (scrollHeight - scrollTop - clientHeight < 200) {
      const nextPage = currentPage + 1;
      const maxPage = Math.ceil(totalProducts / pageSize);
      
      if (nextPage <= maxPage) {
        loadingMoreRef.current = true;
        setCurrentPage(nextPage);
      }
    }
  }, [currentPage, loading, totalProducts, pageSize, paginationMode]);

  // Add scroll event listener for infinite scroll
  useEffect(() => {
    const container = productListRef.current;
    if (paginationMode === 'infinite' && container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [paginationMode, handleScroll]);

  // Reset products and page when search changes
  useEffect(() => {
    if (debouncedSearchTerm !== searchTerm) {
      setProducts([]);
      setCurrentPage(1);
    }
  }, [debouncedSearchTerm, searchTerm]);
  
  // Pagination controls component
 // Update the PaginationControls component to always render
const PaginationControls = memo(() => {
  return (
    <div className="product-picker-pagination">
      <div className="pagination-options">
        {/* Your pagination options code */}
      </div>
      
      {/* Always render pagination regardless of product count */}
      <Pagination
        current={currentPage}
        total={totalProducts}
        pageSize={pageSize}
        onChange={(page) => {
          Logger.log('Changing to page:', page);
          setCurrentPage(page);
          
          // Scroll to top when changing pages
          if (productListRef.current) {
            productListRef.current.scrollTop = 0;
          }
        }}
        showSizeChanger={false}
        showQuickJumper={false}
        className="pagination-controls"
        size="small"
      />
    </div>
  );
});
  
  // Clear search
  const handleClearSearch = useCallback(() => {
    setSearchTerm('');
    debouncedSearch('');
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [debouncedSearch]);

  // Product selection handlers - memoized to prevent re-renders
  const handleToggleProduct = useCallback((product: Product, event?: React.MouseEvent) => {
    // If the click is coming from a button inside the card, don't toggle
    if (event && (
      (event.target as HTMLElement).tagName === 'BUTTON' ||
      (event.target as HTMLElement).closest('button')
    )) {
      return;
    }
    
    Logger.log('ProductPickerPopup: Toggle product', product.name);
    setSelectedProducts(prevSelected => {
      const newMap = new Map(prevSelected);
      
      if (newMap.has(product._id)) {
        newMap.delete(product._id);
      } else {
        newMap.set(product._id, { ...product, quantity: 1 });
      }
      
      return newMap;
    });
  }, []);

  const handleQuantityChange = useCallback((productId: string, newQuantity: number) => {
    Logger.log('ProductPickerPopup: Change quantity', { productId, newQuantity });
    setSelectedProducts(prevSelected => {
      const newMap = new Map(prevSelected);
      const product = newMap.get(productId);
      
      if (product) {
        // Ensure the quantity is at least 1 and round to 2 decimal places if it's a decimal
        const validQuantity = Math.max(1, Number(newQuantity.toFixed(2)));
        newMap.set(productId, { ...product, quantity: validQuantity });
      }
      
      return newMap;
    });
  }, []);

  // Handle confirmation - add all selected products
  const handleConfirm = useCallback(() => {
    const productsArray = Array.from(selectedProducts.values());
    Logger.log('ProductPickerPopup: Confirming selection', productsArray.length);
    onProductsSelect(productsArray);
    onClose();
  }, [selectedProducts, onProductsSelect, onClose]);

  // Calculate totals - memoized to avoid recalculation on every render
  const calculateTotals = useCallback(() => {
    let itemCount = 0;
    let subtotal = 0;
    
    selectedProducts.forEach(product => {
      itemCount += product.quantity;
      subtotal += product.price * product.quantity;
    });
    
    return { itemCount, subtotal };
  }, [selectedProducts]);

  const { itemCount, subtotal } = calculateTotals();
  
  // Selected products list component - memoized
  const SelectedProductsList = memo(() => {
    if (selectedProducts.size === 0) return null;
    
    return (
      <div className="selected-products-section">
        <Divider orientation="left">
          <div className="selected-header">
            <span>Selected Products</span>
            <Badge count={selectedProducts.size} style={{ backgroundColor: '#1890ff' }} />
          </div>
        </Divider>
        <div className="selected-products-list">
          {Array.from(selectedProducts.values()).map(product => (
            <SelectedProductItem
              key={product._id}
              product={product}
              onToggle={handleToggleProduct}
              onQuantityChange={handleQuantityChange}
            />
          ))}
        </div>
      </div>
    );
  });

  // Custom footer with running totals and confirmation button
  // Render a static footer to prevent excessive re-rendering
  const renderFooter = () => (
    <div className="product-picker-footer">
      <div className="product-picker-totals">
        <div className="total-products">
          <span>Selected Items:</span>
          <span className="count">{selectedProducts.size} products ({itemCount} items)</span>
        </div>
        <div className="total-amount">
          <span>Subtotal:</span>
          <span className="amount">₹ {subtotal.toLocaleString()}</span>
        </div>
      </div>
      <div className="product-picker-actions">
        <Button onClick={onClose} size="large">Cancel</Button>
        <Button 
          type="primary" 
          onClick={handleConfirm}
          disabled={selectedProducts.size === 0}
          size="large"
          className="confirm-button"
        >
          <ShoppingCart size={16} className="mr-2" />
          Add {selectedProducts.size} Products to Invoice
        </Button>
      </div>
    </div>
  );

  // Product grid - memoized
  const ProductGrid = memo(() => {
    if (loading) {
      return (
        <div className="product-picker-loading">
          <Spin size="large" />
          <p>Loading products...</p>
        </div>
      );
    }
    
    if (products.length === 0) {
      return (
        <Empty 
          description={
            <div>
              <p>No products found</p>
              {searchTerm && (
                <Button type="link" onClick={handleClearSearch}>
                  Clear search
                </Button>
              )}
            </div>
          }
        />
      );
    }
    
    return (
      <Row gutter={[16, 16]} className="product-grid">
        {products.map(product => {
          const isSelected = selectedProducts.has(product._id);
          const selectedProduct = selectedProducts.get(product._id);
          
          return (
            <Col xs={24} sm={12} md={8} lg={6} key={product._id}>
              <ProductCard
                product={product}
                isSelected={isSelected}
                selectedProduct={selectedProduct}
                onToggle={handleToggleProduct}
                onQuantityChange={handleQuantityChange}
              />
            </Col>
          );
        })}
      </Row>
    );
  });

  return (
    <PopupComponent
      isOpen={isOpen}
      onClose={onClose}
      title="Select Products"
      size="lg"
      footer={renderFooter()}
    >
      <div className="product-picker-container">
        <div className="product-picker-layout">
          <div className="product-catalog-section">
            {/* Search Bar */}
            <div className="product-picker-search">
              <Input
                ref={searchInputRef}
                placeholder="Search products by name or description..."
                prefix={<Search size={16} />}
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
                suffix={
                  searchTerm ? (
                    <Button 
                      type="text" 
                      icon={<X size={16} />} 
                      onClick={handleClearSearch}
                      className="clear-button"
                    />
                  ) : null
                }
              />
            </div>
  
            {/* Product Grid */}
            <div 
              className="product-picker-content" 
              ref={productListRef}
              onScroll={paginationMode === 'infinite' ? handleScroll : undefined}
            >
              <ProductGrid />
              
              {/* Show loading indicator for infinite scroll */}
              {paginationMode === 'infinite' && loading && currentPage > 1 && (
                <div className="infinite-loader">
                  <Spin size="small" />
                  <span>Loading more products...</span>
                </div>
              )}
            </div>
  
            {/* Always render pagination controls, even when there are no products */}
            <div className="product-picker-pagination">
              <div className="pagination-options">
                <Select
                  value={pageSize}
                  onChange={(value) => {
                    setPageSize(value);
                    setCurrentPage(1); // Reset to page 1 when changing page size
                  }}
                  options={[
                    { value: 16, label: '16 / page' },
                    { value: 32, label: '32 / page' },
                    { value: 48, label: '48 / page' },
                  ]}
                  size="small"
                  dropdownMatchSelectWidth={false}
                  suffixIcon={<ChevronDown size={14} />}
                />
              </div>
              
              <Pagination
                current={currentPage}
                total={totalProducts}
                pageSize={pageSize}
                onChange={(page) => {
                  Logger.log('Changing to page:', page);
                  setCurrentPage(page);
                  
                  // Scroll to top when changing pages
                  if (productListRef.current) {
                    productListRef.current.scrollTop = 0;
                  }
                }}
                showSizeChanger={false}
                showQuickJumper={false}
                className="pagination-controls"
                size="small"
              />
            </div>
          </div>
          
          {/* Selected Products Panel */}
          <div className="selected-products-panel">
            {selectedProducts.size > 0 ? (
              <SelectedProductsList />
            ) : (
              <div className="empty-selection">
                <Empty 
                  image={<ShoppingCart size={64} style={{ color: '#d9d9d9' }} />}
                  description="No products selected yet"
                />
                <p className="help-text">Click on products to select them</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </PopupComponent>
  );
};

// Use React.memo with a custom comparison function to prevent unnecessary re-renders
export default React.memo(ProductPickerPopup, (prevProps, nextProps) => {
  // Only re-render if isOpen changes or excludeProductIds length changes
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.excludeProductIds?.length === nextProps.excludeProductIds?.length
  );
});