import React from 'react';
import { Form } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import {
  ChargesContainer,
  SectionHeader,
  SectionTitle,
  ActionButton,
  ChargeGrid,
  StyledInput,
  StyledSelect,
  StyledInputNumber,
  DeleteButton
} from './QuotationAdjustmentsStyles';

interface AdditionalChargesProps {
  form: FormInstance;
  onValuesChange: (values: any) => void;
}

export const AdditionalCharges: React.FC<AdditionalChargesProps> = ({
  form,
  onValuesChange,
}) => {
  return (
    <ChargesContainer>
   

      <Form.List name="additionalCharges">
      {(fields, { add, remove }) => (
        <div className="additional-charges-list">
          {fields.map((field) => (
            <ChargeGrid key={field.key}>
              <Form.Item
                {...field}
                name={[field.name, 'title']}
                rules={[{ 
                  required: true, 
                  message: 'Please enter a title' 
                }]}
                style={{ marginBottom: 0 }}
              >
                <StyledInput placeholder="Enter title" />
              </Form.Item>

              <Form.Item
                {...field}
                name={[field.name, 'type']}
                rules={[{ 
                  required: true, 
                  message: 'Please select a type' 
                }]}
                style={{ marginBottom: 0 }}
              >
                <StyledSelect placeholder="Type">
                  <StyledSelect.Option value="fixed">Fixed</StyledSelect.Option>
                  <StyledSelect.Option value="percentage">Percentage</StyledSelect.Option>
                </StyledSelect>
              </Form.Item>

              <Form.Item
                {...field}
                name={[field.name, 'rate']}
                rules={[{ 
                  required: true, 
                  message: 'Please enter a rate' 
                }]}
                style={{ marginBottom: 0 }}
              >
                <StyledInputNumber
                  placeholder="0.00"
                  formatter={(value: number | string | undefined) => 
                    value ? `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '₹ '
                  }
                  parser={(value: string | undefined) => 
                    value ? value.replace(/₹\s?|(,*)/g, '') : ''
                  }
                  onChange={() => {
                    const values = form.getFieldsValue();
                    onValuesChange(values);
                  }}
                />
              </Form.Item>

              <DeleteButton
                onClick={() => {
                  remove(field.name);
                  const values = form.getFieldsValue();
                  onValuesChange(values);
                }}
              />
            </ChargeGrid>
          ))}
        </div>
      )}
    </Form.List>
    </ChargesContainer>
  );
};