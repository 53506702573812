import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, Modal, Spin, Space } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { Lead } from '../Invoice';
import { getInvoiceId } from '../../../services/InvoiceService';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LeadSelector, { LeadFormItem } from '../../../components/LeadSelector';
import { Logger } from '../../../utils/logger';

export interface LeadSearchResponse extends Lead {
  phone?: string;
}

export interface InvoiceHeaderProps {
  form: FormInstance;
  initialLead?: Lead | null;
}

export const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  form,
  initialLead,
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  Logger.log('Initial lead data:', {
    lead: form.getFieldValue('lead'),
    initialLead,
  });

  // Set initial lead if provided
  useEffect(() => {
    if (initialLead && initialLead._id) {
      Logger.log('Setting initial lead in form:', initialLead);
      form.setFieldValue('lead', initialLead);
    }
  }, [initialLead, form]);

  // Set default date if not already set
  useEffect(() => {
    if (!form.getFieldValue('createdDate')) {
      form.setFieldsValue({
        createdDate: new Date()
      });
    }
  }, [form]);

  // Fetch invoice ID for new invoices
  useEffect(() => {
    const fetchInvoiceId = async () => {
      try {
        setLoading(true);
        const response = await getInvoiceId();
        if (response?.data?.data?.quotationId) {
          form.setFieldsValue({
            InvoiceId: response.data.data.quotationId,
            createdDate: dayjs().toDate(),
          });
        }
      } catch (error) {
        console.error('Error fetching Invoice ID:', error);
        toast.error('Failed to generate Invoice ID');
      } finally {
        setLoading(false);
      }
    };

    if (!form.getFieldValue('InvoiceId')) {
      fetchInvoiceId();
    }
  }, [form]);

  // Handle modal visibility
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Handle modal form submission
  const handleModalSubmit = (values: any) => {
    form.setFieldsValue({
      InvoiceId: values.InvoiceId,
      createdDate: values.createdDate,
    });
    setIsModalVisible(false);
  };

  // Disable future dates in the date picker
  const disabledDate = (date: Date) => {
    return date > new Date();
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="InvoiceId"
            label="Invoice ID"
            rules={[{ required: true, message: 'Invoice ID is required' }]}
          >
            <Input
              disabled
              style={{
                backgroundColor: '#f5f5f5',
                cursor: 'not-allowed',
              }}
              onClick={showModal}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="createdDate"
            label="Date"
            initialValue={new Date()}
            rules={[{ required: true, message: 'Date is required' }]}
          >
            <div style={{ position: 'relative' }}>
  <DatePicker
    selected={(() => {
      const date = form.getFieldValue('createdDate');
      // If it's a Moment object, convert to Date
      if (date && date._isAMomentObject) {
        return date.toDate();
      }
      // If it's a Date object, use it
      if (date && date instanceof Date && !isNaN(date.getTime())) {
        return date;
      }
      // Fallback to current date
      return new Date();
    })()}
    onChange={(date) => {
      form.setFieldsValue({ createdDate: date });
    }}
    dateFormat="dd MMM yyyy"
    className="ant-input"
    disabled={loading}
    filterDate={disabledDate}
  />
</div>
          </Form.Item>
        </Col>
      </Row>

      {/* Lead Selection - Using our new component */}
      <Form.Item
        name="lead"
        label="Lead"
        rules={[{ required: true, message: 'Lead is required' }]}
      >
        {form.getFieldValue('lead') && typeof form.getFieldValue('lead') === 'object' && form.getFieldValue('lead').name ? (
          // In edit mode with existing lead data, show a disabled input with the name
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            border: '1px solid #d9d9d9',
            borderRadius: '6px',
            padding: '4px 11px',
            backgroundColor: '#f5f5f5' 
          }}>
            <div style={{ 
              width: '4px', 
              height: '20px', 
              background: '#1890ff', 
              borderRadius: '2px', 
              marginRight: '10px' 
            }} />
            <span>{form.getFieldValue('lead').name}</span>
            <span style={{ 
              marginLeft: 'auto', 
              fontSize: '12px',
              color: '#1890ff'
            }}>Change</span>
          </div>
        ) : (
          // In create mode or when no lead is selected yet, show our reusable component
          <LeadSelector 
            formInstance={form}
            formFieldName="lead"
            placeholder="Select a lead"
          />
        )}
        {/* Hidden input to preserve the actual lead data */}
        <Input type="hidden" />
      </Form.Item>

      {/* Modal for editing Invoice ID and Date */}
      <Modal
        title="Edit Invoice Date"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            onFinish={handleModalSubmit}
            initialValues={{
              InvoiceId: form.getFieldValue('InvoiceId'),
              createdDate: form.getFieldValue('createdDate') || new Date(),
            }}
          >
            <Row gutter={16}>
              <Col span={14}>
                <Form.Item name="InvoiceId" label="Invoice ID">
                  <Input readOnly style={{ backgroundColor: '#f5f5f5' }} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="createdDate"
                  label="Date"
                  rules={[{ required: true, message: 'Please select a date' }]}
                >
                  <div style={{ position: 'relative' }}>
  <DatePicker
    selected={(() => {
      const date = form.getFieldValue('createdDate');
      // If it's a Moment object, convert to Date
      if (date && date._isAMomentObject) {
        return date.toDate();
      }
      // If it's a Date object, use it
      if (date && date instanceof Date && !isNaN(date.getTime())) {
        return date;
      }
      // Fallback to current date
      return new Date();
    })()}
    onChange={(date) => {
      form.setFieldsValue({ createdDate: date });
    }}
    dateFormat="dd MMM yyyy"
    className="ant-input"
    disabled={loading}
    filterDate={disabledDate}
  />
</div>
                </Form.Item>
              </Col>
            </Row>

            <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleModalCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Update Date
              </Button>
            </Space>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default InvoiceHeader;