import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Space, message, Card,Collapse, InputNumber, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus } from 'lucide-react';
import { calculateTotals, prepareQuotationPayload } from './QuotationUtils';
import { AdditionalCharge, Discount, FormValues, LeadSearchResponse, Product, ProductSummary } from './Quotation';
import ProductSearchDropdown from './Components/ProductSearchDropdown';
import ProductPickerPopup from '../../components/ProductPickerPopup';
import moment from 'moment';
import { QuotationHeader } from './Components/QuotationHeader';
import { ProductsList } from './ProductsList';
import { AdditionalCharges } from './Components/AdditionalCharges';
import { DiscountSection } from './Components/DiscountSection';
import { TaxSection } from './Components/TaxSection';


import { Quotation, Lead } from './Quotation';
import { 
  createQuotations, 
  updateQuotations,
  getQuotationDetails, 
  getQuotationId 
} from '../../services/quotationService';
import type { FormInstance } from 'antd/lib/form';
import './Quotation-form.scss';
import QuotationSummary from './Components/QuotationSummary';
import { styled } from 'styled-components';
import { calculateQuotationTotals } from './Components/QuotationCalculations';
import { transformApiToFormData, transformFormToApiData } from './Quotation-data-transformer';
import { hasValidationErrors, validateQuotation, ValidationSeverity } from './enhanced-Quotation-validation';
import { Logger } from '../../utils/logger';
const { Panel } = Collapse;

interface QuotationIdResponse {
  statusCode: number;
  status: boolean;
  data: {
    quotationId: string;
  }
}

interface AddEditQuotationProps {
  onSuccess: () => void;
  data?: Quotation | null;
  lead?: Lead | null;
}



// Just update these existing styled components with better styles:

const QuotationLayout = styled.div`
  height: 100%;
  background: #f8fafc;
`;

const NavHeader = styled.nav`
  background: white;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

const NavContainer = styled.div`
  height: 64px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
`;

const MainContent = styled.div`
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;

  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }

  .card-body {
    padding: 24px;
  }
`;
const AddEditQuotation: React.FC<AddEditQuotationProps> = ({
  onSuccess,
  data,
  lead
}) => {
  Logger.log('AddEditQuotation: Rendering with data:', { isEdit: !!data?._id, leadId: lead?._id });
  
 
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const [quotationData, setQuotationData] = useState<Quotation | null>(null);
  const [totals, setTotals] = useState({
    subtotal: 0,
    chargesTotal: 0,
    discountAmount: 0,
    taxTotal: 0,
    total: 0
  });

  const [showDiscount, setShowDiscount] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [quotationIdModalVisible, setQuotationIdModalVisible] = useState(false);

  const [leadOptions, setLeadOptions] = useState<LeadSearchResponse[]>([]);
  
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [showProductPicker, setShowProductPicker] = useState(false);

  const [hasInitialized, setHasInitialized] = useState(false);

  // State to track mode and initial data
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [initialData, setInitialData] = useState<Quotation | null>(null);
 
   // Effect to determine mode and initial data
// Merge both initialization effects into one


 
  
  
  
  const handleValuesChange = useCallback((values: any) => {
    const newTotals = calculateTotals(values as FormValues);
    setTotals(newTotals);
  }, []);


  useEffect(() => {
    if (hasInitialized) return; // Prevent duplicate initialization
    
    Logger.log('[INVOICE] Component initialization', { location, data, hasInitialized });
    setLoading(true);
    
    // 1. Determine mode and initial data
    const navigationState = location.state as { 
      mode?: 'create' | 'edit', 
      editData?: Quotation 
    } | null;
  
    const determinedMode = navigationState?.mode || (data ? 'edit' : 'create');
    const determinedInitialData = navigationState?.editData || data || null;
  
    setMode(determinedMode);
    setInitialData(determinedInitialData);
    
    // 2. Initialize data based on mode
    const initializeData = async () => {
      try {
        if (determinedMode === 'edit' && determinedInitialData?._id) {
          Logger.log('[INVOICE] Edit mode - fetching details for ID:', determinedInitialData._id);
          
          // Fetch quotation details
          const response = await getQuotationDetails(determinedInitialData._id);
          
          if (response?.data?.data) {
            const quotation = response.data.data;
            Logger.log('[INVOICE] Successfully fetched quotation details:', quotation);
            
            // Process lead data
            let leadData = quotation.lead;
            if (typeof quotation.lead === 'string') {
              Logger.log('[INVOICE] Lead is a string, creating a fallback object');
              leadData = { _id: quotation.lead, name: 'Unknown Lead' };
            }
            
            Logger.log('[INVOICE] Processed lead data:', leadData);
            
            // Add lead to options if not present
            if (leadData && !leadOptions.some((option) => option._id === leadData._id)) {
              setLeadOptions((prevOptions) => [leadData, ...prevOptions]);
            }
            
            // Prepare form data
            const formData = {
              QuotationId: quotation.quotationId || '',
              _id: quotation._id || '',
              lead: leadData,
              products: quotation.products || [],
              additionalCharges: quotation.additionalCharges || [],
              taxes: quotation.taxes?.map((tax: { title: any; rate: any; }) => ({
                title: tax.title,
                rate: tax.rate
              })) || [],
              discount: quotation.discount,
              notes: quotation.notes || quotation.note || '',
              createdDate: quotation.createdDate ? moment(quotation.createdDate).toDate() : new Date(),
              receivedAmount: quotation.receivedAmount || 0,
              receivedPaymentMode: quotation.receivedPaymentMode || ''
            };
            
            Logger.log('[INVOICE] Prepared form data:', formData);
            
            // Set form values
            form.setFieldsValue(formData);
            
            // Set UI state based on data
            const shouldShowDiscount = !!quotation.discount;
            const shouldShowTax = !!(quotation.taxes && quotation.taxes.length > 0);
            
            if (shouldShowDiscount) {
              setShowDiscount(true);
              Logger.log('[INVOICE] Enabling discount section');
            }
            
            if (shouldShowTax) {
              setShowTax(true);
              Logger.log('[INVOICE] Enabling tax section');
            }
            
            // Calculate totals after setting form values
            handleValuesChange(formData);
          } else {
            console.error('[INVOICE] No data found in response');
            message.error('Could not fetch quotation details');
          }
        } else {
          // Create mode - fetch new ID
          Logger.log('[INVOICE] Create mode - fetching new ID');
          const response = await getQuotationId();
          
          if (response?.data?.data?.quotationId) {
            const id = response.data.data.quotationId;
            Logger.log('[INVOICE] Setting new Quotation ID:', id);
            form.setFieldsValue({
              QuotationId: id,
              createdDate: moment().toDate()
            });
          } else {
            console.error('[INVOICE] Failed to get quotation ID');
            message.error('Could not generate quotation ID');
          }
        }
        
        if (lead && !hasInitialized) {
          Logger.log('[QUOTATION] Create mode with lead data:', lead);
          
          // Add lead to options if not already present
          if (!leadOptions.some((option) => option._id === lead._id)) {
            // Create a type-safe lead option with required fields
            const leadOption = {
              _id: lead._id,
              name: lead.name,
              // Add any other required fields with default values
              // Only include these if LeadSearchResponse requires them
              // If these cause type errors, adjust based on your LeadSearchResponse type
              email: lead.email || '',
              phone: lead.phone || '',
              status: Array.isArray(lead.status) ? lead.status : [],
              label: Array.isArray(lead.label) ? lead.label : []
            };
            
            setLeadOptions((prevOptions) => [leadOption, ...prevOptions]);
          }
          
          // Set the lead in the form - only include the _id as required by your form type
          form.setFieldsValue({
            lead: {
              _id: lead._id
            }
          });
        }

        // Mark as initialized to prevent duplicate fetches
        setHasInitialized(true);
      } catch (error) {
        console.error('[INVOICE] Error initializing data:', error);
        message.error('Error loading quotation data');
      } finally {
        setLoading(false);
      }
    };
    
    initializeData();
    
    // Clean up function if needed
    return () => {
      Logger.log('[INVOICE] Component cleanup');
    };
  }, [data, location, form, leadOptions, hasInitialized, handleValuesChange, navigate]);

  // Keep existing data fetching logic
  const fetchQuotationDetails = useCallback(async () => {
    try {
      if (data?._id) {
        setLoading(true);
        const response = await getQuotationDetails(data._id);
        
        if (response?.data?.data) {
          const quotation = response.data.data;
          Logger.log('Fetched quotation details:', quotation);
          
          // Transform API data to form data with proper validation
          const formData = transformApiToFormData(quotation);
          
          // Set form values safely
          form.setFieldsValue(formData);
          
          // Set UI states based on data properties
          if (formData.discount?.rate !== null && formData.discount?.rate !== undefined) {
            setShowDiscount(true);
            Logger.log('showDiscount set to true');
          }
          
          if (Array.isArray(formData.taxes) && formData.taxes.length > 0) {
            setShowTax(true);
            Logger.log('showTax set to true');
          }
          
          // Calculate totals
          handleValuesChange(formData);
        }
      }
    } catch (error) {
      console.error('Error fetching quotation details:', error);
      message.error('Error fetching quotation details');
    } finally {
      setLoading(false);
    }
  }, [data, form, handleValuesChange]);

  const handleMultipleProductsSelect = useCallback((selectedProducts: any[]) => {
    Logger.log('AddEditQuotation: Multiple products selected', selectedProducts);
    
    try {
      // Safely get current products
      const currentProducts = form.getFieldValue('products') || [];
      
      // Format products with safe property access
      const newProducts = selectedProducts.map(product => ({
        id: product?._id || '',
        quantity: Number(product?.quantity) || 1,
        unitPrice: Number(product?.price) || 0,
        description: product?.description || '',
        product: {
          _id: product?._id || '',
          name: product?.name || 'Unknown Product'
        }
      }));
      
      // Update form safely
      form.setFieldsValue({
        products: [...currentProducts, ...newProducts]
      });
      
      // Update calculations
      handleValuesChange(form.getFieldsValue());
    } catch (error) {
      console.error('Error adding multiple products:', error);
      message.error('Failed to add products');
    }
  }, [form, handleValuesChange]);
  
  // ===== STEP 4: Get current product IDs to exclude from picker =====
  const getExcludedProductIds = useCallback(() => {
    const currentProducts = form.getFieldValue('products') || [];
    return currentProducts.map((product: { id: any; product: { _id: any; }; }) => product.id || product.product?._id);
  }, [form]);

  const handleProductSelect = useCallback((product: Product) => {
    try {
      // Safely get current products
      const currentProducts = form.getFieldValue('products') || [];
      
      // Create new product with safe property access
      const newProduct = {
        id: product?._id || '',
        quantity: 1,
        unitPrice: Number(product?.price) || 0,
        description: product?.description || '',
        product: {
          _id: product?._id || '',
          name: product?.name || 'Unknown Product'
        }
      };
      
      // Update form safely
      form.setFieldsValue({
        products: [...currentProducts, newProduct]
      });
      
      // Update calculations
      handleValuesChange(form.getFieldsValue());
    } catch (error) {
      console.error('Error adding product:', error);
      message.error('Failed to add product');
    }
  }, [form, handleValuesChange]);

  const fetchNewQuotationId = useCallback(async () => {
    try {
      const response = await getQuotationId();
      Logger.log('Fetched ID response:', response);
      
      if (response?.data?.data?.quotationId) {
        // Force immediate form update
        const id = response.data.data.quotationId;
        Logger.log('Setting Quotation ID:', id);
        form.setFieldValue('QuotationId', id);
        form.setFieldValue('createdDate', moment());
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error generating quotation ID');
    }
}, [form]);

useEffect(() => {
  Logger.log('Totals updated:', totals);
}, [totals]);



Logger.log('showDiscount:', showDiscount);
Logger.log('showTax:', showTax);



const handleSubmit = async (values: any) => {
  try {
    Logger.log('1. Initial form values:', values);

    // Run enhanced validation
    const validationResults = validateQuotation(values as FormValues);
    
    // Check if there are validation errors
    if (hasValidationErrors(validationResults)) {
      // Display validation errors
      validationResults
        .filter(result => result.severity === ValidationSeverity.ERROR)
        .forEach(error => {
          message.error(error.message);
        });
      
      return; // Exit early if validation fails
    }

    setLoading(true);
    
    // Transform form data to API payload
    const submitData = transformFormToApiData(values as FormValues, mode === 'edit');
  Logger.log('3b. After transformFormToApiData for update:', submitData);
    
    if (mode === 'edit' && initialData?._id) {
      Logger.log('3a. Update mode detected');
      
      // Include the ID for the API endpoint
      await updateQuotations({ 
        ...submitData, 
        _id: initialData._id 
      });
      
      Logger.log('3e. Quotation updated successfully');
      message.success('Quotation updated successfully');
    } else {
      Logger.log('3a. Create mode detected');
      await createQuotations(submitData);
      Logger.log('3b. Quotation created successfully');
      message.success('Quotation created successfully');
    }

    onSuccess();
    navigate('/quotationlist');
  } catch (error) {
    console.error('4. Error in handleSubmit:', error);
    message.error('Failed to save quotation');
  } finally {
    setLoading(false);
  }
};

  const handleCancel = () => {
    Logger.log('AddEditQuotation: Handling cancel');
    if (onSuccess) {
      onSuccess(); // This will trigger parent's handleFormCancel
    }
  };


  function setShowProductForm(arg0: boolean): void {
    throw new Error('Function not implemented.');
  }

  return (
    <QuotationLayout>
      {/* Header */}
      <NavHeader>
  <NavContainer>
    <div className="d-flex align-items-center gap-3">
    <Button 
  icon={<ArrowLeft size={16} />}
  onClick={handleCancel}
  className="d-flex align-items-center gap-2"
  style={{ 
    height: '38px',
    padding: '0 16px',
    border: '1px solid #e5e7eb',
    background: '#f9fafb',
    color: '#374151',
    fontWeight: 500,
    borderRadius: '6px',
    transition: 'all 0.2s ease'
  }}
>
  Back
</Button>
      <h1 className="mb-0 fs-5">{mode === 'edit' ? 'Edit Quotation' : 'Create Quotation'}</h1>
    </div>
          
          <div className="d-flex gap-3">
          <Button 
  onClick={handleCancel}
  style={{
    height: '40px',
    padding: '0 18px',
    background: '#ffffff',
    border: '1px solid #e5e7eb',
    color: '#4b5563',
    fontWeight: 500,
    borderRadius: '6px',
    transition: 'all 0.2s ease'
  }}
  className="hover-effect"
>
  Cancel
</Button>
      <Button
        type="primary"
        onClick={() => form.submit()}
        loading={loading}
      >
        {mode === 'edit' ? 'Update' : 'Create'} Quotation
      </Button>
    </div>
  </NavContainer>
</NavHeader>
  
      {/* Main Content */}
      <MainContent>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onValuesChange={(_, allValues) => handleValuesChange(allValues)}
        >
          <div className="row g-4">
            {/* Left Content - 75% */}
            <div className="col-lg-8">
              {/* Quotation Header */}
<div className="card mb-4">
  <div className="card-body">
  <QuotationHeader 
  form={form}
  initialLead={lead}
/>
  </div>
</div>
  
             {/* Products Card */}
<div className="quotation-card mb-4">
  <div className="card-body p-4">
    <div className="row">
      <div className="col-12">
        <h2 className="fs-6 fw-semibold mb-4">Products</h2>
        
        {/* Search Container */}
        <div className="mb-4">
  <Button 
    type="primary" 
    icon={<Plus size={16} />}
    onClick={() => setShowProductPicker(true)}
    className="w-100"
  >
    Search & Add Products
  </Button>
  
  {/* Product Picker Popup */}
  <ProductPickerPopup
    isOpen={showProductPicker}
    onClose={() => setShowProductPicker(false)}
    onProductsSelect={handleMultipleProductsSelect}
    excludeProductIds={getExcludedProductIds()}
  />
</div>

        {/* Selected Products List */}
        <ProductsList
          form={form}
          onValuesChange={handleValuesChange}
        />
      </div>
    </div>
  </div>
</div>
  
              {/* Notes Section */}
              <div className="card">
                <div className="card-body">
                  <h2 className="fs-6 mb-3">Additional Information</h2>
                  <Form.Item name="notes" className="mb-0">
                    <Input.TextArea
                      rows={4}
                      placeholder="Add any notes or special instructions..."
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
  
            {/* Right Sidebar - 25% */}
            <div className="col-lg-4">
              <div className="sticky-top" style={{ top: '80px' }}>
                {/* Summary */}
                
                <QuotationSummary
                
  form={form}
  totals={totals}
  productCount={form.getFieldValue('products')?.length || 0}
/>
  
                {/* Adjustments */}
                <div className="card">
                  <div className="card-body">
                    <h2 className="fs-6 mb-4">Adjustments</h2>
                    
                    {/* Additional Charges */}
                    <div className="mb-4">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <span>Additional Charges</span>
                        <Button 
                          type="link" 
                          className="p-0"
                          onClick={() => {
                            const charges = form.getFieldValue('additionalCharges') || [];
                            form.setFieldValue('additionalCharges', [
                              ...charges,
                              { title: '', type: 'fixed', rate: null }
                            ]);
                          }}
                        >
                          + Add Charge
                        </Button>
                      </div>
                      <AdditionalCharges
                        form={form}
                        onValuesChange={handleValuesChange}
                      />
                    </div>
  
                    {/* Discount Section */}
                    <div className="mb-4 pt-3 border-top">
                    <DiscountSection
  show={showDiscount}
  onToggle={() => setShowDiscount(!showDiscount)}
  form={form}
  onValuesChange={handleValuesChange}
/>
                    </div>
  
                    {/* Tax Section */}
                    <div className="pt-3 border-top">
                      <TaxSection
                        show={showTax}
                        onToggle={() => setShowTax(!showTax)}
                        form={form}
                        onValuesChange={handleValuesChange}
                        baseAmount={totals.subtotal - totals.discountAmount}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        </MainContent>
  
     
      
    </QuotationLayout>
  );
};

export default AddEditQuotation;