import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  fetchTeamList,
  getUserOrganizationList,
} from "../../services/dashboardService";
import { dashboardDates } from "../../utils/globalConstants";
import CustomDropdownWithCheckboxes from "./CustomDropdownWithCheckboxes";
import { IDashboardHeader } from './dashboard.types';
import toast from "react-hot-toast";
import { toggleDailyEmailPreference } from "../../services/userService";
import { MailCheck } from "lucide-react";
import GenericConfirmationModal from "../../components/GenericConfirmationModal";
import { Logger } from '../../utils/logger';

const emailPreview = `Subscribe to get your daily business report email like this from 3Sigma CRM:

📊 Daily Performance Summary
──────────────────
New Leads:  15 leads added
──────────────────
Calls Activity:
• Total Calls: 45 calls
• Connected: 32 calls
• Avg Call Time: 3.5 mins
──────────────────
Sales Updates:
• Total Sales: ₹75,000
• sales : 12 completed
──────────────────
Tasks:
• Due Today: 8 tasks
• Completed: 5 tasks
• Overdue: 2 tasks

Note: If you've already subsribed for daily email reports once no need do it again, no need to subscribe again.`;

const DashboardHeader = ({
  fromDate,
  isCustomDate,
  selectFilterDate,
  handleFilteronChange,
  selectFilterUser,
}: IDashboardHeader) => {
  const [teamData, setTeamData] = useState<any>([]);
  const [organizationData, setOrganizationData] = useState<any>([]);
  const [filterUserData, setFilterUserData] = useState<any>([]);
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [showEmailConfirmModal, setShowEmailConfirmModal] = useState(false);
  const [emailPrefLoading, setEmailPrefLoading] = useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>(
    []
  );

  const getDisplayText = () => {
    const selectedValues = [...selectedTeams, ...selectedOrganizations];
    
    if (!selectedValues?.length) return "Select Options";
  
    const selectedOptions = options.filter(opt => 
      selectedValues.includes(opt.value)
    );
  
    if (selectedOptions.length === 1) {
      return selectedOptions[0].label.split(" - ")[0];
    }
  
    const firstTwo = selectedOptions.slice(0, 2).map(opt => 
      opt.label.split(" - ")[0]
    );
    
    if (selectedOptions.length > 2) {
      const othersCount = selectedOptions.length - 2;
      return `${firstTwo.join(", ")} & ${othersCount} ${othersCount === 1 ? 'other' : 'others'}`;
    }
  
    return firstTwo.join(" & ");
  };
  
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const handleEmailPreference = async () => {
    setEmailPrefLoading(true);
    try {
      await toggleDailyEmailPreference(true);
      toast.success("Successfully subscribed to daily sales reports!", {
        position: "top-right",
        duration: 3000
      });
      setShowEmailConfirmModal(false);
    } catch (error) {
      console.error('Failed to update email preference:', error);
      toast.error("Unable to subscribe to daily reports. Please try again.", {
        position: "top-right",
        duration: 4000
      });
    } finally {
      setEmailPrefLoading(false);
    }
  };

  useEffect(() => {
    const getAllTeam = async () => {
      try {
        const response = await fetchTeamList();
        if (response && response.status) {
          setTeamData(response.data.data);
        }
      } catch (err) {
        Logger.log(err);
      }
    };

    const getOrganizationData = () => {
      let tempOrgArray = [];
      let tempOrgData = {
        name: StoreData?.userData?.userDetails?.organization?.name,
        _id: StoreData?.userData?.userDetails?.organization?._id,
      };
      tempOrgArray.push(tempOrgData);
      setOrganizationData(tempOrgArray);
    };

    
    
    
    const handleGetUserOrganization = async () => {
      try {
        const response = await getUserOrganizationList({
          page: 1,
          perPage: 15,
        });
        if (response && response.status) {
          let tempArray = [...response.data.data];
          tempArray.forEach((el: any) => {
            let findRole =
              StoreData?.userData?.userDetails?.organizationRoles?.find(
                (x: any) => x._id === el.role
              )?.displayName || '';
            el.roleName = findRole;
            el.organizationName =
              StoreData?.userData?.userDetails?.organization?.name || '';
          });
    
          // Add proper null checks for role access
          const userRole = StoreData?.userData?.userDetails?.role?.name;
          if (
            userRole === "super_admin" ||
            userRole === "admin"
          ) {
            let tempObj = {
              firstName: StoreData?.userData?.userDetails?.firstName || '',
              _id: StoreData?.userData?.userDetails?._id,
              organizationName:
                StoreData?.userData?.userDetails?.organization?.name || '',
              roleName: StoreData?.userData?.userDetails?.role?.displayName || '',
            };
            tempArray.push(tempObj);
          }
          setFilterUserData(tempArray);
        }
      } catch (err) {
        Logger.log(err, "Error");
      }
    };

    getAllTeam();
    getOrganizationData();
    handleGetUserOrganization();
  }, []);

  let options = [
    ...teamData.map((item: any) => ({
      label: `${item.name} - Team`,
      value: item._id,
    })),
    ...organizationData.map((item: any) => ({
      label: `${item.name} - Organization`,
      value: item._id,
    })),
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="fs-4 fw-bold text-dark">Dashboard</div>
        <div className="dashboard_header">
          <button
            onClick={() => setShowEmailConfirmModal(true)}
            disabled={emailPrefLoading}
            style={{
              backgroundColor: "#06402b",
              color: "white",
              borderRadius: "8px",
              height: "36px",
              padding: "0 16px",
              border: "none",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginRight: "12px",
              transition: "all 0.2s ease",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: "500",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)"
            }}
            className="hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <MailCheck size={18} />
            {emailPrefLoading ? "Processing..." : "Get Daily Email Reports"}
          </button>
          <div className="header_flex">
            <select
              name="type"
              className="form-select"
              value={fromDate}
              placeholder="Date"
              onChange={(e: any) => handleFilteronChange(e.target.value)}
            >
              {dashboardDates.map((item: any, index: number) => (
                <option key={`date-${index}`} value={item.value}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="header_flex">
            <select
              name="type"
              className="form-select"
              onChange={(e: any) => {
                const selectedValue = e.target.value;
                const isOrg = selectedValue === "organization";
                
                if (isOrg) {
                  setSelectedTeams([]);
                  setSelectedOrganizations([selectedValue]);
                  selectFilterUser("organization", "organization");
                } else {
                  setSelectedTeams([selectedValue]);
                  setSelectedOrganizations([]);
                  selectFilterUser(selectedValue, "team");
                }
              }}
            >
              <option value="">{getDisplayText()}</option>
              {teamData?.map((item: any, t: number) => (
                <option value={item?._id} key={t}>
                  {item.name} - Team
                </option>
              ))}
              {organizationData?.map((item: any, t: number) => (
                <option value="organization" key={t}>
                  {item.name} - Organization
                </option>
              ))}
            </select>
          </div>
          <div className="header_flex">
            <select
              name="type"
              className="form-select"
              onChange={(e: any) => selectFilterUser(e.target.value, "Emp")}
            >
              <option value="">Team members</option>
              {filterUserData?.map((item: any) => (
                <option key={`user-${item?._id}`} value={item?._id}>
                  {item.firstName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {isCustomDate && (
        <div className="dashboard_header_row d-flex align-items-center">
          <div className="header_flex">
            <label className="me-2">From : </label>
            <input
              type="date"
              name="from"
              className="form-to-date form-control"
              onChange={(e) => selectFilterDate(e)}
            />
          </div>
          <div className="header_flex ms-4">
            <label className="me-2">To : </label>
            <input
              type="date"
              name="to"
              className="form-to-date form-control"
              onChange={(e) => selectFilterDate(e)}
            />
          </div>
        </div>
      )}
      <GenericConfirmationModal
        showModal={showEmailConfirmModal}
        toggleModal={() => setShowEmailConfirmModal(false)}
        onConfirmation={handleEmailPreference}
        title="Preview Daily Business Report"
        message={emailPreview}
        confirmText="Subscribe"
        cancelText="Maybe Later"
      />
    </div>
  );
};

export default DashboardHeader;
