import React, { useState } from 'react';
import { MessageSquare, FileText, Globe, Search } from 'lucide-react';
import styled from 'styled-components';

const NavContainer = styled.div`
  width: 100%;
  background: white;
  border-bottom: 1px solid #e5e7eb;
`;

const NavContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

const TabList = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0.375rem;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
  
  background: ${props => props.isActive ? '#EBF5FF' : 'transparent'};
  color: ${props => props.isActive ? '#2563EB' : '#4B5563'};

  &:hover {
    background: ${props => props.isActive ? '#EBF5FF' : '#F3F4F6'};
    color: ${props => props.isActive ? '#2563EB' : '#111827'};
  }
`;

const SearchWrapper = styled.div`
  position: relative;
  width: 18rem;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #9CA3AF;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #111827;
  background-color: #F9FAFB;
  border: 1px solid #E5E7EB;
  border-radius: 0.5rem;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #2563EB;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

const ContentCard = styled.div`
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-top: 1rem;
`;

interface TabNavigationProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({ activeTab, setActiveTab }) => {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <NavContainer>
      <NavContent>
        <NavWrapper>
          <TabList>
            <TabButton 
              isActive={activeTab === 'messages'} 
              onClick={() => setActiveTab('messages')}
            >
              <MessageSquare size={16} />
              <span>Messages</span>
            </TabButton>

            <TabButton 
              isActive={activeTab === 'files'} 
              onClick={() => setActiveTab('files')}
            >
              <FileText size={16} />
              <span>Files</span>
            </TabButton>

            <TabButton 
              isActive={activeTab === 'pages'} 
              onClick={() => setActiveTab('pages')}
            >
              <Globe size={16} />
              <span>Pages</span>
            </TabButton>
          </TabList>

          <SearchWrapper>
            <SearchIconWrapper>
              <Search size={16} />
            </SearchIconWrapper>
            <SearchInput
              type="text"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
              placeholder="Search content..."
            />
          </SearchWrapper>
        </NavWrapper>
      </NavContent>
    </NavContainer>
  );
};

// Usage Example
const ContentDetail = () => {
  const [activeTab, setActiveTab] = useState('messages');

  return (
    <div style={{ minHeight: '100vh', background: '#F9FAFB' }}>
      <TabNavigation 
        activeTab={activeTab} 
        setActiveTab={setActiveTab} 
      />
      <NavContent>
        <ContentCard>
          {activeTab === 'messages' && <div>Messages Content</div>}
          {activeTab === 'files' && <div>Files Content</div>}
          {activeTab === 'pages' && <div>Pages Content</div>}
        </ContentCard>
      </NavContent>
    </div>
  );
};

export default ContentDetail;