import React, { useCallback, useEffect, useState, useMemo } from "react";
import OtpInput from "react-otp-input";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { setLogId } from "../../actions/actions";
import Timer from "../../components/timer";
import { generateOtp, signIn } from "../../services/authServices";
// import { getUserPreferences } from "../../services/userService";
import { useCountdown } from "../../utils/countdown";
import {
  userPreferences,
  setUserDetails,
  setCustomSource,
} from "../../actions/actions";
import { API_URL } from "../../config/config";
import { getHeaderOptions } from "../../services/getHeaderOptions";
import axios from "axios";
import { Logger } from '../../utils/logger';

interface HeapUserData {
  _id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  isOnboardCompleted?: boolean;
  subscription?: {
    status: string;
  };
  role?: {
    name: string;
  };
  organization?: {
    name: string;
  };
}

export const OtpVerification = () => {
  const [otp, setOTP] = useState<string>("");
  const [verifying_otp, setVerifyOtp] = useState(false);
  const [otp_verify_error, setOtpVerifyError] = useState(false);
  const logId = useSelector(
    (state: { rootReducers: { logId: { logId: string } } }) =>
      state?.rootReducers?.logId?.logId
  );
  const currentTime = new Date().getTime();
  const timeAfterOneSecond = currentTime + 1 * 60 * 1000;
  const [minutes, seconds] = useCountdown(timeAfterOneSecond);
  const [flag, setFlag] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useMemo(() => {
    if (minutes + seconds <= 0) {
      setFlag(true);
    }
  }, [minutes, seconds]);

  function makeId(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /*
  const initHeapUser = (userData: HeapUserData) => {
    if (typeof window.heap === "undefined") {
      setTimeout(() => initHeapUser(userData), 1000);
      return;
    }

    try {
      window.heap.identify(userData._id);
      window.heap.addUserProperties({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        isOnboardCompleted: userData.isOnboardCompleted,
        isSubscriptionActive: userData.subscription?.status === "active",
        userRole: userData.role?.name,
        organization: userData.organization?.name,
      });
      Logger.log("Heap user properties set successfully");
    } catch (error) {
      console.error("Failed to initialize Heap user:", error);
    }
  };*/

  const handleClick = useCallback(async () => {
    var pattern = /^\d{6}$/;
    if (!pattern.test(otp)) {
      setOtpVerifyError(true);
      Logger.log("[OTP] Invalid OTP format");
      return;
    }

    setVerifyOtp(true);
    let data = {
      loginType: "phone",
      loggedFrom: "web",
      deviceToken: JSON.parse(localStorage.getItem("fcm_token_data") || "{}")
        .token,
      countryCode: localStorage.getItem("countryCode"),
      phone: localStorage.getItem("phone"),
      otp: otp,
    };

    try {
      Logger.log("[OTP] Sending sign in request with data:", data);
      const response = await signIn(data);

      Logger.log("[OTP] Response received:", {
        hasToken: response?.data?.data?.token ? "Yes" : "No",
        isNewUser: response?.data?.data?.isNewAccount ? "Yes" : "No",
        onboardingCompleted: response?.data?.data?.isOnboardCompleted
          ? "Yes"
          : "No",
        subscriptionActive: response?.data?.data?.isSubscriptionActive
          ? "Yes"
          : "No",
      });

      if (response && response.status) {
        // Store data first
        Logger.log("[OTP] Storing auth data and user details");
        localStorage.setItem("auth_token", response?.data?.data?.token);
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));

        if (response?.data?.data?.multiAccountList?.length) {
          // Store just the multi-business array
          localStorage.setItem(
            "userBusinesses",
            JSON.stringify(response.data.data.multiAccountList)
          );
        }

        if (response.data.data.userPreference) {
          Logger.log("[OTP] User preferences found, storing preferences");
          localStorage.setItem(
            "user_preferences",
            JSON.stringify(response.data.data.userPreference)
          );
          dispatch(userPreferences(response.data.data.userPreference));
          dispatch(
            setCustomSource(response.data.data.userPreference.customSource)
          );
        } else {
          Logger.log("[OTP] No user preferences in response");
        }

        dispatch(setUserDetails(response?.data?.data));

        Logger.log("[OTP] Data stored in localStorage:", {
          token: localStorage.getItem("auth_token") ? "Present" : "Missing",
          userData: localStorage.getItem("userData") ? "Present" : "Missing",
          preferences: localStorage.getItem("user_preferences")
            ? "Present"
            : "Missing",
        });

        // Check user status conditions
        Logger.log("[OTP] Checking user status:", {
          isNewAccount: response?.data?.data?.isNewAccount,
          isOnboardCompleted: response?.data?.data?.isOnboardCompleted,
        });

        // New user OR incomplete onboarding -> straight to signup
        if (
          response?.data?.data?.isNewAccount ||
          !response?.data?.data?.isOnboardCompleted
        ) {
          Logger.log("[OTP] Redirecting to signup:", {
            reason: response?.data?.data?.isNewAccount
              ? "New User"
              : "Onboarding Incomplete",
          });
          navigate("/sign-up", { replace: true });
          return;
        }

        // Only check subscription for existing users with completed onboarding
        Logger.log("[OTP] Checking subscription status for existing user");
        if (!response?.data?.data?.isSubscriptionActive) {
          Logger.log("[OTP] Subscription inactive, redirecting to home");
          toast.error("Your subscription is inactive.");
          setVerifyOtp(false);
          navigate("/", { replace: true });
          return;
        }

        // Success path - existing user, completed onboarding, active subscription
       // Logger.log("[OTP] All checks passed, proceeding to leads page");
        //if (response?.data?.data) {
         // initHeapUser(response.data.data);
       // }

        navigate("/leads", { replace: true });
      }
    } catch (err) {
      Logger.log("[OTP] Error occurred:", err);
      setVerifyOtp(false);
      toast.error("Error occurred while logging in!");
    }
  }, [logId, navigate, otp, dispatch]);

  const resendOTP = async (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    e.preventDefault();
    const data = {
      phone: localStorage.getItem("phone"),
      countryCode: localStorage.getItem("countryCode"),
      otpType: "sms",
    };
    try {
      const response = await generateOtp(data);
      if (response && response.status) {
        toast.success("OTP Sent Successfully!");
        dispatch(setLogId(response?.data?.data?.LogID));
      }
    } catch (err) {
      toast.error("Error occured while sending OTP!");
    }
  };

  const getOtpOverCall = async () => {
    const data = {
      phone: localStorage.getItem("phone"),
      countryCode: localStorage.getItem("countryCode"),
      otpType: "voice",
    };
    try {
      const response = await generateOtp(data);
      if (response && response.status) {
        toast.success(response?.data?.message);
        dispatch(setLogId(response?.data?.data?.LogID));
      }
    } catch (err) {
      toast.error("Error occured while sending OTP!");
    }
  };

  const handleChange = (otp: React.SetStateAction<string>) => {
    setOTP(otp);
  };

  useEffect(() => {
    if (otp && otp.length === 6) {
      handleClick();
    }
  }, [handleClick, otp]);

  const editPhoneNumber = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="container">
      <div className="row justify-content-center otp_section">
        <div className="col-12 col-md-6 col-lg-5 col-xl-5 mt-5 login_card">
          <div className="card login-card">
            <div className="d-flex flex-column align-items-center">
              {/* <img src="assets/logo.png" alt="logo" className="mt-3 logo" /> */}
              <h2
                className="text-center mb-3 font-weight-bold"
                style={{ color: "#3A4B86" }}
              >
                Verify Your Phone
              </h2>
              <p className="text-center mb-4"></p>
              <div className="Opt_h4 d-flex text-center">
                <h4 className="align-self-center">
                  Send to
                  {localStorage.getItem("countryCode") != null &&
                    " +" +
                      localStorage.getItem("countryCode") +
                      " " +
                      localStorage.getItem("phone")}
                  .
                </h4>
                <button
                  onClick={editPhoneNumber}
                  className="btn btn-link p-0"
                  style={{ fontSize: "0.9rem" }}
                >
                  Change
                </button>
              </div>
              <div className="otp-verification-header text-center mt-3">
                {/* <p>Enter Your OTP code here</p> */}
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum
                  separator={<span className="ms-2"> </span>}
                  shouldAutoFocus={true}
                  inputStyle={{
                    width: "100%",
                    height: "3rem",
                    fontSize: "1.5rem",
                    borderRadius: "8px",
                    border: "1px solid #ced4da",
                  }}
                  containerStyle="justify-content-between mb-4"
                />
                <button
                  className="btn btn-primary btn-lg w-100 mb-4"
                  style={{
                    backgroundColor: "#3A4B86",
                    borderColor: "#3A4B86",
                    fontSize: "1.1rem",
                    fontWeight: 500,
                    padding: "12px",
                    borderRadius: "8px",
                    maxWidth: "300px", // This sets a maximum width
                  }}
                  onClick={handleClick}
                  disabled={verifying_otp}
                >
                  {verifying_otp ? "Verifying..." : "Verify OTP"}
                </button>
              </div>
              {otp_verify_error && (
                <p className="text-danger text-center mb-3">
                  Invalid OTP. Please try again.
                </p>
              )}
              <div className="otp_footer mt-3">
                {/* <p className="text-center mt-3 mb-1">
                  Didn't receive any code?
                </p> */}
                <div className="d-flex justify-content-center">
                  {/* {minutes + seconds <= 0 ? (
                    ""
                  ) : (
                    <Timer minutes={minutes} seconds={seconds} />
                  )} */}
                </div>
                <div className="d-flex justify-content-center otp-not-received">
                  {flag ? (
                    <>
                      <p className="m-3 p_opt" onClick={(e) => resendOTP(e)}>
                        Resend OTP
                      </p>
                      <p className="my-3">|</p>
                      <p className="m-3 p_opt" onClick={() => getOtpOverCall()}>
                        Get OTP over call
                      </p>
                    </>
                  ) : (
                    <p className="m-3 p_opt">
                      <i className="bi bi-clock"></i> Resend another OTP in
                      <p className="timer_sec">
                        {" "}
                        <Timer seconds={seconds} />
                      </p>
                    </p>
                  )}
                </div>
                <p className="text-muted text-center small mt-4">
                  By continuing you agree to our{" "}
                  <a href="#" className="text-primary">
                    terms of use
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
