import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  repeatOptions,
  taskDueDates,
  taskDueDatesValues,
} from "../utils/globalConstants";
import moment from 'moment';
import 'moment-timezone'; 
import Select from "react-select";
import ErrorText from "./errorText";
import * as yup from "yup";
import update from "immutability-helper";
import { DatePicker, TimePicker } from "antd";
import {
  createNewTask,
  updateTaskbyId,
  getUserOrganizationList,
} from "../services/taskService";
import toast from "react-hot-toast";
import styled from "styled-components";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import ReactAudioPlayer from 'react-audio-player';
import { Logger } from '../utils/logger';
import {
    Root,
    NotesContainer,
    NotesTitle,
    NoteItem,
    NoteContent,
    NoteMeta,
    MetaItem
  } from './TaskFormStyles'; // Save the styles in TaskFormStyles.tsx

interface Props {
  leadId: string; // Now required
  action: string;
  status: string;
  updateTaskValue: any;
  drawerClose: () => void;
  fetchTaskStatusFollowUps: (data?: any) => void;
}

interface taskType {
  task: string;
  type: string;
  dueDate: string | undefined;
  toBePerformAt: string | undefined;
  time: string;
  repeat: string;
  taskAssign: string;
  notes: string;
}
moment.tz.setDefault('Asia/Kolkata');
const TaskForm: React.FC<PropsWithChildren<Props>> = ({
  leadId,
  updateTaskValue,
  action,
  status,
  drawerClose,
  fetchTaskStatusFollowUps,
}) => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  // Initialize with enhanced logging
  const [taskData, setTaskData] = useState<taskType>(() => {
    const istNow = moment().tz('Asia/Kolkata');
    return {
      task: leadId || "",
      type: "",
      dueDate: "",
      toBePerformAt: istNow.format(),
      time: istNow.format(),
      repeat: "NoRepeat",
      taskAssign: "",
      notes: "",
    };
  });
  

  const [isCustomDate, setIsCustomDate] = useState(false);
  const [taskList, setTaskList] = useState<Array<any>>([]);
  const [taskAssignTo, setTaskAssignTo] = useState<Array<any>>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  
  const [errors, setErrors] = useState({
    type: "",
    dueDate: "",
    toBePerformAt: "",
    time: "",
    repeat: "",
    taskAssign: "",
    notes: "",
  });

  // Form validation schema
  const schema = yup.object().shape({
    type: yup.string().required("Task Type is required"),
    dueDate: yup.string().required("Due Date is required"),
    toBePerformAt: yup.string().required("Due Date is required"),
    time: yup.string().required("Time is required"),
  });

  useEffect(() => {
    handleGetUserOrganization();
    
    const taskTypes = StoreData?.user?.userPreferences?.taskType || [];
    setTaskList(taskTypes);
  
    if (action === 'add') {
      Logger.log('Initializing new task defaults');
      
      // Type safe find operation
      const defaultType = taskTypes.find((t: { value: string; name: string }) => 
        t.value.toLowerCase() === 'call' || 
        t.value.toLowerCase() === 'follow up'
      );
  
      // Set tomorrow as default date
      const tomorrow = moment().add(1, 'day');
      
      setTaskData(prev => ({
        ...prev,
        type: defaultType?.value || '',
        dueDate: 'tomorrow',
        toBePerformAt: tomorrow.toISOString(),
        time: tomorrow.toISOString()
      }));
  
      Logger.log('Default task settings:', {
        type: defaultType?.value || 'empty',
        date: tomorrow.format('YYYY-MM-DD'),
        time: tomorrow.format('HH:mm:ss')
      });
    }
  }, []);

  // Edit mode initialization with timezone handling
  useEffect(() => {
    if (action === "edit" && updateTaskValue) {
      const istTime = moment(updateTaskValue.toBePerformAt).tz('Asia/Kolkata');
      
      setTaskData({
        task: leadId,
        type: updateTaskValue.type,
        dueDate: "custom",
        toBePerformAt: istTime.format(),
        time: istTime.format(),
        repeat: updateTaskValue.repeat || "NoRepeat",
        taskAssign: updateTaskValue?.assignedTo[0]?._id,
        notes: "",
      });
  
      setIsCustomDate(true);
      setIsCompleted(updateTaskValue?.isCompleted ?? false);
    }
  }, [action, updateTaskValue, leadId]);

  
  const notePills = [
    "Callback",
    "Busy", 
    "Msg sent",
    
    "Interested",
    "More Info"
  ];

  
  const handleGetUserOrganization = async () => {
    const data = localStorage.getItem("userData");
    if (data) {
      let tempArray = [...JSON.parse(data).organizationEmployee];
      tempArray.forEach((el: any) => {
        let findRole = StoreData.userData.userDetails.organizationRoles.find(
          (x: any) => x._id == el.role
        ).displayName;
        el.roleName = findRole;
        el.organizationName = StoreData.userData.userDetails.organization.name;
      });
      setTaskAssignTo(tempArray);
    }
  };

  const handleSetDueDate = (dueDateType: string) => {
    Logger.log('Setting due date:', dueDateType);
    
    setErrors({
      ...errors,
      toBePerformAt: "",
    });
  
    switch (dueDateType) {
      case "today":
      case "tomorrow":
      case "3d":
      case "1w":
      case "1m":
        const dt = moment()
          .add(taskDueDatesValues[dueDateType as keyof typeof taskDueDatesValues], "days")
          .format("YYYY-MM-DD");
          
        const existingTime = taskData.time 
          ? moment(taskData.time).format("HH:mm:ss")
          : moment().format("HH:mm:ss");
        
        const combined = moment(dt + "T" + existingTime);
        
        Logger.log('Due date calculation:', {
          type: dueDateType,
          calculatedDate: dt,
          existingTime,
          combined: combined.format()
        });
        
        setTaskData({
          ...taskData,
          toBePerformAt: combined.toISOString(),
          dueDate: dueDateType,
          time: combined.toISOString()
        });
        setIsCustomDate(false);
        setIsDatePickerOpen(false);
        break;
  
      case "custom":
        const currentDateTime = taskData.toBePerformAt 
          ? moment(taskData.toBePerformAt)
          : moment();
          
        Logger.log('Setting custom date:', {
          current: currentDateTime.format()
        });
        
        setTaskData({
          ...taskData,
          toBePerformAt: currentDateTime.toISOString(),
          dueDate: dueDateType,
          time: currentDateTime.toISOString()
        });
        setIsCustomDate(true);
        setIsDatePickerOpen(true);
        break;
  
      default:
        break;
    }
  };
  const handleNotePillAppend = (pill: string) => {
    setTaskData(prev => ({
      ...prev,
      notes: prev.notes 
        ? `${prev.notes}\n${pill}` 
        : pill
    }));
  };
  const handleChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    Logger.log('Form field change:', { field: name, value });
    
    setTaskData(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: null,
    }));
  }, []);

  const handleDateChange = (
    name: string,
    value: moment.Moment | null,
    type = ""
  ) => {
    if (!value) return;
  
    const istMoment = moment(value).tz('Asia/Kolkata');
  
    if (type === "time") {
      const newTime = istMoment.format("HH:mm");
      const currentDate = moment(taskData.toBePerformAt).tz('Asia/Kolkata').format("YYYY-MM-DD");
      const combined = moment.tz(`${currentDate} ${newTime}`, "YYYY-MM-DD HH:mm", 'Asia/Kolkata');
  
      setTaskData(prev => ({
        ...prev,
        toBePerformAt: combined.format(),
        time: combined.format()
      }));
    } else {
      const newDate = istMoment.format("YYYY-MM-DD");
      const currentTime = moment(taskData.time).tz('Asia/Kolkata').format("HH:mm");
      const combined = moment.tz(`${newDate} ${currentTime}`, "YYYY-MM-DD HH:mm", 'Asia/Kolkata');
  
      setTaskData(prev => ({
        ...prev,
        toBePerformAt: combined.format(),
        time: combined.format()
      }));
    }
  };

  const handleTaskSubmit = useCallback(async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    Logger.log('Task Submit:', {
      formData: taskData,
      localTime: moment(taskData.toBePerformAt).format(),
      utcTime: moment(taskData.toBePerformAt).utc().format()
    });
  
    try {
      const isFormValid = await schema.isValid(taskData, {
        abortEarly: false,
      });
  
      if (!isFormValid) {
        const validationErrors = await schema.validate(taskData, { abortEarly: false })
          .catch(err => {
            const errors = err.inner.reduce(
              (acc: any, error: { path: string; message: string }) => ({
                ...acc,
                [error.path]: error.message,
              }),
              {}
            );
            setErrors(prevErrors => update(prevErrors, { $set: errors }));
          });
        return;
      }
  
      const utcTime = moment(taskData.toBePerformAt)
        .tz('Asia/Kolkata')
        .utc()
        .format();
  
      // Separate payloads for create and update
      const payload = action === "add" 
        ? {
            leadIds: [leadId],
            type: taskData.type,
            toBePerformAt: utcTime,
            extraDetails: {},
            ...(taskData.taskAssign && { assignedTo: [taskData.taskAssign] }),
            ...(taskData.repeat && { repeat: taskData.repeat }),
            ...(taskData.notes && { notes: taskData.notes })
          }
        : {
            type: taskData.type,
            toBePerformAt: utcTime,
            extraDetails: {},
            ...(taskData.taskAssign && { assignedTo: [taskData.taskAssign] }),
            ...(taskData.repeat && { repeat: taskData.repeat }),
            ...(taskData.notes && { notes: taskData.notes }),
            isCompleted
          };
  
      Logger.log('API Payload:', payload);
  
      const response = action === "add"
        ? await createNewTask(payload)
        : await updateTaskbyId(updateTaskValue._id, payload);
  
      Logger.log('API Response:', response);
  
      if (response?.status) {
        toast.success(response?.data?.message);
        drawerClose();
        fetchTaskStatusFollowUps(response?.data);
      }
    } catch (err) {
      console.error('Task Submit Error:', err);
      toast.error("Error while handling task");
    }
  }, [taskData, leadId, action, updateTaskValue, isCompleted, schema, drawerClose, fetchTaskStatusFollowUps]);

  const getNotesCreatedBy = useCallback((id: string) => {
    const employee = StoreData?.userData?.userDetails?.organizationEmployee ?? [];
    return employee?.find(({ _id }: any) => _id === id)?.firstName ?? "";
  }, [StoreData?.userData?.userDetails?.organizationEmployee]);

  const renderPreviousNotes = useCallback(() => {
    if (action !== "edit" || !updateTaskValue?.noteLogs?.length) return null;
  
    const sortedNotes = _.orderBy(
      updateTaskValue.noteLogs,
      [(obj) => new Date(obj.createdAt)],
      ["desc"]
    );
  
    Logger.log('Sorted notes:', sortedNotes);
  
    const audioFiles = updateTaskValue.files?.filter(
      (file: any) => file.filePath.endsWith('.m4a')
    );
  
    Logger.log('Audio files found:', audioFiles);
  
    return (
      <NotesContainer>
        <NotesTitle>Previous Notes ({sortedNotes.length + audioFiles.length})</NotesTitle>
        {sortedNotes
          ?.filter((e: any) => e.note)
          .map((e: any, i: number) => (
            <NoteItem key={i}>
              <NoteContent>{e.note}</NoteContent>
              <NoteMeta>
                <MetaItem>{moment(e.createdAt).format("DD MMM")}</MetaItem>
                <MetaItem>{moment(e.createdAt).format("hh:mm a")}</MetaItem>
                <MetaItem>by {getNotesCreatedBy(e?.createdBy)}</MetaItem>
              </NoteMeta>
            </NoteItem>
          ))}
        {audioFiles?.map((audioFile: any, i: number) => (
          <NoteItem key={i}>
            <ReactAudioPlayer
              src={`${StoreData?.userData?.userDetails?.bucketUrl}${audioFile.filePath}`}
              controls
            />
            <NoteMeta>
              <MetaItem>{moment(audioFile.uploadedAt).format("DD MMM")}</MetaItem>
              <MetaItem>{moment(audioFile.uploadedAt).format("hh:mm a")}</MetaItem>
              <MetaItem>by {getNotesCreatedBy(audioFile.uploadedBy)}</MetaItem>
            </NoteMeta>
          </NoteItem>
        ))}
      </NotesContainer>
    );
  }, [updateTaskValue, getNotesCreatedBy, action, StoreData?.userData?.userDetails?.bucketUrl]);

  return (
    <Root>
      <div>
        <div className="form-group">
          <label className="form_label">Task Type</label>
          <select
            className="form-select"
            value={taskData.type}
            onChange={handleChange}
            name="type"
          >
            <option value="">Select Task Type</option>
            {taskList?.map((task, j) => (
              <option value={task?.value} key={j}>
                {task?.name}
              </option>
            ))}
          </select>
          {errors.type && <ErrorText message={errors.type} />}
        </div>

        <div className="form-group">
  <label className="form_label">Due Date</label>
  <select
    className="form-select"
    value={taskData.dueDate}
    name="dueDate"
    onChange={(e) => handleSetDueDate(e.target.value)}
  >
    <option value="">Select due date</option>
    {taskDueDates.map((dueDate, d) => (
      <option value={dueDate.value} key={d}>
        {dueDate.name}
      </option>
    ))}
  </select>
  {errors.dueDate && <ErrorText message={errors.dueDate} />}
</div>

{/* Time picker should always be visible */}
<div className="form-group">
  <label className="form_label">Time</label>
  <TimePicker
  format="hh:mm A"
  className="select_date" 
  value={moment(taskData.time).tz('Asia/Kolkata')}
  onChange={(time) => {
    if (time) {
      handleDateChange("time", time, "time");
    }
  }}
/>
  {errors.time && <ErrorText message={errors.time} />}
</div>

{/* Custom date picker appears only for custom selection */}
{isCustomDate && (
  <div className="form-group">
    <label className="form_label">Custom Date</label>
    <DatePicker
      format="DD MMM YYYY"
      value={moment(taskData.toBePerformAt)}
      className="select_date"
      open={isDatePickerOpen}
      onOpenChange={(open) => setIsDatePickerOpen(open)}
      onChange={(date) => {
        if (date) {
          handleDateChange("toBePerformAt", moment(date));
          setIsDatePickerOpen(false);
        }
      }}
    />
  </div>
)}

        <div className="form-group">
          <label className="form_label">Repeat</label>
          <select
            value={taskData.repeat}
            onChange={handleChange}
            name="repeat"
            className="form-select"
          >
            {repeatOptions.map((option, o) => (
              <option value={option.value} key={o}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label className="form_label">Task Assign to</label>
          <select
            value={taskData.taskAssign}
            onChange={handleChange}
            name="taskAssign"
            className="form-select"
          >
            <option value="">Select Task Assign to</option>
            {taskAssignTo?.map((item: any, j: number) => (
              <option value={item._id} key={j}>
                {item.firstName} - {item.roleName} - {item.organizationName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label className="form_label">Note</label>
          <textarea
            className="form-control note_area"
            value={taskData.notes}
            onChange={handleChange}
            name="notes"
            style={{ height: "120px" }}
            placeholder="Enter Note"
          />
        </div>

        <div className="d-flex justify-content-between mb-2">
  {notePills.map((pill) => (
    <button 
      key={pill} 
      type="button" 
      className="btn btn-outline-secondary flex-grow-1 mx-1"
      style={{ fontSize: '0.8rem', padding: '2px 3px' }}
      onClick={() => handleNotePillAppend(pill)}
    >
      {pill}
    </button>
  ))}
</div>
        {renderPreviousNotes()}

        {action === "edit" && (
            <Form.Check
              type="checkbox"
              id="task-complete"
              label="Mark as completed"
              className="mark_checkbox mt-3"
              checked={isCompleted}
              onChange={() => setIsCompleted(!isCompleted)}
            />
          )}
        </div>

        <div className="auto_form_btn">
          <button
            type="button"
            onClick={handleTaskSubmit}
            className="btn btn-primary-save"
          >
            {action === "add" ? "Add Task" : "Update Task"}
          </button>
        </div>
      </Root>
    );
};

export default TaskForm;