import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Logger } from '../../utils/logger';

const MapField = ({ address, locationCord, setAddress, setLocationCord }) => {
  const [location, setLocation] = useState({
    lat: 28.6138954,
    lng: 77.2090057,
  });
  const [input, setInput] = useState("");
  const [marker, setMarker] = useState(null);
  const mapRef = useRef(null);
  const autocompleteRef = useRef(null);

  // Helper function to create a marker with enhanced visibility
  const createEnhancedMarker = (position, map) => {
    return new window.google.maps.Marker({
      position: position,
      map: map,
      // Enhanced marker options for better visibility
      animation: window.google.maps.Animation.DROP,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillColor: "#3FAEFB",
        fillOpacity: 1,
        strokeColor: "#FFFFFF",
        strokeWeight: 2,
      },
      // Add a label to make the marker more visible
      label: {
        text: "📍",
        fontSize: "24px",
        fontWeight: "bold",
        color: "#3FAEFB"
      }
    });
  };

  // Initialize map on component mount
  useEffect(() => {
    // Use the provided location coordinates if available, otherwise use default
    const initialLocation = (locationCord && locationCord.lat && locationCord.lng) 
      ? locationCord 
      : { lat: 28.6138954, lng: 77.2090057 };
    
    Logger.log("Initializing map with location:", initialLocation);
    
    // Initialize the map and autocomplete once the component mounts
    const initMap = () => {
      const mapOptions = {
        center: initialLocation,
        zoom: 13,
        mapTypeControl: true,
        fullscreenControl: true,
        streetViewControl: false,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "on" }]
          }
        ]
      };

      mapRef.current = new window.google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      
      // Add a marker for the initial location with enhanced visibility
      const newMarker = createEnhancedMarker(initialLocation, mapRef.current);
      setMarker(newMarker);
    };

    const initAutocomplete = () => {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        { 
          types: ["geocode"],
          fields: ["formatted_address", "geometry", "name"]
        }
      );
      autocompleteRef.current.addListener("place_changed", handlePlaceChanged);
    };

    // Set the input value from address prop if available
    if (address && typeof address === 'string') {
      setInput(address);
    }

    // Only initialize if Google Maps is available
    if (window.google && window.google.maps) {
      initMap();
      initAutocomplete();
    } else {
      console.error("Google Maps API not available");
    }
    
    // Also set location state from props
    if (locationCord && locationCord.lat && locationCord.lng) {
      setLocation(locationCord);
    }
  }, []);

  // Handle updates to location/address props
  useEffect(() => {
    Logger.log("MapField props updated:", { address, locationCord });
    
    // Handle location coordinates
    if (locationCord && locationCord?.lat && locationCord?.lng) {
      Logger.log("Setting map to location:", locationCord);
      setLocation(locationCord);
      
      // Only attempt to update the map if it's initialized
      if (mapRef.current) {
        mapRef.current.panTo(locationCord);
        mapRef.current.setZoom(15); // Zoom in slightly for better visibility
        
        if (marker) {
          marker.setPosition(locationCord);
        } else {
          const newMarker = createEnhancedMarker(locationCord, mapRef.current);
          setMarker(newMarker);
        }
      }
    }
    
    // Handle address (should be a string, not an array)
    if (address && typeof address === 'string' && address.trim() !== '') {
      Logger.log("Setting input to address:", address);
      setInput(address);
    }
  }, [address, locationCord]);

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry && place.geometry.location) {
      const coordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      
      Logger.log("Place selected:", place.formatted_address, coordinates);
      
      setLocation(coordinates);
      setLocationCord(coordinates);
      setInput(place.formatted_address);
      setAddress(place.formatted_address);
      
      // Update map view
      mapRef.current.panTo(coordinates);
      mapRef.current.setZoom(15);
      
      // Update marker
      if (marker) {
        marker.setPosition(coordinates);
      } else {
        const newMarker = createEnhancedMarker(coordinates, mapRef.current);
        setMarker(newMarker);
      }
    }
  };

  const handleCurrentLocationClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          
          Logger.log("Got current location:", currentPosition);
          
          setLocation(currentPosition);
          setLocationCord(currentPosition);
          
          // Update map
          mapRef.current.panTo(currentPosition);
          mapRef.current.setZoom(15);
          
          // Update marker
          if (marker) {
            marker.setPosition(currentPosition);
          } else {
            const newMarker = createEnhancedMarker(currentPosition, mapRef.current);
            setMarker(newMarker);
          }
          
          // Attempt to reverse geocode to get the address
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ location: currentPosition }, (results, status) => {
            if (status === "OK" && results[0]) {
              const address = results[0].formatted_address;
              setInput(address);
              setAddress(address);
            }
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
          alert("Could not get your current location. Please check your browser permissions.");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      alert("Your browser doesn't support geolocation.");
    }
  };

  const handleChange = (event) => {
    setInput(event.target.value);
    setAddress(event.target.value);
  };

  return (
    <MapContainer>
      <SearchBar>
        <input
          placeholder="Search for an address"
          type="text"
          onChange={handleChange}
          value={input}
          id="autocomplete"
        />
        <LocationButton 
          type="button" 
          onClick={handleCurrentLocationClick}
          title="Use my current location"
        >
          📍
        </LocationButton>
      </SearchBar>
      
      <MapBox id="map"></MapBox>
      
      {locationCord && locationCord.lat && locationCord.lng && (
        <CoordinatesDisplay>
          <span>Lat: {locationCord.lat.toFixed(6)}</span>
          <span>Lng: {locationCord.lng.toFixed(6)}</span>
        </CoordinatesDisplay>
      )}
    </MapContainer>
  );
};

// Styled components for a nicer UI
const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0;
  position: relative;
  
  input {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    font-size: 14px;
    outline: none;
    
    &:focus {
      border-color: #3FAEFB;
      box-shadow: 0 0 0 2px rgba(63, 174, 251, 0.1);
    }
    
    &::placeholder {
      color: #9CA3AF;
    }
  }
`;

const LocationButton = styled.button`
  position: absolute;
  right: 8px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
  z-index: 1;
  
  &:hover {
    transform: scale(1.2);
  }
`;

const MapBox = styled.div`
  height: 200px;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
`;

const CoordinatesDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  background: #F9FAFB;
  font-size: 12px;
  color: #6B7280;
  border-top: 1px solid #E5E7EB;
  
  span {
    font-family: monospace;
  }
`;

export default MapField;