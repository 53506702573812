import React, { PropsWithChildren, useEffect, useState } from "react";
// import { Typeahead } from "react-bootstrap-typeahead";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { DatePicker } from "antd";
import { getAllTeams } from "../../services/teamsService";

import { useSelector } from "react-redux";

import Select from "react-select";
import moment from "moment";
import { Logger } from '../../utils/logger';
interface PropsT {
  onLabelClick: () => void;
  onStatusClick: () => void;
  selectedStatus: string;
  selectedLabel: Array<string>;
  setShowFilterDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  LeadFilterDataList: (objData: any) => void;
}

const sortLeadBy = [
  { value: "createdDate", label: "Created Date" },
  { value: "name", label: "Name" },
  { value: "saleValue", label: "Sale Value" },
  { value: "followupDate", label: "Followup Date" },
  { value: "activityDate", label: "Activity Date" },
];

const DateLeadAdded = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "3DayBefore", label: "3 Days Before Now" },
  { value: "1WeekBefore", label: "1 Week Before Now" },
  { value: "1MonthBefore", label: "1 Month Before Now" },
  { value: "custom", label: "Custom Date" },
];

const FormFilter: React.FC<PropsWithChildren<PropsT>> = ({
  onLabelClick,
  onStatusClick,
  selectedStatus,
  selectedLabel,
  setShowFilterDrawer,
  LeadFilterDataList,
}) => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const [teamMembers, setTeamMembers] = useState<Array<{}>>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [multiSelections, setMultiSelections] = useState([]);
  const [isAscending, setIsAscending] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<any>([]);
  const [labels, setLabels] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [leadSource, setLeadSource] = useState<any>([]);
  const [employee, setEmployee] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>({});
  const [isOrganization, setIsOrganization] = useState<boolean>(false);

  useEffect(() => {
    let local: any = localStorage.getItem("user_preferences");
    let userData: any = localStorage.getItem("userData");
    setLabels(JSON.parse(local)?.labels);
    setStatus(JSON.parse(local)?.status);
    setLeadSource(JSON.parse(local)?.customSource);
    setEmployee(JSON.parse(userData)?.organizationEmployee);
  }, []);

  const fetchTeams = async () => {
    const response = await getAllTeams();
    if (response && response.status) {
      let tempArray = [...response.data.data];
      tempArray.forEach((el: any) => {
        el.showName = el.name + "-" + "Team";
      });

      let tempOrgData = {
        showName:
          StoreData.userData.userDetails.organization.name +
          "-" +
          "Organization",
        _id: "organization",
      };
      tempArray.push(tempOrgData);
      setTeamMembers(tempArray);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleFilter = (e: any, name: any) => {
    if (name === "team") {
      if (e[0].value === "organization") {
        setIsOrganization(true);
      } else {
        setIsOrganization(false);
      }
    }
    setFilterData({ ...filterData, [name]: e });
  };
  const DateFilteronChange = (date: string, name: string) => {
    setFilterData({ ...filterData, [name]: date });
    switch (date) {
      case "today":
        setStartDate(moment().subtract(0, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "yesterday":
        setStartDate(moment().subtract(1, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "3DayBefore":
        setStartDate(moment().subtract(3, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "1WeekBefore":
        setStartDate(moment().subtract(1, "w").utc().format());
        setShowDatePicker(false);
        break;
      case "1MonthBefore":
        setStartDate(moment().subtract(30, "d").utc().format());
        setShowDatePicker(false);
        break;
      case "custom":
        setShowDatePicker(true);
        break;
      default:
        break;
    }
  };

  const selectTeamMember = (val: any) => {
    setMultiSelections(val);
  };

  const selectOrderBy = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderBy(e?.currentTarget?.value);
  };

  const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isAsc = e?.currentTarget?.value === "true" ? true : false;
    setIsAscending(isAsc);
  };

  const resetFilter = () => {
    Logger.log("filter reset");
  };

  const applyFilter = async () => {
    var tempLabel = filterData?.labels;
    var filterLabel = [];
    for (let i = 0; i < tempLabel?.length; i++) {
      filterLabel.push(tempLabel[i].value);
    }
    var tempStatus = filterData?.status;
    var filterStatus = [];
    for (let i = 0; i < tempStatus?.length; i++) {
      filterStatus.push(tempStatus[i].value);
    }
    var tempSource = filterData?.sources;
    var filterSource = [];
    for (let i = 0; i < tempSource?.length; i++) {
      filterSource.push(tempSource[i].value);
    }
    var tempEmp = filterData?.employee;
    var filterEmp = [];
    for (let i = 0; i < tempEmp?.length; i++) {
      filterEmp.push(tempEmp[i].value);
    }

    var tempTeam = filterData?.team;
    var filterTeame = [];
    if (filterData?.team) {
      if (tempTeam[0]?.value !== "organization") {
        for (let i = 0; i < tempTeam?.length; i++) {
          filterTeame.push(tempTeam[i].value);
        }
      }
    }
    let obj = {
      ...(filterData.sortLeadBy
        ? {
            sort: {
              orderBy: filterData?.sortLeadBy?.value,
              isAscending: true,
            },
          }
        : {
            sort: {
              isAscending: true,
            },
          }),
      ...(filterData.dateLead && {
        date: {
          startedAt: showDatePicker
            ? moment(endDate).utc().format()
            : startDate,
          endedAt: showDatePicker
            ? moment(startDate).utc().format()
            : moment(new Date()).utc().format(),
        },
      }),
      ...(filterLabel.length && { label: filterLabel }),
      ...(isOrganization && { byOrganization: isOrganization }),
      ...(filterStatus.length && { status: filterStatus }),
      ...(filterSource.length && { source: filterSource }),
      ...(filterEmp.length && { teamMembers: filterEmp }),
      ...(filterTeame.length && { teams: filterTeame }),
    };
    if (
      filterData.sortLeadBy ||
      filterData.dateLead ||
      filterLabel.length ||
      filterStatus.length ||
      filterSource.length ||
      filterEmp.length ||
      filterTeame.length
    ) {
      ///pass in Lead page using props
      LeadFilterDataList(obj);
    }
  };

  return (
    <form id="LeadsFilterForm" className="leadsform">
      <div className="form-container">
        <div className="product_group">
          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
              Sort by
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="sortLeadBy"
              options={sortLeadBy}
              onChange={(e: any) => handleFilter(e, "sortLeadBy")}
            />
          </div>

          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
              Date lead added
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="dateLead"
              options={DateLeadAdded}
              onChange={(e: any) => DateFilteronChange(e.value, "dateLead")}
            />
          </div>
          {showDatePicker && (
            <div className="form-group">
              <label className="form-label">Custom Dates</label>
              <div className="custom_date_picker">
                <DatePicker
                  format={"DD MMM YYYY"}
                  showToday={false}
                  // value={startDate}
                  onChange={(date: any) => setStartDate(date)}
                />
                <DatePicker
                  format={"DD MMM YYYY"}
                  showToday={false}
                  // value={endDate}
                  onChange={(date: any) => setEndDate(date)}
                />
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="lead-date" className="form-label">
              Lead labels
            </label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={false}
              isClearable={true}
              isRtl={false}
              isMulti
              isSearchable={true}
              name="color"
              options={labels?.map((v: any) => {
                return { value: v?.value, label: v?.name };
              })}
              onChange={(e: any) => handleFilter(e, "labels")}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center w-100">
          <button
            type="reset"
            className="btn LeadsFilterReset w-100"
            onClick={resetFilter}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-dark LeadsFilterApply w-100"
            onClick={applyFilter}
          >
            Apply
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormFilter;
