import axios from "axios";
import { API_URL } from "../config/config";
import { CreateLeadDataI } from "../views/leads/createLeadForm";
import { getHeaderOptions } from "./getHeaderOptions";
import { FilterParams } from "../views/leads/leadsGrid/leads.types";
import { Logger } from '../utils/logger';

export interface GoogleContactLead {
  name: string;
  email?: string;
  phone?: string;
  leadListId?: string;
  customSource: string;
}
interface ApiErrorResponse {
  status: boolean;
  statusCode: number;
  message: string;
  data: Record<string, any>;
}

interface DeleteLeadFileRequest {
  filePaths: string[];
  type: string;
  lead: string | undefined; // Changed to allow undefined
}


interface ApiResponse {
  status: boolean;
  statusCode: number;
  message: string;
  data: {
    successful?: number;
    failed?: number;
  };
}

interface DeviceToken {
  deviceId: string | null;
  token: string;
  tokenFrom: string;
  deviceType?: string;
  isValid?: boolean;
  storedAt: string;
}

export interface LeadPayload {
  name: string;
  email?: string;
  phone?: string;
  leadListId?: string;
  customSource?: string;
  integration?: string;
}
export interface Contact {
  id: string;
  name: string;
  email: string;
  phone?: string;
  imageUrl: string;
}

export interface GoogleContact {
  resourceName: string;
  names?: Array<{ displayName: string }>;
  phoneNumbers?: Array<{ value: string }>;
  emailAddresses?: Array<{ value: string }>;
  photos?: Array<{ url: string }>;
}

export interface BulkImportResult {
  successful: number;
  failed: number;
  total: number;
  errors?: Array<{
    email: string;
    error: string;
  }>;
}


const API_ENDPOINT = API_URL + "api/v1";
const API_V2_ENDPOINT = "https://mapi2.3sigmacrm.com/api/v2/lead";
const INTEGRATION_VALUE = "GOOGLE_CONTACTS";
const isValidPhoneNumber = (phone: string): boolean => {
  const digits = phone.replace(/\D/g, '');
  return digits.length >= 9 && digits.length <= 13;
};

const formatPhoneNumber = (phone: string): string => {
  return phone.replace(/\D/g, '');
};
const parsePhoneNumber = (phone: string): { number?: string; countryCode?: string } => {
  if (!phone) return {};
  
  // Remove all non-digit characters
  const cleaned = phone.replace(/\D/g, '');
  
  // Check for common country code patterns
  if (cleaned.startsWith('91') && cleaned.length >= 12) {
    return {
      countryCode: '+91',
      number: cleaned.slice(2)
    };
  }
  
  // Check for other international format (starting with +)
  if (phone.startsWith('+')) {
    const matches = cleaned.match(/^(\d{1,4})(\d+)$/);
    if (matches) {
      return {
        countryCode: `+${matches[1]}`,
        number: matches[2]
      };
    }
  }
  
  // If no country code is detected, return just the number
  return {
    number: cleaned
  };
};
const formatCountryCode = (code: string): string => {
  if (!code) return '';
  return code.startsWith('+') ? code : `+${code}`;
};

export const getAllLeads = (params: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead`, { headers: obj, params: params });
};

export const createNewLead = (data: CreateLeadDataI) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead`, data, { headers: obj });
};



export const bulkCreateLeadsFromGoogleContacts = async (
  contacts: Array<GoogleContactLead>
): Promise<BulkImportResult> => {
  try {
    const validContacts = contacts
      .filter(contact => 
        contact.name && 
        contact.name !== 'No Name' &&
        contact.phone // Only include contacts with phone numbers
      )
      .map(contact => ({
        name: contact.name,
        email: contact.email,
        phone: contact.phone?.replace(/\s/g, ''),
        leadListId: contact.leadListId !== "0" ? contact.leadListId : undefined,
        customSource: "phonebook",
        integration: "64109561e6c7e737227a6382" // Add the integration ID here
      }));

    if (validContacts.length === 0) {
      return {
        successful: 0,
        failed: 0,
        total: 0,
        errors: [{
          email: 'N/A',
          error: 'No valid contacts with phone numbers to import'
        }]
      };
    }

    const obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem("auth_token") || "",
    };

    const response = await axios.post<ApiResponse>(
      API_V2_ENDPOINT,
      validContacts,
      { headers: obj }
    );

    if (response.data.status === true) {
      return {
        successful: validContacts.length,
        failed: 0,
        total: validContacts.length,
        errors: []
      };
    }

    const errorResponse = response.data as ApiErrorResponse;
    return {
      successful: 0,
      failed: validContacts.length,
      total: validContacts.length,
      errors: [{
        email: 'N/A',
        error: errorResponse.message || 'Failed to create leads'
      }]
    };

  } catch (error) {
    console.error('Bulk create leads error:', error);
    let errorMessage = 'Unknown error occurred';
    
    if (axios.isAxiosError(error)) {
      const errorResponse = error.response?.data as ApiErrorResponse | undefined;
      errorMessage = errorResponse?.message || error.message;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    
    return {
      successful: 0,
      failed: contacts.length,
      total: contacts.length,
      errors: [{
        email: 'N/A',
        error: errorMessage
      }]
    };
  }
};

export const createNewLeadByCsv = (data: FormData) => {
  let obj = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/csv`, data, { headers: obj });
};

export const deleteLead = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.delete(`${API_ENDPOINT}/lead/${id}`, { headers: obj });
};


export const downloadCsvFile = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead/csv-template/${id}`, { headers: obj });
};
export const downloadCsv = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead/csv-template/`, { headers: obj });
};

export const updateLeadStatus = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/status`, data, { headers: obj });
};

export const updateLeadLabel = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/label`, data, { headers: obj });
};

export const getLeadDetail = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead/${id}`, { headers: obj });
};

export const updateLead = (id: string, data: CreateLeadDataI) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/${id}`, data, { headers: obj });
};

export const copyLeadToLeadList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead`, data, { headers: obj });
};

export const moveLeadToLeadList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead`, data, { headers: obj });
};

export class LeadService {
  private static BASE_URL = 'https://mapi2.3sigmacrm.com/api/v2/lead';
  private static INTEGRATION_VALUE = 'GOOGLE_CONTACTS'; // Replace with actual integration value

  static async createLead(payload: LeadPayload): Promise<any> {
    try {
      const response = await fetch(this.BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any required authentication headers here
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to create lead: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error creating lead:', error);
      throw error;
    }
  }

  static async bulkCreateLeads(leads: Omit<LeadPayload, 'integration'>[]): Promise<any> {
    try {
      const processedLeads = leads.map(lead => ({
        ...lead,
        integration: this.INTEGRATION_VALUE
      }));

      // You might want to implement batching if there are many leads
      const results = await Promise.allSettled(
        processedLeads.map(lead => this.createLead(lead))
      );

      return {
        successful: results.filter(r => r.status === 'fulfilled').length,
        failed: results.filter(r => r.status === 'rejected').length,
        total: results.length
      };
    } catch (error) {
      console.error('Error in bulk lead creation:', error);
      throw error;
    }
  }
}

export const filterLeads = (data: FilterParams) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/filter`, data, { headers: obj });
};
export const addCSV = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/csv`, data, { headers: obj });
};

export const putUserPreferences = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/user/preference`, data, {
    headers: obj,
  });
};

export const copyLeadInList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(
    `${API_ENDPOINT}/lead-distribution/copy-lead-in-list`,
    data,
    { headers: obj }
  );
};

export const deleteBulkLeads = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/bulk-delete`, data, { headers: obj });
};

export const mergeDuplicateLeads = (mainLeadId: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/merge-leads`, { mainLeadId }, { headers: obj });
};

export const moveLeadInList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(
    `${API_ENDPOINT}/lead-distribution/move-lead-in-list`,
    data,
    { headers: obj }
  );
};
export const assignLeadInList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/bulk-lead-assign`, data, {
    headers: obj,
  });
};

export const shareContentLead = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/content/share`, data, { headers: obj });
};

export const sendLeadToMobile = (leadId: string, leadName: string, deviceToken: string = "") => {
  const data = {
    deviceToken,
    title: "Lead Reminder",
    message: `Tap to open lead: ${leadName}`,
    channelId: "0",
    data: {
      toScreen: "LEAD_PROFILE_SCREEN",
      leadId: leadId,  // Explicitly using the parameter name
      reloadleads: true
    }
  };
  
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  
  return axios.post(`${API_ENDPOINT}/utility/send-notification`, data, { headers: obj });
};

/**
 * Enhanced function to send lead notification to all user devices
 * @param leadId - The ID of the lead to send
 * @param leadName - Name of the lead for notification
 * @returns Promise with API response
 */
export const sendLeadToAllDevices = async (leadId: string, leadName: string) => {
  try {
    // Get user data from localStorage
    const userDataString = localStorage.getItem("userData");
    if (!userDataString) {
      throw new Error("User data not found");
    }

    const userData = JSON.parse(userDataString);
    let allTokens: string[] = [];
    
    // Add main device token if it exists
    if (userData.deviceToken) {
      allTokens.push(userData.deviceToken);
    }
    
    // Add tokens from deviceTokens array if it exists
    if (userData.deviceTokens && Array.isArray(userData.deviceTokens)) {
      const tokensFromArray = userData.deviceTokens
        .filter((dt: DeviceToken) => 
          dt.token && 
          dt.token.trim() !== "" && 
          (dt.isValid === undefined || dt.isValid === true)
        )
        .map((dt: DeviceToken) => dt.token);
      
      allTokens = [...allTokens, ...tokensFromArray];
    }
    
    // Remove duplicates
    const uniqueTokens = [...new Set(allTokens)];
    
    if (uniqueTokens.length === 0) {
      throw new Error("No valid device tokens found");
    }
    
    // Construct the proper notification payload
    const data = {
      deviceToken: uniqueTokens.join(","), // API expects comma-separated tokens
      title: "Lead Reminder",
      message: `Tap to open lead: ${leadName}`,
      channelId: "0",
      data: {
        toScreen: "LEAD_PROFILE_SCREEN",
        leadId: leadId,
        reloadleads: true
      }
    };
    
    // Create headers with proper structure
    const headers = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem("auth_token") || "",
    };
    
    // Make API call with proper notification structure
    return axios.post(
      `${API_ENDPOINT}/utility/send-notification`,
      data,
      { headers }
    );
  } catch (error) {
    console.error("Error sending lead to mobile devices:", error);
    throw error;
  }
};

export const deleteLeadFile = async (payload: DeleteLeadFileRequest): Promise<any> => {
  if (!payload.lead) {
    throw new Error("Lead ID is required");
  }
  
  try {
    const response = await axios.put(
      `${API_ENDPOINT}/utility/delete-file`,
      payload,
      {
        headers: {
          ...getHeaderOptions,
          Authorization: localStorage.getItem("auth_token") || "",
        }
      }
    );
    
    return response;
  } catch (error) {
    console.error("Error while deleting lead file:", error);
    throw error;
  }
};

/**
 * Upload files for a lead
 * @param formData FormData containing the file(s) and lead information
 */
export const uploadLeadFiles = async (formData: FormData): Promise<any> => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/utility/file-upload`, // Keep the same endpoint
      formData,
      {
        headers: {
          ...getHeaderOptions,
          Authorization: localStorage.getItem("auth_token") || "",
        },
        onUploadProgress: (progressEvent) => {
          // Optional progress handling if needed
          if (progressEvent.total) {
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            Logger.log(`Upload progress: ${percent}%`);
          }
        },
      }
    );
    
    return {
      status: true,
      statusCode: response.status,
      data: response.data.data,
      message: response.data.message || "Files uploaded successfully",
    };
  } catch (error) {
    console.error("Lead file upload error:", error);
    return {
      status: false,
      statusCode: (error as any).response?.status || 500,
      message: (error as any).response?.data?.message || "Upload failed",
      error,
    };
  }
};

/**
 * Get files for a specific lead
 * @param leadId ID of the lead
 */
export const getLeadFiles = async (leadId: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_ENDPOINT}/lead/files/${leadId}`,
      {
        headers: {
          ...getHeaderOptions,
          Authorization: localStorage.getItem("auth_token") || "",
        },
      }
    );
    
    return {
      status: true,
      data: response.data.data || [],
      message: response.data.message,
    };
  } catch (error) {
    console.error("Get lead files error:", error);
    return {
      status: false,
      message: (error as any).response?.data?.message || "Failed to fetch files",
      error,
    };
  }
};

/**
 * Download a specific lead file
 * @param filePath Path of the file to download
 * @param fileName Name to save the file as
 */
export const downloadLeadFile = async (filePath: string, fileName: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_ENDPOINT}/utility/download-file?filePath=${encodeURIComponent(filePath)}`,
      {
        headers: {
          ...getHeaderOptions,
          Authorization: localStorage.getItem("auth_token") || "",
        },
        responseType: 'blob',
      }
    );
    
    // Create a download link and trigger download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    return {
      status: true,
      message: "File downloaded successfully",
    };
  } catch (error) {
    console.error("Download lead file error:", error);
    return {
      status: false,
      message: "Failed to download file",
      error,
    };
  }
};