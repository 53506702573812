// Safe transformation between API and form data

import { Invoice, FormValues, Lead, ProductSummary, AdditionalCharge, Tax, Discount } from './Invoice';

/**
 * Safely transforms API response data to form-compatible data structure
 * with proper type checking and fallbacks
 */
export const transformApiToFormData = (apiData: any): Partial<FormValues> => {
  if (!apiData) {
    return {};
  }

  // Safe transformation of lead data
  let leadData: Lead | string = apiData.lead;
  if (typeof apiData.lead === 'string') {
    // Lead is just an ID string
    leadData = apiData.lead;
  } else if (apiData.lead && typeof apiData.lead === 'object') {
    // Ensure lead object has required properties
    leadData = {
      _id: apiData.lead._id || '',
      name: apiData.lead.name || 'Unknown Lead',
      phone: apiData.lead.phone || '',
      email: apiData.lead.email || null,
      // Add any other expected properties with fallbacks
    };
  }

  // Safe transformation of products data
  const products: ProductSummary[] = [];
  if (Array.isArray(apiData.products)) {
    apiData.products.forEach((apiProduct: any) => {
      const product: ProductSummary = {
        id: apiProduct.id || apiProduct._id || '',
        quantity: Number(apiProduct.quantity) || 1,
        unitPrice: Number(apiProduct.unitPrice) || 0,
        description: apiProduct.description || '',
      };

      // Include product reference if available
      if (apiProduct.product && typeof apiProduct.product === 'object') {
        product.product = {
          _id: apiProduct.product._id || apiProduct.id || '',
          name: apiProduct.product.name || 'Unknown Product'
        };
      }

      products.push(product);
    });
  }

  // Safe transformation of additional charges
  const additionalCharges: AdditionalCharge[] = [];
  if (Array.isArray(apiData.additionalCharges)) {
    apiData.additionalCharges.forEach((apiCharge: any) => {
      if (apiCharge && typeof apiCharge === 'object') {
        additionalCharges.push({
          title: apiCharge.title || '',
          type: apiCharge.type === 'percentage' ? 'rate' : apiCharge.type || 'fixed',
          rate: Number(apiCharge.rate) || 0
        });
      }
    });
  }

  // Safe transformation of taxes
  const taxes: Tax[] = [];
  if (Array.isArray(apiData.taxes)) {
    apiData.taxes.forEach((apiTax: any) => {
      if (apiTax && typeof apiTax === 'object') {
        taxes.push({
          title: apiTax.title || '',
         
          rate: Number(apiTax.rate) || 0
        });
      }
    });
  }

  // Safe transformation of discount
  let discount: Discount | undefined;
  if (apiData.discount && typeof apiData.discount === 'object') {
    discount = {
      type: apiData.discount.type || 'fixed',
      rate: apiData.discount.rate !== null && apiData.discount.rate !== undefined 
        ? Number(apiData.discount.rate) 
        : null
    };
  }

  // Build the transformed data object
  const formData: Partial<FormValues> = {
    InvoiceId: apiData.invoiceId || '',
    _id: apiData._id || '',
    lead: leadData,
    products,
    additionalCharges,
    taxes,
    discount,
    notes: apiData.notes || apiData.note || '',
    createdDate: apiData.createdDate ? new Date(apiData.createdDate) : new Date(),
    receivedAmount: apiData.receivedAmount !== undefined ? Number(apiData.receivedAmount) : undefined,
    receivedPaymentMode: apiData.receivedPaymentMode || ''
  };

  return formData;
};

/**
 * Safely transforms form data to API-compatible structure for submission
 */
export const transformFormToApiData = (formValues: FormValues, isUpdate: boolean = false): any => {
  const payload: any = {};

  // Handle lead reference
  if (!isUpdate && formValues.lead) {
    payload.lead = typeof formValues.lead === 'object' && formValues.lead._id 
      ? formValues.lead._id 
      : formValues.lead;
  }

  // Handle invoice ID
  if (!isUpdate && formValues.InvoiceId) {
    payload.invoiceId = formValues.InvoiceId;
  }

  // Handle products
  if (Array.isArray(formValues.products) && formValues.products.length > 0) {
    payload.products = formValues.products.map(product => ({
      id: product.id || (product.product?._id ? product.product._id : ''),
      quantity: Number(product.quantity) || 1,
      unitPrice: Number(product.unitPrice) || 0,
      description: product.description || ''
    }));
  } else {
    payload.products = [];
  }

  // Handle additional charges
  if (Array.isArray(formValues.additionalCharges) && formValues.additionalCharges.length > 0) {
    payload.additionalCharges = formValues.additionalCharges
      .filter(charge => charge.title && charge.rate !== null && charge.rate !== undefined)
      .map(charge => ({
        title: charge.title,
        type: charge.type || 'fixed',
        rate: Number(charge.rate)
      }));
  }

  // Handle taxes
  if (Array.isArray(formValues.taxes) && formValues.taxes.length > 0) {
    payload.taxes = formValues.taxes
      .filter(tax => tax.title && tax.rate !== null && tax.rate !== undefined)
      .map(tax => ({
        title: tax.title,
        
        rate: Number(tax.rate)
      }));
  }

  // Handle discount
// Handle discount
if (formValues.discount && formValues.discount.rate !== null && formValues.discount.rate !== undefined) {
  // Ensure correct type conversion
  const discountType = formValues.discount.type === "rate" ? "rate" : "fixed";
  const discountRate = Number(formValues.discount.rate);
  
  // Just send the basic information - let the backend calculate the actual amount
  payload.discount = {
    type: discountType,
    rate: discountRate
  };
  

}

  if (Array.isArray(formValues.taxes) && formValues.taxes.length > 0) {
    payload.taxes = formValues.taxes
      .filter(tax => tax.title && tax.rate !== null && tax.rate !== undefined)
      .map(tax => ({
        title: tax.title,
        rate: Number(tax.rate)
      }));
  }

  // Handle notes
  if (formValues.notes) {
    payload.notes = formValues.notes.trim();
  }

  // Handle payment information
  if (formValues.receivedAmount !== undefined && formValues.receivedAmount !== null) {
    const numAmount = Number(formValues.receivedAmount);
    if (!isNaN(numAmount) && numAmount > 0) {
      payload.receivedAmount = numAmount;
      if (formValues.receivedPaymentMode) {
        payload.receivedPaymentMode = formValues.receivedPaymentMode;
      }
    }
  }

  return payload;
};