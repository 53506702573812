import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Logger } from '../../utils/logger';

// Basic types for conditions
export interface LeadCondition {
    id: string;
    fieldId: string;
    operator: string;
    value: string;
    logicOperator?: 'AND' | 'OR';
  }
  
  export interface LeadConditionBlockProps {
    onChange: (conditions: LeadCondition[]) => void;
    initialConditions?: LeadCondition[];
    isVisible: boolean;
    isLoading?: boolean;
  }



// Operator mapping for each field type - only using supported operators
const FIELD_OPERATORS: Record<string, {value: string, label: string}[]> = {
  name: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  email: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  phone: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  amount: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' },
    { value: 'greater_than', label: 'greater than' },
    { value: 'greater_than_equal', label: 'greater than or equal to' },
    { value: 'less_than', label: 'less than' },
    { value: 'less_than_equal', label: 'less than or equal to' }
  ],
  text: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  number: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' },
    { value: 'greater_than', label: 'greater than' },
    { value: 'greater_than_equal', label: 'greater than or equal to' },
    { value: 'less_than', label: 'less than' },
    { value: 'less_than_equal', label: 'less than or equal to' }
  ],
  selection: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  'multi-selection': [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ]
};

/**
 * LeadConditionBlock component - Handles field-level conditions for lead automation
 */
const LeadConditionBlock: React.FC<LeadConditionBlockProps> = ({
  onChange,
  initialConditions = [],
  isVisible,
  isLoading = false,
}) => {
  // State for storing conditions
  const [conditions, setConditions] = useState<LeadCondition[]>([]);

// Get field data from Redux store and filter out location, date, and time fields
const customFormFields = useSelector((state: any) => {
  const fields = state?.rootReducers?.user?.userPreferences?.customForm || [];
  // Filter out location, date, and time type fields
  return fields.filter((field: any) => 
    field.type !== 'location' && 
    field.type !== 'date' && 
    field.type !== 'time'
  );
});
Logger.log('LeadConditionBlock received conditions:', JSON.stringify(initialConditions));
Logger.log('LeadConditionBlock received conditions:', initialConditions);

useEffect(() => {
  if (initialConditions && initialConditions.length > 0) {
    Logger.log('Updating conditions state from props:', initialConditions);
    setConditions(initialConditions);
  }
}, [initialConditions]);

 // Debug effect
 useEffect(() => {
  Logger.log('LeadConditionBlock mounted/updated with isVisible:', isVisible);
}, [isVisible, initialConditions]);

// Update parent when conditions change
useEffect(() => {
  onChange(conditions);
}, [conditions, onChange]);

// Only render content if visible
if (!isVisible) {
  Logger.log('LeadConditionBlock not visible, returning null');
  return null;
}



  // Generate a unique ID for new conditions
  const generateId = () => `condition-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

  // Add a new condition
  const addCondition = () => {
    // Default values - use first field and first operator if available
    const defaultField = customFormFields[0]?.value || '';
    const defaultType = customFormFields[0]?.type || 'text';
    const defaultOperator = FIELD_OPERATORS[defaultType]?.[0]?.value || 'equals';

    const newCondition: LeadCondition = {
      id: generateId(),
      fieldId: defaultField,
      operator: defaultOperator,
      value: '',
      logicOperator: 'AND'
    };

    setConditions([...conditions, newCondition]);
  };

  // Remove a condition
  const removeCondition = (id: string) => {
    setConditions(conditions.filter(condition => condition.id !== id));
  };

  // Update a specific field in a condition
  const updateCondition = (id: string, field: keyof LeadCondition, value: string) => {
    setConditions(conditions.map(condition => {
      if (condition.id === id) {
        // Special handling when field changes - reset operator based on new field type
        if (field === 'fieldId') {
          const fieldObj = customFormFields.find((f: any) => f.value === value);
          const fieldType = fieldObj?.type || 'text';
          const defaultOperator = FIELD_OPERATORS[fieldType]?.[0]?.value || 'equals';
          
          return {
            ...condition,
            fieldId: value,
            operator: defaultOperator,
            value: '' // Reset value when field changes
          };
        }
        
        return { ...condition, [field]: value };
      }
      return condition;
    }));
  };

  // Update the logic operator (AND/OR)
  const updateLogicOperator = (id: string, value: 'AND' | 'OR') => {
    setConditions(conditions.map(condition => {
      if (condition.id === id) {
        return { ...condition, logicOperator: value };
      }
      return condition;
    }));
  };

  // Get field type from field ID
  const getFieldType = (fieldId: string) => {
    const field = customFormFields.find((f: any) => f.value === fieldId);
    return field?.type || 'text';
  };

  // Render operators dropdown based on field type
  const renderOperators = (fieldId: string) => {
    const fieldType = getFieldType(fieldId);
    const operators = FIELD_OPERATORS[fieldType] || FIELD_OPERATORS.text;
    
    return operators.map((op, index) => (
      <option key={index} value={op.value}>{op.label}</option>
    ));
  };

  // Render appropriate value input based on field type and operator
  const renderValueInput = (condition: LeadCondition) => {
    const { fieldId, operator, value } = condition;
    const fieldObj = customFormFields.find((f: any) => f.value === fieldId);
    const fieldType = fieldObj?.type || 'text';
    
    // Handler for value changes
    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      updateCondition(condition.id, 'value', e.target.value);
    };
    
    // For fields with options (selection, multi-selection)
    if ((fieldType === 'selection' || fieldType === 'multi-selection') && fieldObj?.options) {
      return (
        <select
          className="form-select"
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
        >
          <option value="">Select</option>
          {fieldObj.options.map((option: string, index: number) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }
    
    // For date fields
    if (fieldType === 'date') {
      return (
        <input
          type="date"
          className="form-control"
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
        />
      );
    }
    
    // For number fields
    if (fieldType === 'number' || fieldType === 'amount') {
      return (
        <input
          type="number"
          className="form-control"
          placeholder="Enter value..."
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
        />
      );
    }
    
    // Default to text input
    return (
      <input
        type="text"
        className="form-control"
        placeholder="Enter value..."
        value={value}
        onChange={handleValueChange}
        disabled={isLoading}
      />
    );
  };

  // Don't render anything if not visible
  if (!isVisible) {
    return null;
  }

  return (
    <div className="col-md-12 condition-block">
      <span className="auto_dot">
        <label className="dot_no">2</label>
      </span>
      <label className="when_then_label">Field Criteria</label>
      <span className="when_then_trigger">
        Define conditions for lead filtering
      </span>
      
     {/* Add condition example and case sensitivity note */}
<div className="mt-2 mb-3 p-2 bg-light rounded">
  <small className="text-muted">
    <strong>Example:</strong> Where <i>Category</i> is <i>equal to</i> "Example Value"
    <br />
    <strong>Note:</strong> Values are case sensitive. "Example Value" is not the same as "example value".
  </small>
</div>
      {/* Condition Rows */}
      {conditions.map((condition, index) => (
        <div key={condition.id} className="col-md-12 mt-3">
          <div className="row align-items-center">
            {/* Field Selector */}
            <div className="col-md-3">
              <select
                className="form-select"
                value={condition.fieldId}
                onChange={(e) => updateCondition(condition.id, 'fieldId', e.target.value)}
                disabled={isLoading}
              >
                <option value="">Select Field</option>
                {customFormFields.map((field: any, idx: number) => (
                  <option key={idx} value={field.value}>
                    {field.name}
                  </option>
                ))}
              </select>
            </div>
            
            {/* Operator Selector */}
            <div className="col-md-3">
              <select
                className="form-select"
                value={condition.operator}
                onChange={(e) => updateCondition(condition.id, 'operator', e.target.value)}
                disabled={isLoading || !condition.fieldId}
              >
                <option value="">Select Operator</option>
                {renderOperators(condition.fieldId)}
              </select>
            </div>
            
            {/* Value Input */}
            <div className="col-md-3">
              {renderValueInput(condition)}
            </div>
            
            {/* Remove Button */}
            <div className="col-md-1">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={() => removeCondition(condition.id)}
                disabled={isLoading}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            
            {/* Logic Operator (not for last condition) */}
            {index < conditions.length - 1 && (
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={condition.logicOperator || 'AND'}
                  onChange={(e) => updateLogicOperator(condition.id, e.target.value as 'AND' | 'OR')}
                  disabled={isLoading}
                >
                  <option value="AND">AND</option>
                  <option value="OR">OR</option>
                </select>
              </div>
            )}
          </div>
        </div>
      ))}
      
      {/* Add Condition Button */}
      <div className="col-md-12 mt-3">
        <button
          type="button"
          className="btn btn-outline-primary btn-sm"
          onClick={addCondition}
          disabled={isLoading}
        >
          <i className="fa fa-plus"></i> Add Condition
        </button>
      </div>
    </div>
  );
};

export default LeadConditionBlock;