import React, { useState, useCallback } from 'react';
import { Button, Dropdown, DatePicker, Space, Menu } from 'antd';
import { CalendarOutlined, CloseOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { Logger } from '../../../utils/logger';

const { RangePicker } = DatePicker;

interface DateFilterDropdownProps {
  onDateChange: (dates: { fromDate?: string; toDate?: string } | null) => void;
}

interface ActiveFilter {
  label: string;
  range: [Moment, Moment];
}

const DATE_PRESETS = [
  {
    key: 'today',
    label: 'Today',
    getRange: (): [Moment, Moment] => [moment().startOf('day'), moment().endOf('day')]
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
    getRange: (): [Moment, Moment] => [
      moment().subtract(1, 'day').startOf('day'),
      moment().subtract(1, 'day').endOf('day')
    ]
  },
  {
    key: 'this_week',
    label: 'This Week',
    getRange: (): [Moment, Moment] => [moment().startOf('week'), moment().endOf('week')]
  },
  {
    key: 'this_month',
    label: 'This Month',
    getRange: (): [Moment, Moment] => [moment().startOf('month'), moment().endOf('month')]
  },
  {
    key: 'last_month',
    label: 'Last Month',
    getRange: (): [Moment, Moment] => [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month')
    ]
  }
];

const DateFilterDropdown: React.FC<DateFilterDropdownProps> = ({ onDateChange }) => {
  Logger.log('DateFilterDropdown: Rendering');
  
  const [activeFilter, setActiveFilter] = useState<ActiveFilter | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handlePresetSelect = useCallback(({ key }: { key: string }) => {
    Logger.log('DateFilterDropdown: Preset selected:', key);
    
    if (key === 'custom') {
      return; // Don't close dropdown for custom
    }

    const preset = DATE_PRESETS.find(p => p.key === key);
    if (!preset) return;

    const range = preset.getRange();
    setActiveFilter({
      label: preset.label,
      range: range
    });

    onDateChange({
      fromDate: range[0].utc().toISOString(),
      toDate: range[1].utc().toISOString()
    });
    setDropdownVisible(false);
  }, [onDateChange]);

  const handleCustomRange = useCallback((
    dates: [Moment | null, Moment | null] | null,
    dateStrings: [string, string]
  ) => {
    Logger.log('DateFilterDropdown: Custom range selected:', dates);
    
    if (!dates?.[0] || !dates?.[1]) {
      Logger.log('DateFilterDropdown: Waiting for complete range');
      return;
    }

    setActiveFilter({
      label: `${dateStrings[0]} - ${dateStrings[1]}`,
      range: [dates[0], dates[1]] as [Moment, Moment]
    });

    onDateChange({
      fromDate: dates[0].utc().toISOString(),
      toDate: dates[1].utc().toISOString()
    });
    setDropdownVisible(false);
  }, [onDateChange]);

  const clearFilter = useCallback((e: React.MouseEvent) => {
    Logger.log('DateFilterDropdown: Clearing filter');
    e.stopPropagation();
    setActiveFilter(null);
    onDateChange(null);
  }, [onDateChange]);

  const disabledDate = useCallback((current: Moment) => {
    return current && current > moment().endOf('day');
  }, []);

  const menu = (
    <Menu onClick={handlePresetSelect}>
      {DATE_PRESETS.map(preset => (
        <Menu.Item key={preset.key}>
          {preset.label}
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item 
  key="custom"
  onClick={(e: any) => {
    // Prevent the default menu item click behavior
    e?.domEvent?.stopPropagation();
  }}
>
        <RangePicker
          onChange={handleCustomRange}
          disabledDate={disabledDate}
          onClick={e => e.stopPropagation()}
          onCalendarChange={(dates) => {
            if (!dates?.[1]) {
              // Keep dropdown open while selecting range
              Logger.log('DateFilterDropdown: First date selected, waiting for second date');
            }
          }}
        />
      </Menu.Item>
    </Menu>
  );

  if (activeFilter) {
    return (
      <Button 
        onClick={clearFilter}
        icon={<CalendarOutlined />}
      >
        {activeFilter.label}
        <CloseOutlined style={{ marginLeft: 8 }} />
      </Button>
    );
  }

  return (
    <Dropdown 
      visible={dropdownVisible}
      onVisibleChange={setDropdownVisible}
      overlay={menu} 
      trigger={['click']}
    >
      <Button icon={<CalendarOutlined />}>
        Select Date Range
      </Button>
    </Dropdown>
  );
};

export default DateFilterDropdown;