import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ErrorText from '../../components/errorText';
import {
  LeadListTriggerProps,
  LeadListTriggerData,
  TRIGGER_OPTIONS,
  SOURCE_LABELS,
  ERROR_MESSAGES,
  SourceData,
  ContentItem,
  UserPreferences,
  ApiResponse,
} from './LeadListTriggerTypes';
import {
  getAllIntegration,
  getFilePageContent,
  getLeadList,
} from '../../services/automationService';

/**
 * LeadListTriggerSection Component
 * Handles trigger selection and configuration for lead list automation
 */
const LeadListTriggerSection: React.FC<LeadListTriggerProps> = ({
  onChange,
  initialData = { event: '', targetValues: '' },
  isLoading: parentLoading = false,
}) => {
  // Local state
  const [triggerData, setTriggerData] = useState<LeadListTriggerData>(initialData);
  const [isShowAutoEvent, setIsShowAutoEvent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  // Data sources state
  const [sourceByData, setSourceByData] = useState<SourceData[]>([]);
  const [viewPageData, setViewPageData] = useState<ContentItem[]>([]);
  const [viewFileData, setViewFileData] = useState<ContentItem[]>([]);
  const [leadListData, setLeadListData] = useState<ContentItem[]>([]);

  // Redux integration
  const userPreferences = useSelector((state: any) => {
    return state?.rootReducers?.user?.userPreferences as UserPreferences;
  });

  // Memoized merged sources including custom sources
  const allSources = useMemo(() => {
    if (!userPreferences?.customSource) return sourceByData;
    return [
      ...sourceByData,
      ...userPreferences.customSource.map(cs => ({
        _id: cs.value,
        name: cs.name,
        isActive: cs.isActive
      }))
    ].filter(source => source.isActive);
  }, [sourceByData, userPreferences?.customSource]);

  const needsCondition = (eventType: string): boolean => {
    const eventsWithoutConditions = [
      'lead_edited',
      'quotation_created',
      'quotation_edited',
      'incoming_call_activity',
      'outgoing_call_activity',
      'missed_call_activity'
    ];
    return !eventsWithoutConditions.includes(eventType);
  };

  /**
   * Fetches integration sources
   */
  // In LeadListTriggerSection.tsx
  const fetchSourceData = useCallback(async () => {
    try {
      setLoading(true);
      const axiosResponse = await getAllIntegration({
        isAscending: false,
        page: 1,
        perPage: 50, // Increased to ensure all integrations are fetched
      });
      
      // Transform Axios response to match our ApiResponse type
      const response: ApiResponse<SourceData[]> = {
        status: axiosResponse.status === 200,
        data: axiosResponse.data
      };
      
      if (response.status) {
        // Filter integrations that are both active and displayable
        const filteredSources = response.data.data.filter(
          (source: SourceData) => 
            source.isActive === true && 
            source.isDisplayable === true
        );
        
        // Preserve the existing transformation logic
        setSourceByData(filteredSources);
      }
    } catch (err) {
      console.error('Error fetching source data:', err);
      setError(ERROR_MESSAGES.FAILED_LOAD);
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Fetches page/file content based on type
   */
  const fetchContentData = useCallback(async (type: 'page' | 'file') => {
    try {
      setLoading(true);
      const response = await getFilePageContent({
        isAscending: false,
        page: 1,
        perPage: 15,
        type
      });

      if (response?.status) {
        if (type === 'page') {
          setViewPageData(response.data.data);
        } else {
          setViewFileData(response.data.data);
        }
      }
    } catch (err) {
      console.error(`Error fetching ${type} content:`, err);
      setError(ERROR_MESSAGES.FAILED_LOAD);
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Fetches lead lists
   */
  const fetchLeadLists = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getLeadList({
        isAscending: false,
        page: 1,
        perPage: 15,
      });

      if (response?.status) {
        setLeadListData(response.data.data);
      }
    } catch (err) {
      console.error('Error fetching lead lists:', err);
      setError(ERROR_MESSAGES.FAILED_LOAD);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial data load
  useEffect(() => {
    fetchSourceData();
  }, [fetchSourceData]);

  // Load content data when specific triggers are selected
  useEffect(() => {
    if (triggerData.event === 'view_page') {
      fetchContentData('page');
    } else if (triggerData.event === 'view_file') {
      fetchContentData('file');
   
    }
  }, [triggerData.event, fetchContentData, fetchLeadLists]);

  // Update from initial data changes
  useEffect(() => {
    setTriggerData(initialData);
    setIsShowAutoEvent(!!initialData.event);
    setError('');
  }, [initialData]);

  /**
   * Handles changes in trigger selection
   */
  const handleTriggerChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const updatedTriggerData = {
      ...triggerData,
      [name]: value,
      isValid: true,
    };
  
    // For triggers that don't need conditions, automatically set targetValues
    if (name === 'event') {
      if (!needsCondition(value)) {
        updatedTriggerData.targetValues = '';
      } else {
        updatedTriggerData.targetValues = '';
      }
      setIsShowAutoEvent(!!value);
    }
  
    setTriggerData(updatedTriggerData);
    setError('');
    onChange(updatedTriggerData);
  }, [triggerData, onChange]);

  /**
   * Renders target value options based on selected trigger
   */
  const renderTargetValues = useCallback(() => {
    const { event } = triggerData;

    switch (event) {
      case 'create_lead':
        return allSources.map((source, i) => (
          <option value={source._id} key={i}>{source.name}</option>
        ));

      case 'status_change':
        return userPreferences?.status?.map((status, i) => (
          <option value={status.value} key={i}>{status.name}</option>
        ));

      case 'label_change':
        return userPreferences?.labels?.map((label, i) => (
          <option value={label.value} key={i}>{label.name}</option>
        ));

      case 'view_page':
        return viewPageData.map((page, i) => (
          <option value={page._id} key={i}>{page.details.title}</option>
        ));

      case 'view_file':
        return viewFileData.map((file, i) => (
          <option value={file._id} key={i}>{file.details.title}</option>
        ));

      case 'create_task':
        return userPreferences?.taskType?.map((task, i) => (
          <option value={task.value} key={i}>{task.name}</option>
        ));

      case 'edit_task':
        return [
          <option value="toBePerformAt" key="1">Due date is updated</option>,
          <option value="isCompleted" key="2">Task is completed</option>
        ];

      case 'create_activity':
        return userPreferences?.activityType?.map((activity, i) => (
          <option value={activity.value} key={i}>{activity.name}</option>
        ));

     

      default:
        return null;
    }
  }, [
    triggerData.event,
    allSources,
    userPreferences,
    viewPageData,
    viewFileData,
    leadListData
  ]);

  const isLoadingState = loading || parentLoading;

  return (
    <div className="col-md-12 when_then">
      <span className="auto_dot">
        <label className="dot_no">1</label>
      </span>
      <label className="when_then_label">When</label>
      <span className="when_then_trigger">
        Select a trigger to start your list automation
      </span>
      
      <div className="col-md-12">
        {/* Trigger Selection */}
        <div className="col-md-4 auto_select">
          <select
            name="event"
            value={triggerData.event}
            className="form-select"
            onChange={handleTriggerChange}
            disabled={isLoadingState}
          >
            <option value="">Select trigger</option>
            {TRIGGER_OPTIONS.map((option, i) => (
              <option value={option.value} key={i}>
                {option.name}
              </option>
            ))}
          </select>
        </div>

        {/* Target Value Selection */}
       {isShowAutoEvent && needsCondition(triggerData.event) && (
  <div className="col-md-8 condition-group">
    <div className="d-flex align-items-center gap-2">
      <span className="and">AND</span>
      <span className="and_source">
        {SOURCE_LABELS[triggerData.event] || ''}
      </span>
      <select
        name="targetValues"
        className="form-select and_select"
        value={triggerData.targetValues}
        onChange={handleTriggerChange}
        disabled={isLoadingState}
      >
        <option value="">Select</option>
        {renderTargetValues()}
        
      </select>
    </div>
  </div>
)}

        {/* Error Display */}
        {error && <ErrorText message={error} />}
        
        {/* Loading Indicator */}
        {isLoadingState && (
          <div className="mt-2 text-muted">
            Loading...
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadListTriggerSection