// Safe transformation between API and form data

import { Quotation, FormValues, Lead, ProductSummary, AdditionalCharge, Tax, Discount } from './Quotation';
import { Logger } from '../../utils/logger';



/**
 * Safely transforms API response data to form-compatible data structure
 * with proper type checking and fallbacks
 */
export const transformApiToFormData = (apiData: any): Partial<FormValues> => {
  Logger.log('transformApiToFormData: Starting transformation', { apiDataType: typeof apiData });
  
  if (!apiData) {
    Logger.log('transformApiToFormData: No API data provided, returning empty object');
    return {};
  }

  // Log the incoming products data to see its structure
  Logger.log('transformApiToFormData: Incoming products data:', apiData.products);

  // Safe transformation of lead data
  let leadData: Lead | string = apiData.lead;
  if (typeof apiData.lead === 'string') {
    // Lead is just an ID string
    Logger.log('transformApiToFormData: Lead is a string ID:', apiData.lead);
    leadData = apiData.lead;
  } else if (apiData.lead && typeof apiData.lead === 'object') {
    // Ensure lead object has required properties
    Logger.log('transformApiToFormData: Lead is an object:', apiData.lead);
    leadData = {
      _id: apiData.lead._id || '',
      name: apiData.lead.name || 'Unknown Lead',
      phone: apiData.lead.phone || '',
      email: apiData.lead.email || null,
      // Add any other expected properties with fallbacks
    };
  }

  // Safe transformation of products data
  const products: ProductSummary[] = [];
  if (Array.isArray(apiData.products)) {
    Logger.log(`transformApiToFormData: Processing ${apiData.products.length} products`);
    
    apiData.products.forEach((apiProduct: any, index: number) => {
      Logger.log(`transformApiToFormData: Processing product ${index}:`, apiProduct);
      
      const product: ProductSummary = {
        id: apiProduct.id || apiProduct._id || '',
        quantity: Number(apiProduct.quantity) || 1,
        unitPrice: Number(apiProduct.unitPrice) || 0,
        description: apiProduct.description || '',
        // FIXED: Always create the product object with name from the top level
        product: {
          _id: apiProduct.id || '',
          name: apiProduct.name || 'Unknown Product'
        }
      };

      Logger.log(`transformApiToFormData: Transformed product ${index}:`, product);
      products.push(product);
    });
  } else {
    Logger.log('transformApiToFormData: No products array found or not an array');
  }

  // Safe transformation of additional charges
  const additionalCharges: AdditionalCharge[] = [];
  if (Array.isArray(apiData.additionalCharges)) {
    Logger.log(`transformApiToFormData: Processing ${apiData.additionalCharges.length} additional charges`);
    
    apiData.additionalCharges.forEach((apiCharge: any, index: number) => {
      if (apiCharge && typeof apiCharge === 'object') {
        const charge = {
          title: apiCharge.title || '',
          type: apiCharge.type === 'percentage' ? 'rate' : apiCharge.type || 'fixed',
          rate: Number(apiCharge.rate) || 0
        };
        
        Logger.log(`transformApiToFormData: Transformed charge ${index}:`, charge);
        additionalCharges.push(charge);
      }
    });
  } else {
    Logger.log('transformApiToFormData: No additional charges array found or not an array');
  }

  // Safe transformation of taxes
  const taxes: Tax[] = [];
  if (Array.isArray(apiData.taxes)) {
    Logger.log(`transformApiToFormData: Processing ${apiData.taxes.length} taxes`);
    
    apiData.taxes.forEach((apiTax: any, index: number) => {
      if (apiTax && typeof apiTax === 'object') {
        const tax = {
          title: apiTax.title || '',
          rate: Number(apiTax.rate) || 0
        };
        
        Logger.log(`transformApiToFormData: Transformed tax ${index}:`, tax);
        taxes.push(tax);
      }
    });
  } else {
    Logger.log('transformApiToFormData: No taxes array found or not an array');
  }

  // Safe transformation of discount
  let discount: Discount | undefined;
  if (apiData.discount && typeof apiData.discount === 'object') {
    Logger.log('transformApiToFormData: Processing discount:', apiData.discount);
    
    discount = {
      type: apiData.discount.type || 'fixed',
      rate: apiData.discount.rate !== null && apiData.discount.rate !== undefined 
        ? Number(apiData.discount.rate) 
        : null
    };
    
    Logger.log('transformApiToFormData: Transformed discount:', discount);
  } else {
    Logger.log('transformApiToFormData: No discount data found');
  }

  // Build the transformed data object
  const formData: Partial<FormValues> = {
    QuotationId: apiData.quotationId || '',
    _id: apiData._id || '',
    lead: leadData,
    products,
    additionalCharges,
    taxes,
    discount,
    notes: apiData.notes || apiData.note || '',
    createdDate: apiData.createdDate ? new Date(apiData.createdDate) : new Date(),
    receivedAmount: apiData.receivedAmount !== undefined ? Number(apiData.receivedAmount) : undefined,
    receivedPaymentMode: apiData.receivedPaymentMode || ''
  };

  Logger.log('transformApiToFormData: Final transformed data:', formData);
  return formData;
};

/**
 * Safely transforms form data to API-compatible structure for submission
 */
export const transformFormToApiData = (formValues: FormValues, isUpdate: boolean = false): any => {
  const payload: any = {};

  // Handle lead reference
  if (!isUpdate && formValues.lead) {
    payload.lead = typeof formValues.lead === 'object' && formValues.lead._id 
      ? formValues.lead._id 
      : formValues.lead;
  }

  // Handle quotation ID
  if (!isUpdate && formValues.QuotationId) {
    payload.quotationId = formValues.QuotationId;
  }

  // Handle products
  if (Array.isArray(formValues.products) && formValues.products.length > 0) {
    payload.products = formValues.products.map(product => ({
      id: product.id || (product.product?._id ? product.product._id : ''),
      quantity: Number(product.quantity) || 1,
      unitPrice: Number(product.unitPrice) || 0,
      description: product.description || ''
    }));
  } else {
    payload.products = [];
  }

  // Handle additional charges
  if (Array.isArray(formValues.additionalCharges) && formValues.additionalCharges.length > 0) {
    payload.additionalCharges = formValues.additionalCharges
      .filter(charge => charge.title && charge.rate !== null && charge.rate !== undefined)
      .map(charge => ({
        title: charge.title,
        type: charge.type || 'fixed',
        rate: Number(charge.rate)
      }));
  }

  // Handle taxes
  if (Array.isArray(formValues.taxes) && formValues.taxes.length > 0) {
    payload.taxes = formValues.taxes
      .filter(tax => tax.title && tax.rate !== null && tax.rate !== undefined)
      .map(tax => ({
        title: tax.title,
        
        rate: Number(tax.rate)
      }));
  }

  // Handle discount
// Handle discount
if (formValues.discount && formValues.discount.rate !== null && formValues.discount.rate !== undefined) {
  // Ensure correct type conversion
  const discountType = formValues.discount.type === "rate" ? "rate" : "fixed";
  const discountRate = Number(formValues.discount.rate);
  
  // Just send the basic information - let the backend calculate the actual amount
  payload.discount = {
    type: discountType,
    rate: discountRate
  };
  

}

  if (Array.isArray(formValues.taxes) && formValues.taxes.length > 0) {
    payload.taxes = formValues.taxes
      .filter(tax => tax.title && tax.rate !== null && tax.rate !== undefined)
      .map(tax => ({
        title: tax.title,
        rate: Number(tax.rate)
      }));
  }

  // Handle notes
  if (formValues.notes) {
    payload.notes = formValues.notes.trim();
  }

  // Handle payment information
  if (formValues.receivedAmount !== undefined && formValues.receivedAmount !== null) {
    const numAmount = Number(formValues.receivedAmount);
    if (!isNaN(numAmount) && numAmount > 0) {
      payload.receivedAmount = numAmount;
      if (formValues.receivedPaymentMode) {
        payload.receivedPaymentMode = formValues.receivedPaymentMode;
      }
    }
  }

  return payload;
};