import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";
import { store } from "../store/store";

export const API_ENDPOINT = API_URL + "api/v1";

interface UserData {
  _id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

interface DeviceToken {
  deviceId: string | null;
  token: string;
  tokenFrom: string;
  deviceType?: string;
  isValid?: boolean;
  storedAt: string;
}

export const getUserPreferences = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/preference`, { headers: obj });
};

export const toggleDailyEmailPreference = async (isActive: boolean) => {
  const headers = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };

  const state = store.getState();
  const userDetails = state?.rootReducers?.userData?.userDetails;
  
  // Type assertion since we know userDetails contains _id
  const userId = (userDetails as unknown as UserData)?._id;

  if (!userId) {
    throw new Error('User ID not found in Redux store');
  }

  return axios.post(
    `${API_ENDPOINT}/user/manage-report-subscription`,
    {
      user: userId,
      duration: ["daily"],
      isActive
    },
    { headers }
  );
};

