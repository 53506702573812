import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import toast from "react-hot-toast";
import { userPreferences } from "../../actions/actions";
import { putUserPreferences } from "../../services/leadService";
import { PreferenceI } from "./leadsGrid/leads-grid";
import { Columns } from "lucide-react";
import { Logger } from '../../utils/logger';

interface IManageLeadsColumns {
  defaultColumns?: { value: string; label: string }[];
  readOnlyColumns?: string[];
}

const ManageLeadsColumns: React.FC<PropsWithChildren<IManageLeadsColumns>> = ({
  defaultColumns = [],
  readOnlyColumns = [],
}) => {
  const dispatch = useDispatch();
  const preferences: PreferenceI = useSelector(
    (state: { rootReducers: { user: { userPreferences: any } } }) =>
      state?.rootReducers?.user?.userPreferences
  );

  const [columns, setColumns] = useState<{ value: string; label: string }[]>(
    []
  );

  const fbSpecificColumns = [
    { label: "FB Campaign", value: "campaign_name" },
    { label: "FB Lead Form", value: "formName" }
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [checkedColumns, setCheckedColumns] = useState<string[]>(
    preferences?.leadGridPreferences?.columnOrders || []
  );

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
  // Combine custom form fields, default columns, and FB-specific columns
  const updatedColumns = [
    ...(preferences.customForm ?? []),
    ...defaultColumns,
    ...fbSpecificColumns // Add the FB columns
  ].map((c) => ({ label: c.label, value: c.value }));
  
  // Remove duplicates as before
  const uniqueColumns = updatedColumns.filter(
    (c, index, self) =>
      index === self.findIndex((obj) => obj.value === c.value)
  );
  
  setColumns(uniqueColumns);
}, [preferences?.customForm, defaultColumns]);

  useEffect(() => {
    setCheckedColumns([
      ...(preferences.leadGridPreferences?.columnOrders || []),
      ...readOnlyColumns,
    ]);
  }, [preferences.leadGridPreferences, readOnlyColumns]);

  const onApply = () => {
    const uniqueCheckedColumns = [...new Set(checkedColumns)];
    const leadGridPreferences = {
      ...preferences.leadGridPreferences,
      columnOrders: uniqueCheckedColumns,
    };
    const isEq = isEqual(leadGridPreferences, preferences.leadGridPreferences);
    if (!isEq) {
      dispatch(
        userPreferences({
          ...preferences,
          leadGridPreferences,
        })
      );
      handleAddGridPreferences(leadGridPreferences);
    }
    setShowPopup(false);
  };

  const handleAddGridPreferences = async (data: any) => {
    try {
      const response = await putUserPreferences({ leadGridPreferences: data });
      if (response && response.status) {
        Logger.log(response.data.message);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Error while updating Source!");
      }
    }
  };

  const handleColumnCheck = (value: string, isChecked: boolean) => {
    const updatedColumns = isChecked
      ? [...checkedColumns, value]
      : checkedColumns.filter((column) => column !== value);
    setCheckedColumns(updatedColumns);
  };

  // Close popup when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  return (
    <div className="position-relative">
        <button
  onClick={() => setShowPopup(true)}
  className="btn d-flex align-items-center"
  style={{
    backgroundColor: "#EBF0F4",
    borderRadius: "6px",
    height: "26px",
    width: "auto",
    marginRight: 5,
    padding: "0 8px",
    fontFamily: "Gilroy, sans-serif",
  }}
  data-tour="edit-columns"  // Add this
  title="Edit Columns"
  aria-label="Edit Columns"
>
  <Columns className="me-2" size={16} />
  Edit Columns
</button>

      {showPopup && (
        <div
          ref={popupRef}
          className="position-absolute bg-white rounded shadow-sm"
          style={{
            top: "100%",
            right: 0,
            width: "320px",
            zIndex: 1000,
            marginTop: "8px",
          }}
        >
          <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
            <h6 className="m-0">Columns</h6>
            <div>
              <button
                className="btn btn-primary btn-sm me-2"
                style={{ width: 100 }}
                onClick={onApply}
              >
                Apply
              </button>
              <button
                className="btn btn-light btn-sm"
                onClick={() => setShowPopup(false)}
              >
                <span>&times;</span>
              </button>
            </div>
          </div>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {columns.map((column) => (
              <div key={column.value} className="px-3 py-2 hover-bg-light">
                <label className="d-flex align-items-center m-0 cursor-pointer user-select-none">
                  <input
                    type="checkbox"
                    className="form-check-input me-3"
                    checked={checkedColumns.includes(column.value)}
                    onChange={(e) =>
                      handleColumnCheck(column.value, e.target.checked)
                    }
                    disabled={readOnlyColumns.includes(column.value)}
                  />
                  <span>{column.label}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageLeadsColumns;
