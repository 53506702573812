// components/content/types.ts

export interface ContentI {
  organization: any;
  _id: string;
  type: string;
  uniqueLinkId?: string;
  details: {
    title: string;
    tags: string[];
    description?: string;
    message?: string;
    files?: ContentFile[];   
    subject?: string;
    html?: string;
    videoUrl?: string;
    location?: any;
    address?: string;
  };
  files?: ContentFile[];   
  viewCount: number;
}

export interface Tag {
  name: string;
  value: string;
}

export interface FileWithProgress {
  name: string;
  fileName?: string;
  size: number;
  filePath?: string;
  progress?: number;
  uploaded: boolean;
  uploadFile?: File; // Add this property to support file uploads
}
// Log levels for consistent logging
export enum LogLevel {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  DEBUG = 'DEBUG'
}

// Types for content management system
export type ContentType = 'message' | 'page' | 'file' | 'email_template';

export interface ContentDetails {
  title?: string;
  tags?: string[];
  description?: string;
  message?: string;
  subject?: string;
  files?: Array<{
    fileName?: string;
    filePath?: string;
    name?: string;
  }>;
  videoUrl?: string;
  location?: any;
  address?: string;
  html?: string;
}

export interface ContentGridProps {
  contentData?: ContentI[];
  filterData: ContentI[];
  activeTab: ContentType;
  openMessageBox: boolean;
  isSearching?: boolean;
  searchError?: string | null;
  setMessageData: React.Dispatch<React.SetStateAction<{
    title: string;
    tags: string[];
    message: string;
  }>>;
  setActiveId: (id: string) => void;
  setOpenMessageBox: (value: boolean) => void;
  openPageBox: boolean;
  setOpenPageBox: (value: boolean) => void;
  openFileBox: boolean;
  setOpenFileBox: (value: boolean) => void;
  openEmailBox: boolean;
  setOpenEmailBox: (value: boolean) => void;
  setEmailData: (data: ContentI['details']) => void;
  setPageData: React.Dispatch<React.SetStateAction<any>>; // Add this line
}

export interface PageData {
  title: string;
  tags: string[];
  description: string;
  videoUrl: string;
  location: any;
  address: string;
  files: FileWithProgress[]; 
  uniqueLinkId?: string;
  _id?: string;
  organization?: string;
  type?: string;
  viewCount?: number;
}

export type ContentFile = FileWithProgress;