/**
 * Logger utility that conditionally logs based on environment
 * Only logs in development mode, silently suppressed in production
 */
export const Logger = {
    log: (message?: any, ...optionalParams: any[]) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log(message, ...optionalParams);
      }
    },
    warn: (message?: any, ...optionalParams: any[]) => {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(message, ...optionalParams);
      }
    },
    error: (message?: any, ...optionalParams: any[]) => {
      // Errors are still logged in production for critical issues
      console.error(message, ...optionalParams);
    },
    info: (message?: any, ...optionalParams: any[]) => {
      if (process.env.NODE_ENV !== 'production') {
        console.info(message, ...optionalParams);
      }
    },
    debug: (message?: any, ...optionalParams: any[]) => {
      if (process.env.NODE_ENV !== 'production') {
        console.debug(message, ...optionalParams);
      }
    }
  };
  
  // For backwards compatibility with your existing code
  export default Logger;