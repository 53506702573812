import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { 
  Globe, 
  FileText, 
  MessageCircle, 
  Mail, 
  Eye,
  Tag
} from 'lucide-react';

// Import types
import { ContentGridProps, ContentI } from './types';
import { Logger } from '../../utils/logger';

// Styled Components
const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  width: 100%;
  padding: 16px;
  font-family: 'Gilroy', sans-serif;
`;

const Card = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid #edf2f7;
  height: 100%;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
`;

const CardHeader = styled.div`
  padding: 16px;
  border-bottom: 1px solid #f1f5f9;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #EFF6FF;
  color: #3B82F6;
`;

const CardTitle = styled.h3`
  font-size: 15px;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
  line-height: 1.3;
  flex-grow: 1;
`;

const CardBody = styled.div`
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CardDescription = styled.p`
  margin: 0 0 16px 0;
  color: #64748b;
  font-size: 13px;
  line-height: 1.5;
  flex-grow: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const CardFooter = styled.div`
  padding: 12px 16px;
  border-top: 1px solid #f1f5f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: auto;
`;

const ContentTag = styled.span`
  display: inline-flex;
  align-items: center;
  background: #EFF6FF;
  color: #3B82F6;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ViewCount = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #64748b;
  font-size: 12px;
`;

// Type for processed item to improve type safety
interface ProcessedContentItem extends ContentI {
  truncatedTitle: string;
  truncatedTag: string;
  truncatedDescription: string;
}

// Icon mapping for different content types
const getContentTypeIcon = (activeTab: string) => {
  const iconMap = {
    'message': MessageCircle,
    'page': Globe,
    'file': FileText,
    'email_template': Mail,
    'default': FileText
  };

  return iconMap[activeTab as keyof typeof iconMap] || iconMap['default'];
};

const ContentGrid: React.FC<ContentGridProps> = React.memo(({ 
  filterData, 
  activeTab,
  setMessageData, 
  setActiveId, 
  setOpenMessageBox, 
  setOpenPageBox, 
  setOpenFileBox, 
  setOpenEmailBox,
  setEmailData,
  setPageData 
}) => {
  // Dynamic icon based on active tab
  const ContentTypeIcon = getContentTypeIcon(activeTab);

  // Memoized text truncation to prevent unnecessary recalculations
  const truncateText = useCallback((text: string, maxLength: number) => {
    if (!text) return '';
    return text.length <= maxLength 
      ? text 
      : `${text.slice(0, maxLength)}...`;
  }, []);

  // Memoized row click handler to maintain referential stability
  const handleRowClick = useCallback((item: ContentI) => {
    // Set activeId first
    setActiveId(item._id);

    try {
      switch (activeTab) {
        case "message":
          setMessageData({
            title: item.details?.title || '',
            tags: item.details?.tags || ["no_tag"],
            message: item.details?.message || ''
          });
          setOpenMessageBox(true);
          break;
        
          case "page": {
            Logger.log('ContentGrid - Page clicked, raw data:', item);
            
            const safePageData = {
              title: item.details?.title || '',
              tags: item.details?.tags || ['no_tag'],
              description: item.details?.description || '',
              videoUrl: item.details?.videoUrl || '',
              location: item.details?.location || {},
              address: item.details?.address || '',
              files: Array.isArray(item.files) ? [...item.files] : [],
              uniqueLinkId: item.uniqueLinkId || '',
              _id: item._id,
              organization: item.organization,
              type: item.type,
              viewCount: item.viewCount || 0
            };
            
            Logger.log('ContentGrid - Setting page data with files:', safePageData.files);
            setPageData(safePageData);
            setOpenPageBox(true);
            break;
          }
        
        case "file":
          setOpenFileBox(true);
          break;
        
        case "email_template":
          if (item.details) {
            setEmailData({
              title: item.details.title || '',
              tags: item.details.tags || ["no_tag"],
              subject: item.details.subject || '',
              message: item.details.message || '',
              html: item.details.html || ''
            });
          }
          setOpenEmailBox(true);
          break;
        
        default:
          Logger.warn(`[ContentGrid] Unhandled tab type: ${activeTab}`);
      }
    } catch (error) {
      console.error('[ContentGrid] Error in card click handler', error);
    }
  }, [
    activeTab, 
    setActiveId, 
    setMessageData, 
    setOpenMessageBox,
    setPageData,
    setOpenPageBox,
    setOpenFileBox,
    setOpenEmailBox,
    setEmailData
  ]);

  // Memoize filtered and transformed data
  const processedData = useMemo(() => {
    const processed: ProcessedContentItem[] = filterData.map((item: ContentI) => ({
      ...item,
      truncatedTitle: truncateText(item.details?.title || '', 60),
      truncatedTag: truncateText(item.details?.tags?.[0] || 'no tag', 20),
      truncatedDescription: truncateText(
        activeTab === 'email_template' 
          ? item.details?.subject || ''
          : item.details?.description || item.details?.message || '', 
        120
      )
    }));

    return processed;
  }, [filterData, activeTab, truncateText]);

  // Get description label based on content type
  const getDescriptionLabel = useCallback(() => {
    switch (activeTab) {
      case 'message':
        return 'Message';
      case 'email_template':
        return 'Subject';
      default:
        return 'Description';
    }
  }, [activeTab]);

  return (
    <CardGrid>
      {processedData.map((item) => (
        <Card key={item._id} onClick={() => handleRowClick(item)}>
          <CardHeader>
            <IconWrapper>
              <ContentTypeIcon size={18} />
            </IconWrapper>
            <CardTitle>{item.truncatedTitle}</CardTitle>
          </CardHeader>
          
          <CardBody>
            <CardDescription>
              {item.truncatedDescription}
            </CardDescription>
            
            <TagsContainer>
              {item.details?.tags?.map((tag, index) => (
                <ContentTag key={`${item._id}-tag-${index}`}>
                  {tag.replace(/_/g, ' ')}
                </ContentTag>
              ))}
              {(!item.details?.tags || item.details?.tags.length === 0) && (
                <ContentTag>no tag</ContentTag>
              )}
            </TagsContainer>
          </CardBody>
          
          <CardFooter>
            <ViewCount>
              <Eye size={14} />
              {item.viewCount || 0} views
            </ViewCount>
          </CardFooter>
        </Card>
      ))}
      
      {processedData.length === 0 && (
        <div style={{ gridColumn: '1 / -1', textAlign: 'center', padding: '40px 0', color: '#64748b' }}>
          No content found.
        </div>
      )}
    </CardGrid>
  );
});

export default ContentGrid;