import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActivityList } from '../../../actions/actions';
import { activityById } from '../../../services/activityService';
import ActivityItem from './ActivityItem';
import ActivityEmptyState from './ActivityEmptyState';
import { toast } from 'react-hot-toast';
import { Logger } from '../../../utils/logger';

interface ActivityTabProps {
  leadId: string;
  totalActivities: number;
  onAddActivity: () => void;
  onEditActivity: (activityId: string, action: string) => void;
  className?: string;
}

interface Activity {
  _id: string;
  type: string;
  description: string;
  createdAt: string;
  // Add other activity properties as needed
}

const ActivityTab = React.memo<ActivityTabProps>(({
  leadId,
  totalActivities,
  onAddActivity,
  onEditActivity,
  className = ''
}) => {
  Logger.log('ActivityTab Render:', { leadId, totalActivities });

  const dispatch = useDispatch();
  const activities = useSelector((state: any) => state.rootReducers?.activity?.activities || []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleEditActivity = useCallback(async (name: string, id: string, action: string) => {
    Logger.log('Edit Activity:', { name, id, action });
    
    try {
      const response = await activityById(id);
      Logger.log('Activity Data Fetched:', response);
      
      if (response && response.status) {
        onEditActivity(id, action);
      }
    } catch (err) {
      console.error('Activity Edit Error:', err);
      toast.error('Error while fetching activity data');
    }
  }, [onEditActivity]);

  useEffect(() => {
    Logger.log('Activities Updated:', activities.length);
  }, [activities]);

  if (error) {
    console.error('ActivityTab Error State:', error);
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className={`activity-tab ${className}`}>
      <div className="row popup-section-4-add-11">
        <button
          className="btn align-items-center"
          onClick={() => {
            Logger.log('Add Activity Clicked');
            onAddActivity();
          }}
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.5 16.2C5.3105 16.2 1.9 12.969 1.9 9C1.9 5.031 5.3105 1.8 9.5 1.8C13.6895 1.8 17.1 5.031 17.1 9C17.1 12.969 13.6895 16.2 9.5 16.2ZM9.5 0C8.25244 0 7.0171 0.232792 5.86451 0.685084C4.71191 1.13738 3.66464 1.80031 2.78249 2.63604C1.00089 4.32387 0 6.61305 0 9C0 11.3869 1.00089 13.6761 2.78249 15.364C3.66464 16.1997 4.71191 16.8626 5.86451 17.3149C7.0171 17.7672 8.25244 18 9.5 18C12.0196 18 14.4359 17.0518 16.2175 15.364C17.9991 13.6761 19 11.3869 19 9C19 7.8181 18.7543 6.64778 18.2769 5.55585C17.7994 4.46392 17.0997 3.47177 16.2175 2.63604C15.3354 1.80031 14.2881 1.13738 13.1355 0.685084C11.9829 0.232792 10.7476 0 9.5 0ZM10.45 4.5H8.55V8.1H4.75V9.9H8.55V13.5H10.45V9.9H14.25V8.1H10.45V4.5Z"
              fill="white"
            />
          </svg>
          Add Activity
        </button>
      </div>
      
      <div className="detail_activity_scroll">
        {isLoading ? (
          <div className="text-center py-4">Loading activities...</div>
        ) : activities.length > 0 ? (
          activities.map((activity: Activity, index: number) => {
            Logger.log('Rendering Activity:', { id: activity._id, index });
            return (
              <ActivityItem
                key={activity._id}
                activity={activity}
                handleEditModal={handleEditActivity}
                name="activity"
              />
            );
          })
        ) : (
          <ActivityEmptyState type="activities" />
        )}
      </div>
    </div>
  );
});

ActivityTab.displayName = 'ActivityTab';

export default ActivityTab;