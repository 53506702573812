import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";
import { FileWithProgress, LogLevel } from "../views/content/types";
import { Logger } from '../utils/logger';

const API_ENDPOINT = API_URL + "api/v1";

// Types from FileUploadHandler that we want to keep
interface FileProgress {
  fileName: string;
  progress: number;
  uploaded: boolean;
  error?: string;
}

interface UploadOptions {
  maxSize?: number; // in MB
  onProgress?: (progress: FileProgress) => void;
  abortSignal?: AbortSignal;
}

interface DeleteFileRequest {
  filePaths: string[];
  type: string;
  content: string;
}

// Constants
const DEFAULT_MAX_SIZE = 100; // MB
const UPLOAD_QUEUE: Array<() => Promise<any>> = [];
let isProcessingQueue = false;

// Logger utility
const log = (message: string, level: LogLevel = LogLevel.INFO) => {
  Logger.log(`[UtilityService] [${level}] ${message}`);
};

// Validation function inspired by FileUploadHandler
const validateFileSize = (file: File, maxSize: number): string | null => {
  log(`Validating file size: ${file.name}`, LogLevel.DEBUG);
  const sizeMB = file.size / (1024 * 1024);
  return sizeMB > maxSize ? `File size exceeds ${maxSize}MB limit` : null;
};

// Queue processor
const processQueue = async () => {
  if (isProcessingQueue || UPLOAD_QUEUE.length === 0) return;
  
  isProcessingQueue = true;
  log(`Processing queue: ${UPLOAD_QUEUE.length} items`, LogLevel.INFO);
  
  try {
    let result;
    while (UPLOAD_QUEUE.length > 0) {
      const uploadTask = UPLOAD_QUEUE.shift();
      if (uploadTask) {
        result = await uploadTask(); // Store the result
      }
    }
    return result; // Return the last task's result
  } catch (error) {
    log(`Queue processing error: ${error}`, LogLevel.ERROR);
    throw error; // Propagate error up
  } finally {
    isProcessingQueue = false;
  }
};

// Enhanced uploadFiles function - keeping the same signature but improving internals
export const uploadFiles = async (
  formData: FormData, 
  options: UploadOptions = {}
): Promise<any> => {
  const {
    maxSize = DEFAULT_MAX_SIZE,
    onProgress,
    abortSignal
  } = options;

  // Validate all files first
  const files = Array.from(formData.getAll('files') as File[]);
  log(`Preparing to upload ${files.length} files`, LogLevel.INFO);
  
  for (const file of files) {
    const error = validateFileSize(file, maxSize);
    if (error) {
      log(error, LogLevel.ERROR);
      throw new Error(error);
    }
  }

  // Create upload task with proper response handling
  const uploadTask = () => new Promise((resolve, reject) => {
    const config: AxiosRequestConfig = {
      headers: {
        ...getHeaderOptions,
        Authorization: localStorage.getItem("auth_token") || "",
      },
      onUploadProgress: (progressEvent) => {
        if (onProgress && progressEvent.total) {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          files.forEach(file => {
            onProgress({
              fileName: file.name,
              progress: percent,
              uploaded: percent === 100
            });
          });
        }
      },
      signal: abortSignal
    };

    log(`Starting upload for ${files.length} files`, LogLevel.INFO);

    axios
      .post(`${API_ENDPOINT}/utility/file-upload`, formData, config)
      .then((response) => {
        log('Upload successful', LogLevel.INFO);
        Logger.log("RAW API RESPONSE:", JSON.stringify(response.data));
        // Convert response to a standardized format
        const result = {
          status: response.status,
          statusCode: response.status,
          data: response.data.data || response.data,
          message: response.data.message || 'Files uploaded successfully'
        };
        Logger.log("TRANSFORMED RESPONSE:", JSON.stringify(result));
        
        resolve(result);
      })
      .catch((error) => {
        console.error("UPLOAD API ERROR:", error);
        const err = error as Error;
    log(`Upload failed: ${err.message || String(err)}`, LogLevel.ERROR);
    reject({
      status: false,
      statusCode: (err as any).response?.status || 500,
      message: (err as any).response?.data?.message || err.message || 'Upload failed',
      error: err
    });
      });
  });
  Logger.log("ADDING TO UPLOAD QUEUE");
  // Add to queue and process
  UPLOAD_QUEUE.push(uploadTask);
  return processQueue();
};

// Enhanced deleteLeadFile function
export const deleteLeadFile = async (payload: DeleteFileRequest): Promise<any> => {
  log(`Deleting files: ${payload.filePaths.join(', ')}`, LogLevel.INFO);
  
  try {
    const response = await axios.put(
      `${API_ENDPOINT}/utility/delete-file`,
      payload,
      {
        headers: {
          ...getHeaderOptions,
          Authorization: localStorage.getItem("auth_token") || "",
        }
      }
    );

    log('Deletion successful', LogLevel.INFO);
    return response;
  } catch (exception) {
    const error = exception as Error;
    const errorMessage = (error as any).response?.data?.message || error.message || 'Deletion failed';
    log(`Deletion failed: ${errorMessage}`, LogLevel.ERROR);
    throw error;
  }
};

// Cleanup utility
export const cleanupUploads = (): void => {
  log('Cleaning up upload queue', LogLevel.INFO);
  UPLOAD_QUEUE.length = 0;
  isProcessingQueue = false;
};