import React, { useCallback, useEffect, useState } from "react";
import Header from "../../components/header";
import toast, { Toaster } from "react-hot-toast";

import styled from "styled-components";
import { createNewContent, getAllContent, getContentByType, updateContent } from "../../services/contentService";
import GlobalDrawer from "../../components/GlobalDrawer";
import { useSelector } from "react-redux";
import { deleteLeadFile, uploadFiles } from "../../services/utilityService";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import MapField from "./MapField";
import PageContent from './components/PageContent/index';
import FileContent from './components/FileContent/index';
import TabNavigation from './TabNavigation';
import MessageContent from './components/MessageContent/index';
import InputMaps from "../../components/InputMaps";
import { Spin } from "antd";
import ConfirmationModal from "../../components/confirmationModal";
import EmailDashboard from "./EmailDashboard";
import FileUploadHandler, { FileWithProgress } from '../../components/FileUploadHandler';
import ContentGrid from "./ContentGrid";
import { 
  
  handleFormSubmit,
  deleteFileApi,
  handleFileDeletion,
  getClearDataFunction
} from './contentFunctions';
import { 
  ContentType, 
  ContentI, 
  ContentDetails 
} from './types';
import _ from "lodash";
import { Logger } from '../../utils/logger';

interface ContentItem {
  name: string;
  value: string;
}



interface EmailData {
  title: string;
  tags: string[];
  subject: string;
  message: string;
  type?: string; // Add this line
}


interface Tag {
  name: string;
  value: string;
}

interface MessageData {
  title: string;
  tags: string[];
  message: string;
}


const Content: React.FC = () => {


  const [activeTab, setActiveTab] = useState<ContentType>("page");
  const [openMessageBox, setOpenMessageBox] = useState<boolean>(false);
  const [openPageBox, setOpenPageBox] = useState<boolean>(false);
  const [openFileBox, setOpenFileBox] = useState<boolean>(false);
  const [openEmailBox, setOpenEmailBox] = useState<boolean>(false);
  const [contentData, setContentData] = useState<Array<ContentI>>([]);
  const [messageTag, setMessageTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
const [isLoading, setIsLoading] = useState(false);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const userId = useSelector((state: any) => {
    return state.rootReducers?.userData?.userDetails?._id; // CORRECT PATH
  });
  const [messageData, setMessageData] = useState<MessageData>({
    title: "",
    tags: ["no_tag"],
    message: "",
  });


  
  const updateMessageData = useCallback((details: any) => {
    if (!details) return;
    
    setMessageData({
      title: details.title || "",
      tags: Array.isArray(details.tags) ? details.tags : ["no_tag"],
      message: details.message || ""
    });
  }, []);

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await getContentByType(activeTab, {
        search: query,
        page: 1,
        perPage: 15
      });
      if (response?.status) {
        setContentData(response.data.data);
      }
    } catch (error) {
      toast.error("Search failed");
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleMessageBoxOpen = useCallback((data: ContentI) => {
    if (!data) return;
    
    updateMessageData(data.details);
    setActiveId(data._id);
    setOpenMessageBox(true);
  }, [updateMessageData]);

  const [emailData, setEmailData] = useState<ContentI['details']>({
    title: "",
    tags: ["no_tag"],
    subject: "",
    message: "",
    html: "",
  });
  const [fileData, setFileData] = useState<any>({
    title: "",
    tags: ["no_tag"],
    files: [],
    description: '',
  });
  
  const [pageData, setPageData] = useState<any>({
    title: "",
    tags: ["no_tag"],
    description: '',
    videoUrl: '',
    location: {},
    address: '',
    files: [],
    uniqueLinkId: '', // Add this to initial state
    _id: '',         // Add this to initial state
    organization: '', // Add this to initial state
    type: '',        // Add this to initial state
    viewCount: 0     // Add this to initial state
  });

  const [activeId, setActiveId] = useState('')
  
  const [mapAddress, setMapAddress] = useState('')
  const [mapLocation, setMapLocation] = useState({})
  const fetchContent = async () => {
    try {
      Logger.log('🔄 Fetching content data...');
      const data = {
        isAscending: false,
        page: 1,
        perPage: 500,
      };
      const response = await getAllContent(data);
      
      if (response && response.status) {
        Logger.log('✅ Content data fetched successfully');
        setContentData(response?.data?.data);
        return response.data.data; // Make sure this line is present
      } else {
        console.error('❌ Failed to fetch content data');
        toast.error('Failed to fetch content');
        return null;
      }
    } catch (error) {
      console.error('❌ Error fetching content data:', error);
      toast.error('Error fetching content');
      throw error;
    }
  };

  useEffect(() => {
    if (openPageBox && pageData?._id) {
      setActiveId(pageData._id)
    }
  }, [openPageBox, pageData])

  useEffect(() => {
    fetchContent();
    let userPreferences = JSON.parse(
      localStorage.getItem("user_preferences") || ""
    );
    setMessageTags(userPreferences.messageTags);
  }, []);

  const filterData = contentData.filter((item) => item.type === activeTab);

  const handleMessageForm = (key: string, value: string) => {
    if (key == "tags") {
      setMessageData({ ...messageData, [key]: [value] });
    } else {
      setMessageData({ ...messageData, [key]: value });
    }
  };

  const handleEmailForm = (key: string, value: string | string[]) => {
    if (key === "tags") {
      setEmailData({ ...emailData, [key]: Array.isArray(value) ? value : [value] });
    } else {
      setEmailData({ ...emailData, [key]: value });
    }
  };

  const filteredData = filterData.filter(item => {
    if (!searchQuery) return true;
    
    const searchLower = searchQuery.toLowerCase();
    const title = item.details?.title?.toLowerCase() || '';
    const description = item.details?.description?.toLowerCase() || '';
    const message = item.details?.message?.toLowerCase() || '';
    const subject = item.details?.subject?.toLowerCase() || '';
    
    return (
      title.includes(searchLower) ||
      description.includes(searchLower) ||
      message.includes(searchLower) ||
      subject.includes(searchLower)
    );
  });
  

// Add this useEffect in content.tsx
useEffect(() => {
  if (activeId && openFileBox) {
    // Find the corresponding content item
    const contentItem = contentData.find(item => item._id === activeId);
    
    if (contentItem) {
      // Update on first mount with this activeId/openFileBox combination
      Logger.log('Setting file data from content item');
      setFileData({
        title: contentItem.details?.title || '',
        tags: contentItem.details?.tags || ['no_tag'],
        description: contentItem.details?.description || '',
        files: contentItem.files || []
      });
    }
  }
}, [activeId, openFileBox]); // Only depend on these two values

const handleFileForm = (key: string, value: any) => {
  if (key === "tags") {
    // Set the tags as a single value in an array
    setFileData({ ...fileData, [key]: [value] });
  } else if (key === "files") {
    // Ensure we preserve the uploadFile property
    const updatedFiles = Array.isArray(value) ? 
      value.map(file => {
        // If this is an existing file item from our state
        const existingFile = fileData.files?.find((f: { name: any; fileName: any; }) => f.name === file.name || f.fileName === file.name);
        
        // Preserve the uploadFile if it exists either in the new file or the existing file
        return {
          ...file,
          uploadFile: file.uploadFile || (existingFile ? existingFile.uploadFile : undefined)
        };
      }) : 
      [];
    
    setFileData({ ...fileData, files: updatedFiles });
  } else {
    setFileData({ ...fileData, [key]: value });
  }
};

  const handlePageForm = (key: string, value: any) => {
    Logger.log('[handlePageForm] Updating:', key, value);
    
    setPageData((prev: typeof pageData) => {
      const currentData = prev || {};
      
      if (key === "tags") {
        return { ...currentData, [key]: [value] };
      } 
      else if (key === "files") {
        Logger.log('[handlePageForm] Current files:', currentData.files);
        Logger.log('[handlePageForm] New files:', value);
        
        // If we're setting files directly (like during file removal)
        if (Array.isArray(value) && value.every(file => file.filePath || file.fileName)) {
          return { ...currentData, files: value };
        }
        
        // For new file additions, ensure no duplicates
        const existingFiles = Array.isArray(currentData.files) ? currentData.files : [];
        const newFiles = Array.isArray(value) ? value : [value];
        
        // Create unique files array based on fileName/name
        const uniqueFiles = [...existingFiles];
        
        newFiles.forEach(newFile => {
          const fileIdentifier = newFile.fileName || newFile.name;
          const existingIndex = uniqueFiles.findIndex(f => 
            (f.fileName || f.name) === fileIdentifier
          );
          
          if (existingIndex === -1) {
            uniqueFiles.push(newFile);
          }
        });
        
        return { ...currentData, files: uniqueFiles };
      } 
      else {
        return { ...currentData, [key]: value };
      }
    });
  };
  
  const addLeadName = () => {
    if (openMessageBox) {
      setMessageData({ ...messageData, message: messageData.message + `@lead name`, });
    } else if (openPageBox) {
      setPageData({ ...pageData, description: pageData.description + `@lead name`, });
    } else if (openEmailBox) {
      setEmailData({ ...emailData, message: emailData.message + `@lead name`, });
    }
  };

  const deleteFile = (key: any, index: number) => {
    if (key == 'file') {
      const newData = fileData.files.filter((_: any, ix: number) => ix !== index);
      setFileData({ ...fileData, files: newData })
    } else if (key == 'page') {
      const newData = pageData.files.filter((_: any, ix: number) => ix !== index);
      setPageData({ ...pageData, files: newData })
    }
  }

  const getEmbeddedCode = (link: any) => {

    return link
  }




  const closeAllDrawers = () => {
    setOpenMessageBox(false);
    setOpenPageBox(false);
    setOpenFileBox(false);
    setOpenEmailBox(false);
  };
 

 

  const setFileCard = (data: any) => {
    Logger.log('Setting file card with data:', data); // Debug log
    
    let updateData = {
      title: data.details?.title || '',
      tags: Array.isArray(data.details?.tags) ? data.details.tags : ['no_tag'],
      description: data.details?.description || '',
      files: Array.isArray(data.files) ? data.files : []
    };
    
    Logger.log('Formatted file data:', updateData); // Debug log
    setFileData(updateData);
  }

  // UI Component
  const filesDesign = (data: ContentI) => {
    return (
      <div className="message_box" onClick={() => { 
        Logger.log('File clicked:', data); // Debug log
        
        // First set the data
        setFileCard(data); 
        setActiveId(data._id);
        
        // Then open the dialog in the next tick
        setTimeout(() => {
          Logger.log('Opening file box with data:', fileData); // Debug log
          setOpenFileBox(true);
        }, 10); // Slight delay to ensure state updates
      }}>
        <div>
          <h4 className="title">{data?.details.title}</h4>
          <div className="tags">
            {/* {data?.details?.tags?.map((tag: string) => (
              <span>{tag.replace(/_/g, " ")}</span>
            ))} */}

            {Array.isArray(data?.details?.tags) &&
              data.details.tags.map((tag: string) => (
                <span>{tag.replace(/_/g, " ")}</span>
              ))}
          </div>
        </div>

        <div>
          <p className="description">{data.details?.description?.replace(/\n @lead name \n/g, 'Lead Name')}</p>
          <div className="view_count">Viewed {data?.viewCount || 0} times</div>
        </div>
      </div>
    );
  };


const messageDesign = (data: ContentI) => {
  return (
    <div className="message_box" onClick={() => {
      if (!data?._id || !data?.details) return; // Guard clause
      
      // Set message data first
      setMessageData({
        title: data.details.title || '',
        tags: data.details.tags || ["no_tag"],
        message: data.details.message || ''
      });
      
      // Then set ID and open drawer
      setActiveId(data._id);
      setOpenMessageBox(true);
    }}>
      <div>
        <h4 className="title">{data.details?.title}</h4>
        <div className="tags">
          {data.details?.tags?.map((tag: string) => (
            <span>{tag.replace(/_/g, " ")}</span>
          ))}
        </div>
      </div>

      <div>
        <p className="description">{data.details?.message?.replace(/\n @lead name \n/g, 'Lead Name')}</p>
        <div className="view_count">Viewed {data?.viewCount || 0} times</div>
      </div>
    </div>
  );
};


const pageDesign = (data: ContentI) => {
  const handlePageClick = () => {
    Logger.log('Page data:', data); // Debug log
    
    const safePageData = {
      title: data.details?.title || '',
      tags: data.details?.tags || ['no_tag'],
      description: data.details?.description || '',
      videoUrl: data.details?.videoUrl || '',
      location: data.details?.location || {},
      address: data.details?.address || '',
      files: data.files || [],
      uniqueLinkId: data.uniqueLinkId, // This is important - it comes from root level
      _id: data._id,
      organization: data.organization,
      type: data.type,
      viewCount: data.viewCount
    };
    
    Logger.log('Setting page data to:', safePageData); // Debug log
    
    setPageData(safePageData);
    setTimeout(() => {
      Logger.log('State after update:', {
        pageData: safePageData,
        activeId: data._id,
        uniqueLinkId: safePageData.uniqueLinkId
      });
    }, 0);
    setActiveId(data._id);
    setOpenPageBox(true);
  };

  return (
    <div className="message_box" onClick={handlePageClick}>
      <div>
        <h4 className="title">{data.details?.title}</h4>
        <div className="tags">
          {data.details?.tags?.map((tag: string) => (
            <span key={tag}>{tag.replace(/_/g, " ")}</span>
          ))}
        </div>
      </div>

      <div>
        <p className="description">{data.details?.description?.replace(/\n @lead name \n/g, 'Lead Name')}</p>
        <div className="view_count">Viewed {data?.viewCount || 0} times</div>
      </div>
    </div>
  );
};
  const emailDesign = (data: any) => {
    Logger.log('emailDesign ====== ui =====>',data)
    return (
      <div className="message_box" onClick={() => { setOpenEmailBox(true); setEmailData(data.details); setActiveId(data._id) }}>
        <div>
          <h4 className="title">{data.details?.title}</h4>
          <div className="tags">
            {data.details?.tags?.map((tag: string) => (
              <span>{tag.replace(/_/g, " ")}</span>
            ))}
          </div>
        </div>

        <div>
          <p className="description">Subject : {data.details.subject}</p>
          {/* <div className="view_count">Viewed {data.viewCount} times</div> */}
        </div>
        <p className="description">Body : {data.details.message}</p>
      </div>
    );
  };

  
  const handleSearchReset = () => {
    setSearchQuery("");  // Clear search query
    fetchContent();      // Fetch original content
  };

  const handlePageFiles = useCallback((newFiles: FileWithProgress[]) => {
    Logger.log('[handlePageFiles] Processing files update');
    
    setPageData((prev: typeof pageData) => {
        // Create a Map using fileName/name as key for true uniqueness
        const uniqueFiles = new Map();
        
        // Process existing files first
        prev.files?.forEach((file: { fileName: any; name: any; }) => {
            const key = file.fileName || file.name;
            // Only add if not already in map
            if (!uniqueFiles.has(key)) {
                uniqueFiles.set(key, file);
            }
        });
        
        // Process new files
        newFiles.forEach(file => {
            const key = file.fileName || file.name;
            // New files only replace existing if they don't have filePath
            if (!uniqueFiles.has(key) || !file.filePath) {
                uniqueFiles.set(key, file);
            }
        });
        
        const finalFiles = Array.from(uniqueFiles.values());
        Logger.log('[handlePageFiles] Updated files list:', finalFiles);
        
        return {
            ...prev,
            files: finalFiles
        };
    });
}, []);
  
  const handleDeletePageFile = useCallback(async (path: string) => {
    Logger.log('[Delete File] Starting deletion for path:', path);
    setIsPageLoad(true);
    
    try {
        const obj = {
            filePaths: [path],
            type: 'content',
            content: activeId
        };
        
        const response = await deleteLeadFile(obj);
        
        if (response.status === 200) {
            Logger.log('[Delete File] API success, updating states');
            
            // Update both contentData and pageData simultaneously
            setContentData(prev => 
                prev.map(obj => 
                    obj._id === activeId 
                        ? {...obj, files: obj.files?.filter(file => file.filePath !== path)}
                        : obj
                )
            );

            // Update pageData to remove the deleted file
            setPageData((prev: { files: any[]; }) => ({
                ...prev,
                files: prev.files?.filter(file => file.filePath !== path) || []
            }));

            toast.success(response.data.message);
            Logger.log('[Delete File] States updated successfully');
        }
    } catch (err) {
        console.error('[Delete File] Error:', err);
        toast.error('Failed to delete file');
    } finally {
        setIsPageLoad(false);
    }
}, [activeId]);

const clearData = getClearDataFunction({
  setActiveId,
  setFileData,
  setEmailData, 
  setMessageData,
  setPageData
});

  Logger.log('messageData ===>',messageData)
  return (
    <Spin size="large" tip="Loading..." spinning={isPageLoad}>
      <Root id="main" className="main content_page">
        <Header />
        <TabNavigation 
  activeTab={activeTab}
  setActiveTab={setActiveTab}
  searchQuery={searchQuery}
  setSearchQuery={(query) => {
    setSearchQuery(query);
    handleSearch(query);
  }}
  onReset={handleSearchReset}
/>
        
        <button
          className="add_button"
          onClick={() => {
            switch(activeTab) {
              case "message":
                setOpenMessageBox(true);
                break;
              case "page":
                setOpenPageBox(true);
                break;
              case "file":
                setOpenFileBox(true);
                break;
              case "email_template":
                setOpenEmailBox(true);
                break;
            }
          }}
        >
          Add {activeTab.replace(/_/g, " ")}
        </button>

        <ContentGrid 
  contentData={contentData}
  filterData={filterData}
  activeTab={activeTab}
  openMessageBox={openMessageBox}
  setMessageData={setMessageData}
  setActiveId={setActiveId}
  setOpenMessageBox={setOpenMessageBox}
  openPageBox={openPageBox}
  setOpenPageBox={setOpenPageBox}
  openFileBox={openFileBox}
  setOpenFileBox={setOpenFileBox}
  openEmailBox={openEmailBox}
  setOpenEmailBox={setOpenEmailBox}  
  setEmailData={setEmailData}   
  setPageData={setPageData}      
/>
        {filterData.length == 0 && <h1 className="text-center" style={{ fontSize: '20px' }}>No Record Found</h1>}
        <MessageContent
  openMessageBox={openMessageBox}
  setOpenMessageBox={setOpenMessageBox}
  activeId={activeId}
  messageData={messageData}
  handleMessageForm={handleMessageForm}
  handleFormSubmit={handleFormSubmit}
  messageTag={messageTag}
  clearData={clearData}
  onSuccessfulSubmit={fetchContent}
/>

        {activeTab === "page" && (
 <PageContent
 openPageBox={openPageBox}
 setOpenPageBox={setOpenPageBox}
 activeId={activeId}
 userId={userId}
 pageData={pageData}
 handlePageForm={handlePageForm}
 handleFormSubmit={handleFormSubmit}
 messageTag={messageTag}
 clearData={clearData}
 setPageData={setPageData} // Add this line
 onSuccessfulSubmit={fetchContent}
/>
)}

<FileContent
  openFileBox={openFileBox}
  setOpenFileBox={setOpenFileBox}
  activeId={activeId}
  fileData={fileData}
  handleFileForm={handleFileForm}
  handleFormSubmit={handleFormSubmit}
  messageTag={messageTag}
  clearData={clearData}
  onSuccessfulSubmit={fetchContent}
/>

        {/* Dialog Box for add Email */}

        <EmailDashboard
  openEmailBox={openEmailBox}
  setOpenEmailBox={(value) => {
    setOpenEmailBox(value);
    if (!value) clearData();
  }}
  activeId={activeId}
  handleFormSubmit={handleFormSubmit}
  messageTag={messageTag}
  initialData={{
    title: emailData.title || "",
    tags: emailData.tags || ["no_tag"],
    subject: emailData.subject || "",
    message: emailData.message || ""
  }}
  onSuccessfulSubmit={fetchContent}  // Pass the fetch function
/>

        <Toaster position="top-right" reverseOrder={false} />
      </Root>
    </Spin>

  );
};

export default Content;
const Root = styled.section`
  font-family: "Gilroy-Bold";
  .action_tabs {
    background: #fff;
    padding: 8px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    > button {
      background: #ebf0f4;
      border-radius: 10px;
      padding: 4px 24px;
      font-family: "Gilroy-Bold";
      color: #000;
      font-size: 18px;
      &.active {
        color: #fff;
        background: #3faefdd1;
      }
    }
  }
  .message_box {
    background-color: #fff;
    border-radius: 10px;
    padding:6px 18px;
    width: 100%;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tags {
      display: flex;
      gap: 6px;
      > span {
        background-color: #3faefdd1;
        padding: 4px 10px;
        border-radius: 10px;
        color: #ffffff;
        font-size: 16px;
        min-width: 80px;
        text-align: center;
      }
    }
    .description {
      color: #000000b0;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-family: "Gilroy-Bold";
    }
    .view_count {
      width: 200px;
      text-align: right;
      font-size: 16px;
      color: #000000;
    }
  }
  .add_button {
    background: #64bdfe;
    color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 18px;
    display: block;
    margin-left: auto;
    position: fixed;
    bottom: 24px;
    right: 16px;
    z-index: 9;
  }
  .content_section {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 18px;
    @media (max-width:768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }


  .content_form {
  overflow: scroll;
  padding:2px;
  label {
    display: block;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  input,
  textarea {
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: 10px;
    border: none;
    margin-top: 12px;
    color: #000;
    font-size: 16px;
    font-family: Gilroy-regular;
  }
  button.add_lead_name {
    background: none;
    border: none;
    color: #60bafc;
  }
  .user_form_box:first-child {
    margin-top: 0px;
  }
}
.user_form_box {
  margin-top: 20px;
  label {
    font-size: 16px;
    font-weight:400;
  }
}
.all_tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  span {
    cursor: pointer;
    background: #dee3e9;
    color: #000000d6;
    border-radius: 10px;
    padding: 8px 16px;
    display: block;
    width: fit-content;
    font-weight: 600;
    font-size: 12px;
  }
  span.active {
    background: #3faefdd1;
    color: #fff;
  }
}
`;