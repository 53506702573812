import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { BASE_API_URL } from "../../config/config";
import { Logger } from '../../utils/logger';

// Same category options from your constants
const BusinessCategoryOptions = [
  {name: 'Real Estate', value: 'real_estate'},
  {name: 'Insurance Agents', value: 'insurance_agents'},
  {name: 'Financial Services', value: 'financial_services'},
  {name: 'Digital Marketing Agency', value: 'digital_marketing_agency'},
  {name: 'Event Management', value: 'event_management'},
  {name: 'Creator/Influencer/Online Coach', value: 'creator_influencer_online_coach'},
  {name: 'Construction and Interior Design', value: 'construction_interior_design'},
  {name: 'Health and Wellness', value: 'health_wellness'},
  {name: 'Education and Training Institutes', value: 'education_training_institutes'},
  {name: 'IT Services and Software Companies', value: 'it_services_software_companies'},
  {name: 'Manufacturing & B2B Sales', value: 'manufacturing_b2b_sales'},
  {name: 'Hospitality and Tourism', value: 'hospitality_tourism'},
  {name: 'Automotive Dealerships', value: 'automotive_dealerships'},
  {name: 'Telecalling Team', value: 'telecalling_team'},
  {name: 'Services Business', value: 'services_business'},
  {name: 'E-commerce', value: 'e_commerce'},
  {name: 'Travel Consultancy', value: 'travel_consultancy'},
  {name: 'Others', value: 'others'}
];

const TeamSizeOptions = [
  { label: 'Just me', value: '1', description: 'Solo Entrepreneur' },
  { label: '2-5', value: '5', description: 'Small Team' },
  { label: '6-10', value: '10', description: 'Growing Team' },
  { label: '10-20', value: '20', description: 'Medium Team' },
  { label: '20+', value: '50', description: 'Large Team' }
];

const Signup = () => {
  Logger.log('[Signup] Component mounted');
  Logger.log('[Signup] Current localStorage token:', localStorage.getItem('auth_token'));
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  
  const [formData, setFormData] = useState({
    firstName: "",
    email: localStorage.getItem("email") || "",
    phone: localStorage.getItem("phone") || "",
    companyName: "",
    teamSize: "",
    businessCategory: ""
  });

  const handleFirstStep = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.firstName.trim()) {
      toast.error("Please enter your name");
      return;
    }
  
    // Force email entry for all users
    if (!formData.email.trim()) {
      toast.error("Email is required");
      return;
    }
  
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email");
      return;
    }
  
    setStep(2);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  Logger.log('[Signup] Pre-submission token check:', localStorage.getItem('auth_token'));
  Logger.log('[Signup] Form data being submitted:', formData);
  
    // Full validation before API call
    if (!formData.firstName.trim() || !formData.email.trim() || !formData.companyName.trim() || !formData.businessCategory || !formData.teamSize) {
      toast.error("Please fill in all required fields");
      return;
    }
  
    setLoading(true);
  
    try {
      const token = localStorage.getItem("auth_token");
      const countryCode = localStorage.getItem("countryCode"); 
      
      Logger.log('[Signup] Token at API call:', token);
      Logger.log('[Signup] All localStorage keys:', Object.keys(localStorage));
      
      if (!token) {
        Logger.log('[Signup] Token missing - localStorage dump:', JSON.stringify(localStorage));
        throw new Error("Authentication token not found");
      }
  
      if (!countryCode) {
        throw new Error("Country code not found");
      }
  
      const response = await fetch(`${BASE_API_URL}/user/onboard`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`  // Using auth_token directly
        },
        body: JSON.stringify({
          profile: {
            firstName: formData.firstName.trim(),
            email: formData.email.trim(),
            phone: formData.phone,
            countryCode: countryCode 
          },
          organization: {
            name: formData.companyName.trim(),
            teamSize: formData.teamSize,
            businessCategory: formData.businessCategory
          }
        })
      });
  
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || "Failed to update profile");
  
      toast.success("Profile completed successfully!");
      navigate("/leads", { replace: true });
    } catch (err) {
      toast.error(err instanceof Error ? err.message : "Error while updating profile");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Container>
      <FormWrapper>
        <Logo>3sigma</Logo>
        
        {step === 1 ? (
          <>
            <Title>Get started with 3sigma CRM</Title>
            <Subtitle>First, tell us about yourself</Subtitle>
            
            <StepIndicator>
              <StepDot active>1</StepDot>
              <StepLine />
              <StepDot>2</StepDot>
            </StepIndicator>

           <Form onSubmit={handleFirstStep}> 
              <FormGroup>
                <Label>Name</Label>
                <Input
                  name="firstName"
                  placeholder="Enter your name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </FormGroup>

              {localStorage.getItem("phone") && (
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </FormGroup>
              )}

              <Button type="submit">Continue</Button>
            </Form>
          </>
        ) : (
          <>
            <Title>Tell us about your business</Title>
            <Subtitle>Set up your 3sigma account</Subtitle>
            
            <StepIndicator>
              <StepDot completed>1</StepDot>
              <StepLine completed />
              <StepDot active>2</StepDot>
            </StepIndicator>

            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Organisation name</Label>
                <Input
                  name="companyName"
                  placeholder="Enter your organisation name"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>Business category</Label>
                <Select
                  name="businessCategory"
                  value={formData.businessCategory}
                  onChange={handleChange}
                >
                  <option value="">Select business category</option>
                  {BusinessCategoryOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </Select>
              </FormGroup>

              <Label>Team size</Label>
              <TeamSizeGrid>
                {TeamSizeOptions.map(option => (
                  <TeamSizeOption
                    key={option.value}
                    selected={formData.teamSize === option.value}
                    onClick={() => setFormData(prev => ({ ...prev, teamSize: option.value }))}
                  >
                    <TeamSizeTitle>{option.label}</TeamSizeTitle>
                    <TeamSizeDescription>{option.description}</TeamSizeDescription>
                  </TeamSizeOption>
                ))}
              </TeamSizeGrid>

              <ButtonGroup>
                <BackButton type="button" onClick={() => setStep(1)}>
                  Back
                </BackButton>
                <Button type="submit" disabled={loading}>
                  {loading ? "Please wait..." : "Let's Go"}
                </Button>
              </ButtonGroup>
            </Form>
          </>
        )}
      </FormWrapper>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #F8FAFC;
  padding: 20px;
`;

const FormWrapper = styled.div`
  max-width: 480px;
  width: 100%;
`;

const Logo = styled.div`
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
  color: #3A4B86;
`;

const Title = styled.h1`
  font-size: 32px;
  color: #3A4B86;
  text-align: center;
  margin-bottom: 8px;
  font-weight: 600;
`;

const Subtitle = styled.p`
  color: #64748B;
  text-align: center;
  margin-bottom: 32px;
`;

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

const StepDot = styled.div<{ active?: boolean; completed?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: ${props => props.active || props.completed ? '#3A4B86' : '#E2E8F0'};
  color: ${props => props.active || props.completed ? 'white' : '#64748B'};
`;

const StepLine = styled.div<{ completed?: boolean }>`
  height: 2px;
  width: 80px;
  background: ${props => props.completed ? '#3A4B86' : '#E2E8F0'};
  margin: 0 8px;
`;

const Form = styled.form`
  width: 100%;
`;

const FormGroup = styled.div`
  margin-bottom: 24px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #1E293B;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #E2E8F0;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #3A4B86;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #E2E8F0;
  border-radius: 8px;
  font-size: 16px;
  background: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #3A4B86;
  }
`;

const TeamSizeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
`;

const TeamSizeOption = styled.div<{ selected?: boolean }>`
  padding: 20px;
  border: 2px solid ${props => props.selected ? '#3A4B86' : '#E2E8F0'};
  border-radius: 12px;
  cursor: pointer;
  background: ${props => props.selected ? '#F8FAFC' : 'white'};
  transition: all 0.2s;

  &:hover {
    border-color: #3A4B86;
  }
`;

const TeamSizeTitle = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
`;

const TeamSizeDescription = styled.div`
  font-size: 14px;
  color: #64748B;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`;

const Button = styled.button`
  flex: 1;
  padding: 14px 24px;
  background: #3A4B86;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background: #2D3A6A;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const BackButton = styled(Button)`
  background: #F1F5F9;
  color: #475569;

  &:hover {
    background: #E2E8F0;
  }
`;

export default Signup;