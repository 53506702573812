import React, { useState, useCallback, useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import ErrorText from "../../components/errorText";
import TabContent from './TabContent';
import PopupComponent from '../../components/PopupComponent';
import { 
  createLeadList, 
  deleteLeadList, 
  formPutLeadList, 
  getSingleLeadList 
} from "../../services/leadListService";
import { LeadListTriggerData } from "./LeadListTriggerTypes";
import { LeadCondition } from "./LeadConditionBlock";
import { Trash2 } from 'lucide-react';
import GenericConfirmationModal from '../../components/GenericConfirmationModal';
import { Logger } from '../../utils/logger';

interface Props {
  mode: string;
  id?: string;
  getLeadList: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export interface TeamMembersT {
  _id: string;
  name: string;
  organization: string;
  role: string;
}

// Get user data from localStorage
let userData = localStorage.getItem("userData") || "{}";
if (userData === "undefined") {
  userData = "{}";
}

const AddEditList: React.FC<Props> = ({
  mode,
  id,
  getLeadList,
  onClose,
  isOpen
}) => {
  // Core states
  const [listName, setListName] = useState('');
  const [error, setError] = useState({ listName: '' });
  const [isLoading, setIsLoading] = useState(false);
  
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  // Distribution states
  const [enableLeadDistribution, setEnableLeadDistribution] = useState(false);
  const [distributionType, setDistributionType] = useState("round-robin");
  
  // Members and access states
  const [checkedState, setCheckedState] = useState<{
    [_id: string]: { recepient: boolean; access: boolean };
  }>({});
  
  // Trigger/Automation state
  const [triggerData, setTriggerData] = useState<LeadListTriggerData>({
    event: '',
    targetValues: ''
  });

  // Field conditions state
  const [conditions, setConditions] = useState<LeadCondition[]>([]);
  
  // Fetch existing list data
  const fetchLeadList = useCallback(async (id: string) => {
    setIsLoading(true);
    Logger.log('Fetching lead list data for ID:', id);
    setConditions([]);
    try {
      const response = await getSingleLeadList(id);
      Logger.log('API response:', response);
      
      if (response && response.status === 200) {
        const data = response.data.data;
        Logger.log('Raw data from API:', data);
        
        // Normalize the data structure if needed
        if (data.automateActionRule && !data.automationRule) {
          Logger.log('Found automateActionRule but no automationRule, converting...');
          data.automationRule = data.automateActionRule;
          Logger.log('Data structure after normalization:', data);
        }
        
        // Set basic list properties
        Logger.log('Setting basic list properties...');
        setListName(data.name);
        setEnableLeadDistribution(data.isDistributionActive || false);
        setDistributionType(data.distributionType || "round-robin");
        setIsPinned(data.isPinned || false);
        
        // Reset conditions to prevent mixing with previous data
        setConditions([]);
        
        // Set trigger data if automation rule exists
        if (data.automationRule) {
          Logger.log('Loaded automation rule:', data.automationRule);
          const rule = data.automationRule;
          
          // Handle both array and single value cases for targetValues
          let targetValues: string[] = [];
          if (rule.condition?.targetValues) {
            targetValues = Array.isArray(rule.condition.targetValues) 
              ? rule.condition.targetValues 
              : [rule.condition.targetValues];
          }
          
          Logger.log('Target values before processing:', targetValues);
          setTriggerData({
            event: rule.event || '',
            targetValues: targetValues.join(','),
            isValid: true
          });
          
          // Load field conditions if they exist
          if (rule.condition?.criteria && Array.isArray(rule.condition.criteria)) {
            Logger.log('Loading field conditions:', JSON.stringify(rule.condition.criteria));
            if (rule.condition.criteria.length > 0) {
              const loadedConditions = rule.condition.criteria.map((item: any, index: number) => ({
                id: `loaded-condition-${index}`,
                fieldId: item.key || '',
                operator: transformOperatorReverse(item.operator || 'eq'),
                value: item.value || '',
                logicOperator: index < rule.condition.criteria.length - 1 ? 'AND' : undefined
              }));
              Logger.log('Setting new conditions:', loadedConditions);
              setConditions(loadedConditions); // Here the new conditions are set
            }
          } else {
            Logger.log('No criteria found in condition or not an array');
          }
        } else {
          Logger.log('No automation rule found in data');
          setTriggerData({
            event: '',
            targetValues: '',
            isValid: false
          });
        }
        
        // Set recipients and access
        const recipients = data.recipients;
        const readAccess = data.readAccess;
        
        const checkedStateObject: {
          [_id: string]: { recepient: boolean; access: boolean };
        } = {};
  
        Logger.log('Processing recipients and access...');
        
        if (recipients && typeof recipients === "object") {
          Logger.log('Recipients data:', recipients);
          Object.keys(recipients.ids || {}).forEach((id: string) => {
            const recipient = recipients.ids[id];
            if (recipient && recipient.status && recipient.waitage === 1) {
              checkedStateObject[id] = { recepient: true, access: false };
            }
          });
        }
  
        if (readAccess && Array.isArray(readAccess)) {
          Logger.log('Read access data:', readAccess);
          readAccess.forEach((id: string) => {
            if (checkedStateObject[id]) {
              checkedStateObject[id].access = true;
            } else {
              checkedStateObject[id] = { recepient: false, access: true };
            }
          });
        }
        
        Logger.log('Final checkedState object:', checkedStateObject);
        setCheckedState(checkedStateObject);
      } else {
        console.error('Invalid response status:', response?.status);
        toast.error("Error fetching lead list data: Invalid response");
      }
    } catch (err) {
      console.error('Error in fetchLeadList:', err);
      toast.error("Error fetching lead list data");
    } finally {
      setIsLoading(false);
      Logger.log('Finished fetchLeadList execution');
    }
  }, []);

// First useEffect - only reset form state when opening/closing the form
useEffect(() => {
  // Reset state completely when:
  // 1. Opening a new form (mode === 'add' && isOpen)
  // 2. Closing the popup (!isOpen)
  if ((mode === 'add' && isOpen) || (!isOpen)) {
    Logger.log('Resetting form state - form opened/closed');
    setListName('');
    setIsPinned(false);
    setEnableLeadDistribution(false);
    setDistributionType("round-robin");
    setCheckedState({});
    setTriggerData({
      event: '',
      targetValues: ''
    });
    setConditions([]);
  }
}, [mode, isOpen]); // Remove id from dependency array

// Second useEffect - handle loading data when in edit mode
useEffect(() => {
  if (mode === "edit" && id && isOpen) {
    // Only fetch when we have a valid ID in edit mode and the form is open
    Logger.log('Loading list data in edit mode - ID:', id);
    fetchLeadList(id);
  }
}, [mode, id, isOpen, fetchLeadList]);

  useEffect(() => {
    if (mode === "edit" && id) {
      fetchLeadList(id);
    }
  }, [mode, id, fetchLeadList]);

  const handleListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListName(e.target.value);
    setError({ ...error, listName: "" });
  };

  const handleDeleteList = async () => {
    if (!id) return; // Ensure we have an ID to delete

    try {
      setIsLoading(true);
      const response = await deleteLeadList(id);
      
      if (response.status === 200) {
        toast.success("Lead list deleted successfully");
        getLeadList(); // Refresh the list
        onClose(); // Close the modal
      }
    } catch (err) {
      console.error(err);
      toast.error("Error deleting lead list");
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false);
    }
  };

  // Helper function to transform UI operators to API operators
  const transformOperator = (uiOperator: string): string => {
    const operatorMap: {[key: string]: string} = {
      'equals': 'eq',
      'not_equals': 'ne',
      'greater_than': 'gt',
      'greater_than_equal': 'gte',
      'less_than': 'lt',
      'less_than_equal': 'lte'
    };
    
    return operatorMap[uiOperator] || uiOperator;
  };
  
// Helper function to transform API operators to UI operators
const transformOperatorReverse = (apiOperator: string): string => {
  const operatorMap: {[key: string]: string} = {
    'eq': 'equals',
    'ne': 'not_equals',
    'gt': 'greater_than',
    'gte': 'greater_than_equal',
    'lt': 'less_than',
    'lte': 'less_than_equal'
  };
  
  return operatorMap[apiOperator] || apiOperator;
};

const handleSubmit = async () => {
  Logger.log('handleSubmit started');
  
  // Validate required fields
  if (!listName.trim()) {
    Logger.log('Validation failed: List name is required');
    setError({ ...error, listName: "List Name is required" });
    return;
  }

  Logger.log('Creating base data object');
  // Base data object with required fields
  const submitData: any = {
    name: listName,
    isPinned: isPinned,
  };

  Logger.log('Processing distribution settings');
  // Only add distribution if enabled
  if (enableLeadDistribution) {
    submitData.isDistributionActive = true;
    submitData.distributionType = distributionType;
  }

  Logger.log('Processing recipients');
  // Only add recipients if any are selected
  const recipientsObject = Object.keys(checkedState).reduce(
    (accumulator: any, id) => {
      if (checkedState[id]?.recepient) {
        accumulator[id] = {
          status: true,
          waitage: 1,
        };
      }
      return accumulator;
    },
    {}
  );

  if (Object.keys(recipientsObject).length > 0) {
    submitData.recipients = {
      ids: recipientsObject,
    };
    Logger.log('Recipients added:', submitData.recipients);
  }

  Logger.log('Processing access control');
  // Only add access control if any members have access rights
  const accessMembers = Object.keys(checkedState)?.filter(
    (id) => checkedState[id]?.access
  );
  if (accessMembers.length > 0) {
    submitData.readAccess = accessMembers;
    Logger.log('Access members added:', submitData.readAccess);
  }

  Logger.log('Processing automation rules');
  Logger.log('Current trigger data:', triggerData);
  Logger.log('Current conditions:', conditions);
  
  // Add automation rule only if event is selected
  if (triggerData.event) {
    Logger.log('Building automation rule for event:', triggerData.event);
    
    const eventsWithoutConditions = [
      'lead_edited',
      'quotation_created',
      'quotation_edited',
      'incoming_call_activity',
      'outgoing_call_activity',
      'missed_call_activity'
    ];

    const automationRule: any = {
      event: triggerData.event,
      action: {
        day_0: [
          {
            type: "move_to_list",
            data: {
              targetListId: id || "",
            },
          },
        ],
      },
    };
    
    Logger.log('Base automation rule created:', automationRule);

    // Check if conditions should be added
    if ((!eventsWithoutConditions.includes(triggerData.event) && triggerData.targetValues) || conditions.length > 0) {
      Logger.log('Adding conditions to automation rule');
      // Create the condition object 
      const conditionObj: any = {};
      
      // Add target values if they exist
      if (!eventsWithoutConditions.includes(triggerData.event) && triggerData.targetValues) {
        Logger.log('Processing target values:', triggerData.targetValues);
        // Handle both single value and comma-separated values
        const targetValueArray = triggerData.targetValues.includes(',')
          ? triggerData.targetValues.split(',').filter(Boolean)
          : [triggerData.targetValues];
        Logger.log('Processed target array:', targetValueArray);
        conditionObj.targetValues = targetValueArray;
      }
      
      // Add field criteria if they exist
      if (conditions.length > 0) {
        Logger.log('Adding field conditions to API payload:', conditions);
        
        // Transform LeadCondition array to criteria array format
        const criteriaArray = conditions
          .filter(condition => condition.fieldId && condition.value) // Only include valid conditions
          .map(condition => ({
            key: condition.fieldId,
            operator: transformOperator(condition.operator),
            value: condition.value
          }));
        
        Logger.log('Final criteria array for API:', criteriaArray);
        
        // Only add criteria if there are valid conditions
        if (criteriaArray.length > 0) {
          conditionObj.criteria = criteriaArray;
          Logger.log('Full condition object for API:', JSON.stringify(conditionObj));
        }
      
      }
      
      // Set the condition object in the automation rule
      automationRule.condition = conditionObj;
      Logger.log('Condition object added to automation rule:', conditionObj);
    }

    // Use automationRule as specified in the desired schema
    submitData.automationRule = automationRule;
    Logger.log('Final automation rule added to submitData:', automationRule);
    
    // Create a copy for API submission that might expect automateActionRule 
    const apiSubmitData = {...submitData};
    if (process.env.REACT_APP_USE_LEGACY_API === 'true') {
      Logger.log('Legacy API mode detected, copying automationRule to automateActionRule');
      apiSubmitData.automateActionRule = submitData.automationRule;
      delete apiSubmitData.automationRule;
    }
  }

  try {
    setIsLoading(true);
    Logger.log('Submitting data:', submitData);
    let response;

    if (id) {
      Logger.log('Updating existing lead list with ID:', id);
      response = await formPutLeadList(id, submitData);
    } else {
      Logger.log('Creating new lead list');
      response = await createLeadList(submitData);
      
      if (response?.status && triggerData.event) {
        const newListId = response.data.data._id;
        Logger.log('New list created with ID:', newListId);
        
        // Update automation rule with new list ID
        if (submitData.automationRule) {
          Logger.log('Updating automation rule with new list ID');
          submitData.automationRule.action.day_0[0].data.targetListId = newListId;
          response = await formPutLeadList(newListId, submitData);
        }
      }
    }

    if (response && response.status) {
      Logger.log('Success response:', response.data);
      toast.success(response?.data?.message || "Lead list saved successfully");
      getLeadList();
      onClose();
    } else {
      console.error('Error response:', response);
      toast.error("Error while saving lead list!");
    }
  } catch (err) {
    console.error('Error in handleSubmit:', err);
    toast.error("Error while saving lead list!");
  } finally {
    setIsLoading(false);
    Logger.log('handleSubmit completed');
  }
};
Logger.log('Conditions structure:', JSON.stringify(conditions));
Logger.log('Rendering AddEditList with:', {
  listName,
  triggerData,
  conditions,
  isPinned
});

  // Footer content with action buttons
  const footerContent = (
    <div className="d-flex justify-content-end gap-2 w-full">
      <button
        type="button"
        className="px-4 py-2 rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 transition-colors"
        onClick={onClose}
        style={{
          minWidth: '80px',
          fontWeight: 500
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        className="px-4 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-colors"
        onClick={handleSubmit}
        disabled={isLoading || !listName.trim()}
        style={{
          minWidth: '120px',
          fontWeight: 500,
          backgroundColor: '#0095FF'
        }}
      >
        {mode === 'add' ? 'Add List' : 'Update List'}
      </button>
    </div>
  );

  const mainContent = (
    <>
      <div className="mb-4">
        <label className="form-label fw-bold">List Name</label>
        <div className="d-flex align-items-center">
          <input
            type="text"
            className="form-control flex-grow-1"
            placeholder="Enter list name"
            value={listName}
            onChange={handleListNameChange}
            disabled={isLoading}
          />
          {mode === 'edit' && (
            <div 
              className="ml-2 text-danger cursor-pointer" 
              onClick={() => setShowDeleteModal(true)} 
              title="Delete List"
            >
              <Trash2 size={20} />
            </div>
          )}
        </div>
        {error.listName && <ErrorText message={error.listName} />}
      </div>

      <div className="mb-4">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="isPinned"
            checked={isPinned}
            onChange={(e) => setIsPinned(e.target.checked)}
            disabled={isLoading}
          />
          <label className="form-check-label" htmlFor="isPinned">
            Pin this list for quick access
          </label>
        </div>
      </div>

      <TabContent 
        mode={mode}
        id={id}
        isLoading={isLoading}
        triggerData={triggerData}
        setTriggerData={setTriggerData}
        checkedState={checkedState}
        setCheckedState={setCheckedState}
        enableLeadDistribution={enableLeadDistribution}
        setEnableLeadDistribution={setEnableLeadDistribution}
        distributionType={distributionType}
        setDistributionType={setDistributionType}
        conditions={conditions}
        setConditions={setConditions}
      />
    </>
  );

  return (
    <>
      <PopupComponent
        isOpen={isOpen}
        onClose={onClose}
        title={mode === 'add' ? 'Add List' : 'Edit List'}
        footer={footerContent}
        size="lg"
        loading={isLoading}
      >
        <div className="px-2" style={{ maxHeight: 'calc(80vh - 120px)', overflowY: 'auto', padding: '0 16px' }}>
          {mainContent}
        </div>
      </PopupComponent>

      {/* Delete Confirmation Modal */}
      <GenericConfirmationModal
        showModal={showDeleteModal}
        toggleModal={() => setShowDeleteModal(!showDeleteModal)}
        onConfirmation={handleDeleteList}
        message="Deleting this list will permanently remove:
        - The entire lead list
        - All leads within this list
        - Associated tasks
        - Related notes
        
        This action cannot be undone. Are you absolutely sure you want to proceed?"
        title="Delete Lead List"
        confirmText="Delete"
        isWarning={true}
        confirmClassName="btn-danger"
      />
    </>
  );
};

export default AddEditList;