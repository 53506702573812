import React, { useState, useEffect, useCallback } from 'react';
import { Form, Button, Space, message, Card,Collapse, InputNumber, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus } from 'lucide-react';
import { calculateTotals, prepareInvoicePayload } from './InvoiceUtils';
import { AdditionalCharge, Discount, FormValues, LeadSearchResponse, Product, ProductSummary } from './Invoice';
import ProductSearchDropdown from './Components/ProductSearchDropdown';
import ProductPickerPopup from '../../components/ProductPickerPopup';
import moment from 'moment';
import { InvoiceHeader } from './Components/InvoiceHeader';
import { ProductsList } from './ProductsList';
import { AdditionalCharges } from './Components/AdditionalCharges';
import { DiscountSection } from './Components/DiscountSection';
import { TaxSection } from './Components/TaxSection';


import { Invoice, Lead } from './Invoice';
import { 
  createInvoices, 
  updateInvoices,
  getInvoiceDetails, 
  getInvoiceId 
} from '../../services/InvoiceService';
import type { FormInstance } from 'antd/lib/form';
import './invoice-form.scss';
import InvoiceSummary from './Components/InvoiceSummary';
import { styled } from 'styled-components';
import { calculateInvoiceTotals } from './Components/invoiceCalculations';
import { transformApiToFormData, transformFormToApiData } from './invoice-data-transformer';
import { hasValidationErrors, validateInvoice, ValidationSeverity } from './enhanced-invoice-validation';
import { Logger } from '../../utils/logger';
const { Panel } = Collapse;

interface InvoiceIdResponse {
  statusCode: number;
  status: boolean;
  data: {
    quotationId: string;
  }
}

interface AddEditInvoiceProps {
  onSuccess: () => void;
  data?: Invoice | null;
  lead?: Lead | null;
}



// Just update these existing styled components with better styles:

const InvoiceLayout = styled.div`
  height: 100%;
  background: #f8fafc;
`;

const NavHeader = styled.nav`
  background: white;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

const NavContainer = styled.div`
  height: 64px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
`;

const MainContent = styled.div`
  padding: 24px;
  max-width: 1400px;
  margin: 0 auto;

  .card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }

  .card-body {
    padding: 24px;
  }
`;
const AddEditInvoice: React.FC<AddEditInvoiceProps> = ({
  onSuccess,
  data,
  lead
}) => {
  Logger.log('AddEditInvoice: Rendering with data:', { isEdit: !!data?._id, leadId: lead?._id });
  
 
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState<Invoice | null>(null);
  const [totals, setTotals] = useState({
    subtotal: 0,
    chargesTotal: 0,
    discountAmount: 0,
    taxTotal: 0,
    total: 0
  });

  const [showDiscount, setShowDiscount] = useState(false);
  const [showTax, setShowTax] = useState(false);
  const [invoiceIdModalVisible, setInvoiceIdModalVisible] = useState(false);

  const [leadOptions, setLeadOptions] = useState<LeadSearchResponse[]>([]);
  
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [showProductPicker, setShowProductPicker] = useState(false);

  const [hasInitialized, setHasInitialized] = useState(false);

  // State to track mode and initial data
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [initialData, setInitialData] = useState<Invoice | null>(null);
 
   // Effect to determine mode and initial data
// Merge both initialization effects into one


 
  
  
  
  const handleValuesChange = useCallback((values: any) => {
    const newTotals = calculateTotals(values as FormValues);
    setTotals(newTotals);
  }, []);


  useEffect(() => {
    if (hasInitialized) return; // Prevent duplicate initialization
    
    Logger.log('[INVOICE] Component initialization', { location, data, hasInitialized });
    setLoading(true);
    
    // 1. Determine mode and initial data
    const navigationState = location.state as { 
      mode?: 'create' | 'edit', 
      editData?: Invoice 
    } | null;
  
    const determinedMode = navigationState?.mode || (data ? 'edit' : 'create');
    const determinedInitialData = navigationState?.editData || data || null;
  
    setMode(determinedMode);
    setInitialData(determinedInitialData);
    
    // 2. Initialize data based on mode
    const initializeData = async () => {
      try {
        if (determinedMode === 'edit' && determinedInitialData?._id) {
          Logger.log('[INVOICE] Edit mode - fetching details for ID:', determinedInitialData._id);
          
          // Fetch invoice details
          const response = await getInvoiceDetails(determinedInitialData._id);
          
          if (response?.data?.data) {
            const invoice = response.data.data;
            Logger.log('[INVOICE] Successfully fetched invoice details:', invoice);
            
            // Process lead data
            let leadData = invoice.lead;
            if (typeof invoice.lead === 'string') {
              Logger.log('[INVOICE] Lead is a string, creating a fallback object');
              leadData = { _id: invoice.lead, name: 'Unknown Lead' };
            }
            
            Logger.log('[INVOICE] Processed lead data:', leadData);
            
            // Add lead to options if not present
            if (leadData && !leadOptions.some((option) => option._id === leadData._id)) {
              setLeadOptions((prevOptions) => [leadData, ...prevOptions]);
            }
            
            // Prepare form data
            const formData = {
              InvoiceId: invoice.invoiceId || '',
              _id: invoice._id || '',
              lead: leadData,
              products: invoice.products || [],
              additionalCharges: invoice.additionalCharges || [],
              taxes: invoice.taxes?.map((tax: { title: any; rate: any; }) => ({
                title: tax.title,
                rate: tax.rate
              })) || [],
              discount: invoice.discount,
              notes: invoice.notes || invoice.note || '',
              createdDate: invoice.createdDate ? moment(invoice.createdDate).toDate() : new Date(),
              receivedAmount: invoice.receivedAmount || 0,
              receivedPaymentMode: invoice.receivedPaymentMode || ''
            };
            
            Logger.log('[INVOICE] Prepared form data:', formData);
            
            // Set form values
            form.setFieldsValue(formData);
            
            // Set UI state based on data
            const shouldShowDiscount = !!invoice.discount;
            const shouldShowTax = !!(invoice.taxes && invoice.taxes.length > 0);
            
            if (shouldShowDiscount) {
              setShowDiscount(true);
              Logger.log('[INVOICE] Enabling discount section');
            }
            
            if (shouldShowTax) {
              setShowTax(true);
              Logger.log('[INVOICE] Enabling tax section');
            }
            
            // Calculate totals after setting form values
            handleValuesChange(formData);
          } else {
            console.error('[INVOICE] No data found in response');
            message.error('Could not fetch invoice details');
          }
        } else {
          // Create mode - fetch new ID
          Logger.log('[INVOICE] Create mode - fetching new ID');
          const response = await getInvoiceId();
          
          if (response?.data?.data?.quotationId) {
            const id = response.data.data.quotationId;
            Logger.log('[INVOICE] Setting new Invoice ID:', id);
            form.setFieldsValue({
              InvoiceId: id,
              createdDate: moment().toDate()
            });
          } else {
            console.error('[INVOICE] Failed to get invoice ID');
            message.error('Could not generate invoice ID');
          }
        }
        
        // Mark as initialized to prevent duplicate fetches
        setHasInitialized(true);
      } catch (error) {
        console.error('[INVOICE] Error initializing data:', error);
        message.error('Error loading invoice data');
      } finally {
        setLoading(false);
      }
    };
    
    initializeData();
    
    // Clean up function if needed
    return () => {
      Logger.log('[INVOICE] Component cleanup');
    };
  }, [data, location, form, leadOptions, hasInitialized, handleValuesChange, navigate]);

  // Keep existing data fetching logic
  const fetchInvoiceDetails = useCallback(async () => {
    try {
      if (data?._id) {
        setLoading(true);
        const response = await getInvoiceDetails(data._id);
        
        if (response?.data?.data) {
          const invoice = response.data.data;
          Logger.log('Fetched invoice details:', invoice);
          
          // Transform API data to form data with proper validation
          const formData = transformApiToFormData(invoice);
          
          // Set form values safely
          form.setFieldsValue(formData);
          
          // Set UI states based on data properties
          if (formData.discount?.rate !== null && formData.discount?.rate !== undefined) {
            setShowDiscount(true);
            Logger.log('showDiscount set to true');
          }
          
          if (Array.isArray(formData.taxes) && formData.taxes.length > 0) {
            setShowTax(true);
            Logger.log('showTax set to true');
          }
          
          // Calculate totals
          handleValuesChange(formData);
        }
      }
    } catch (error) {
      console.error('Error fetching invoice details:', error);
      message.error('Error fetching invoice details');
    } finally {
      setLoading(false);
    }
  }, [data, form, handleValuesChange]);

  const handleMultipleProductsSelect = useCallback((selectedProducts: any[]) => {
    Logger.log('AddEditInvoice: Multiple products selected', selectedProducts);
    
    try {
      // Safely get current products
      const currentProducts = form.getFieldValue('products') || [];
      
      // Format products with safe property access
      const newProducts = selectedProducts.map(product => ({
        id: product?._id || '',
        quantity: Number(product?.quantity) || 1,
        unitPrice: Number(product?.price) || 0,
        description: product?.description || '',
        product: {
          _id: product?._id || '',
          name: product?.name || 'Unknown Product'
        }
      }));
      
      // Update form safely
      form.setFieldsValue({
        products: [...currentProducts, ...newProducts]
      });
      
      // Update calculations
      handleValuesChange(form.getFieldsValue());
    } catch (error) {
      console.error('Error adding multiple products:', error);
      message.error('Failed to add products');
    }
  }, [form, handleValuesChange]);
  
  // ===== STEP 4: Get current product IDs to exclude from picker =====
  const getExcludedProductIds = useCallback(() => {
    const currentProducts = form.getFieldValue('products') || [];
    return currentProducts.map((product: { id: any; product: { _id: any; }; }) => product.id || product.product?._id);
  }, [form]);

  const handleProductSelect = useCallback((product: Product) => {
    try {
      // Safely get current products
      const currentProducts = form.getFieldValue('products') || [];
      
      // Create new product with safe property access
      const newProduct = {
        id: product?._id || '',
        quantity: 1,
        unitPrice: Number(product?.price) || 0,
        description: product?.description || '',
        product: {
          _id: product?._id || '',
          name: product?.name || 'Unknown Product'
        }
      };
      
      // Update form safely
      form.setFieldsValue({
        products: [...currentProducts, newProduct]
      });
      
      // Update calculations
      handleValuesChange(form.getFieldsValue());
    } catch (error) {
      console.error('Error adding product:', error);
      message.error('Failed to add product');
    }
  }, [form, handleValuesChange]);

  const fetchNewInvoiceId = useCallback(async () => {
    try {
      const response = await getInvoiceId();
      Logger.log('Fetched ID response:', response);
      
      if (response?.data?.data?.quotationId) {
        // Force immediate form update
        const id = response.data.data.quotationId;
        Logger.log('Setting Invoice ID:', id);
        form.setFieldValue('InvoiceId', id);
        form.setFieldValue('createdDate', moment());
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Error generating invoice ID');
    }
}, [form]);

useEffect(() => {
  Logger.log('Totals updated:', totals);
}, [totals]);



Logger.log('showDiscount:', showDiscount);
Logger.log('showTax:', showTax);



const handleSubmit = async (values: any) => {
  try {
    Logger.log('1. Initial form values:', values);

    // Run enhanced validation
    const validationResults = validateInvoice(values as FormValues);
    
    // Check if there are validation errors
    if (hasValidationErrors(validationResults)) {
      // Display validation errors
      validationResults
        .filter(result => result.severity === ValidationSeverity.ERROR)
        .forEach(error => {
          message.error(error.message);
        });
      
      return; // Exit early if validation fails
    }

    setLoading(true);
    
    // Transform form data to API payload
    const submitData = transformFormToApiData(values as FormValues, mode === 'edit');
  Logger.log('3b. After transformFormToApiData for update:', submitData);
    
    if (mode === 'edit' && initialData?._id) {
      Logger.log('3a. Update mode detected');
      
      // Include the ID for the API endpoint
      await updateInvoices({ 
        ...submitData, 
        _id: initialData._id 
      });
      
      Logger.log('3e. Invoice updated successfully');
      message.success('Invoice updated successfully');
    } else {
      Logger.log('3a. Create mode detected');
      await createInvoices(submitData);
      Logger.log('3b. Invoice created successfully');
      message.success('Invoice created successfully');
    }

    onSuccess();
    navigate('/invoicelist');
  } catch (error) {
    console.error('4. Error in handleSubmit:', error);
    message.error('Failed to save invoice');
  } finally {
    setLoading(false);
  }
};

  const handleCancel = () => {
    Logger.log('AddEditInvoice: Handling cancel');
    if (onSuccess) {
      onSuccess(); // This will trigger parent's handleFormCancel
    }
  };


  function setShowProductForm(arg0: boolean): void {
    throw new Error('Function not implemented.');
  }

  return (
    <InvoiceLayout>
      {/* Header */}
      <NavHeader>
  <NavContainer>
    <div className="d-flex align-items-center gap-3">
    <Button 
  icon={<ArrowLeft size={16} />}
  onClick={handleCancel}
  className="d-flex align-items-center gap-2"
  style={{ 
    height: '38px',
    padding: '0 16px',
    border: '1px solid #e5e7eb',
    background: '#f9fafb',
    color: '#374151',
    fontWeight: 500,
    borderRadius: '6px',
    transition: 'all 0.2s ease'
  }}
>
  Back
</Button>
      <h1 className="mb-0 fs-5">{mode === 'edit' ? 'Edit Invoice' : 'Create Invoice'}</h1>
    </div>
          
          <div className="d-flex gap-3">
          <Button 
  onClick={handleCancel}
  style={{
    height: '40px',
    padding: '0 18px',
    background: '#ffffff',
    border: '1px solid #e5e7eb',
    color: '#4b5563',
    fontWeight: 500,
    borderRadius: '6px',
    transition: 'all 0.2s ease'
  }}
  className="hover-effect"
>
  Cancel
</Button>
      <Button
        type="primary"
        onClick={() => form.submit()}
        loading={loading}
      >
        {mode === 'edit' ? 'Update' : 'Create'} Invoice
      </Button>
    </div>
  </NavContainer>
</NavHeader>
  
      {/* Main Content */}
      <MainContent>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onValuesChange={(_, allValues) => handleValuesChange(allValues)}
        >
          <div className="row g-4">
            {/* Left Content - 75% */}
            <div className="col-lg-8">
              {/* Invoice Header */}
<div className="card mb-4">
  <div className="card-body">
  <InvoiceHeader 
  form={form}
  initialLead={lead}
/>
  </div>
</div>
  
             {/* Products Card */}
<div className="invoice-card mb-4">
  <div className="card-body p-4">
    <div className="row">
      <div className="col-12">
        <h2 className="fs-6 fw-semibold mb-4">Products</h2>
        
        {/* Search Container */}
        <div className="mb-4">
  <Button 
    type="primary" 
    icon={<Plus size={16} />}
    onClick={() => setShowProductPicker(true)}
    className="w-100"
  >
    Search & Add Products
  </Button>
  
  {/* Product Picker Popup */}
  <ProductPickerPopup
    isOpen={showProductPicker}
    onClose={() => setShowProductPicker(false)}
    onProductsSelect={handleMultipleProductsSelect}
    excludeProductIds={getExcludedProductIds()}
  />
</div>

        {/* Selected Products List */}
        <ProductsList
          form={form}
          onValuesChange={handleValuesChange}
        />
      </div>
    </div>
  </div>
</div>
  
              {/* Notes Section */}
              <div className="card">
                <div className="card-body">
                  <h2 className="fs-6 mb-3">Additional Information</h2>
                  <Form.Item name="notes" className="mb-0">
                    <Input.TextArea
                      rows={4}
                      placeholder="Add any notes or special instructions..."
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
  
            {/* Right Sidebar - 25% */}
            <div className="col-lg-4">
              <div className="sticky-top" style={{ top: '80px' }}>
                {/* Summary */}
                
                <InvoiceSummary
                
  form={form}
  totals={totals}
  productCount={form.getFieldValue('products')?.length || 0}
/>
  
                {/* Adjustments */}
                <div className="card">
                  <div className="card-body">
                    <h2 className="fs-6 mb-4">Adjustments</h2>
                    
                    {/* Additional Charges */}
                    <div className="mb-4">
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <span>Additional Charges</span>
                        <Button 
                          type="link" 
                          className="p-0"
                          onClick={() => {
                            const charges = form.getFieldValue('additionalCharges') || [];
                            form.setFieldValue('additionalCharges', [
                              ...charges,
                              { title: '', type: 'fixed', rate: null }
                            ]);
                          }}
                        >
                          + Add Charge
                        </Button>
                      </div>
                      <AdditionalCharges
                        form={form}
                        onValuesChange={handleValuesChange}
                      />
                    </div>
  
                    {/* Discount Section */}
                    <div className="mb-4 pt-3 border-top">
                    <DiscountSection
  show={showDiscount}
  onToggle={() => setShowDiscount(!showDiscount)}
  form={form}
  onValuesChange={handleValuesChange}
/>
                    </div>
  
                    {/* Tax Section */}
                    <div className="pt-3 border-top">
                      <TaxSection
                        show={showTax}
                        onToggle={() => setShowTax(!showTax)}
                        form={form}
                        onValuesChange={handleValuesChange}
                        baseAmount={totals.subtotal - totals.discountAmount}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        </MainContent>
  
     
      
    </InvoiceLayout>
  );
};

export default AddEditInvoice;