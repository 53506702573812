import React from 'react';
import { Form, Select } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import {
  DiscountContainer,
  SectionHeader,
  SectionTitle,
  ActionButton,
  DiscountControls,
  FixedWidthSelect,
  FixedWidthInputNumber
} from './QuotationAdjustmentsStyles';

interface DiscountSectionProps {
  show: boolean;
  onToggle: () => void;
  form: FormInstance;
  onValuesChange: (values: any) => void;
}

export const DiscountSection: React.FC<DiscountSectionProps> = ({
  show,
  onToggle,
  form,
  onValuesChange,
}) => {
  const handleTypeChange = (value: string) => {
    // Make sure type is lowercase for consistency
    form.setFieldValue(['discount', 'type'], value.toLowerCase());
    form.setFieldValue(['discount', 'rate'], null);
    const values = form.getFieldsValue();
    onValuesChange(values);
  };

  const validateRate = (_: any, value: number | null) => {
    if (value === null) return Promise.resolve();
    // Make sure we're comparing against lowercase 'rate'
    if (form.getFieldValue(['discount', 'type']) === 'rate') {
      if (value < 0 || value > 100) {
        return Promise.reject(new Error('Rate must be between 0 and 100'));
      }
    }
    return Promise.resolve();
  };

  // Initialize discount type if not set
  React.useEffect(() => {
    if (show) {
      const currentType = form.getFieldValue(['discount', 'type']);
      if (!currentType) {
        // Default to lowercase 'rate'
        form.setFieldValue(['discount', 'type'], 'rate');
      } else if (typeof currentType === 'string' && currentType !== currentType.toLowerCase()) {
        // Normalize any capitalized values
        form.setFieldValue(['discount', 'type'], currentType.toLowerCase());
      }
    }
  }, [show, form]);

  return (
    <DiscountContainer>
      <SectionHeader>
        <SectionTitle>Discount</SectionTitle>
        <ActionButton 
          type="link" 
          onClick={onToggle}
        >
          {show ? 'Remove' : 'Add Discount'}
        </ActionButton>
      </SectionHeader>

      {show && (
        <DiscountControls>
          <Form.Item name={['discount', 'type']}>
            <FixedWidthSelect
              onChange={handleTypeChange}
            >
              {/* Use lowercase values for options */}
              <Select.Option value="rate">Rate</Select.Option>
              <Select.Option value="fixed">Fixed Amount</Select.Option>
            </FixedWidthSelect>
          </Form.Item>

          <Form.Item 
            name={['discount', 'rate']}
            rules={[
              { validator: validateRate }
            ]}
          >
            <FixedWidthInputNumber
              min={0}
              placeholder="Value"
              formatter={(value: number | string | undefined) => 
                value ? `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '₹ '
              }
              parser={(value: string | undefined) => 
                value ? value.replace(/₹\s?|(,*)/g, '') : ''
              }
              onChange={() => {
                const values = form.getFieldsValue();
                onValuesChange(values);
              }}
            />
          </Form.Item>
        </DiscountControls>
      )}
    </DiscountContainer>
  );
};