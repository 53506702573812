import React, { useState } from "react";
import { useSelector } from "react-redux";
import ErrorText from "../../components/errorText";
import TriggerSelectionPopup from "./TriggerSelectionPopup";
import { 
  User, 
  FileText, 
  Calendar,
  MessageCircle, 
  Mail, 
  Receipt,
  ScrollText,
  Eye,
  Tag,
  ClipboardList,
  ChevronDown,
  ListChecks,
  PhoneIncoming,
  PhoneOutgoing,
  PhoneMissed,
  PhoneCall,
  Edit
} from 'lucide-react';

import './TriggerSelectionPopup.css';

const TriggerSection = ({
  automationData,
  automationWhenTriggerData,
  isShowAutoEvent,
  isUpdateAuto,
  handleAutoFormChange,
  sourceByData,
  statusLabelData,
  viewPageData,
  viewFileData,
  taskData,
  activityListData,
  customActivityType,
  organizationLeadList,
  errors,
}: any) => {
  const [showTriggerPopup, setShowTriggerPopup] = useState(false);
  
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const { customSource } = StoreData?.user?.userPreferences;

  // Get display name for the selected trigger
  const getTriggerDisplayName = () => {
    const selected = automationWhenTriggerData.find(
      (item: any) => item.value === automationData?.event
    );
    return selected ? selected.name : "Select trigger";
  };

  // Update the getSourceLabel function
  const getSourceLabel = (event: string): string => {
    switch (event) {
      case "create_lead":
        return "Lead source is";
      case "status_change":
        return "Status is updated to";
      case "label_change":
        return "Label is updated to";
      case "view_page":
        return "Page is";
      case "view_file":
        return "File is";
      case "create_activity":
        return "Activity is";
      case "create_task":
        return "Task type is";
      case "new_lead_added":
        return "List name";
      default:
        return "";
    }
  };

  // Handle trigger selection
  const handleTriggerSelect = (value: string) => {
    // Create a proper synthetic event to fix destructuring error
    const syntheticEvent = {
      target: { name: 'event', value },
      currentTarget: { name: 'event', value }
    };
    
    handleAutoFormChange(syntheticEvent);
    setShowTriggerPopup(false);
  };

  // Convert trigger data to the format expected by the popup
  const formatTriggerOptions = () => {
    return automationWhenTriggerData.map((item: any) => ({
      value: item.value,
      name: item.name || getTriggerNameByValue(item.value),
      description: item.description || getTriggerDescriptionByValue(item.value),
      category: item.category || getTriggerCategoryByValue(item.value),
      icon: getIconForTrigger(item.value)
    }));
  };

  return (
    <div className="col-md-12 when_then">
      <span className="auto_dot">
        <label className="dot_no">1</label>
      </span>
      <label className="when_then_label">When this happens...</label>
      <span className="when_then_trigger">
        Choose the event that starts your automation
      </span>
      <div className="col-md-12">
        {/* Visual trigger selector button */}
        <div className="col-md-4 auto_select">
          <div 
            className={`trigger-selector-button ${isUpdateAuto ? "disabled" : ""}`}
            onClick={() => !isUpdateAuto && setShowTriggerPopup(true)}
          >
            <span className="trigger-selected-text">
              {getTriggerDisplayName()}
            </span>
            {!isUpdateAuto && (
              <span className="trigger-selector-arrow">
                <ChevronDown size={16} />
              </span>
            )}
          </div>
          
          {/* Hidden original select for form compatibility */}
          <select
            name="event"
            value={automationData?.event}
            className="hidden-select"
            onChange={(e) => handleAutoFormChange(e)}
            style={{ display: 'none' }}
          >
            <option value="">Select trigger</option>
            {automationWhenTriggerData.map((data: any, i: number) => (
              <option value={data.value} key={i}>
                {data.name}
              </option>
            ))}
          </select>
        </div>

        {/* Trigger selection popup */}
        <TriggerSelectionPopup
          isOpen={showTriggerPopup}
          onClose={() => setShowTriggerPopup(false)}
          onSelect={handleTriggerSelect}
          currentValue={automationData?.event}
          isEditMode={isUpdateAuto}
          triggerOptions={formatTriggerOptions()}
        />

        {isShowAutoEvent && (
          <div className="col-md-8 condition-group">
            <div className="d-flex align-items-center gap-2">
              <span className="and">AND</span>
              <span className="and_source">
                {getSourceLabel(automationData?.event)}
              </span>
              <select
                name="targetValues"
                className={
                  isUpdateAuto
                    ? "form-select and_select disabled_input"
                    : "form-select and_select"
                }
                disabled={isUpdateAuto}
                value={automationData?.targetValues}
                onChange={(e) => handleAutoFormChange(e)}
              >
                <option value="">Select</option>
                {automationData?.event === "create_lead" && (
                  <>
                    {[
                      ...customSource?.map((e: any) => ({
                        ...e,
                        _id: e.value,
                        isActive: true,
                      })),
                      ...sourceByData,
                    ]
                      .filter((item) => item.isActive)
                      .map((item: any, i: number) => (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      ))}
                    <option value="any">Any</option>
                  </>
                )}

                {(automationData?.event === "status_change" ||
                  automationData?.event === "label_change") && (
                  <>
                    {statusLabelData.map((item: any, i: number) => (
                      <option value={item.value} key={i}>
                        {item.name}
                      </option>
                    ))}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "view_page" && (
                  <>
                    {viewPageData.map((item: any, i: number) => (
                      <option value={item._id} key={i}>
                        {item.details.title}
                      </option>
                    ))}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "view_file" && (
                  <>
                    {viewFileData.map(
                      (item: any, i: number) => (
                        <option value={item._id} key={i}>
                          {item?.details?.title}
                        </option>
                      )
                    )}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "create_task" && (
                  <>
                    {taskData.map((item: any, i: number) => (
                      <option value={item.value} key={i}>
                        {item.name}
                      </option>
                    ))}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "edit_task" && (
                  <>
                    <option value="toBePerformAt">Due date is updated</option>
                    <option value="isCompleted">Task is completed</option>
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "create_activity" && (
                  <>
                    {[...activityListData, ...customActivityType].map(
                      (item: any, i: number) => (
                        <option value={item.value} key={i}>
                          {item.name}
                        </option>
                      )
                    )}
                    <option value="any">Any</option>
                  </>
                )}

                {automationData?.event === "new_lead_added" && (
                  <>
                    {organizationLeadList.map((item: any, i: number) => (
                      <option value={item._id} key={i}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
          </div>
        )}
        {errors.event && <ErrorText message={errors.event} />}
      </div>
    </div>
  );
};

// Helper functions to provide standardized names, descriptions, and categories
// These are fallbacks if the data doesn't include these fields
const getTriggerNameByValue = (value: string): string => {
  switch (value) {
    case 'create_lead': return 'New Lead Added';
    case 'edit_lead': return 'Lead Information Updated';
    case 'status_change': return 'Lead Status Changed';
    case 'label_change': return 'Lead Label Changed';
    case 'new_lead_added': return 'Lead Added to List';
    case 'create_quotation': return 'New Quotation Created';
    case 'edit_quotation': return 'Quotation Updated';
    case 'create_invoice': return 'New Invoice Created';
    case 'edit_invoice': return 'Invoice Updated';
    case 'create_order': return 'New Order Created';
    case 'edit_order': return 'Order Updated';
    case 'create_task': return 'New Task Created';
    case 'edit_task': return 'Task Updated/Completed';
    case 'create_activity': return 'New Activity Logged';
    case 'view_page': return 'Lead Views Page';
    case 'view_file': return 'Lead Views Document';
    case 'send_email': return 'Email Sent';
    case 'send_whatsapp': return 'WhatsApp Message Sent';
    default: return value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
};

const getTriggerDescriptionByValue = (value: string): string => {
  switch (value) {
    case 'create_lead': return 'Triggers when a new lead is created in your CRM';
    case 'edit_lead': return 'Triggers when any lead information is changed';
    case 'status_change': return 'Triggers when a lead moves to a different status';
    case 'label_change': return 'Triggers when a lead\'s label is updated';
    case 'new_lead_added': return 'Triggers when a lead is added to a specific list';
    case 'create_quotation': return 'Triggers when you create a new quotation for a lead';
    case 'edit_quotation': return 'Triggers when a quotation is edited or status changes';
    case 'create_invoice': return 'Triggers when you create a new invoice for a lead';
    case 'edit_invoice': return 'Triggers when an invoice is edited or status changes';
    case 'create_order': return 'Triggers when you create a new order for a lead';
    case 'edit_order': return 'Triggers when an order is edited or status changes';
    case 'create_task': return 'Triggers when you create a new follow-up task';
    case 'edit_task': return 'Triggers when a task is updated or marked as complete';
    case 'create_activity': return 'Triggers when you log a new activity with a lead';
    case 'view_page': return 'Triggers when a lead views a specific page on your website';
    case 'view_file': return 'Triggers when a lead opens a file you\'ve shared';
    case 'send_email': return 'Triggers when an email is sent to a lead';
    case 'send_whatsapp': return 'Triggers when a WhatsApp message is sent to a lead';
    default: return 'Triggers when this event occurs';
  }
};

const getTriggerCategoryByValue = (value: string): string => {
  if (value.includes('lead') || value === 'status_change' || value === 'label_change' || value === 'new_lead_added') {
    return 'Lead Management';
  } else if (value.includes('quotation') || value.includes('invoice') || value.includes('order')) {
    return 'Sales & Quotes';
  } else if (value.includes('task') || value.includes('activity') || value.includes('view_')) {
    return 'Activities & Tasks';
  } else if (value.includes('email') || value.includes('whatsapp')) {
    return 'Communication';
  } else {
    return 'Other';
  }
};

// Helper function to get icon for trigger type
const getIconForTrigger = (triggerValue: string) => {
  switch (triggerValue) {
    case 'create_lead':
    case 'edit_lead':
    case 'new_lead_added':
      return <User size={22} />;
      
    case 'status_change':
      return <ListChecks size={22} />;
      
    case 'label_change':
      return <Tag size={22} />;
      
    case 'create_quotation':
    case 'edit_quotation':
      return <ScrollText size={22} />;
      
    case 'create_invoice':
    case 'edit_invoice':
      return <Receipt size={22} />;
      
    case 'create_order':
    case 'edit_order':
      return <ClipboardList size={22} />;
      
    case 'create_task':
      return <Calendar size={22} />;
      
    case 'edit_task':
      return <Edit size={22} />;
      
    case 'create_activity':
      return <FileText size={22} />;
    
    case 'incoming_call':
      return <PhoneIncoming size={22} />;
      
    case 'outgoing_call':
      return <PhoneOutgoing size={22} />;
      
    case 'missed_call':
      return <PhoneMissed size={22} />;
      
    case 'view_page':
    case 'view_file':
      return <Eye size={22} />;
      
    case 'send_email':
      return <Mail size={22} />;
      
    case 'send_whatsapp':
      return <MessageCircle size={22} />;
      
    default:
      return <FileText size={22} />;
  }
};

export default TriggerSection;