import { Logger } from '../../utils/logger';

import {
  FormValues,
  QuotationPayload,
  TotalCalculation,
  ProductSummary,
  AdditionalCharge,
  Tax,
  Quotation
} from './Quotation';

export const calculateTotals = (values: FormValues): TotalCalculation => {
  Logger.log('Input values for calculateTotals:', values);

  // Calculate subtotal
  const subtotal = values.products?.reduce((sum: number, product: ProductSummary) => 
    sum + (product.quantity * product.unitPrice), 0) || 0;

  // Calculate additional charges
  const chargesTotal = values.additionalCharges?.reduce((sum: number, charge: AdditionalCharge) => {
    if (charge.type === 'fixed') {
      return sum + (charge.rate || 0);
    }
    return sum + ((subtotal * charge.rate) / 100);
  }, 0) || 0;

  // Calculate discount
  const discountAmount = values.discount?.rate ? 
    (values.discount.type === 'fixed' ? 
      values.discount.rate : 
      ((subtotal + chargesTotal) * (values.discount.rate || 0)) / 100) 
    : 0;

  // Calculate tax on amount after discount
  const baseForTax = subtotal + chargesTotal - discountAmount;
  const taxTotal = values.taxes?.reduce((sum: number, tax: Tax) => 
    sum + ((baseForTax * (tax.rate || 0)) / 100), 0) || 0;

  // Calculate total
  const total = baseForTax + taxTotal;
  
  // Calculate balance due (if advance payment received)
  const receivedAmount = typeof values.receivedAmount === 'number' ? values.receivedAmount : 0;
  const balanceDue = Math.max(0, Number(total) - Number(receivedAmount));

  const totals = {
    subtotal,
    chargesTotal,
    discountAmount,
    taxTotal,
    total,
    receivedAmount,
    balanceDue
  };

  Logger.log('Calculated totals:', totals);
  return totals;
};

export const prepareQuotationPayload = (values: FormValues): QuotationPayload => {
  const cleanedPayload: any = {
    "lead": typeof values.lead === 'object' ? values.lead._id : values.lead,
    "createdDate": new Date().toISOString()
  };
  
  cleanedPayload["products"] = values.products.map(item => ({
    "id": item.id,
    "quantity": item.quantity,
    "unitPrice": item.unitPrice,
    "description": item.description || undefined
  }));

  if (Array.isArray(values.additionalCharges) && values.additionalCharges.length > 0) {
    cleanedPayload["additionalCharges"] = values.additionalCharges.map(charge => ({
      "title": charge.title,
      "type": charge.type || 'fixed',
      "rate": String(charge.rate || 0)
    }));
  }

  // Fixed taxes to exclude total
  if (Array.isArray(values.taxes) && values.taxes.length > 0) {
    cleanedPayload["taxes"] = values.taxes.map(({ title, rate }) => ({  // Destructure only what we need
      "title": title,
      "rate": Number(rate || 0)
    }));
  }

  if (values.discount?.rate !== undefined) {
    cleanedPayload["discount"] = {
      "type": values.discount.type || 'fixed',
      "rate": String(values.discount.rate || 0)
    };
  }

  if (values.notes) {
    cleanedPayload["notes"] = values.notes.trim();
  }
  if (values.receivedPaymentMode) {
    cleanedPayload["receivedPaymentMode"] = values.receivedPaymentMode;
  }
  if (values.receivedAmount !== undefined) {
    const numReceivedAmount = Number(values.receivedAmount);
    if (!isNaN(numReceivedAmount) && numReceivedAmount > 0) {
      cleanedPayload["receivedAmount"] = String(numReceivedAmount);
    }
  }

  return cleanedPayload;
};

export const validateQuotation = (values: Partial<FormValues>): string[] => {
  const errors: string[] = [];

  if (!values.products?.length) {
    errors.push('Please add at least one product');
  }

  if (values.discount?.type === 'fixed' && typeof values.discount.rate === 'number') {
    const totals = calculateTotals(values as FormValues);
    if (values.discount.rate > totals.total) {
      errors.push('Discount cannot exceed total amount');
    }
  }
  
  // Validate received amount
  if (values.receivedAmount !== undefined) {
    const numReceivedAmount = Number(values.receivedAmount);
    const totals = calculateTotals(values as FormValues);
    
    if (isNaN(numReceivedAmount)) {
      errors.push('Advanced payment must be a valid number');
    } else if (numReceivedAmount < 0) {
      errors.push('Advanced payment cannot be negative');
    } else if (numReceivedAmount > totals.total) {
      errors.push('Advanced payment cannot exceed total amount');
    }
  }
  
  
  return errors;
};

export const formatCurrency = (amount: number): string => {
  return `₹ ${amount.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`;
};