import React, { PropsWithChildren, useCallback, memo, useEffect } from "react";
import { Modal } from "reactstrap";
import styled from "styled-components";
import { Logger } from '../utils/logger';

interface GenericModalProps {
  showModal: boolean;
  onConfirmation: () => void;
  toggleModal: () => void;
  message?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  confirmClassName?: string;
  cancelClassName?: string;
  isWarning?: boolean;
}

interface WarningProps {
  $isWarning?: boolean;
}

// Extend button props with our warning prop
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, WarningProps {}

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  z-index: 9999 !important; /* Force high z-index */
`;

const ModalHeader = styled.div<WarningProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #e9ecef;
  background: ${({ $isWarning }) => $isWarning ? 'rgba(255, 59, 48, 0.05)' : '#fff'};
  border-radius: 8px 8px 0 0;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
  font-weight: 500;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #6c757d;
  font-size: 20px;
  line-height: 1;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: #f8f9fa;
    color: #343a40;
  }
`;

const ModalBody = styled.div<WarningProps>`
  padding: 20px;
  color: ${({ $isWarning }) => $isWarning ? '#dc3545' : '#6c757d'};
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-line;  // This preserves the line breaks in the message
  p {
    margin-bottom: 16px;  // Add more spacing between paragraphs
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
  border-radius: 0 0 8px 8px;
  gap: 12px;
`;

const BaseButton = styled.button<ButtonProps>`
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
  border: 1px solid transparent;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const CancelButton = styled(BaseButton)`
  background: #fff;
  border-color: #dee2e6;
  color: #6c757d;

  &:hover {
    background: #f8f9fa;
    border-color: #cbd3da;
  }
`;

const ConfirmButton = styled(BaseButton)<ButtonProps>`
  background: ${({ $isWarning }) => $isWarning ? '#dc3545' : '#0d6efd'};
  color: white;

  &:hover {
    background: ${({ $isWarning }) => $isWarning ? '#c82333' : '#0b5ed7'};
  }
`;

// This injects global styles to ensure modals appear on top
const injectModalStyles = () => {
  const styleId = 'modal-priority-styles';
  
  // Only inject if not already present
  if (!document.getElementById(styleId)) {
    const style = document.createElement('style');
    style.id = styleId;
    style.innerHTML = `
      .modal-backdrop {
        z-index: 9998 !important;
      }
      .modal {
        z-index: 9999 !important;
      }
      .modal-open {
        overflow: hidden;
        padding-right: 0 !important;
      }
      .modal-dialog {
        margin: 1.75rem auto !important;
      }
      /* Fix to ensure this modal appears above other reactstrap modals */
      #root .modal {
        z-index: 9999 !important;
      }
    `;
    document.head.appendChild(style);
  }
};

const GenericConfirmationModal: React.FC<PropsWithChildren<GenericModalProps>> = ({
  showModal,
  toggleModal,
  onConfirmation,
  message = "Are you sure you want to proceed?",
  title = "Confirm Action",
  confirmText = "Ok",
  cancelText = "Cancel",
  confirmClassName = "",
  cancelClassName = "",
  isWarning = false,
  children,
}) => {
  // Inject the global styles when component mounts
  useEffect(() => {
    injectModalStyles();
    
    // Add debug logging
    if (showModal) {
      Logger.log('[ConfirmationModal] Modal is being displayed', { 
        title, 
        hasOnConfirmation: !!onConfirmation, 
        hasToggleModal: !!toggleModal 
      });
    }
    
    // Return cleanup if needed
    return () => {
      // Nothing to clean up for now
    };
  }, [showModal, title, onConfirmation, toggleModal]);

  const handleConfirmation = useCallback(() => {
    Logger.log('[ConfirmationModal] Confirm button clicked');
    
    try {
      if (onConfirmation) {
        Logger.log('[ConfirmationModal] Calling onConfirmation function');
        onConfirmation();
        Logger.log('[ConfirmationModal] onConfirmation function called successfully');
      } else {
        Logger.warn('[ConfirmationModal] onConfirmation function is not defined');
      }
      
      Logger.log('[ConfirmationModal] Calling toggleModal to close');
      toggleModal();
      Logger.log('[ConfirmationModal] Modal should be closed now');
    } catch (error) {
      console.error('[ConfirmationModal] Error in confirmation process:', error);
    }
  }, [onConfirmation, toggleModal]);

  return (
    <StyledModal
      isOpen={showModal}
      toggle={toggleModal}
      centered
      backdrop="static"
      size="md"
      zIndex={9999}
      style={{ zIndex: 9999 }}
      contentClassName="confirmation-modal-content"
      className="confirmation-modal"
    >
      <ModalHeader $isWarning={isWarning}>
        <Title>{title}</Title>
        <CloseButton onClick={toggleModal} aria-label="Close">×</CloseButton>
      </ModalHeader>

      <ModalBody $isWarning={isWarning}>
        {message && <p className="mb-3">{message}</p>}
        {children}
      </ModalBody>

      <ModalFooter>
        <CancelButton
          onClick={toggleModal}
          className={`cancel-btn ${cancelClassName}`}
          data-testid="modal-cancel-button"
        >
          {cancelText}
        </CancelButton>
        <ConfirmButton
          onClick={handleConfirmation}
          $isWarning={isWarning}
          className={`confirm-btn ${confirmClassName}`}
          data-testid="modal-confirm-button"
        >
          {confirmText}
        </ConfirmButton>
      </ModalFooter>
    </StyledModal>
  );
};

export default memo(GenericConfirmationModal);