import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Form, InputNumber, Select, Radio } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { Logger } from '../../../utils/logger';

const SummaryWrapper = styled.div`
  position: sticky;
  top: 80px;
  margin-bottom: 16px;
  width: 100%;
`;

const SummaryCard = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  width: 100%;
  overflow: hidden;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #1890ff;
    border-radius: 10px 10px 0 0;
  }
`;

const CardBody = styled.div`
  padding: 16px 20px;
`;

const SectionTitle = styled.h2`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #2c3e50;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Label = styled.span`
  color: #718096;
  font-size: 14px;
`;

const Value = styled.span`
  font-weight: 500;
  color: #2d3748;
`;

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    height: 32px;
    padding: 4px 8px;
    border-radius: 6px;
  }
`;

const PaymentPillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const PaymentPill = styled(Radio.Button)`
  border-radius: 16px !important;
  padding: 1px 10px;
  height: 28px !important;
  font-size: 12px;
  line-height: 26px;
  
  &.ant-radio-button-wrapper {
    border-left-width: 1px !important;
    &::before {
      display: none;
    }
  }
  
  &.ant-radio-button-wrapper-checked {
    background: #1890ff;
    border-color: #1890ff;
    color: white;
    box-shadow: none;
  }
`;

interface InvoiceSummaryProps {
  form: FormInstance;
  totals: {
    subtotal: number;
    total: number;
    chargesTotal: number;
    discountAmount: number;
    taxTotal: number;
    receivedAmount?: number;
    balanceDue?: number;
  };
  productCount: number;
  onValuesChange?: (values: any) => void;
}

export const InvoiceSummary: React.FC<InvoiceSummaryProps> = ({
  form,
  totals,
  productCount,
  onValuesChange
}) => {
  Logger.log('InvoiceSummary: Component rendering');
  Logger.log('InvoiceSummary: Received totals:', totals);
  Logger.log('InvoiceSummary: Product count:', productCount);
  
  useEffect(() => {
    Logger.log('InvoiceSummary: Component mounted/updated');
    Logger.log('InvoiceSummary: Current form values:', form.getFieldsValue());
  }, [form, totals]);
  
  // Function to format currency values
  const formatCurrency = (value: number): string => {
    try {
      return value.toLocaleString('en-IN');
    } catch (error) {
      console.error('InvoiceSummary: Error formatting currency:', error);
      return value.toString();
    }
  };
  
  const handlePaymentChange = () => {
    if (onValuesChange) {
      const values = form.getFieldsValue();
      
      // Ensure receivedAmount is a valid number
      if (values.receivedAmount !== undefined) {
        const numAmount = Number(values.receivedAmount);
        if (isNaN(numAmount) || numAmount < 0) {
          form.setFieldsValue({ receivedAmount: 0 });
          values.receivedAmount = 0;
        }
      }
      
      onValuesChange(values);
    }
  };
  
  return (
    <SummaryWrapper>
      <SummaryCard>
        <CardBody>
          <SectionTitle>Invoice Summary</SectionTitle>
          
          {/* Base Amount */}
          <SummaryRow>
            <Label>Subtotal ({productCount} items)</Label>
            <Value>₹{formatCurrency(totals.subtotal)}</Value>
          </SummaryRow>

          {/* Additional Charges */}
          {totals.chargesTotal > 0 && (
            <SummaryRow>
              <Label>Additional Charges</Label>
              <Value>+₹{formatCurrency(totals.chargesTotal)}</Value>
            </SummaryRow>
          )}
          
          {/* Discount if applied */}
          {totals.discountAmount > 0 && (
            <SummaryRow>
              <Label>Discount Applied</Label>
              <Value style={{ color: '#4CAF50' }}>-₹{formatCurrency(totals.discountAmount)}</Value>
            </SummaryRow>
          )}
          
          {/* Tax amount if any */}
          {totals.taxTotal > 0 && (
            <SummaryRow>
              <Label>Tax Amount</Label>
              <Value>+₹{formatCurrency(totals.taxTotal)}</Value>
            </SummaryRow>
          )}
          
          {/* Total Amount */}
          <SummaryRow style={{ marginTop: 16, paddingTop: 12, borderTop: '1px solid #edf2f7', marginBottom: 10 }}>
            <Label style={{ fontSize: '15px', fontWeight: 600, color: '#2d3748' }}>
              Total Amount
            </Label>
            <Value style={{ fontSize: '16px', color: '#1890ff', fontWeight: 700 }}>
              ₹{formatCurrency(totals.total)}
            </Value>
          </SummaryRow>
          
          {/* Advanced Payment Section - Even More Compact */}
          <SummaryRow style={{ marginTop: 12, marginBottom: 4 }}>
            <Label>Advanced Payment</Label>
            <Form.Item name="receivedAmount" noStyle>
              <StyledInputNumber
                placeholder="₹ 0.00"
                formatter={(value: number | string | undefined) => 
                  value ? `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '₹ '
                }
                parser={(value: string | undefined) => 
                  value ? value.replace(/₹\s?|(,*)/g, '') : ''
                }
                onChange={handlePaymentChange}
                style={{ width: '120px' }}
                min={0}
              />
            </Form.Item>
          </SummaryRow>
          
          {/* Payment Mode Pills - Horizontal Compact Layout */}
          <Form.Item name="receivedPaymentMode" style={{ marginBottom: 10 }}>
            <Radio.Group 
              onChange={handlePaymentChange}
              buttonStyle="solid"
              style={{ width: '100%' }}
            >
              <PaymentPillContainer>
                <PaymentPill value="cash">Cash</PaymentPill>
                <PaymentPill value="card">Card</PaymentPill>
                <PaymentPill value="upi">UPI</PaymentPill>
                <PaymentPill value="cheque">Cheque</PaymentPill>
                <PaymentPill value="bankTransfer">Bank</PaymentPill>
              </PaymentPillContainer>
            </Radio.Group>
          </Form.Item>
          
          {/* Show Balance Due if advance payment is made - More compact */}
          {/* Show only Balance Due if advance payment is made */}
{totals.receivedAmount && totals.receivedAmount > 0 && (
  <SummaryRow style={{ marginTop: 12, paddingTop: 10, borderTop: '1px dashed #edf2f7' }}>
    <Label style={{ fontWeight: 500, color: '#2d3748' }}>
      Balance Due
    </Label>
    <Value style={{ color: '#f56565', fontWeight: 600 }}>
      ₹{formatCurrency(totals.balanceDue || 0)}
    </Value>
  </SummaryRow>
)}
        </CardBody>
      </SummaryCard>
    </SummaryWrapper>
  );
};

export default InvoiceSummary;