import { useEffect, useRef, useState } from "react";
import { 
  Mail, 
  Phone, 
  User, 
  Calendar, 
  Clock, 
  DollarSign, 
  MapPin, 
  AlignLeft, 
  Type, 
  List, 
  ChevronDown, 
  Hash,
  Link,
  Lock,
  Upload,
  Search,
  CheckSquare
} from "lucide-react";

type PropsType = {
  disabled?: boolean;
  name: string;
  className: string;
  type: string;
  value: string | string[];  // Allow string[] for multi-select
  placeholder: string;
  onChange: (e: any) => void;
  label: string;
  autoComplete: string;
  isRequired: boolean;
  options: any;  // Keep as any to maintain backward compatibility
  id: string;
  des?: string;
};

export default function Inputs(Props: PropsType) {
  const { label, isRequired, ...rest } = Props;
  // Add states only for multi-select
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle click outside only for multi-select
  useEffect(() => {
    if (Props.type !== 'multi-selection') return;
    
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [Props.type]);

  // Function to get the appropriate icon based on field type or label
  const getFieldIcon = () => {
    // First check by type (more specific)
    if (Props.type === "name") {
      return <User size={18} className="field-icon" />;
    } else if (Props.type === "email") {
      return <Mail size={18} className="field-icon" />;
    } else if (Props.type === "phone") {
      return <Phone size={18} className="field-icon" />;
    } else if (Props.type === "amount") {
      return <DollarSign size={18} className="field-icon" />;
    } else if (Props.type === "date") {
      return <Calendar size={18} className="field-icon" />;
    } else if (Props.type === "time") {
      return <Clock size={18} className="field-icon" />;
    } else if (Props.type === "location") {
      return <MapPin size={18} className="field-icon" />;
    } else if (Props.type === "text") {
      return <Type size={18} className="field-icon" />;
    } else if (Props.type === "selection") {
      return <List size={18} className="field-icon" />;
    } else if (Props.type === "multi-selection") {
      return <CheckSquare size={18} className="field-icon" />;
    } else if (Props.type === "number") {
      return <Hash size={18} className="field-icon" />;
    } else if (Props.type === "url") {
      return <Link size={18} className="field-icon" />;
    } else if (Props.type === "password") {
      return <Lock size={18} className="field-icon" />;
    } else if (Props.type === "file") {
      return <Upload size={18} className="field-icon" />;
    } else if (Props.type === "search") {
      return <Search size={18} className="field-icon" />;
    }
    
    // Then check by label (for backwards compatibility)
    else if (Props.label === "Client Name" || Props.label === "Name" || Props.label.includes("name")) {
      return <User size={18} className="field-icon" />;
    } else if (Props.label === "Email ID" || Props.label === "Email" || Props.label.toLowerCase().includes("email")) {
      return <Mail size={18} className="field-icon" />;
    } else if (Props.label === "Phone Number" || Props.label.toLowerCase().includes("phone")) {
      return <Phone size={18} className="field-icon" />;
    } else if (Props.label === "Sale Value" || Props.label === "Sales Value" || Props.label.includes("amount")) {
      return <DollarSign size={18} className="field-icon" />;
    } else if (Props.label === "Notes" || Props.label === "Description") {
      return <AlignLeft size={18} className="field-icon" />;
    } else if (Props.label.toLowerCase().includes("address") || Props.label.toLowerCase().includes("location")) {
      return <MapPin size={18} className="field-icon" />;
    }
    
    // Default icon for other types
    return <Type size={18} className="field-icon" />;
  };

  return (
    <>
      <div className="input-field-container">
        {Props.label !== "" && (
          <label className="form-label">
            {label} <span className="text-danger">{isRequired && "*"}</span>
          </label>
        )}
        
        {Props.des ? (
          <p className="fs-8 mb-2" style={{ marginTop: "-6px" }}>
            {Props.des}
          </p>
        ) : null}

        {label !== "Notes" && label !== "Description" ? (
          <>
            {Props.type === "multi-selection" ? (
              <div className="position-relative" ref={dropdownRef}>
                <div 
                  className="form-control d-flex align-items-center justify-content-between cursor-pointer"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className="input-with-icon">
                    {getFieldIcon()}
                    <span className="selected-text">
                      {Array.isArray(Props.value) && Props.value.length > 0
                        ? `${Props.value.length} selected`
                        : Props.placeholder || 'Select options'}
                    </span>
                  </div>
                  <ChevronDown size={18} />
                </div>
                
                {isOpen && Array.isArray(Props.options) && (
                  <div className="dropdown-menu show w-100 p-0 mt-1">
                    {Props.options.map((option, index) => (
                      <div 
                        key={index}
                        className="dropdown-item d-flex align-items-center gap-2 py-2"
                        onClick={() => {
                          const currentValue = Array.isArray(Props.value) ? Props.value : [];
                          const newValue = currentValue.includes(option)
                            ? currentValue.filter(v => v !== option)
                            : [...currentValue, option];
                          
                          Props.onChange({
                            target: {
                              name: Props.name,
                              value: newValue
                            }
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={Array.isArray(Props.value) && Props.value.includes(option)}
                          onChange={() => {}} // Handled by div click
                          className="form-check-input m-0"
                        />
                        <span>{option}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : Props.type !== "selection" ? (
              <div className="input-with-icon-wrapper">
                {getFieldIcon()}
                <input
                  {...rest}
                  className={`${rest.className} input-with-icon`}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
              </div>
            ) : (
              <div className="position-relative input-with-icon-wrapper">
                {getFieldIcon()}
                <select {...rest} className={`${rest.className} input-with-icon`}>
                  <option value="">Select {label}</option>
                  {Props.options?.map((type: any, i: number) => (
                    <option value={type} key={i}>
                      {type}
                    </option>
                  ))}
                </select>
                <ChevronDown size={18} className="position-absolute end-0 top-50 translate-middle-y me-2" />
              </div>
            )}
          </>
        ) : (
          <div className="input-with-icon-wrapper">
            {getFieldIcon()}
            <textarea style={{ height: "120px" }} {...rest} className={`${rest.className} input-with-icon`} />
          </div>
        )}
      </div>
      
      {/* Add these styles to your CSS file instead of using styled-jsx */}
    </>
  );
}