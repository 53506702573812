// contentFunctions.tsx
import { toast } from 'react-hot-toast';
import { ContentI, LogLevel } from './types';
import { deleteLeadFile, cleanupUploads } from "../../services/utilityService";
import { createNewContent, updateContent } from "../../services/contentService";
import { Logger } from '../../utils/logger';

// Logger function
const log = (message: string, level: LogLevel = LogLevel.INFO) => {
  Logger.log(`[ContentFunctions] [${level}] ${message}`);
};

// Simplified handleFormSubmit function that doesn't handle file uploads
// contentFunctions.tsx - complete replacement for handleFormSubmit

export const handleFormSubmit = async (data: any): Promise<any> => {
  Logger.log('🚀 FORM SUBMIT - Starting submission process');
  Logger.log('📦 FORM DATA:', JSON.stringify(data, null, 2));
  
  const isUpdate = data._id && data._id.length > 1;
  Logger.log(`🔍 OPERATION TYPE: ${isUpdate ? 'UPDATE' : 'CREATE'}`);

  // Validate essential fields
  if (!data.type && !data._id) {
    const errorMsg = 'Content type is required for new content';
    console.error('❌ VALIDATION ERROR:', errorMsg);
    toast.error(errorMsg);
    return Promise.reject(new Error(errorMsg));
  }
  
  // Ensure we have the details object with right structure
  if (!data.details) {
    Logger.log('⚠️ Creating empty details object');
    data.details = {}; // Create it if it doesn't exist
  }
  
  // Ensure required fields
  if (!data.details.title) {
    const errorMsg = 'Title is required';
    console.error('❌ VALIDATION ERROR:', errorMsg);
    toast.error(errorMsg);
    return Promise.reject(new Error(errorMsg));
  }
  
  // Standardize the tags field
  if (!data.details.tags || !Array.isArray(data.details.tags) || data.details.tags.length === 0) {
    Logger.log('⚠️ Setting default tag: no_tag');
    data.details.tags = ['no_tag'];
  }
  
  // Standardize the tag field from tags array - critical for API
  data.details.tag = data.details.tag || 
                     (Array.isArray(data.details.tags) ? data.details.tags[0] : null) ||
                     'no_tag';
  
  Logger.log(`📋 CONTENT INFO - Type: ${data.type}, Has ID: ${!!data._id}`);
  Logger.log(`🏷️ Using tag: ${data.details.tag}`);
  
  try {
    let response: any;
    
    if (data._id?.length > 1) {
      Logger.log(`🔄 UPDATING CONTENT - ID: ${data._id}`);
      
      // For updates, create a clean payload without type field
      const updatePayload = {
        
        details: {
          ...data.details,
          // Ensure tag is properly set
          tag: data.details.tag
        }
        // No type field for updates
      };
      
      Logger.log('📤 UPDATE PAYLOAD:', JSON.stringify(updatePayload, null, 2));
      response = await updateContent(updatePayload, data._id);
    } else {
      Logger.log('✨ CREATING NEW CONTENT');
      
      // For new content, include the type field
      const createPayload = {
        details: {
          ...data.details,
          // Ensure tag is properly set
          tag: data.details.tag
        },
        type: data.type
      };
      
      Logger.log('📤 CREATE PAYLOAD:', JSON.stringify(createPayload, null, 2));
      response = await createNewContent(createPayload);
    }
    
    Logger.log(`📥 RESPONSE STATUS: ${response?.status}`);
    
    if (response?.status === 200) {
      const contentId = response.data.data._id || data._id;
      Logger.log(`✅ SUCCESS - Content ${data._id ? 'updated' : 'created'} with ID: ${contentId}`);
      Logger.log('📥 API RESPONSE:', response.data);
      
      toast.success(`Content ${data._id ? 'updated' : 'created'} successfully`);
      return response;
    } else {
      console.error(`❌ API ERROR - Non-200 status: ${response?.status}`);
      console.error('Response details:', response);
      toast.error('Error saving content');
      return null;
    }
  } catch (unknown) {
    const error = unknown as any;
    const errorMessage = error?.message || 'Unknown error occurred';
    console.error('❌ SUBMIT ERROR:', errorMessage);
    console.error('Error details:', error);
    
    if (error.response) {
      console.error('API Response Error:', {
        status: error.response?.status,
        headers: error.response?.headers,
        data: error.response?.data
      });
    }
    
    toast.error('Error saving content');
    throw error;
  }
};

// Function to delete a file by path
export const deleteFileApi = async (path: string, activeId: string, contentData: ContentI[], setContentData: React.Dispatch<React.SetStateAction<ContentI[]>>, closeDrawer: () => void) => {
  log(`Starting deletion for path: ${path}`, LogLevel.INFO);
  
  try {
    const obj = {
      filePaths: [path],
      type: 'content',
      content: activeId
    };
    
    const response = await deleteLeadFile(obj);
    
    if (response.status === 200) {
      log('File deleted successfully from the server', LogLevel.INFO);
      
      // Update content data to reflect the deletion
      const filteredArray = contentData.map((obj: ContentI) => {
        if (obj._id === activeId) {
          return {
            ...obj,
            files: obj.files?.filter((file: any) => file.filePath !== path)
          };
        }
        return { ...obj };
      });
      
      setContentData([...filteredArray]);
      closeDrawer();
      toast.success(response.data.message || 'File deleted successfully');
      return true;
    } else {
      log(`API returned non-200 status: ${response?.status}`, LogLevel.WARNING);
      toast.error('Failed to delete file');
      return false;
    }
  } catch (error) {
    const err = error as Error;
    log(`Error deleting file: ${err.message || String(err)}`, LogLevel.ERROR);
    toast.error('Failed to delete file');
    return false;
  }
};

// Simplified helper function for file deletion
export const handleFileDeletion = async (
    path: string, 
    activeId: string, 
    contentData: ContentI[], 
    setContentData: React.Dispatch<React.SetStateAction<ContentI[]>>
  ) => {
    log(`Starting file deletion: ${path}`, LogLevel.INFO);
    
    try {
      const payload = {
        filePaths: [path],
        type: 'content',
        content: activeId
      };
      
      const response = await deleteLeadFile(payload);
      
      if (response.status === 200) {
        log('File deleted successfully', LogLevel.INFO);
        
        // Update local state
        const updatedContent = contentData.map(item => {
          if (item._id === activeId) {
            return {
              ...item,
              files: item.files?.filter(file => file.filePath !== path) || []
            };
          }
          return item;
        });
        
        setContentData(updatedContent);
        toast.success('File deleted successfully');
        return true;
      } else {
        log(`API returned non-200 status: ${response?.status}`, LogLevel.WARNING);
        toast.error('Failed to delete file');
        return false;
      }
    } catch (exception) {
      const error = exception as Error;
      log(`Deletion error: ${error.message || String(error)}`, LogLevel.ERROR);
      toast.error('Failed to delete file');
      return false;
    }
  };
  
// Cleanup utility
export const cleanupContentFiles = () => {
    log('Cleaning up file operations', LogLevel.INFO);
    cleanupUploads();
  };
  
// Common utility for resetting form data
export const getClearDataFunction = (setters: {
  setActiveId: (id: string) => void,
  setFileData: React.Dispatch<React.SetStateAction<any>>,
  setEmailData: React.Dispatch<React.SetStateAction<any>>,
  setMessageData: React.Dispatch<React.SetStateAction<any>>,
  setPageData: React.Dispatch<React.SetStateAction<any>>
}) => {
  return () => {
    log('Resetting all form data', LogLevel.INFO);
    const { setActiveId, setFileData, setEmailData, setMessageData, setPageData } = setters;
    
    setActiveId('');
    setFileData({
      title: "",
      tags: ["no_tag"],
      files: [],
      description: '',
    });
    setEmailData({
      title: "",
      tags: ["no_tag"],
      subject: "",
      message: "",
    });
    setMessageData({
      title: "",
      tags: ["no_tag"],
      message: "",
    });
    setPageData({
      title: "",
      tags: ["no_tag"],
      description: '',
      videoUrl: '',
      location: {},
      address: '',
      uniqueLinkId: '',
    });
  };
};