import { TableColumnProps } from 'antd';
import { Quotation, Lead } from './Quotation';

export interface QuotationApiResponse {
  _id: string;
  quotationId: string;
  lead: Lead;
  title: string;
  total: number;
  status: string;
  createdBy: string;
  createdAt: string;
  createdByName: string;
  quotationUrl?: string;
}

export interface ApiResponse {
  data: QuotationApiResponse[];
  total: number;
  quotationStatus: Array<{
    type: string;
    count: number;
    total: number;
  }>;
}

export interface AuthState {
  user?: {
    _id: string;
    bucketUrl: string;
  };
}

export interface RootState {
  auth: AuthState;
}

export interface StatusCardProps {
  type: string;
  count: number;
  total: number;
  isSelected: boolean;
  onSelect: (type: string | null) => void;
}

// Safely get custom status colors from localStorage
const getCustomStatusColors = () => {
  try {
    const userPrefs = localStorage.getItem("user_preferences");
    if (!userPrefs) return null;
    
    const colors = JSON.parse(userPrefs)?.quotationCustomization?.quotationStatus;
    if (!Array.isArray(colors)) return null;
    
    return colors;
  } catch (error) {
    console.error('Error loading custom colors:', error);
    return null;
  }
};

// Generate a consistent color based on string input
const generateColorFromString = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Generate a color with good contrast
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  
  return color;
};

export const getStatusColor = (status: string): string => {
  if (!status) return '#8c8c8c'; // Default gray for empty status

  // Get custom colors
  const customColors = getCustomStatusColors();
  
  // Find matching custom color
  if (customColors) {
    const customColor = customColors.find(
      (s: any) => s.value.toLowerCase() === status.toLowerCase()
    )?.color;
    
    if (customColor) return customColor;
  }
  
  // If no custom color found, use the consistent generated color
  return generateColorFromString(status);
};

export const isValidStatus = (status: string | undefined): status is Quotation['status'] => {
  if (!status) return false;
  return ['pending', 'overdue', 'created', 'approved', 'partially_paid', 'fully_paid', 'ff'].includes(status);
};