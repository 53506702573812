import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { Toggle } from "rsuite";
import toast, { Toaster } from "react-hot-toast";
import EmptyState from "./EmptyState";
import {
  getAutomationList,
  deleteAutomation,
  updateAutomation,
} from "../../services/automationService";
import { Spin } from "antd";
import moment from "moment";
import { MdOndemandVideo, MdOutlineAdd, MdOutlineHelp } from "react-icons/md";
import { getTemplates } from "../../services/templates";
import { getContentByType } from "../../services/contentService";
import AutomationRuleWrapper from "./AutomationRuleWrapper";
// Import the CSS
import "./AutomationRuleWrapper.css";
import UltimatePlanInfo from "../../components/UltimatePlanInfo/UltimatePlanInfo";
import { Logger } from '../../utils/logger';

interface AutomationItem {
  _id: string;
  name: string;
  description: string;
  isActive: boolean;
  lastRunAt: string | null;
  totalNoOfRun: number;
  totalAction: number;
  totalActionCompleted: number;
  totalActionFailed: number;
}

const Automation: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [showRuleEditor, setShowRuleEditor] = useState<boolean>(false);
  const [automationList, setAutomationList] = React.useState<AutomationItem[]>([]);
  const [updateAutomationDetail, setUpdateAutomationDetail] = React.useState<any>({});
  const [isUpdateAuto, setIsUpdateAuto] = useState<boolean>(false);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [whatsappTemplate, setWhatsappTemplate] = useState<any>([]);
  const [emailTemplate, setEmailTemplate] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("");

  const [isUltimate, setIsUltimate] = useState<boolean | null>(null);
  
  useEffect(() => {
    async function handleDataFetch() {
      await handleGetAutomationList();
      await fetchWhatsappTemplated();
      await getEmailTemplate();
    }

    handleDataFetch();
  }, []);

  useEffect(() => {
    if (searchType === "Send Email") {
      getEmailTemplate();
    } else if (searchType === "Send Whatsapp") {
      fetchWhatsappTemplated();
    }

    if (!searchValue && !searchType) {
      getEmailTemplate();
      fetchWhatsappTemplated();
    }
  }, [searchValue, searchType]);

  useEffect(() => {
    const local = window.localStorage?.getItem("userData");
    if (local) {
      const userData = JSON.parse(local);
      setIsUltimate(userData?.subscriptionPlanType?.toLowerCase() === "ultimate");
    }
  }, []);

  const fetchWhatsappTemplated = async () => {
    const payload: any = {
      page: 1,
      perPage: 2000,
    };

    if (searchValue) {
      payload.search = searchValue;
    }

    const { data } = await getTemplates(payload);

    setWhatsappTemplate(data.data);
  };

  const getEmailTemplate = async () => {
    try {
      let response: any;

      if (searchValue) {
        response = await getContentByType("email_template", {
          search: searchValue,
        });
      } else {
        response = await getContentByType("email_template");
      }
      const data = response.data?.data;
      setEmailTemplate(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleGetAutomationList = async () => {
    try {
      setIsPageLoad(true);
      const response = await getAutomationList();
      setIsPageLoad(false);
      if (response && response.status) {
        setAutomationList(response.data.data);
      }
    } catch (err) {
      setIsPageLoad(false);
      Logger.log(err, "Error");
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e?.currentTarget?.value);
  };

  const toggleRuleEditor = () => {
    setShowRuleEditor(!showRuleEditor);
    
    // If we're closing the editor, reset update state
    if (showRuleEditor) {
      setIsUpdateAuto(false);
      setUpdateAutomationDetail({});
    }
  };

  const editRule = (data: any) => {
    setUpdateAutomationDetail(data);
    setIsUpdateAuto(true);
    setShowRuleEditor(true);
  };

  const handleRuleToggle = async (data: any) => {
    try {
      let response = await updateAutomation(data._id, {
        isActive: !data.isActive,
      });
      if (response && response.status) {
        toast.success(response?.data?.message);
        handleGetAutomationList();
      }
    } catch (err) {
      toast.error("error while update rule");
    }
  };

  const onAutomationDelete = async (id: string) => {
    try {
      const res = await deleteAutomation(id);
      if (res && res.status) {
        toast.success(res?.data?.message);
        handleGetAutomationList();
        setShowRuleEditor(false);
      }
    } catch (err) {
      toast.error("error while deleting rule.");
    }
  };

  const onTemplateSearch = (value: string, type: string) => {
    Logger.log("value>>", value, type);
    setSearchType(type);
    setSearchValue(value);
  };

  const onResetTemplates = () => {
    setSearchType("");
    setSearchValue("");
  };

  if (isUltimate === false) { // Explicitly check for false
    return (
      <div id="main" className="main">
        <Header />
        <UltimatePlanInfo />
      </div>
    );
  }

  return (
    <div id="main" className="main">
      <Header onSearch={(e: any) => onSearch(e)} />
      
      {showRuleEditor ? (
        // Show the rule editor in the main content area
        <AutomationRuleWrapper
          autoRuleDrawerToggle={toggleRuleEditor}
          onAutomationDelete={onAutomationDelete}
          updateAutomationDetail={updateAutomationDetail}
          isUpdateAuto={isUpdateAuto}
          handleGetAutomationList={handleGetAutomationList}
          emailTemplate={emailTemplate}
          whatsappTemplate={whatsappTemplate}
          onSearch={onTemplateSearch}
          searchValue={searchValue}
          resetTemplates={onResetTemplates}
        />
      ) : (
        // Show the automation list
        <>
          <section className="auto-section-1">
            <label>
              Automation Rules ({automationList.length})
            </label>
            <div className="auto-section-1-actions">
              <a 
                href="https://help.3sigmacrm.com/automation/automations" 
                target="_blank" 
                rel="noopener noreferrer"
                className="help-btn"
              >
                <MdOutlineHelp size={18} />
                Help
              </a>
              <a 
                href="https://youtu.be/McvKjl6jurs" 
                target="_blank" 
                rel="noopener noreferrer"
                className="help-btn"
              >
                <MdOndemandVideo size={18} />
                Video
              </a>
              <button
                className="btn fw-bold auto_btn"
                type="button"
                onClick={toggleRuleEditor}
              >
                <MdOutlineAdd size={20} />
                New rule
              </button>
            </div>
          </section>
        
          <Spin size="large" tip="Loading..." spinning={isPageLoad}>
            <section className="auto-section-2">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Rule Name</th>
                    <th>Last Run</th>
                    <th>No of Runs</th>
                    <th>Completed</th>
                    <th>❌ Errors</th>
                    <th>Leads handled</th>
                    <th>🎯 Worked</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {automationList.length > 0 ? (
                    automationList.map((item: any) => {
                      const timeAgo = !item.metrics?.lastRunAt ? 'Never run' : moment(item.metrics.lastRunAt).fromNow();
const successRate = item.metrics?.totalActions > 0
  ? Math.round((item.metrics.completedActionsCount / item.metrics.totalActions) * 100)
  : 0;
        
                      return (
                        <tr 
                          key={item._id} 
                          onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                            // Check if click target is the toggle switch
                            const target = e.target as HTMLElement;
                            if (target.closest('.rs-toggle') || target.classList.contains('rs-toggle')) {
                              return;
                            }
                            
                            Logger.log('Opening rule for editing:', item.name);
                            editRule(item);
                          }}
                          className="clickable-row"
                        >
                          <td>
                            <div className="name-cell">
                              <div className="primary text-truncate" title={item.name}>
                                {item.name}
                              </div>
                              <div className="secondary text-truncate" title={item.description}>
                                {item.description}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="last-run-cell">
                              <i className="bi bi-lightning-fill"></i>
                              {timeAgo}
                            </div>
                          </td>
                          <td className="number-cell">{item.metrics?.totalRuns || 0}</td>           
<td className="number-cell">{item.metrics?.completedActionsCount || 0}</td>   
<td className="number-cell">{item.metrics?.failedActionsCount || 0}</td>      
<td className="number-cell">{item.metrics?.totalLeads || 0}</td>             
                          <td className="number-cell">{successRate}%</td>
                          
                          <td>
                            <Toggle
                              checked={item.isActive}
                              onClick={(e: React.MouseEvent) => {
                                e.stopPropagation(); // Prevent row click
                              }}
                              onChange={() => {
                                Logger.log('Toggling rule status:', item.name);
                                handleRuleToggle(item);
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <EmptyState
                          onCreateNew={toggleRuleEditor}
                          isPageLoad={isPageLoad}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
          </Spin>
        </>
      )}
      
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Automation;