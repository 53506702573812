import React, { useCallback } from 'react';
import { Form, Input, Button } from 'antd';
import { Trash2, Plus, Minus } from 'lucide-react';

import type { FormInstance } from 'antd/lib/form';
import { Logger } from '../../utils/logger';

interface ProductsListProps {
  form: FormInstance;
  onValuesChange: (values: any) => void;
}

export const ProductsList: React.FC<ProductsListProps> = ({
  form,
  onValuesChange,
}) => {
  // Console logging for debugging
  Logger.log('ProductsList: Component rendering');
  
  const handleQuantityChange = useCallback((index: number, value: number) => {
    Logger.log('ProductsList: Quantity changing', {index, value});
    
    // Changed to use whole numbers by default, minimum 1
    if (value >= 1) {
      // Now we use the value as is without rounding to decimals
      form.setFieldValue(['products', index, 'quantity'], value);
      onValuesChange(form.getFieldsValue());
    }
  }, [form, onValuesChange]);

  // Helper to format large numbers in Indian format (handles crores and lakhs)
  const formatIndianCurrency = (amount: number) => {
    Logger.log('ProductsList: Formatting amount', amount);
    if (!amount && amount !== 0) return '';
    
    // Format number in Indian numbering system (lakhs, crores)
    return amount.toLocaleString('en-IN');
  };

  // Get total number of products for the counter
  const getProductCount = () => {
    const products = form.getFieldValue('products') || [];
    Logger.log('ProductsList: Product count', products.length);
    return products.length;
  };

  return (
    <div className="products-section">
      {/* Product Counter Badge */}
      <div style={{ marginBottom: '12px' }}>
        <span style={{ fontWeight: 500 }}>Products ({getProductCount()})</span>
      </div>
      
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <>
            {/* Table Headers */}
            <div className="row g-2 border-bottom py-2">
              <div className="col-5">
                <span className="text-secondary small">Product Details</span>
              </div>
              <div className="col-2 text-center">
                <span className="text-secondary small">Quantity</span>
              </div>
              <div className="col-2 text-center">
                <span className="text-secondary small">Unit Price</span>
              </div>
              <div className="col-2 text-end">
                <span className="text-secondary small">Total</span>
              </div>
              <div className="col-1 text-end">
                <span className="text-secondary small"></span>
              </div>
            </div>

            {/* Product Rows */}
            {fields.map((field, index) => {
              const values = form.getFieldsValue().products?.[index] || {};
              const total = (values.quantity || 0) * (values.unitPrice || 0);
              Logger.log('ProductsList: Rendering product row', {index, values, total});

              return (
                <div key={field.key} className="row g-2 border-bottom py-3 align-items-center">
                  {/* Product Details */}
                  <div className="col-5">
                    <div className="product-details">
                    <div className="mb-1 fw-medium">
  {values.product?.name || values.name || 'Product Name'}
</div>
                      {values.description && (
                        <div className="text-secondary small text-truncate">
                          {values.description}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Quantity - Improved */}
                  <div className="col-2">
                    <div className="d-flex align-items-center justify-content-center">
                      <Button
                        type="text"
                        onClick={() => handleQuantityChange(field.name, Math.max(1, (values.quantity || 1) + 1))}
                        style={{ border: 'none', background: 'none', padding: 0, marginRight: '8px', height: '24px', width: '24px' }}
                      >
                        <Plus size={14} />
                      </Button>
                      
                      <Form.Item
                        {...field}
                        name={[field.name, 'quantity']}
                        className="mb-0"
                        style={{ marginBottom: 0 }}
                      >
                        <Input
                          type="number"
                          min={1}
                          style={{ 
                            width: '50px', 
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                            border: '1px solid #d9d9d9',
                            borderRadius: '6px',
                            height: '32px'
                          }}
                          onChange={(e) => handleQuantityChange(field.name, parseFloat(e.target.value) || 1)}
                        />
                      </Form.Item>

                      <Button
                        type="text"
                        onClick={() => handleQuantityChange(field.name, Math.max(1, (values.quantity || 1) - 1))}
                        disabled={values.quantity <= 1}
                        style={{ border: 'none', background: 'none', padding: 0, marginLeft: '8px', height: '24px', width: '24px' }}
                      >
                        <Minus size={14} />
                      </Button>
                    </div>
                  </div>

                  {/* Unit Price - Improved */}
                  <div className="col-2">
                    <Form.Item
                      {...field}
                      name={[field.name, 'unitPrice']}
                      className="mb-0"
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        type="text"
                        prefix="₹"
                        style={{ 
                          backgroundColor: 'transparent', 
                          border: '1px solid #d9d9d9',
                          borderRadius: '6px',
                          textAlign: 'right',
                          height: '32px'
                        }}
                        onChange={(e) => {
                          // Keep the raw input value including the decimal point
                          const inputValue = e.target.value.replace(/[^0-9.]/g, '');
                          
                          // Set the raw value first to preserve input experience
                          const fieldPath = ['products', field.name, 'unitPrice'];
                          
                          // Allow typing decimal points
                          if (inputValue === '.') {
                            form.setFieldValue(fieldPath, inputValue);
                          } else if (inputValue === '') {
                            form.setFieldValue(fieldPath, 0);
                          } else {
                            // Only convert to float for calculation
                            form.setFieldValue(fieldPath, inputValue);
                          }
                          
                          onValuesChange(form.getFieldsValue());
                        }}
                      />
                    </Form.Item>
                  </div>

                  {/* Total - Improved with better formatting */}
                  <div className="col-2 text-end">
                    <span className="fw-medium" style={{ fontSize: '1rem', color: '#1f2937' }}>
                      ₹{formatIndianCurrency(total)}
                    </span>
                  </div>

                  {/* Delete - Moved to far right */}
                  <div className="col-1 text-end">
                    <Button
                      type="text"
                      onClick={() => {
                        Logger.log('ProductsList: Removing product', field.name);
                        remove(field.name);
                        onValuesChange(form.getFieldsValue());
                      }}
                      style={{ 
                        color: '#ff4d4f', 
                        border: 'none', 
                        background: 'none', 
                        padding: 0,
                        height: '24px',
                        width: '24px'
                      }}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </div>
                </div>
              );
            })}
            {/* Removed Add Product button from bottom */}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ProductsList;