import React, { useState, ChangeEvent, KeyboardEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { sendMessageToLead, uploadMedia } from "../../services/whatsapp";
import { useSelector } from "react-redux";
import { IoMdAttach } from "react-icons/io";
import {
  DOC_SIZE_LIMIT,
  IMAGE_SIZE_LIMIT,
  VIDEO_SIZE_LIMIT
} from "../template/createTemplate/headerSection/whatsapp-media-validator";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { isImage, isVideo } from "../../utils/helpers";
import { Spin } from "antd";
import { Logger } from '../../utils/logger';

const TypingActionArea: React.FC<any> = (props) => {
  const [reply, setReply] = useState<string>("");
  const userInfo = useSelector((state: any) => {
    return state?.rootReducers?.userData?.userDetails ?? {};
  });
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const phoneId =
    userInfo?.userIntegrations?.find(
      (e: any) => e?.integration?.key === "whatsapp_business"
    )?.details?.[0]?.phoneNumberId ?? "";

  // const phoneId = "359836737206513";

  // Logger.log(
  //   "phoenif>>",
  //   userInfo?.userIntegrations?.find(
  //     (e: any) => e?.integration?.key === "whatsapp_business"
  //   )?.details
  // );

  const sendMessage = async () => {
    if (!reply.trim() && !selectedFile) return;

    setLoading(true);

    if (selectedFile) {
      const formData = new FormData();

      formData.append("files", selectedFile);
      // formData.append("isTemplateFile", "false");

      const response = await uploadMedia(formData);

      if (response?.data) {
        const payload: any = {
          phone: props.users.mobile,
          phoneId,
        };

        if (isImage(selectedFile.name)) {
          payload.type = "image";
          payload.image = {
            id: response?.data?.data[0]?.uploadedId,
          };
        } else if (isVideo(selectedFile.name)) {
          payload.type = "video";
          payload.video = {
            id: response?.data?.data[0]?.uploadedId,
          };
        } else {
          payload.type = "document";
          payload.document = {
            id: response?.data?.data[0]?.uploadedId,
          };
        }

        await sendMessageToLead(payload);
        setLoading(false);
        setSelectedFile(null);
      }
    }

    if (!reply.trim()) return;

    setLoading(true);

    props.setInputText(reply);

    const data = {
      phone: props.users.mobile,
      type: "text",
      phoneId,
      text: { body: reply },
    };

    setReply("");

    try {
      await sendMessageToLead(data);
      setLoading(false);

      props.setUpdateList((prevState: boolean) => !prevState);
    } catch (error) {
      setLoading(false);

      console.error("Error sending message:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <>
      {selectedFile && (
        <FileNameContainer>
          {selectedFile?.name}{" "}
          <IoClose size={16} onClick={() => setSelectedFile(null)} />
        </FileNameContainer>
      )}
      <Container>
        <StyledTextarea
          placeholder="Type to send reply"
          value={reply}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            setReply(e.target.value)
          }
          onKeyDown={handleKeyDown}
        />
        {/* Attach file */}
        <FileContainer>
          <label htmlFor="fileInput" className="fw-light">
            <IoMdAttach size={22} />
          </label>
          <input
            disabled={selectedFile}
            accept={".jpeg,.png,.mp4,.3gp,.pdf"}
            type="file"
            id="fileInput"
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
            className="h-45 "
            style={{ display: "none" }}
            onChange={(e: any) => {
              const file = e.target.files[0];

              if (isImage(file.name) && file.size > IMAGE_SIZE_LIMIT) {
                toast.error("Please select file less than 5 MB size");
                return;
              }

              if (isVideo(file.name) && file.size > VIDEO_SIZE_LIMIT) {
                toast.error("Please select file less than 16 MB size");
                return;
              }

              if (
                !isImage(file.name) &&
                !isVideo(file.name) &&
                file.size > DOC_SIZE_LIMIT
              ) {
                toast.error("Please select file less than 16 MB size");
                return;
              }

              setSelectedFile(file);
            }}
          />
        </FileContainer>

        <SendButton
          loading={loading}
          onClick={sendMessage}
          disabled={!reply.trim() && !selectedFile}
        >
          {loading ? (
            <Spin size="small" />
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} />
          )}
        </SendButton>
      </Container>
    </>
  );
};

export default TypingActionArea;

const Container = styled.div`
  background-color: #f0f2f5;
  border-radius: 8px;
  padding: 8px;
  position: relative;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  min-height: 40px;
  max-height: 120px;
  padding: 10px;
  padding-right: 40px;
  border: none;
  border-radius: 18px;
  background-color: #ffffff;
  font-size: 14px;
  resize: vertical;
  &:focus {
    outline: none;
  }
`;

const SendButton = styled.button<{ loading: boolean }>`
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: ${(props) => (props.loading ? `white` : `#0084ff`)};
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:disabled {
    background-color: #e4e6eb;
    cursor: not-allowed;
  }
`;

const FileContainer = styled.div`
  position: absolute;
  bottom: 25px;
  right: 62px;
  label {
    cursor: pointer;
  }
`;

const FileNameContainer = styled.div`
  background-color: #ebf0f4;
  border-radius: 5px;
  padding: 4px 5px;
  width: fit-content;
  margin: 10px 2px;
  font-family: "Gilroy-Semibold";
  font-size: 12px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 6px;
    cursor: pointer;
  }
`;
