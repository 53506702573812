import React, { useEffect, useState } from "react";
import FormTable from "./FormTable";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Header from "../../components/header";
import DrawerComponent from "../../components/drawer";
import FormFilter from "./formFilter";
import { useParams } from "react-router-dom";
import { deleteUserForm, publishForm } from "../../services/formService";
import toast, { Toaster } from "react-hot-toast";
import { Spin } from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";
import copy from "copy-to-clipboard";
import ConfirmationModal from "../../components/confirmationModal";
import DynamicConfirmationModal from "../../components/DynamicConfirmationModal";
import styled from "styled-components";
import { Logger } from '../../utils/logger';
type propType = {
  onhandleEdit: (e: any) => void;
  handleActiveForm: (e: any) => void;
  handleFormDelete: (e: any) => void;
  formDetail: object;
};
const UserForm: React.FC<propType> = ({
  formDetail,
  onhandleEdit,
  handleActiveForm,
  handleFormDelete,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);
  const [showLabelPopup, setShowLabelPopup] = useState<boolean>(false);
  const [showStatusPopup, setShowStatusPopup] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<Array<string>>([]);
  const [formData, setFormData] = useState<any>({});
  const [publishConfirmation, setPublishConfirmation] = useState<any>(false);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);

  const params = useParams();

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleTabOnchange = (i: any) => {};
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = () => {
    setIsOpen(!isOpen);
  };

  const toggleLeadFilterDrawer = () => {
    setShowFilterDrawer(true);
  };
  const onLabelClick = () => {
    setShowLabelPopup(true);
  };

  const onStatusClick = () => {
    setShowStatusPopup(true);
  };

  const LeadFilterDataList = async (objectFilterData: any) => {
    // try {
    //   setSearchFilterDataStore(objectFilterData);
    //   if (objectFilterData) {
    //     if (totalLead >= 10 || 0 < 10) {
    //       let increasePage = currentPage;
    //       if (increasePage === currentPage) {
    //         increasePage = increasePage + 1;
    //       }
    //       let finalObj = {
    //         ...objectFilterData,
    //         paginationParams: {
    //           page: currentPage,
    //           perPage: 10,
    //         },
    //       };
    //       const response = await filterLeads(finalObj);
    //       if (response && response.status) {
    //         let responseData = response?.data;
    //         let tempArray: any =
    //           responseData.page === 1 ? [] : [...leadListData];
    //         tempArray.push.apply(tempArray, responseData.data);
    //         setLeadCounts(responseData.total);
    //         setLeadListData(tempArray);
    //         setCurrentPage(increasePage);
    //         setTotalLead(tempArray?.length);
    //         setLeadCounts(responseData.total);
    //         dispatch(setLeads(responseData.data));
    //         setIsFilterData(true);
    //         setShowFilterDrawer(false);
    //       }
    //     }
    //   }
    // } catch (err) {
    //   toast.error("Error while filtering leads");
    // }
  };

  const handleDeleteUserForm = async (id: string) => {
    try {
      setIsPageLoad(true);
      const res = await deleteUserForm(id);
      if (res && res.status) {
        handleFormDelete(id);
        toast.success(res?.data?.message);
        setIsPageLoad(false);
      }
    } catch (err) {
      setIsPageLoad(false);
      toast.error("error while deleting rule.");
    }
  };
  const copyData = () => {
    copy(formData.uniqueLink);
    // toast.success("Link Copied to Clipboard");
  };
  const publishUserForm = async (id: string) => {
    try {
      const data = {
        status: "published",
      };
      setIsPageLoad(true);
      const res = await publishForm(data, id);
      if (res && res.status) {
        const updateData = { ...formDetail, status: "published" };
        setPublishConfirmation(false);
        setFormData(updateData);
        toast.success(res?.data?.message);
        setIsPageLoad(false);
      }
    } catch (err) {
      setIsPageLoad(false);
      toast.error("Error while Edit Form.");
    }
  };

  const handleDrawerClick = () => {
    handleDeleteUserForm(formData._id);
  };

  const toggleEditModal = () => {
    setShowExitModal(!showExitModal);
  };

  useEffect(() => {
    if (formDetail) {
      setFormData(formDetail);
    }
  }, [formDetail]);
  return (
    <Root>
      <Spin size="large" tip="Loading..." spinning={isPageLoad}>
        <div className="mt-2">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <h4
                className="btn  text-capitalize"
                style={{ fontSize: "24px", padding: "0px" }}
              >
                {formData.name}
              </h4>
              <p>
                Created by {formData.createdBy} on{" "}
                {moment(formData.createdAt).format("Do MMM YYYY")} at{" "}
                {moment(formData.createdAt).format("hh:mm A")}
              </p>
            </div>
            <div className="col-md-8 col-sm-12">
              <ul
                className="nav mt-2 justify-content-end d-flex"
                style={{ gap: "10px" }}
              >
                <div className="d-flex">
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle
                      caret
                      className="text-dark bg-light border-0"
                    >
                      {" "}
                      <img
                        alt="img"
                        src="assets/img/setting.png"
                        title="setting"
                        className="cursor-pointer "
                      />{" "}
                      Options
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* <DropdownItem onClick={()=>{copyData()}}><img src='assets/img/copy.png' style={{ width: '12px', marginRight: '10px' }} />Copy Link</DropdownItem> */}
                      {/* <DropdownItem><img src='assets/img/edit3.png' style={{ width: '12px', marginRight: '10px' }} /> Rename</DropdownItem> */}
                      <DropdownItem
                        onClick={() => {
                          setShowExitModal(true);
                        }}
                      >
                        <img
                          src="assets/img/delete.png"
                          style={{ width: "12px", marginRight: "10px" }}
                        />
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <button
                  className="ml-2 cursor btn btn-light border rounded"
                  onClick={() => {
                    copyData();
                  }}
                >
                  <img
                    alt="img"
                    src="assets/img/copy.png"
                    title="Copy link"
                    className="cursor-pointer "
                    style={{ width: "12px", marginRight: "10px" }}
                  />
                  Copy Link
                </button>
                <button
                  className="ml-2 cursor btn btn-light border rounded"
                  onClick={() => {
                    onhandleEdit(true);
                  }}
                >
                  <img
                    alt="img"
                    src="assets/img/edit2.png"
                    title="Edit"
                    className="cursor-pointer "
                  />{" "}
                  Edit form
                </button>
                <button className="ml-3 cursor btn btn-light border rounded">
                  <NavLink
                    to={`/form/${formData.uniqueCode}`}
                    target="_blank"
                    className="text-dark"
                  >
                    <img
                      alt="img"
                      src="assets/img/preview.png"
                      title="Preview"
                      className="cursor-pointer "
                    />{" "}
                    Preview
                  </NavLink>
                </button>
                <button
                  className="ml-3 cursor btn btn-light border rounded"
                  onClick={() => {
                    setPublishConfirmation(true);
                  }}
                  disabled={formData.status == "Published" ? true : false}
                >
                  <img
                    alt="img"
                    src="assets/img/world.png"
                    title="Publish"
                    className="cursor-pointer "
                  />{" "}
                  Publish
                </button>
              </ul>
              <p className="justify-content-end d-flex mt-1 ">
                {formData.status == "draft"
                  ? "In Draft"
                  : formData.status == "published"
                  ? `Published on ${moment(
                      formData.publishedLog?.publishedAt
                    ).format("Do MMM YYYY")} at ${moment(
                      formData.publishedLog?.publishedAt
                    ).format("hh:mm A")}`
                  : ""}{" "}
              </p>
            </div>
          </div>
          <div className="row mt-4">
            {/* <p className="font-weight-light" style={{ fontSize: '18px' }}>Saving responses to default {formData.listName} list. <button className="text-primary">Change</button></p> */}
          </div>
          <div className="row mt-3">
            <div className=" d-flex">
              <button
                className="form_tab "
                onClick={() => handleTabOnchange("lead")}
              >
                <h5 className="alert-heading">Responses</h5>
                <h5 className="alert-heading">{formData.totalResponse}</h5>
              </button>
              {/* <button
                                className="form_tab"
                                onClick={() => handleTabOnchange("lead")}
                            >
                                <h5 className="alert-heading">Unique views</h5>
                                <h5 className="alert-heading">{totalLeadCount}</h5>
                            </button>
                            <button
                                className="form_tab"
                                onClick={() => handleTabOnchange("lead")}
                            >
                                <h5 className="alert-heading">Completion rate</h5>
                                <h5 className="alert-heading">{totalLeadCount}</h5>
                            </button> */}
            </div>
          </div>
          <hr className="divider" />
          <ul className="nav mt-2 justify-content-between d-flex">
            <li>
              <button className="mx-2 cursor btn btn-light border rounded p-[10px]">
                Responses ({formData.totalLeads})
              </button>
            </li>
            <li>
              <div
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "5px",
                  height: "36px",
                  marginRight: "10px",
                }}
                className="d-flex justify-content-center align-items-center px-2"
              >
                {/* {isOpen ? (
                                    <div className="d-flex justify-content-center align-items-center cursor bg-light px-2 border rounded">
                                        <i className="fa fa-search cursor-pointer"
                                            aria-hidden="true"
                                        ></i>
                                        <input
                                            type="text"
                                            placeholder="Type to search..."
                                            className="form-control  background-transparent"
                                            style={{
                                                outline: "none",
                                                border: "none",
                                                backgroundColor: "transparent",
                                            }}
                                            onChange={(e) => Logger.log(e)}
                                        />
                                        <i
                                            className="fa fa-times pe-2 cursor-pointer"
                                            aria-hidden="true"
                                            onClick={handleChange}
                                        ></i>
                                    </div>
                                ) : (
                                    <button className="mx-2 cursor btn btn-light border rounded" onClick={handleChange}>
                                        <i className="fa fa-search cursor-pointer"
                                            aria-hidden="true"
                                        ></i> Search
                                    </button>
                                )} */}

                {/* <div
                                    style={{ backgroundColor: "#EBF0F4", borderRadius: "5px" }}
                                    className="p-2"
                                >
                                    <button className="mx-2 cursor btn btn-light border rounded" onClick={toggleLeadFilterDrawer}>
                                        <img
                                            alt="img"
                                            onClick={toggleLeadFilterDrawer}
                                            src="assets/img/filterBlack.png"
                                            title="filter"
                                            id="filter-img"
                                            className="cursor-pointer "
                                        /> Filter
                                    </button>

                                </div> */}
              </div>
            </li>
          </ul>
          <FormTable tableData={formData} />
        </div>
      </Spin>

      <DrawerComponent
        openWithHeader={showFilterDrawer}
        setOpenWithHeader={setShowFilterDrawer}
        drawerTitle="User Form"
        size="xs"
      >
        <FormFilter
          onLabelClick={onLabelClick}
          onStatusClick={onStatusClick}
          selectedStatus={selectedStatus}
          selectedLabel={selectedLabel}
          setShowFilterDrawer={setShowFilterDrawer}
          LeadFilterDataList={LeadFilterDataList}
        />
      </DrawerComponent>

      <ConfirmationModal
        message="Are you sure you want to delete this task?"
        onConfirmation={handleDrawerClick}
        showModal={showExitModal}
        toggleModal={toggleEditModal}
        title="Form"
      />
      <DynamicConfirmationModal
        onConfirmation={() => publishUserForm(formData._id)}
        showModal={publishConfirmation}
        toggleModal={() => {
          setPublishConfirmation(false);
        }}
        title="Are you sure to want to publish this form"
      />
      <Toaster position="top-center" reverseOrder={false} />
    </Root>
  );
};

export default UserForm;
const Root = styled.div``;
