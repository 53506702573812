import { Fragment, useEffect, useState } from "react";
import styles from "./bulkLeadOptions.module.scss";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RxCopy } from "react-icons/rx";
import DrawerExistModal from "../drawerExistModal";
import GlobalDrawer from "../GlobalDrawer";
import { useSelector } from "react-redux";
import { getAllLeadList } from "../../services/leadListService";
import { MdOutlineAddTask } from "react-icons/md";
import { BsMegaphone } from "react-icons/bs";
import { getAutomationList } from "../../services/automationService";
import { FileDown } from "lucide-react";
import { Logger } from '../../utils/logger';

interface IProps {
  onDeleteLead: () => void;
  handleAssignLead: (teamId: string) => void;
  handleCopyLead: (leadId: string, option: string) => void;
  onEditStatus: () => void;
  onEditLabel: () => void;
  onBulkTask: () => void;
  onExportLeads: () => void;
  isTask?: boolean;
  onCreateCampaign?: () => void;
  handleSelectAutomate?: (id: string) => void;
}

const BulkLeadOptions = ({
  onDeleteLead,
  handleAssignLead,
  handleCopyLead,
  onEditStatus,
  onEditLabel,
  onExportLeads,
  onBulkTask,
  isTask = false,
  onCreateCampaign,
  handleSelectAutomate,
}: IProps) => {
  const [showExitModal, setExitModal] = useState(false);
  const [assignLeadDrawer, setAssignLeadDrawer] = useState(false);
  const [copyLeadDrawer, setCopyLeadDrawer] = useState(false);
  const [activeLeadOption, setActiveLeadOption] = useState("copy");
  const [leadListData, setLeadListData] = useState<any>([]);
  const [automationList, setAutomationList] = useState<any>([]);
  const [automationDrawer, setAutomationDrawer] = useState(false);

  const StateData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const teamMembers =
  StateData?.userData?.userDetails?.organizationEmployee
  ?.filter((item: any) => 
    item?._id !== StateData?.userData?.userDetails?._id && // Exclude current user
    item?.isActive === true // Only active users
  )
  .map((item: any) => ({
    value: item?._id, 
    label: `${item?.firstName} ${item?.lastName}`, // Space between names
    role: item?.role // Include role for additional display
  }));

  const toggleEditModal = () => setExitModal(!showExitModal);

  useEffect(() => {
    handleGetLeadList();
    handleGetAutomationList();
  }, []);

  const handleGetLeadList = async () => {
    try {
      const response = await getAllLeadList({
        isAscending: false,
        page: 1,
        perPage: 50,
      });
      if (response && response.status) {
        let defaultLead = {
          name: "Default lead list",
          _id: "",
        };
        setLeadListData([defaultLead, ...response.data.data]);
      }
    } catch (err) {
      Logger.log(err, "Error");
    }
  };

  const handleGetAutomationList = async () => {
    try {
      const response = await getAutomationList();

      if (response && response.status) {
        setAutomationList(response.data.data);
      }
    } catch (err) {
      Logger.log(err, "Error");
    }
  };

  return (
    <Fragment>
      {isTask ? (
        <div
          className={styles.selection_container}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <button onClick={toggleEditModal}>
            <RiDeleteBin5Line color="#DF3737" />
            Delete
          </button>
          <button onClick={() => setAssignLeadDrawer(true)}>
            <img src="/assets/img/assign.png" />
            Bulk assign
          </button>
          <button
            onClick={() => {
              setCopyLeadDrawer(true);
              setActiveLeadOption("copy");
            }}
          >
            <RxCopy />
            Update due date
          </button>
        </div>
      ) : (
        <div className={styles.selection_container}>
          <button onClick={toggleEditModal}>
            <RiDeleteBin5Line color="#DF3737" />
            Delete
          </button>
          <button onClick={() => setAssignLeadDrawer(true)}>
            <img src="/assets/img/assign.png" />
            Bulk assign
          </button>
          <button
            onClick={() => {
              setCopyLeadDrawer(true);
              setActiveLeadOption("copy");
            }}
          >
            <RxCopy />
            Copy to list
          </button>
          <button
            onClick={() => {
              setCopyLeadDrawer(true);
              setActiveLeadOption("move");
            }}
          >
            <img src="/assets/img/move.png" />
            Move to list
          </button>
          <button onClick={onEditStatus}>
            <img src="/assets/img/edit_status.png" />
            Edit status
          </button>
          <button onClick={onEditLabel}>
            <img src="/assets/img/edit_label.png" />
            Edit label
          </button>
          {/* <button>Send Broadcast</button> */}
          <button onClick={onBulkTask}>
            <MdOutlineAddTask color="#128807" />
            Bulk task
          </button>
          <button onClick={onCreateCampaign}>
            <BsMegaphone /> Create campaign
          </button>
          <button onClick={onExportLeads}>
  <FileDown size={16} />
  Export
</button>

          <button onClick={() => setAutomationDrawer(true)}>
            Add to automation
          </button>
        </div>
      )}

      {/* Automation List */}
      <GlobalDrawer
        openWithHeader={automationDrawer}
        setOpenWithHeader={() => {
          setAutomationDrawer(false);
        }}
        drawerTitle={"Automation List"}
        size="xs"
        customHeader
      >
        {/* <label className="form-label">Select Destination lead list </label> */}
        <div>
          {automationList?.length > 0
            ? automationList?.map((item: any, ix: number) => {
                return (
                  <button
                    onClick={() => {
                      handleSelectAutomate && handleSelectAutomate(item._id);

                      setAutomationDrawer(false);
                    }}
                    key={ix}
                    className="d-block bg-white w-100 p-3 rounded mb-2"
                    style={{ margin: "8px 0px", textAlign: "left" }}
                  >
                    {item.name}
                  </button>
                );
              })
            : "No Record Found"}
        </div>
      </GlobalDrawer>

      <DrawerExistModal
        title="Are you sure you want to delete ?"
        showExitModal={showExitModal}
        toggleEditModal={toggleEditModal}
        handleDrawerClick={() => {
          toggleEditModal();
          onDeleteLead();
        }}
      />

      {/* Assign Lead */}
      <GlobalDrawer
        openWithHeader={assignLeadDrawer}
        setOpenWithHeader={() => {
          setAssignLeadDrawer(false);
        }}
        drawerTitle={"Assign Lead"}
        size="xs"
        customHeader
      >
        <label className="form-label">Select team member</label>
        <div>
          <button
            onClick={() => {
              handleAssignLead(StateData?.userData?.userDetails?._id);
              setAssignLeadDrawer(false);
            }}
            className="d-block bg-white w-100 p-3 rounded mb-2"
            style={{ margin: "8px 0px", textAlign: "left" }}
          >
            Assign to self
          </button>
          {teamMembers?.map((item: any, idx: number) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  handleAssignLead(item.value);
                  setAssignLeadDrawer(false);
                }}
                className="d-block bg-white w-100 p-3 rounded mb-2"
                style={{ margin: "8px 0px", textAlign: "left" }}
              >
                {item.label.replace("null", "")}
              </button>
            );
          })}
        </div>
      </GlobalDrawer>

      {/* Copy Lead */}
      <GlobalDrawer
        openWithHeader={copyLeadDrawer}
        setOpenWithHeader={() => {
          setCopyLeadDrawer(false);
        }}
        drawerTitle={activeLeadOption === "copy" ? "Copy Lead" : "Move Lead"}
        size="xs"
        customHeader
      >
        <label className="form-label">Select Destination lead list </label>
        <div>
          {leadListData?.length > 0
            ? leadListData?.map((item: any, ix: number) => {
                // if (item._id != leadData.list) {
                return (
                  <button
                    onClick={() => {
                      handleCopyLead(item._id, activeLeadOption);
                      setCopyLeadDrawer(false);
                    }}
                    key={ix}
                    className="d-block bg-white w-100 p-3 rounded mb-2"
                    style={{ margin: "8px 0px", textAlign: "left" }}
                  >
                    {item.name}
                  </button>
                );
                // } else {
                // }
              })
            : "No Record Found"}
        </div>
      </GlobalDrawer>
    </Fragment>
  );
};

export default BulkLeadOptions;
