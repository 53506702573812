import { io, Socket } from "socket.io-client";
import { SOCKET_API_URL } from "../config/config";
import { Logger } from '../utils/logger';

interface ServerToClientEvents {
  message: (data: {
    createdAt: string;
    message: {
      from: string;
      id: string;
      timestamp: string;
      text: { body: string };
      type: string;
    };
    username: string;
  }) => void;
  roomData: (data: {
    room: string;
    users: { id: string; username: string; room: string }[];
  }) => void;
}

interface ClientToServerEvents {
  join: (
    options: { username: string; room: string },
    callback: (error?: string) => void
  ) => void;
  sendMessage: (message: string, callback: () => void) => void;
  "leave-room": (callback: () => void) => void;
}

const token = localStorage.getItem("auth_token");

Logger.log(">>>", token?.split(" ")[1]);

const socket: Socket<any> = io(SOCKET_API_URL, {
  reconnectionDelayMax: 10000,
  query: {
    path: "/",
    token: token?.split(" ")[1] || "",
  },
  transports: ["websocket"],
});

socket.on("connect", () => {
  Logger.log("Connected to Socket.IO server");
});

socket.on("connect_error", (error) => {
  console.error("Connection Error: ", error);
});

socket.io.on("error", (error) => {
  Logger.log("Socket error: ", error);
});

socket.on("disconnect", (reason) => {
  console.error("Disconnected: ", reason);
});

const disconnectRoom = () => {
  // socket.disconnect();
  socket.emit("leave-room", (error?: string) => {
    Logger.log("Disconnect Error: ", error);
    if (error) {
      console.error("Disconnect Error: ", error);
      alert(error);
    }
  });
};

const joinRoom = (username: string, room: string): void => {
  socket.emit("join", { username, room }, (error?: string) => {
    if (error) {
      console.error("Join Error: ", error);
      alert(error);
    }
  });
};

const sendMessageFun = (message: string): void => {
  socket.emit("sendMessage", message, () => {
    Logger.log("Message sent: ", message);
  });
};

const onMessage = (
  callback: (data: {
    createdAt: string;
    message: {
      from: string;
      id: string;
      timestamp: string;
      text: { body: string };
      type: string;
    };
    username: string;
  }) => void
): void => {
  socket.on("message", callback);
};

export { socket, joinRoom, sendMessageFun, onMessage, disconnectRoom };