import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { 
  Mail, Phone, Video, MessageSquare, 
  PhoneMissed, Users, FileText, Upload,
  CheckCircle, UserCheck
} from 'lucide-react';

interface ITask {
  activity: any;
  onEditTask?: (e: any) => void; 
}

// Styled Components
const TimelineRow = styled.div.attrs({
  className: 'container row'
})`
  margin-bottom: 1rem;
`;

const LeftColumn = styled.div`
  width: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    bottom: -20px;
    width: 2px;
    background: #e9ecef;
  }
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #3FAEFD;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const DateText = styled.h5`
  color: #4a5568;
  font-size: 0.85rem;
  font-weight: 500;
  margin-top: 0.75rem;
  text-align: center;
`;

const TaskCard = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem 1.25rem;
  transition: transform 0.2s;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);

  &:hover {
    transform: translateX(4px);
  }
`;

const StatusBadge = styled.div<{ color: string }>`
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  background-color: ${props => props.color}15;
  color: ${props => props.color};
  border: 1px solid ${props => props.color}30;
`;

const NoteBlock = styled.div`
  background: white;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin-top: 0.75rem;
  font-size: 0.85rem;
  border-left: 3px solid #3FAEFD;
`;

const MetaText = styled.div`
  color: #718096;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  padding-left: 0.25rem;
`;

const NewTaskItem = ({ activity, onEditTask }: ITask) => {
  const StoreData = useSelector((state: any) => state?.rootReducers);
  const taskList = StoreData?.user?.userPreferences?.taskType ?? [];

  const getTagName = () => {
    if (activity?.isCompleted) {
      return { tag: "Done", color: "#0DA30A" };
    }
    const now = moment().startOf("day");
    const taskDate = moment(activity?.toBePerformAt).startOf("day");
    
    if (now.isSame(taskDate)) {
      return { tag: "Due today", color: "#3FAEFD" };
    }
    if (now.isBefore(taskDate)) {
      return { tag: "Upcoming", color: "#061840" };
    }
    return { tag: "Overdue", color: "#E11D1D" };
  };

  const getTaskNameFromType = (type: string) => {
    return taskList?.find((item: any) => item.value === type)?.name || type;
  };

  const getIcon = () => {
    const iconMap: Record<string, React.ReactNode> = {
      'call': <Phone size={20} />,
      'video-meeting': <Video size={20} />,
      'message': <MessageSquare size={20} />,
      'email': <Mail size={20} />,
      'follow-up': <UserCheck size={20} />,
      'Missed': <PhoneMissed size={20} />,
      'lead_assigned': <Users size={20} />,
      'note_created': <FileText size={20} />,
      'file_upload': <Upload size={20} />
    };
    return iconMap[activity.type] || <CheckCircle size={20} />;
  };

  const status = getTagName();

  return (
    <TimelineRow>
      <LeftColumn>
        <IconContainer>
          {getIcon()}
        </IconContainer>
        <DateText>
          {moment(activity?.toBePerformAt).format("DD MMM")}
        </DateText>
      </LeftColumn>

      <div className="col cursor-pointer" onClick={() => onEditTask?.(activity)}>
        <TaskCard>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-capitalize fw-bold m-0">
              {getTaskNameFromType(activity.type)} -{" "}
              {moment(activity.toBePerformAt).format("hh:mm A")}
            </h5>
            <StatusBadge color={status.color}>
              {status.tag}
            </StatusBadge>
          </div>

          {activity.repeat && (
            <div className="mt-2 text-muted">
              Repeat: {activity.repeat}
            </div>
          )}

          {(activity?.note || activity?.notes || activity?.extraDetails?.description) && (
            <NoteBlock>
              {activity?.note || activity?.notes || activity?.extraDetails?.description}
            </NoteBlock>
          )}
        </TaskCard>

        <MetaText>
          {moment(activity?.createdAt).format("DD MMM YY hh:mm a")} by{" "}
          {activity?.createdBy?.firstName ?? activity?.createdBy}
        </MetaText>
      </div>
    </TimelineRow>
  );
};

export default NewTaskItem;