import React, { useEffect, useState } from 'react';
import { Eye, Edit2, Trash2, Calendar, Clock } from 'lucide-react';
import Header from "../../components/header";
import { useLocation, useNavigate } from 'react-router-dom';
import { QuotationStatusManagement, StatusTotal } from './Components/QuotationStatusManagement';
import { useSelector } from 'react-redux';
import DateFilterDropdown from './Components/DateFilterDropdown'
import { 
  Table, Button, Input, Space, Card, message, Modal,
  TableColumnProps
} from 'antd';
import { 
  SearchOutlined, PlusOutlined, ReloadOutlined, 
  DownloadOutlined, FilterOutlined 
} from '@ant-design/icons';
import { getAllQuotations, deleteQuotations } from '../../services/quotationService';

import QuotationViewer from './QuotationViewer';
import AddEditQuotation from './AddEditQuotation';
import './Quotation.scss';
import { 
  isValidStatus,
 
  StatusCardProps 
} from './QuotationList.types';
import { Quotation, QuotationViewerProps, AuthState,QuotationApiResponse,ApiResponse  } from './Quotation';
import moment from 'moment';
import { BUCKET_URL } from '../../config/config';
import UltimatePlanInfo from '../../components/UltimatePlanInfo/UltimatePlanInfo';
import { Logger } from '../../utils/logger';

type AlignType = TableColumnProps<any>['align'];


// Add root state type
interface RootState {
  auth: AuthState;
}


const QuotationList = () => {
  const [quotations, setQuotations] = useState<QuotationApiResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editData, setEditData] = useState<Quotation | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState<string | null>(null);
  const [statusTotals, setStatusTotals] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [totalQuotations, setTotalQuotations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 15;
  const user = useSelector((state: RootState) => state.auth?.user);
  const token = localStorage.getItem('auth_token');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [allStatusTotals, setAllStatusTotals] = useState<StatusTotal[]>([]);
  
  const [isUltimate, setIsUltimate] = useState<boolean | null>(null);

  const [view, setView] = useState<'list' | 'create' | 'edit'>('list');
  const [selectedQuotationData, setSelectedQuotationData] = useState<Quotation | null>(null);

const [viewerOpen, setViewerOpen] = useState(false);
const [selectedViewQuotation, setSelectedViewQuotation] = useState<QuotationApiResponse | null>(null);  
const [dateFilter, setDateFilter] = useState<{
  fromDate?: string;
  toDate?: string;
} | null>(null);



const location = useLocation();
const navigate = useNavigate();
const [isModalVisible, setIsModalVisible] = useState(false);

// Replace the existing search/filter related useEffects with this single one
useEffect(() => {
  Logger.log('=== CONSOLIDATED EFFECT TRIGGERED ===');
  Logger.log('State values:', { 
    currentPage, 
    selectedStatus, 
    dateFilter, 
    debouncedSearchTerm,
    searchTerm
  });
  
  // Function to fetch quotations
  const fetchQuotations = async () => {
    // Skip API calls during initial render when default values are set
    if (
      selectedStatus === undefined && 
      dateFilter === undefined && 
      debouncedSearchTerm === undefined
    ) {
      Logger.log('Skipping initial API call with undefined values');
      return;
    }
    
    Logger.log('Filter/search criteria changed, fetching quotations with:', {
      page: 1, // Always reset to page 1 when filters change
      status: selectedStatus,
      dateFilter,
      searchTerm: debouncedSearchTerm
    });
    
    setLoading(true);
    await getQuotations(1); // Reset to page 1 when filters change
  };

  // Only run the fetch if we have actual changes to filter/search
  // This avoids the initial render API call
  if (selectedStatus !== undefined || dateFilter !== undefined || debouncedSearchTerm !== undefined) {
    Logger.log('Executing fetchQuotations...');
    fetchQuotations();
  } else {
    Logger.log('No filters/search defined yet, skipping fetch');
  }
  
  // Return cleanup function to handle component unmount or dependency changes
  return () => {
    Logger.log('Cleaning up previous effect before new one runs');
  };
}, [selectedStatus, dateFilter, debouncedSearchTerm]); // Dependencies that trigger this effect



useEffect(() => {
  Logger.log('Current Location:', location);
  Logger.log('Current Pathname:', location.pathname);
}, [location]);

useEffect(() => {
  Logger.log('Search: Debounce effect triggered with term:', searchTerm);
  const timer = setTimeout(() => {
    Logger.log('Search: Debounced search term updated to:', searchTerm);
    setDebouncedSearchTerm(searchTerm);
  }, 500);

  return () => {
    Logger.log('Search: Clearing previous debounce timer');
    clearTimeout(timer);
  };
}, [searchTerm]);

useEffect(() => {
  const local = window.localStorage?.getItem("userData");
  if (local) {
    const userData = JSON.parse(local);
    setIsUltimate(userData?.subscriptionPlanType?.toLowerCase() === "ultimate");
  }
}, []);


useEffect(() => {
  // Check for navigation state from LeadOptionsMenu
  if (location.state && location.state.openCreateForm === true) {
    Logger.log('Detected openCreateForm state, lead data:', location.state.leadForQuotation);
    
    // Switch to create view and clear state to prevent infinite loop
    setView('create');
    
    // Clear the navigation state
    window.history.replaceState({}, document.title);
  }
}, [location.state]);

const getQuotations = async (page = currentPage) => {
  Logger.log('API: Fetching quotations with params:', {
    page,
    searchTerm: debouncedSearchTerm,
    status: selectedStatus,
    dateFilter
  });
  
  try {
    setLoading(true);
    setSearchLoading(true);

    // Add date validation
    if (dateFilter?.fromDate && dateFilter?.toDate) {
      const startDate = new Date(dateFilter.fromDate);
      const endDate = new Date(dateFilter.toDate);
      if (startDate > endDate) {
        message.error('Start date cannot be after end date');
        setLoading(false);
        setSearchLoading(false);
        return;
      }
    }

    const params = {
      orderBy: "createdAt",
      page,
      perPage: pageSize,
      ...(selectedStatus && { status: selectedStatus }),
      ...(debouncedSearchTerm && { search: debouncedSearchTerm.trim() }),
      ...(dateFilter?.fromDate && { fromDate: dateFilter.fromDate }),
      ...(dateFilter?.toDate && { toDate: dateFilter.toDate })
    };
    
    const response = await getAllQuotations(params);
    if (response?.data) {
      Logger.log('API: Search results received:', {
        count: response.data.data.length,
        total: response.data.total
      });
      const apiResponse = response.data as ApiResponse;
      setQuotations(apiResponse.data);
      setStatusTotals(apiResponse.quotationStatus || []);
      setTotalQuotations(apiResponse.total);
    }
  } catch (error) {
    console.error('API: Error in getQuotations:', error);
    message.error('Error fetching Quotations');
  } finally {
    Logger.log('API: Search request completed');
    setLoading(false);
    setSearchLoading(false);
  }
};

  useEffect(() => {
    Logger.log('Auth Token:', localStorage.getItem('auth_token'));
  }, []);
 
  const getContrastTextColor = (hexcolor: string): string => {
    // Remove hash if it exists
    hexcolor = hexcolor.replace('#', '');
    
    // If shorthand hex (e.g. #abc), convert to full form (e.g. #aabbcc)
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(c => c + c).join('');
    }
    
    // Default to dark if invalid hex
    if (hexcolor.length !== 6) {
      return '#000000';
    }
  
    // Convert hex to RGB and calculate brightness
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    
    // YIQ formula - determines perceived brightness
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    
    // Return dark for bright colors, light for dark colors
    return yiq >= 128 ? '#333333' : '#ffffff';
  };

  
  const formatTime = (dateString: string): string => {
    return new Intl.DateTimeFormat('en-IN', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Kolkata' // IST timezone
    }).format(new Date(dateString));
  };
  
  const formatDate = (dateString: string): string => {
    return new Intl.DateTimeFormat('en-IN', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: 'Asia/Kolkata' // IST timezone
    }).format(new Date(dateString));
  };

  const handleAdd = () => {
    Logger.log('QuotationList: Switching to create view');
    setSelectedQuotationData(null);
    setView('create');
  };


  const [authData, setAuthData] = useState({
    token: localStorage.getItem('auth_token'),
    userId: '' // Get from appropriate source
  });
  
  const handleEdit = (record: QuotationApiResponse) => {
    Logger.log('QuotationList: Starting edit with record:', record);
    
    // Properly structure the quotation data with full lead object
    const quotationData: Quotation = {
      _id: record._id,
      quotationId: record.quotationId,
      lead: {
        _id: record.lead._id,
        name: record.lead.name,
        // Include all lead fields that come from API
       
      },
      title: record.title,
      status: record.status as Quotation['status'],
      total: record.total,
      createdBy: record.createdBy,
      createdAt: new Date(record.createdAt),
      products: record.products
    };
  
    Logger.log('QuotationList: Setting selected quotation data:', quotationData);
    setSelectedQuotationData(quotationData);
    setView('edit');
  };

  const handleFormSuccess = () => {
    Logger.log('QuotationList: Form submitted successfully');
    setView('list');
    getQuotations(currentPage);
    // If any navigation is happening here, it should be to /quotationlist instead of /quotations
    navigate('/quotationlist');
  };

  const handleFormCancel = () => {
    Logger.log('QuotationList: Form cancelled');
    setView('list');
  };
  

  const handleDelete = async (confirm: boolean) => {
    if (confirm && selectedQuotation) {
      try {
        await deleteQuotations(selectedQuotation);
        message.success('Quotation deleted successfully');
        getQuotations();
      } catch (error) {
        message.error('Error deleting Quotation');
      }
    }
    setDeleteModalVisible(false);
    setSelectedQuotation(null);
  };

  const getStatusColor = (status: string) => {
    const colors: Record<string, string> = {
      pending: '#faad14',
      overdue: '#ff4d4f',
      created: '#8c8c8c',
      partially_paid: '#52c41a',
      fully_paid: '#1890ff',
      ff: '#8c8c8c'
    };
    return colors[status] || '#8c8c8c';
  };


  const columns: TableColumnProps<QuotationApiResponse>[] = [
    {
      title: 'Quotation ID',
      dataIndex: 'quotationId',
      key: 'quotationId',
      render: (id: string) => <span className="quotation-id">{id}</span>
    },
    {
      title: 'Lead Name',
      dataIndex: ['lead', 'name'],
      key: 'leadName',
    },
    {
      title: 'Created',
      key: 'created',
      render: (_, record: QuotationApiResponse) => (
        <div className="creator-info">
          <div className="creator-name">
            {record.createdByName}
          </div>
          <div className="creator-time">
            {formatDate(record.createdAt)} • {formatTime(record.createdAt)}
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
  dataIndex: 'status',
  key: 'status',
  render: (status: string) => {
    const statusColor = getStatusColor(status); // Use the same dynamic function you use for cards
    const textColor = getContrastTextColor(statusColor);
    
    return (
      <div className="status-pill-container">
        <span 
          className="status-pill"
          style={{
            backgroundColor: `${statusColor}20`, // 20% opacity
            color: textColor,
            border: `1px solid ${statusColor}`,
            display: 'inline-flex',
            alignItems: 'center',
            padding: '6px 12px',
            borderRadius: '16px',
            fontSize: '13px',
            fontWeight: 500
          }}
        >
          <span 
            style={{ 
              width: '8px', 
              height: '8px', 
              borderRadius: '50%', 
              backgroundColor: statusColor,
              marginRight: '6px',
              display: 'inline-block'
            }} 
          />
          {status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
        </span>
      </div>
    );
  },
},
    {
      title: 'Amount',
      dataIndex: 'total',
      key: 'total',
      align: 'right' as AlignType,
      render: (total: number) => <span className="amount-value">₹ {total?.toLocaleString() || 0}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record: QuotationApiResponse) => {
        if (!record) return null;
        
        return (
          <div className="action-buttons">
            <Button
              type="text"
              className="action-button view"
              onClick={() => {
                setSelectedViewQuotation(record);
                setViewerOpen(true);
              }}
              icon={<Eye size={16} />}
            />
            <Button
              type="text" 
              className="action-button edit"
              onClick={() => handleEdit(record)}
              icon={<Edit2 size={16} />}
            />
            <Button
              type="text"
              className="action-button delete"
              onClick={() => {
                setSelectedQuotation(record._id);
                setDeleteModalVisible(true);
              }}
              icon={<Trash2 size={16} />}
            />
          </div>
        );
      }
    }
  ];

  if (isUltimate === false) { // Explicitly check for false
    return (
      <div className="quotation-list-container">
        <Header />
        <UltimatePlanInfo />
      </div>
    );
  }
  
  return (
    <div className="quotation-list-container">
      {view === 'list' ? (
        // Original List View
        <div className="quotation-list-content">
          <Header />
          <div className="bg-gray-50 min-h-screen">
          <div className="flex justify-between items-center mb-3 header-buttons"></div>
            <Card bodyStyle={{ padding: '12px' }}>
              {/* Header Section */}
              <div className="flex justify-between items-center mb-6"> {/* or similar class name */}
  <div className="text-xl font-semibold mr-4">
    Quotations ({totalQuotations})
  </div>
  
                <div className="quotation-header-container">
                  <div className="header-actions">
                    <Space size={12}>
                      <Input.Search 
                        placeholder="Search quotations..."
                        allowClear
                        loading={searchLoading}
                        onChange={(e) => {
                          Logger.log('Search: Input changed to:', e.target.value);
                          setSearchTerm(e.target.value);
                        }}
                        onSearch={() => {
                          Logger.log('Search: Ignoring onSearch event');
                        }}
                        value={searchTerm}
                        size="large"
                        className="w-64"
                      />
                      <Space size={12}>
                        <DateFilterDropdown 
                          onDateChange={(dates) => {
                            Logger.log('Setting date filter:', dates);
                            setDateFilter(dates);
                          }}
                          
                        />
                      </Space>
                      <Button 
                        icon={<ReloadOutlined />}
                        onClick={() => {
                          Logger.log('Refreshing quotation list');
                          getQuotations(1);
                        }}
                      >
                        Refresh
                      </Button>
                      <Button 
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleAdd}
                      >
                        Create Quotation
                      </Button>
                    </Space>
                  </div>
                </div>
              </div>
  
              {/* Status Cards */}
              <QuotationStatusManagement 
  statusTotals={statusTotals}
  selectedStatus={selectedStatus}
  onStatusChange={(status) => {
    Logger.log('Status changed in parent, updating state only:', status);
    setSelectedStatus(status);
    // Remove getQuotations(1) from here - let the useEffect handle it
  }}
  loading={loading}
/>
  
              {/* Table */}
              <Table
  columns={columns}
  dataSource={quotations}
  loading={loading}
  rowKey="_id"
  className="quotation-table"
  pagination={{
    current: currentPage,
    pageSize: pageSize,
    total: totalQuotations,
    onChange: (page) => {
      Logger.log('Changing page to:', page);
      setCurrentPage(page);
      getQuotations(page);
    },
    showSizeChanger: false,
    showTotal: (total) => `Total ${total} items`,
    position: ['bottomCenter'],
    style: { marginBottom: 0 }
  }}
  scroll={{ 
    y: 'calc(100vh - 400px)'
  }}
/>
  
              {/* Delete Modal */}
              <Modal
                title="Delete Quotation"
                open={deleteModalVisible}
                onOk={() => handleDelete(true)}
                onCancel={() => handleDelete(false)}
              >
                <p>Are you sure you want to delete this Quotation?</p>
              </Modal>
  
              {/* Quotation Viewer Modal */}
              {viewerOpen && selectedViewQuotation && (
                <QuotationViewer
                visible={viewerOpen}
                onClose={() => setViewerOpen(false)}
                quotationId={selectedViewQuotation._id}
                token={token || ''}
                userId={user?._id || ''}
                bucketUrl={BUCKET_URL}  // Use the imported constant instead of user?.bucketUrl
                order={{
                  ...selectedViewQuotation,
                  status: isValidStatus(selectedViewQuotation.status) 
                    ? selectedViewQuotation.status 
                    : 'created',
                  createdAt: new Date(selectedViewQuotation.createdAt)
                }}
                onEdit={() => handleEdit(selectedViewQuotation)}
              />
              )}
            </Card>
          </div>
        </div>
      ) : (
       
          <div className="quotation-list-content">
            <Header />
            <AddEditQuotation
              onSuccess={handleFormSuccess}
              data={selectedQuotationData}
              lead={location.state?.leadForQuotation || null}
            />
          </div>
        )}
    </div>
  );
};

export default QuotationList;