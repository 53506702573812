import styled from "styled-components";

export const Root = styled.section`
  width: 400px;
  max-width: 90vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
  background: white;
  
  .form-group {
    margin-bottom: 12px;
  }

  .form_label {
    font-size: 14px;
    font-weight: 600;
    color: #344054;
    margin-bottom: 6px;
    display: block;
  }

  .form-select, .form-control, .select_date .ant-picker {
    font-size: 14px;
    color: #101828;
    background-color: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 8px 12px;
    width: 100%;
    transition: all 0.2s;
    height: 42px;

    &:focus {
      border-color: #7f56d9;
      box-shadow: 0 0 0 4px rgba(127, 86, 217, 0.1);
      outline: none;
    }
  }

  .note_area {
    resize: vertical;
    min-height: 120px;
    max-height: 250px;
    font-size: 14px;
  }

  .auto_form_btn {
    margin-top: auto;
    padding: 16px 0;
    background: white;
    border-top: 1px solid #e5e7eb;

    .btn-primary-save {
      width: 100%;
      padding: 12px;
      background: #7f56d9;
      border: none;
      border-radius: 8px;
      color: white;
      font-weight: 600;
      font-size: 15px;
      transition: background 0.2s, transform 0.1s;

      &:hover {
        background: #6941c6;
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }

  .note-pills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 12px;

    .btn-outline-secondary {
      flex-grow: 0;
      font-size: 13px;
      padding: 4px 10px;
      border-color: #d0d5dd;
      color: #667085;
      transition: all 0.2s;

      &:hover {
        background-color: #f9fafb;
        border-color: #7f56d9;
        color: #7f56d9;
      }
    }
  }

  .select_date {
    .ant-picker-input > input {
      font-size: 14px;
    }
  }

  .mark_checkbox {
    margin-top: 12px;
    
    .form-check-label {
      font-size: 14px;
      color: #344054;
      font-weight: 500;
    }

    .form-check-input {
      width: 18px;
      height: 18px;
      margin-right: 8px;

      &:checked {
        background-color: #7f56d9;
        border-color: #7f56d9;
      }
    }
  }
`;

export const NotesContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
`;

export const NotesTitle = styled.h6`
  font-size: 15px;
  font-weight: 600;
  color: #344054;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
`;

export const NoteItem = styled.div`
  background: #f9fafb;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  transition: background-color 0.2s ease;

  &:hover {
    background: #f3f4f6;
  }
`;

export const NoteContent = styled.div`
  font-size: 14px;
  color: #1a202c;
  line-height: 1.4;
  margin-bottom: 8px;
  word-break: break-word;
`;

export const NoteMeta = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #667085;
`;

export const MetaItem = styled.span`
  color: #667085;
  
  &:not(:last-child)::after {
    content: "•";
    margin-left: 8px;
    color: #CBD5E0;
  }
`;