import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Upload, X, FileText, Image, Video, File, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import FileUploadHandler, { FileUploadHandlerRef } from '../../FileUploadHandler';
import { useRef } from 'react';
import { deleteLeadFile } from "../../../../services/utilityService";
import PopupComponent from '../../../../components/PopupComponent';
import MapField from '../../MapField';


import type { ContentI, PageData, Tag, FileWithProgress, ContentFile } from '../../types';
import GenericConfirmationModal from '../../../../components/GenericConfirmationModal';
import { deleteContent } from '../../../../services/contentService';
import { Logger } from '../../../../utils/logger';


interface PageContentProps {
  openPageBox: boolean;
  setOpenPageBox: (open: boolean) => void;
  activeId: string;
  userId: string;
  pageData: PageData;
  onSuccessfulSubmit?: () => Promise<void>;
  handlePageForm: (key: keyof PageData, value: any) => void;
  handleFormSubmit: (data: { details: ContentI['details']; type: string }) => Promise<any>;
  messageTag: Tag[];
  clearData: () => void;
  setPageData: React.Dispatch<React.SetStateAction<PageData>>;
}

const PageContent: React.FC<PageContentProps> = ({
  openPageBox,
  setOpenPageBox,
  activeId,
  userId,
  pageData,
  handlePageForm,
  handleFormSubmit,
  messageTag,
  clearData,
  setPageData ,
  onSuccessfulSubmit  
}) => {
  const [isPageLoad, setIsPageLoad] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const fileUploadRef = useRef<FileUploadHandlerRef>(null);

  // Add a logging line to track component initialization
  Logger.log('[PageContent] Initializing with activeId:', activeId);

  // Update the handleClose function
  const handleClose = useCallback(() => {
    Logger.log('[PageContent] Closing dialog and cleaning up');
    // Reset file upload handler if needed
    if (fileUploadRef.current) {
      fileUploadRef.current.resetFiles();
    }
    clearData();
    setOpenPageBox(false);
  }, [clearData, setOpenPageBox]);

  const addLeadName = () => {
    handlePageForm('description', `${pageData.description || ''}@lead name`);
  };

  const renderFooter = () => (
    <FooterContainer>
      {activeId && (
        <>
          {pageData?.uniqueLinkId && userId && (
            <PreviewButton
              onClick={() => {
                const previewUrl = `https://integration.3sigmacrm.com/page/${pageData.uniqueLinkId}?u=${userId}`;
                window.open(previewUrl, '_blank');
              }}
            >
              Preview
            </PreviewButton>
          )}
          
          <DeleteButton
            onClick={() => setShowDeleteConfirm(true)}
          >
            <Trash2 size={16} />
            Delete
          </DeleteButton>
        </>
      )}
      
   
      <SaveButton
        onClick={async () => {
          Logger.log('[PageContent] Save button clicked');
          
          // Validate required fields
          if (!pageData.title.trim()) {
            toast.error('Title is required');
            return;
          }
          
          if (!pageData.tags?.length) {
            toast.error('Please select at least one tag');
            return;
          }
        
          // First create/update the content without files
          const formData = {
            details: {
              title: pageData.title,
              tags: [pageData.tags[0]], // Convert single tag to array
              type: 'page',
              ...(pageData.description && { description: pageData.description }),
              ...(pageData.videoUrl && { videoUrl: pageData.videoUrl }),
              ...(Object.keys(pageData.location || {}).length > 0 && { location: pageData.location }),
              ...(pageData.address && { address: pageData.address }),
            },
            type: 'page',
            _id: activeId // Include ID when editing existing content
          };
          
          Logger.log('PageContent - Submitting form data with ID:', activeId);

          try {
            Logger.log('[PageContent] Submitting content data first');
            setIsPageLoad(true);
            
            // Call handleFormSubmit without expecting a return value
            await handleFormSubmit(formData);
            
            // Use activeId directly, assuming handleFormSubmit internally updates the content
            const contentId = activeId;
            Logger.log('[PageContent] Content saved successfully, ID:', contentId);
            
            // Check if there are any files that need uploading
            const hasFilesToUpload = pageData.files?.some(file => !file.uploaded && !file.filePath);
            
            if (hasFilesToUpload && fileUploadRef.current && contentId) {
              Logger.log('PageContent - Uploading pending files for content ID:', contentId);
              try {
                const uploadedFiles = await fileUploadRef.current.uploadPendingFiles(contentId);
                Logger.log('PageContent - Files uploaded successfully:', uploadedFiles);
                
                // Critical: Refresh content data to show newly uploaded files
                if (typeof onSuccessfulSubmit === 'function') {
                  await onSuccessfulSubmit();
                }
              } catch (uploadError) {
                console.error('PageContent - Error uploading files:', uploadError);
                toast.error('Content saved but some files failed to upload');
              }
            } else {
              Logger.log('PageContent - No files to upload');
            }
            
            // Success and cleanup
            toast.success('Page saved successfully');
            setOpenPageBox(false);
            clearData();
          } catch (error) {
            console.error('[PageContent] Error saving content:', error);
            toast.error('Failed to save content');
          } finally {
            setIsPageLoad(false);
          }
        }}
      >
         Save
         </SaveButton>
     
   
    </FooterContainer>
  );

  return (
    <PopupComponent
      isOpen={openPageBox}
      onClose={handleClose}
      title={activeId.length > 1 ? 'Edit Page' : 'Add Page'}
      size="lg"
      loading={isPageLoad}
      footer={renderFooter()}
    >
      <FormContainer>
        <div className="user_form_box">
          <label>Title</label>
          <input
            type="text"
            value={pageData.title}
            onChange={(e) => handlePageForm("title", e.target.value)}
          />
        </div>

        <div className="user_form_box">
          <label>Tags</label>
          <div className="all_tags mt-2">
            {messageTag.map((tag) => (
              <span
                key={tag.value}
                onClick={() => handlePageForm("tags", tag.value)}
                className={pageData.tags.includes(tag.value) ? "active" : ""}
              >
                {tag.name}
              </span>
            ))}
          </div>
        </div>

        <div className="user_form_box">
          <div className="label-wrapper">
            <label>Description</label>
            <button className="add_lead_name" onClick={addLeadName}>
              Add @lead name
            </button>
          </div>
          <textarea
            rows={4}
            cols={50}
            value={pageData.description}
            onChange={(e) => handlePageForm("description", e.target.value)}
          />
        </div>

        <div className="user_form_box">
          <label>YouTube Embedded Videos Urls</label>
          <input
            type="text"
            placeholder="Add youtube video"
            value={pageData.videoUrl}
            onChange={(e) => handlePageForm("videoUrl", e.target.value)}
          />
        </div>

        <div className="user_form_box">
          <label>Add Map</label>
          <MapField
            address={pageData?.address}
            locationCord={pageData?.location}
            setAddress={(e: string) => handlePageForm("address", e)}
            setLocationCord={(e: any) => handlePageForm("location", e)}
          />
        </div>

        <div className="user_form_box">
          <label>Files</label>
          <FileUploadHandler
            ref={fileUploadRef}
            files={pageData.files || []}
            onFileChange={(updatedFiles) => {
              Logger.log('[PageContent] Files updated:', updatedFiles.length);
              handlePageForm("files", updatedFiles);
            }}
            onDeleteServerFile={async (path) => {
              Logger.log('PageContent - Deleting server file:', path);
              try {
                setIsPageLoad(true);
                const obj = {
                  filePaths: [path],
                  type: 'content',
                  content: activeId
                };
                
                const response = await deleteLeadFile(obj);
                
                if (response.status === 200) {
                  Logger.log('PageContent - File deleted successfully');
                  
                  // Update pageData to remove the deleted file
                  setPageData((prev) => {
                    const updatedData = {
                      ...prev,
                      files: prev.files?.filter(file => file.filePath !== path) || []
                    };
                    Logger.log('PageContent - Updated pageData after deletion:', updatedData);
                    return updatedData;
                  });
                  
                  // Important: Refresh the main content data
                  if (typeof onSuccessfulSubmit === 'function') {
                    await onSuccessfulSubmit();
                  }
                  
                  toast.success(response.data.message || 'File deleted successfully');
                }
              } catch (err) {
                console.error('PageContent - Error deleting file:', err);
                toast.error('Failed to delete file');
              } finally {
                setIsPageLoad(false);
              }
            }}
            maxSize={100}
            acceptedTypes={['.mp4', '.wmv', '.pdf', '.jpeg', '.jpg', '.png', '.gif', '.webp']}
            activeId={activeId}
          />
        </div>
      </FormContainer>
      {showDeleteConfirm && (
  <GenericConfirmationModal
    showModal={showDeleteConfirm}
    toggleModal={() => setShowDeleteConfirm(false)}
    onConfirmation={async () => {
      Logger.log('PageContent - Deleting content with ID:', activeId);
      try {
        setIsPageLoad(true);
        const response = await deleteContent(activeId);
        Logger.log('PageContent - Delete response:', response);
        
        if (response.status === 200) {
          toast.success('Page deleted successfully');
          setOpenPageBox(false);
          clearData();
          
          // Refresh content list
          if (typeof onSuccessfulSubmit === 'function') {
            await onSuccessfulSubmit();
          }
        } else {
          toast.error('Failed to delete page');
        }
      } catch (error) {
        console.error('PageContent - Error deleting page:', error);
        toast.error('Failed to delete page');
      } finally {
        setIsPageLoad(false);
      }
    }}
    title="Delete Page"
    message="Are you sure you want to delete this page? This action cannot be undone."
    confirmText="Delete"
    cancelText="Cancel"
    isWarning={true}
  />
)}
    </PopupComponent>
  );
};

const FormContainer = styled.div`
  padding: 24px 32px;
  overflow-y: auto;
  max-height: calc(90vh - 140px);
  background: #fff;

  .user_form_box {
    margin-bottom: 24px;
    
    .label-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }

    label {
      display: block;
      font-weight: 500;
      color: #4A4A4A;
      font-size: 14px;
    }

    input[type="text"], textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #E5E7EB;
      border-radius: 8px;
      font-size: 14px;
      color: #374151;
      background: #fff;
      transition: all 0.2s ease;
      
      &::placeholder {
        color: #9CA3AF;
      }
      
      &:focus {
        outline: none;
        border-color: #3FAEFB;
        box-shadow: 0 0 0 2px rgba(63, 174, 251, 0.1);
      }
    }

    textarea {
      resize: vertical;
      min-height: 120px;
      line-height: 1.5;
    }

    .all_tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;

      span {
        padding: 8px 16px;
        border-radius: 6px;
        background: #F3F4F6;
        cursor: pointer;
        font-size: 14px;
        color: #4B5563;
        transition: all 0.2s ease;
        border: 1px solid transparent;
        
        &:hover {
          background: #E5E7EB;
        }
        
        &.active {
          background: #EBF5FF;
          color: #3FAEFB;
          border-color: #3FAEFB;
        }
      }
    }
  }

  // Add to FormContainer styled component:
.upload-progress {
  margin-top: 16px;
  
  .progress-item {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
    font-size: 13px;
    color: #4B5563;
    
    .progress-bar {
      flex: 1;
      height: 4px;
      background: #E5E7EB;
      border-radius: 2px;
      overflow: hidden;
      
      .progress-fill {
        height: 100%;
        background: #3FAEFB;
        transition: width 0.2s ease;
      }
    }
  }
}

  .add_lead_name {
    padding: 6px 12px;
    background: #F3F4F6;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    font-size: 13px;
    color: #374151;
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
      background: #E5E7EB;
    }
  }

  .file-section {
    margin-top: 16px;

    .upload-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      background: #F0F9FF;
      border: 1px dashed #3FAEFB;
      border-radius: 6px;
      color: #3FAEFB;
      cursor: pointer;
      transition: all 0.2s ease;

      svg {
        margin-right: 8px;
      }

      &:hover {
        background: #E0F2FE;
      }
    }

    .files-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 12px;
      margin-top: 16px;
    }

    .file-item {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      border: 1px solid #E5E7EB;
      border-radius: 6px;
      background: #F9FAFB;

      .file-icon {
        color: #6B7280;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }

      .file-name {
        flex: 1;
        font-size: 13px;
        color: #4B5563;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .delete-btn {
        padding: 4px;
        background: none;
        border: none;
        color: #EF4444;
        cursor: pointer;
        display: flex;
        align-items: center;
        opacity: 0.6;
        margin-left: 8px;
        border-radius: 4px;
        
        &:hover {
          opacity: 1;
          background: #FEE2E2;
        }
      }
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  padding: 16px 24px;
  border-top: 1px solid #E5E7EB;
`;

const DeleteButton = styled.button`
  padding: 10px 20px;
  background-color: #FEE2E2;
  color: #EF4444;
  border: 1px solid #EF4444;
  border-radius: 8px;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 120px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #FEE3E2;
  }
`;

const PreviewButton = styled.button`
  padding: 10px 20px;
  background-color: #E8F0F7;
  color: #3FAEFB;
  border: 1px solid #3FAEFB;
  border-radius: 8px;
  font-size: 15px;
  min-width: 120px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #F0F9FF;
  }
`;

const SaveButton = styled.button`
  padding: 10px 24px;
  background-color: #3FAEFB;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  height: 42px;
  min-width: 120px;
  max-width: 240px;
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #2E9FEA;
  }
  
  &:active {
    background-color: #2B96DE;
  }
`;

export default PageContent;