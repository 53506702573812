import React, { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { getContentByType } from '../../services/contentService';
import { getTemplates } from '../../services/templates';
import PopupComponent from '../../components/PopupComponent';
import DOMPurify from 'dompurify';
import { Logger } from '../../utils/logger';

// Define interfaces for type safety
interface WhatsAppComponent {
  type: string;
  text?: string;
  // Add other component properties as needed
}

// Define the TemplateParams interface to ensure type safety
interface TemplateParams {
    page: number;
    perPage: number;
    search?: string; // Optional property for search
  }

// Unified template type that can represent both email and WhatsApp templates
interface UnifiedTemplate {
  _id: string;
  name: string;
  type: 'email' | 'whatsapp';
  subject?: string;
  preview?: string;
  status?: string;
  category?: string;
  tags?: string[];
  
  // Original source objects
  originalEmailTemplate?: any;
  originalWhatsAppTemplate?: any;
}

interface UnifiedTemplateSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onTemplateSelect: (template: any) => void;
  selectedTemplateId?: string;
  templateType: 'whatsapp' | 'email' | 'both';
  handleDiscard: () => void;
}

const UnifiedTemplateSelector: React.FC<UnifiedTemplateSelectorProps> = ({
  isOpen,
  onClose,
  onTemplateSelect,
  selectedTemplateId,
  templateType,
  handleDiscard,
}) => {
  const [unifiedTemplates, setUnifiedTemplates] = useState<UnifiedTemplate[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<UnifiedTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(selectedTemplateId || null);
  const [searchInput, setSearchInput] = useState('');
  const [currentTab, setCurrentTab] = useState<'email' | 'whatsapp'>(
    templateType === 'both' ? 'email' : templateType
  );
  const [loading, setLoading] = useState<{email: boolean, whatsapp: boolean}>({
    email: false,
    whatsapp: false
  });
  const [error, setError] = useState<{email: boolean, whatsapp: boolean}>({
    email: false,
    whatsapp: false
  });
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<{email: number, whatsapp: number}>({
    email: 0,
    whatsapp: 0
  });
  const PER_PAGE = 20;

  // Style definitions
  const buttonStyle = {
    padding: '8px 16px',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: '14px',
    fontWeight: 600,
  } as const;

  const primaryButtonStyle = (disabled: boolean) => ({
    ...buttonStyle,
    backgroundColor: disabled ? '#D1D5DB' : '#3FAEFD',
    color: 'white',
    cursor: disabled ? 'not-allowed' : 'pointer',
  });

  const secondaryButtonStyle = {
    ...buttonStyle,
    backgroundColor: 'transparent',
    color: '#6B7280',
  };

  const tabButtonStyle = (active: boolean) => ({
    padding: '8px 16px',
    borderRadius: '6px 6px 0 0',
    border: 'none',
    borderBottom: active ? '2px solid #3FAEFD' : '2px solid transparent',
    backgroundColor: active ? '#F0F9FF' : 'transparent',
    color: active ? '#3FAEFD' : '#6B7280',
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  });

  // Transform email template to unified format
  const mapEmailToUnified = useCallback((emailTemplate: any): UnifiedTemplate => {
    const template: UnifiedTemplate = {
      _id: emailTemplate._id,
      name: emailTemplate.details?.title || emailTemplate.name || 'Untitled Template',
      type: 'email',
      subject: emailTemplate.details?.subject || emailTemplate.subject || '',
      preview: emailTemplate.details?.message || emailTemplate.body || '',
      tags: emailTemplate.details?.tags || [],
      originalEmailTemplate: emailTemplate
    };
    return template;
  }, []);

  // Transform WhatsApp template to unified format
  const mapWhatsAppToUnified = useCallback((whatsAppTemplate: any): UnifiedTemplate => {
    const bodyComponent = whatsAppTemplate.components?.find((c: WhatsAppComponent) => c.type === 'BODY');
    
    const template: UnifiedTemplate = {
      _id: whatsAppTemplate._id,
      name: whatsAppTemplate.name || 'Untitled Template',
      type: 'whatsapp',
      preview: bodyComponent?.text || '',
      status: whatsAppTemplate.status,
      category: whatsAppTemplate.category,
      originalWhatsAppTemplate: whatsAppTemplate
    };
    return template;
  }, []);

  // Fetch email templates
  const fetchEmailTemplates = useCallback(async () => {
    try {
      setLoading(prev => ({ ...prev, email: true }));
      setError(prev => ({ ...prev, email: false }));
      
      // Create params object, only adding search if it's not empty
      const params: any = { 
        page, 
        perPage: PER_PAGE 
      };
      
      if (searchInput && searchInput.trim().length > 0) {
        params.search = searchInput.trim();
      }
      
      Logger.log('Fetching email templates...', params);
      
      const response = await getContentByType('email_template', params) as AxiosResponse<any>;
      
      Logger.log('Email API response:', response.data);
      
      if (response.data && response.data.status && response.data.statusCode === 200) {
        const emailTemplates = response.data.data.map(mapEmailToUnified);
       
        
        setUnifiedTemplates(prev => {
          // Keep WhatsApp templates and replace email templates with the new ones
          const whatsappTemplates = prev.filter(t => t.type === 'whatsapp');
          return [...whatsappTemplates, ...emailTemplates];
        });
        
        // Explicitly set the total count
        setTotal(prev => {
          const newTotal = { ...prev, email: response.data.total || 0 };
          Logger.log('Updated total state:', newTotal);
          return newTotal;
        });
      } else {
        console.error('Unexpected API response format:', response.data);
        setError(prev => ({ ...prev, email: true }));
      }
    } catch (error) {
      console.error('Error fetching email templates:', error);
      setError(prev => ({ ...prev, email: true }));
    } finally {
      setLoading(prev => ({ ...prev, email: false }));
    }
  }, [page, searchInput, mapEmailToUnified]);

 

  
  // Fetch WhatsApp templates
  const fetchWhatsAppTemplates = useCallback(async () => {
    try {
      setLoading(prev => ({ ...prev, whatsapp: true }));
      setError(prev => ({ ...prev, whatsapp: false }));
      
      // Create params object, only adding search if it's not empty
      const params: TemplateParams = {
        page,
        perPage: PER_PAGE
      };
      
      if (searchInput && searchInput.trim().length > 0) {
        params.search = searchInput.trim();
      }
      
      Logger.log('Fetching WhatsApp templates...', params);
      
      const response = await getTemplates(params);
      
      Logger.log('WhatsApp API response:', response?.data);
      
      if (response?.data?.data) {
        // Filter for approved templates
        const approvedTemplates = response.data.data
          .filter((template: any) => template.status === 'APPROVED')
          .map(mapWhatsAppToUnified);
        
       
        
        setUnifiedTemplates(prev => {
          // Keep email templates and replace WhatsApp templates with the new ones
          const emailTemplates = prev.filter(t => t.type === 'email');
          return [...emailTemplates, ...approvedTemplates];
        });
        
        // Explicitly set the total count
        setTotal(prev => {
          const newTotal = { ...prev, whatsapp: response.data.total || 0 };
          Logger.log('Updated whatsapp total state:', newTotal);
          return newTotal;
        });
      } else {
        console.error('Failed to fetch WhatsApp templates or empty response');
        setError(prev => ({ ...prev, whatsapp: true }));
      }
    } catch (error) {
      console.error('Error fetching WhatsApp templates:', error);
      setError(prev => ({ ...prev, whatsapp: true }));
    } finally {
      setLoading(prev => ({ ...prev, whatsapp: false }));
    }
  }, [page, searchInput, mapWhatsAppToUnified]);

  // Fetch templates based on current tab - using React.useRef to avoid double fetching
  const hasFetchedRef = React.useRef<{email: boolean, whatsapp: boolean}>({
    email: false,
    whatsapp: false
  });
  
  useEffect(() => {
    // Only run this effect for search input changes, not for page changes
    const delayDebounceFn = setTimeout(() => {
      // Reset to page 1 when search changes
      setPage(1);
      
      if (currentTab === 'email') {
        fetchEmailTemplates();
      } else {
        fetchWhatsAppTemplates();
      }
    }, 500);
    
    return () => clearTimeout(delayDebounceFn);
  }, [searchInput, currentTab]);

  useEffect(() => {
    if (!isOpen) {
      // Reset fetch status when popup closes
      hasFetchedRef.current = {
        email: false,
        whatsapp: false
      };
      return;
    }

    if (templateType === 'both' || templateType === currentTab) {
      if (currentTab === 'email' && !hasFetchedRef.current.email) {
        Logger.log('Initial fetch of email templates');
        hasFetchedRef.current.email = true;
        fetchEmailTemplates();
      } else if (currentTab === 'whatsapp' && !hasFetchedRef.current.whatsapp) {
        Logger.log('Initial fetch of WhatsApp templates');
        hasFetchedRef.current.whatsapp = true;
        fetchWhatsAppTemplates();
      }
    }
  }, [isOpen, currentTab, templateType, fetchEmailTemplates, fetchWhatsAppTemplates]);

  // Filter templates based on search input - client-side filtering as backup
  useEffect(() => {
    // Get templates of current tab type only
    const currentTabTemplates = unifiedTemplates.filter(template => template.type === currentTab);
    
    Logger.log('Client-side filtering', { 
      currentTab,
      totalTemplates: currentTabTemplates.length
    });
    
    // Set filtered templates to all templates of current type
    // (we rely on server-side search)
    setFilteredTemplates(currentTabTemplates);
  }, [unifiedTemplates, currentTab]);
  
  // Debounced API fetch for searching
  useEffect(() => {
    // Avoid empty search API calls that would cause 422 errors
    const delayDebounceFn = setTimeout(() => {
      if (currentTab === 'email') {
        Logger.log('Triggering email templates fetch after search change');
        fetchEmailTemplates();
      } else {
        Logger.log('Triggering WhatsApp templates fetch after search change');
        fetchWhatsAppTemplates();
      }
    }, 500); // 500ms delay
    
    return () => clearTimeout(delayDebounceFn);
  }, [searchInput, currentTab, fetchEmailTemplates, fetchWhatsAppTemplates]);

  // Check if the content has HTML
  const isHtmlContent = (content: string): boolean => {
    if (!content) return false;
    const htmlTagRegex = /<\/?[\w\s="/.':;#-\/?\[\]]+>/gi;
    return htmlTagRegex.test(content);
  };

  // Render email template preview
  const renderTemplatePreview = (template: UnifiedTemplate) => {
    if (!template) {
      console.error('Undefined template passed to renderTemplatePreview');
      return <p>No preview available</p>;
    }
    
    // Use React.memo pattern to avoid multiple re-renders of the same content
    // This is a key step to prevent the double rendering issue
    const memoizedKey = `preview-${template._id}`;
    
    if (template.type === 'email') {
      const message = template.preview || '';
      const hasHtmlTags = isHtmlContent(message);
      
      if (hasHtmlTags) {
        const sanitizedHTML = DOMPurify.sanitize(message);
        return (
          <div 
            key={memoizedKey}
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
            style={{ 
              maxHeight: '140px', // Further increased maximum height
              overflow: 'hidden', 
              textOverflow: 'ellipsis',
              fontSize: 14 
            }} 
          />
        );
      }
    }
    
    // Plain text preview
    return (
      <p 
        key={memoizedKey}
        style={{ 
          maxHeight: '140px', // Further increased maximum height
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 14,
          margin: 0,
          lineHeight: '1.6' // Better line spacing for readability
        }}
      >
        {template.preview || 'No preview available'}
      </p>
    );
  };

  // Handle template selection
  const handleTemplateClick = (template: UnifiedTemplate) => {
    
    setSelectedTemplate(template._id);
    
    // Keep the original template object for the callback
    const originalTemplate = template.type === 'email' 
      ? template.originalEmailTemplate 
      : template.originalWhatsAppTemplate;
      
    Logger.log('Original template to send back:', originalTemplate);
    
    // We don't immediately call onTemplateSelect here - this just selects it visually
    // The actual selection is triggered by the Save button
  };

  // Handle save button click
  const handleSave = () => {
    Logger.log('Save button clicked, looking for template with ID:', selectedTemplate);
    
    const template = unifiedTemplates.find(t => t._id === selectedTemplate);
    if (template) {
      
      
      const originalTemplate = template.type === 'email' 
        ? template.originalEmailTemplate 
        : template.originalWhatsAppTemplate;
        
      Logger.log('Sending original template back to parent:', originalTemplate);
      onTemplateSelect(originalTemplate);
      onClose();
    } else {
      console.error('No template found with ID:', selectedTemplate);
    }
  };

  // Render template card based on type
  const renderTemplateCard = (template: UnifiedTemplate) => {
    return (
      <div 
        key={template._id}
        onClick={() => handleTemplateClick(template)}
        style={{
          padding: '20px', // Increased padding
          border: `2px solid ${template._id === selectedTemplate ? '#3FAEFD' : '#E5E7EB'}`,
          borderRadius: '10px', // Slightly more rounded corners
          backgroundColor: template._id === selectedTemplate ? '#F0F9FF' : 'white',
          cursor: 'pointer',
          height: '260px', // Further increased height
          width: '100%', // Ensure it takes full width in the grid
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 0.2s ease',
          boxShadow: template._id === selectedTemplate 
            ? '0 4px 8px rgba(0,0,0,0.1)' 
            : '0 1px 3px rgba(0,0,0,0.05)',
          overflow: 'hidden',
          position: 'relative', // For absolute positioning of elements if needed
          marginBottom: '8px' // Add some bottom margin
        }}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginBottom: '8px'
        }}>
          <div style={{
            fontSize: '15px',
            fontWeight: 600,
            color: '#1F2937',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '80%'
          }}>
            {template.name}
          </div>
          
          <div style={{
            padding: '3px 6px',
            fontSize: '11px',
            backgroundColor: template.type === 'email' ? '#EFF6FF' : '#F0FDF4',
            color: template.type === 'email' ? '#3B82F6' : '#22C55E',
            borderRadius: '4px',
            fontWeight: 500
          }}>
            {template.type === 'email' ? 'Email' : 'WhatsApp'}
          </div>
        </div>
        
        {template.subject && (
          <div style={{
            fontSize: '14px',
            fontWeight: 500,
            color: '#4B5563',
            marginBottom: '8px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}>
            <span style={{ fontWeight: 600, color: '#374151' }}>Subject: </span>
            {template.subject}
          </div>
        )}
        
        {template.status && (
          <div style={{
            fontSize: '12px',
            color: '#059669',
            marginBottom: '4px'
          }}>
            {template.status}
          </div>
        )}
        
        <div style={{
          fontSize: '14px', // Larger font
          color: '#6B7280',
          lineHeight: '1.6', // Increased line height for better readability
          display: '-webkit-box',
          WebkitLineClamp: 6, // Show more lines of content
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          flex: 1,
          marginTop: '10px', // More space above the preview
          marginBottom: '10px', // Add space below the preview
          wordBreak: 'break-word' // Handles long words better
        }}>
          {renderTemplatePreview(template)}
        </div>
        
        {template.tags && template.tags.length > 0 && (
          <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
            marginTop: '8px'
          }}>
            {template.tags.slice(0, 2).map((tag, index) => (
              <span key={index} style={{
                fontSize: '10px',
                padding: '2px 6px',
                backgroundColor: '#F3F4F6',
                borderRadius: '4px',
                color: '#4B5563'
              }}>
                {tag}
              </span>
            ))}
            {template.tags.length > 2 && (
              <span style={{
                fontSize: '10px',
                padding: '2px 6px',
                backgroundColor: '#F3F4F6',
                borderRadius: '4px',
                color: '#4B5563'
              }}>
                +{template.tags.length - 2} more
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  // Helper to get the count of current template type
  const getCurrentTemplateCount = () => {
    return total[currentTab] || 0;
  };

  // Render the popup footer
  const renderFooter = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <button
        onMouseEnter={(e) => {
          e.currentTarget.style.color = '#374151';
          e.currentTarget.style.backgroundColor = '#F3F4F6';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.color = '#6B7280';
          e.currentTarget.style.backgroundColor = 'transparent';
        }}
        onClick={() => {
          handleDiscard();
          onClose();
        }}
        style={secondaryButtonStyle}
      >
        Discard
      </button>
      <button
        onClick={handleSave}
        disabled={!selectedTemplate}
        style={primaryButtonStyle(!selectedTemplate)}
      >
        Save Template
      </button>
    </div>
  );

  // Render empty state
  const renderEmptyState = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px 0',
      color: '#6B7280',
      textAlign: 'center'
    }}>
      <div style={{
        fontSize: '64px',
        marginBottom: '16px',
        color: '#D1D5DB'
      }}>
        <i className="ri-file-list-3-line"></i>
      </div>
      <h4 style={{ marginBottom: '8px', color: '#4B5563' }}>No templates found</h4>
      {searchInput ? (
        <p style={{ maxWidth: '300px', margin: '0 auto' }}>
          No templates match your search criteria. Try adjusting your search or clear filters.
        </p>
      ) : (
        <p style={{ maxWidth: '300px', margin: '0 auto' }}>
          {currentTab === 'email' 
            ? "You don't have any email templates yet." 
            : "You don't have any WhatsApp templates yet."}
        </p>
      )}
      
      {searchInput && (
        <button 
          onClick={() => setSearchInput('')}
          style={{
            marginTop: '16px',
            padding: '8px 16px',
            backgroundColor: '#F3F4F6',
            border: 'none',
            borderRadius: '6px',
            color: '#4B5563',
            fontSize: '14px',
            cursor: 'pointer'
          }}
        >
          Clear Search
        </button>
      )}
    </div>
  );

// The core issue appears to be a layout problem. The pagination controls are likely 
// being rendered but are either:
// 1. Hidden by overflow constraints
// 2. Rendered outside the visible area
// 3. Cut off by a fixed height container

// First, modify the main container to ensure it has room for pagination
return (
  <PopupComponent
    isOpen={isOpen}
    onClose={onClose}
    title={templateType === 'both' 
      ? `Templates (${total.email + total.whatsapp})` 
      : `${currentTab === 'email' ? 'Email' : 'WhatsApp'} Templates (${total[currentTab]})`}
    size="lg"
    footer={renderFooter()}
  >
    <div style={{ 
      // Remove fixed height constraint to allow pagination to be visible
      // minHeight: '700px', 
      display: 'flex', 
      flexDirection: 'column',
      maxHeight: '80vh', // Limit max height to 80% of viewport height
      position: 'relative' // Add this to help with absolute positioning
    }}>
      {/* Tabs for switching between template types */}
      {templateType === 'both' && (
        <div style={{ 
          display: 'flex', 
          borderBottom: '1px solid #E5E7EB',
          marginBottom: '16px'
        }}>
          <button 
            onClick={() => setCurrentTab('email')}
            style={tabButtonStyle(currentTab === 'email')}
          >
            Email Templates
          </button>
          <button 
            onClick={() => setCurrentTab('whatsapp')}
            style={tabButtonStyle(currentTab === 'whatsapp')}
          >
            WhatsApp Templates
          </button>
        </div>
      )}
      
      {/* Search input */}
      <div style={{ marginBottom: '16px', position: 'relative' }}>
        <input
          type="text"
          placeholder={`Search ${currentTab} templates...`}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{
            width: '100%',
            padding: '8px 12px 8px 36px',
            borderRadius: '6px',
            border: '1px solid #E5E7EB',
            fontSize: '14px',
            outline: 'none',
            boxShadow: searchInput ? '0 0 0 2px rgba(63, 174, 253, 0.2)' : 'none',
            borderColor: searchInput ? '#3FAEFD' : '#E5E7EB',
          }}
        />
        <i className="ri-search-line" style={{
          position: 'absolute',
          left: '12px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: '#9CA3AF',
          fontSize: '16px'
        }}></i>
        {searchInput && (
          <button
            onClick={() => setSearchInput('')}
            style={{
              position: 'absolute',
              right: '12px',
              top: '50%',
              transform: 'translateY(-50%)',
              background: 'none',
              border: 'none',
              color: '#9CA3AF',
              cursor: 'pointer',
              padding: '0',
              fontSize: '16px'
            }}
          >
            <i className="ri-close-circle-line"></i>
          </button>
        )}
      </div>

      {/* Error state */}
      {error[currentTab] && (
        <div style={{
          color: '#EF4444',
          textAlign: 'center',
          margin: '16px 0',
          padding: '12px',
          backgroundColor: '#FEF2F2',
          borderRadius: '6px',
          fontSize: '14px'
        }}>
          Failed to load templates. Please try again later.
          <button
            onClick={() => currentTab === 'email' ? fetchEmailTemplates() : fetchWhatsAppTemplates()}
            style={{
              display: 'block',
              margin: '8px auto 0',
              background: 'none',
              border: 'none',
              color: '#2563EB',
              textDecoration: 'underline',
              cursor: 'pointer'
            }}
          >
            Retry
          </button>
        </div>
      )}

      {/* Loading state */}
      {loading[currentTab] ? (
        <div style={{ 
          padding: '40px 0',
          textAlign: 'center',
          color: '#6B7280',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1
        }}>
          <div style={{ marginBottom: '16px' }}>
            <i className="ri-loader-4-line" style={{ 
              fontSize: '32px', 
              animation: 'spin 1s linear infinite',
              display: 'inline-block'
            }}></i>
            <style>{`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}</style>
          </div>
          <div>Loading templates...</div>
        </div>
      ) : (
        <>
          {/* Template grid - REDUCE FIXED HEIGHT TO MAKE ROOM FOR PAGINATION */}
          {filteredTemplates.length > 0 ? (
            <div style={{ 
              flex: 1,
              overflowY: 'auto',
              maxHeight: '400px', // Reduced from 560px to ensure pagination is visible
              padding: '4px'
            }}>
              <div style={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
                gap: '24px',
                padding: '12px',
              }}>
                {filteredTemplates.map(renderTemplateCard)}
              </div>
            </div>
          ) : (
            renderEmptyState()
          )}
          
          {/* Pagination - MOVE OUTSIDE SCROLLABLE AREA WITH FIXED STYLES */}
          {!loading[currentTab] && filteredTemplates.length > 0 && total[currentTab] > 0 && (
            <div id="pagination-container" style={{ 
              marginTop: '16px',
              marginBottom: '16px',
              padding: '16px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
              borderTop: '1px solid #E5E7EB',
              backgroundColor: '#FFFFFF',
            }}>
              
              
              
              <button
                onClick={() => setPage((prev) => Math.max(1, prev - 1))}
                disabled={page === 1}
                style={{
                  ...buttonStyle,
                  backgroundColor: page === 1 ? '#F3F4F6' : '#EFF6FF',
                  color: page === 1 ? '#9CA3AF' : '#3B82F6',
                  cursor: page === 1 ? 'not-allowed' : 'pointer',
                }}
              >
                <i className="ri-arrow-left-line" style={{ marginRight: '4px' }}></i>
                Previous
              </button>
              
              <span style={{ 
                color: '#4B5563',
                fontSize: '14px'
              }}>
                Page {page} of {Math.max(1, Math.ceil(total[currentTab] / PER_PAGE))}
              </span>
              
              <button
                onClick={() => setPage((prev) => prev + 1)}
                disabled={page >= Math.ceil(total[currentTab] / PER_PAGE)}
                style={{
                  ...buttonStyle,
                  backgroundColor: page >= Math.ceil(total[currentTab] / PER_PAGE) ? '#F3F4F6' : '#EFF6FF',
                  color: page >= Math.ceil(total[currentTab] / PER_PAGE) ? '#9CA3AF' : '#3B82F6',
                  cursor: page >= Math.ceil(total[currentTab] / PER_PAGE) ? 'not-allowed' : 'pointer',
                }}
              >
                Next
                <i className="ri-arrow-right-line" style={{ marginLeft: '4px' }}></i>
              </button>
            </div>
          )}
        </>
      )}
    </div>
  </PopupComponent>
);
};

export default UnifiedTemplateSelector;