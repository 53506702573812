import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import RangeDatePicker from '../../components/RangeDatePicker';
import { DateRange } from './dashboard';
import styled from 'styled-components';

interface ColumnChartProps {
    title: string;
    data: ChartData[];
    height?: number;
    callBackFun: Function;
    dateRangeValue: DateRange;
    showConversionTable?: boolean;  // Add this prop
  }

interface ChartData {
  label: string;
  value: number;
}

const ColumnChart: React.FC<ColumnChartProps> = ({
  title,
  data,
  height = 350,
  callBackFun,
  dateRangeValue,
}) => {
  const chartOptions: ApexOptions = {
    chart: {
      type: 'bar' as const,
      height: height,
      toolbar: {
        show: false
      },
      fontFamily: 'Gilroy, sans-serif',
      foreColor: '#6B7280',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      dropShadow: {
        enabled: true,
        opacity: 0.1,
        blur: 3,
        left: 1,
        top: 1
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '35%',
        distributed: true,
        dataLabels: {
          position: 'top'
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return val.toLocaleString();
      },
      offsetY: -20,
      style: {
        fontSize: '11px',
        fontFamily: 'Gilroy, sans-serif',
        fontWeight: '500',
        colors: ['#000']
      },
    
    },
    colors: ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6', '#EC4899', '#6366F1'],
    xaxis: {
      categories: data.map(item => item.label),
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Gilroy, sans-serif',
          fontWeight: '500'
        },
        rotate: -45,
        rotateAlways: false,
        trim: true,
        maxHeight: 120
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        formatter: function(val: number) {
          return val.toLocaleString();
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Gilroy, sans-serif',
          fontWeight: '500'
        }
      }
    },
    grid: {
      show: true,
      borderColor: '#F3F4F6',
      strokeDashArray: 4,
      position: 'back',
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      }
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      custom: function({ series, seriesIndex, dataPointIndex, w }: any) {
        const value = series[seriesIndex][dataPointIndex];
        const label = w.globals.labels[dataPointIndex];
        const total = series[seriesIndex].reduce((a: number, b: number) => a + b, 0);
        const percentage = ((value / total) * 100).toFixed(1);
        
        return (
          '<div class="tooltip-custom">' +
            '<div class="tooltip-header">' + label + '</div>' +
            '<div class="tooltip-body">' +
              '<div class="tooltip-value">Value: ' + value.toLocaleString() + '</div>' +
              '<div class="tooltip-percentage">Percentage: ' + percentage + '%</div>' +
            '</div>' +
          '</div>'
        );
      }
    },
    states: {
      hover: {
        filter: {
          type: 'lighten',
          value: 0.05
        }
      },
      active: {
        filter: {
          type: 'darken',
          value: 0.1
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: 0.95,
        opacityTo: 0.75,
        stops: [0, 100]
      }
    }
  };

  const chartSeries = [{
    name: 'Value',
    data: data.map(item => item.value)
  }];

  return (
    <StyledCard>
      <StyledCardHeader>
        <ChartTitle>{title}</ChartTitle>
        <RangeDatePicker
          dateRangeValue={dateRangeValue}
          callBackFun={callBackFun}
        />
      </StyledCardHeader>
      <CardBody>
        {data.length === 0 ? (
          <NoDataMessage>No data available</NoDataMessage>
        ) : (
          <ChartContainer>
            <ReactApexChart
              options={chartOptions}
              series={chartSeries}
              type="bar"
              height={height}
            />
          </ChartContainer>
        )}
      </CardBody>
      <style>
        {`
          .tooltip-custom {
            background: #FFFFFF;
            padding: 12px;
            border-radius: 6px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            border: 1px solid #E5E7EB;
            min-width: 150px;
          }
          .tooltip-header {
            font-weight: 600;
            color: #1F2937;
            padding-bottom: 8px;
            border-bottom: 1px solid #E5E7EB;
            margin-bottom: 8px;
          }
          .tooltip-body {
            color: #4B5563;
            font-size: 13px;
          }
          .tooltip-value {
            margin-bottom: 4px;
          }
          .tooltip-percentage {
            font-size: 12px;
            color: #6B7280;
          }
        `}
      </style>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.06);
  background: #FFFFFF;
  overflow: hidden;
`;

const StyledCardHeader = styled(CardHeader)`
  background: transparent;
  border-bottom: 1px solid #F3F4F6;
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChartTitle = styled.h5`
  margin: 0;
  font-family: 'Gilroy-Bold', sans-serif;
  font-size: 16px;
  color: #1F2937;
  font-weight: 600;
`;

const ChartContainer = styled.div`
  margin: 0.5rem;
  position: relative;
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #6B7280;
  font-family: 'Gilroy-Medium', sans-serif;
  font-size: 14px;
`;

export default ColumnChart;