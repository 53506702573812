import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import PopupComponent from '../../../../components/PopupComponent';
import FileUploadHandler, { FileUploadHandlerRef } from '../../FileUploadHandler';
import { deleteLeadFile } from "../../../../services/utilityService";
import { ContentI, FileWithProgress, Tag, LogLevel } from '../../types';
import { deleteContent } from '../../../../services/contentService';
import { Trash2 } from 'lucide-react';
import GenericConfirmationModal from '../../../../components/GenericConfirmationModal';
import { Logger } from '../../../../utils/logger';

// Logger utility function
const log = (component: string, message: string, level: LogLevel = LogLevel.INFO) => {
  Logger.log(`[${component}] [${level}] ${message}`);
};

interface FileContentProps {
  openFileBox: boolean;
  setOpenFileBox: (open: boolean) => void;
  activeId: string;
  fileData: {
    title: string;
    tags: string[];
    description: string;
    files: FileWithProgress[];
  };
  handleFileForm: (key: string, value: any) => void;
  handleFormSubmit: (data: { details: any; type: string }) => Promise<any>;
  messageTag: Tag[];
  clearData: () => void;
  onSuccessfulSubmit?: () => Promise<void>; // Add this line
}

const FileContent: React.FC<FileContentProps> = ({
  openFileBox,
  setOpenFileBox,
  activeId,
  fileData,
  handleFileForm,
  handleFormSubmit,
  messageTag,
  clearData,
  onSuccessfulSubmit
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileUploadRef = useRef<FileUploadHandlerRef>(null);
  const [showContentDeleteModal, setShowContentDeleteModal] = useState(false);

  // Add logging lines to track component initialization
  log('FileContent', `Initializing with activeId: ${activeId}`, LogLevel.INFO);
  log('FileContent', `Initial file data: ${JSON.stringify({
    title: fileData.title,
    description: fileData.description?.substring(0, 30) + (fileData.description?.length > 30 ? '...' : ''),
    tags: fileData.tags,
    fileCount: fileData.files?.length || 0
  })}`, LogLevel.DEBUG);


  useEffect(() => {
    if (activeId && fileData.files && fileData.files.length > 0) {
      // Format files only if needed
      const formattedFiles = fileData.files.map((file: FileWithProgress) => ({
        name: file.fileName || file.name || '',
        fileName: file.fileName || file.name || '',
        filePath: file.filePath || '',
        size: file.size || 0,
        uploaded: !!file.filePath
      }));
      
      // Only update if really needed
      const currentFileStr = JSON.stringify(fileData.files);
      const newFileStr = JSON.stringify(formattedFiles);
      
      if (currentFileStr !== newFileStr) {
        handleFileForm('files', formattedFiles);
      }
    }
  }, [activeId, fileData.files, handleFileForm]);

  // Hook to log component lifecycle events
  useEffect(() => {
    log('FileContent', 'Component mounted', LogLevel.INFO);
    return () => {
      log('FileContent', 'Component unmounting', LogLevel.INFO);
    };
  }, []);



  // Monitor changes to fileData.files
  useEffect(() => {
    log('FileContent', `Files updated, new count: ${fileData.files?.length || 0}`, LogLevel.DEBUG);
    if (fileData.files?.length > 0) {
      log('FileContent', `File types: ${fileData.files.map(f => 
        f.fileName?.split('.').pop() || f.name?.split('.').pop() || 'unknown').join(', ')}`, LogLevel.DEBUG);
    }
  }, [fileData.files]);

  const handleClose = useCallback(() => {
    log('FileContent', 'Closing dialog and cleaning up', LogLevel.INFO);
    // Reset file upload handler if needed
    if (fileUploadRef.current) {
      log('FileContent', 'Resetting file upload handler', LogLevel.DEBUG);
      fileUploadRef.current.resetFiles();
    }
    clearData();
    setOpenFileBox(false);
  }, [clearData, setOpenFileBox]);


  const headerActions = activeId ? (
    <DeleteButton onClick={() => setShowContentDeleteModal(true)}>
      <Trash2 size={16} />
      Delete
    </DeleteButton>
  ) : null;
  
  const handleContentDelete = useCallback(async () => {
    if (!activeId) return;
    
    log('FileContent', `Deleting content with ID: ${activeId}`, LogLevel.INFO);
    
    try {
      setIsLoading(true);
      const response = await deleteContent(activeId);
      
      if (response.status === 200) {
        log('FileContent', `Content deleted successfully`, LogLevel.INFO);
        toast.success('File content deleted successfully');
        
        // Refresh content list if callback exists
        if (onSuccessfulSubmit) {
          await onSuccessfulSubmit();
        }
        
        // Close the dialog
        setOpenFileBox(false);
        clearData();
      } else {
        log('FileContent', `Unexpected response status: ${response.status}`, LogLevel.WARNING);
        toast.error('Failed to delete content');
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : String(err);
      log('FileContent', `Error deleting content: ${errorMessage}`, LogLevel.ERROR);
      console.error('[FileContent] Error deleting content:', err);
      toast.error('Failed to delete content');
    } finally {
      setIsLoading(false);
    }
  }, [activeId, setOpenFileBox, clearData, onSuccessfulSubmit]);

  const renderFooter = () => (
    <FooterContainer>
      <SaveButton
        // Replace this code in FileContent.tsx
// Find the SaveButton onClick handler and replace it with this implementation:

onClick={async () => {
  log('FileContent', 'Save button clicked', LogLevel.INFO);
  
  // Validate required fields
  log('FileContent', `Validating form data: title=${!!fileData.title.trim()}, tags=${fileData.tags?.length || 0}, files=${fileData.files?.length || 0}`, LogLevel.DEBUG);
  
  if (!fileData.title.trim()) {
    log('FileContent', 'Validation failed: Title is required', LogLevel.WARNING);
    toast.error('Title is required');
    return;
  }
  
  if (!fileData.tags?.length) {
    log('FileContent', 'Validation failed: No tags selected', LogLevel.WARNING);
    toast.error('Please select at least one tag');
    return;
  }
  
  if (!fileData.files?.length && !activeId) {
    log('FileContent', 'Validation failed: No files uploaded for new content', LogLevel.WARNING);
    toast.error('Please upload at least one file');
    return;
  }
  
  log('FileContent', 'Form validation passed', LogLevel.INFO);

  // First create/update the content without files
  const formData = {
    _id: activeId,
    details: {
      title: fileData.title,
      tags: [fileData.tags[0]], // Convert single tag to array
      type: 'file',
      ...(fileData.description && { description: fileData.description }),
    },
    type: 'file'
  };

  try {
    log('FileContent', `Submitting content data: ${JSON.stringify({
      title: formData.details.title,
      description: formData.details.description ? 
        (formData.details.description.substring(0, 30) + (formData.details.description.length > 30 ? '...' : '')) : 
        'No description',
      isUpdate: !!activeId
    })}`, LogLevel.INFO);
    
    setIsLoading(true);
    
    // Create/update the content
    const response = await handleFormSubmit(formData);
    
    // Get the content ID (from response or use activeId if updating)
    const contentId = response?.data?.data?._id || activeId;
    log('FileContent', `Content saved successfully, ID: ${contentId}`, LogLevel.INFO);
    log('FileContent', `Response status: ${response?.status}, has data: ${!!response?.data}`, LogLevel.DEBUG);
    
    // Check if there are any files that need uploading
    const pendingFiles = fileData.files?.filter(file => !file.uploaded && !file.filePath) || [];
    const hasFilesToUpload = pendingFiles.length > 0;
    
    log('FileContent', `Pending files check: ${pendingFiles.length} files need uploading`, LogLevel.INFO);
    
    if (pendingFiles.length > 0) {
      log('FileContent', `Pending files: ${pendingFiles.map(f => f.name).join(', ')}`, LogLevel.DEBUG);
    }
    
    if (hasFilesToUpload && fileUploadRef.current && contentId) {
      log('FileContent', `Starting upload for ${pendingFiles.length} files to contentId: ${contentId}`, LogLevel.INFO);
      
      try {
        const uploadStartTime = performance.now();
        const uploadedFiles = await fileUploadRef.current.uploadPendingFiles(contentId);
        const uploadEndTime = performance.now();
        
        log('FileContent', `Files uploaded successfully: ${uploadedFiles.length} files in ${Math.round(uploadEndTime - uploadStartTime)}ms`, LogLevel.INFO);
        
        if (uploadedFiles.length > 0) {
          log('FileContent', `Uploaded file names: ${uploadedFiles.map(f => f.fileName || f.name).join(', ')}`, LogLevel.DEBUG);
        }
      } catch (uploadError) {
        log('FileContent', `Error uploading files: ${uploadError}`, LogLevel.ERROR);
        console.error('[FileContent] Error uploading files:', uploadError);
        toast.error('Content saved but some files failed to upload');
      }
    } else {
      log('FileContent', 'No files need uploading', LogLevel.INFO);
    }
    
    // Success and cleanup
    log('FileContent', 'Full save operation completed successfully', LogLevel.INFO);
    toast.success('File content saved successfully');
    
    // IMPORTANT FIX: Call the onSuccessfulSubmit function before closing the drawer
    if (onSuccessfulSubmit) {
      try {
        await onSuccessfulSubmit();
        log('FileContent', 'Content list refreshed successfully', LogLevel.INFO);
      } catch (error) {
        log('FileContent', `Error refreshing content: ${error}`, LogLevel.ERROR);
        console.error('Error refreshing content after save:', error);
      }
    }
    
    setOpenFileBox(false);
    clearData();
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    log('FileContent', `Error saving content: ${errorMessage}`, LogLevel.ERROR);
    console.error('[FileContent] Error saving content:', error);
    toast.error('Failed to save content');
  } finally {
    log('FileContent', 'Ending save operation, resetting loading state', LogLevel.INFO);
    setIsLoading(false);
  }
}}
      >
        Save
      </SaveButton>
    </FooterContainer>
  );

  return (
    <PopupComponent
      isOpen={openFileBox}
      onClose={handleClose}
      title={activeId.length > 1 ? 'Edit File' : 'Add File'}
      size="lg"
      loading={isLoading}
      footer={renderFooter()}
      headerActions={headerActions} 
    >
      <FormContainer>
        <div className="user_form_box">
          <label>Title</label>
          <input
            type="text"
            value={fileData.title}
            onChange={(e) => handleFileForm("title", e.target.value)}
            placeholder="Enter title"
          />
        </div>

        <div className="user_form_box">
          <label>Tags</label>
          <div className="all_tags">
  {messageTag.map((tag) => (
    <span
      key={tag.value}
      onClick={() => handleFileForm("tags", tag.value)}
      className={fileData.tags.includes(tag.value) ? "active" : ""}
    >
      {tag.name}
    </span>
  ))}
</div>
        </div>

        <div className="user_form_box">
          <label>Description</label>
          <textarea
            rows={4}
            cols={50}
            value={fileData.description}
            onChange={(e) => handleFileForm("description", e.target.value)}
            placeholder="Enter description"
          />
        </div>

        <div className="user_form_box">
          <label>Files</label>
          <FileUploadHandler
            ref={fileUploadRef}
            files={fileData.files || []}
            onFileChange={(updatedFiles) => {
              log('FileContent', `Files updated via FileUploadHandler: ${updatedFiles.length} files`, LogLevel.INFO);
              
              if (updatedFiles.length > 0) {
                log('FileContent', `Updated files: ${updatedFiles.map(f => 
                  `${f.name || f.fileName || 'unnamed'}${f.uploaded ? ' (uploaded)' : ' (pending)'}`
                ).join(', ')}`, LogLevel.DEBUG);
              }
              
              handleFileForm("files", updatedFiles);
            }}
            onDeleteServerFile={async (path) => {
              log('FileContent', `Deleting server file: ${path}`, LogLevel.INFO);
              
              try {
                setIsLoading(true);
                const deletePayload = {
                  filePaths: [path],
                  type: 'content',
                  content: activeId
                };
                
                log('FileContent', `Sending delete request for contentId: ${activeId}`, LogLevel.DEBUG);
                const deleteStartTime = performance.now();
                const response = await deleteLeadFile(deletePayload);
                const deleteEndTime = performance.now();
                
                if (response.status === 200) {
                  log('FileContent', `File deleted successfully in ${Math.round(deleteEndTime - deleteStartTime)}ms`, LogLevel.INFO);
                  
                  // Count remaining files
                  const remainingFiles = fileData.files?.filter(file => file.filePath !== path) || [];
                  log('FileContent', `Remaining files after deletion: ${remainingFiles.length}`, LogLevel.DEBUG);
                  
                  // Update fileData to remove the deleted file
                  handleFileForm("files", remainingFiles);
                  toast.success(response.data.message || 'File deleted successfully');
                } else {
                  log('FileContent', `Unexpected response status: ${response.status}`, LogLevel.WARNING);
                  toast.error('Unexpected response from server');
                }
              } catch (err) {
                const errorMessage = err instanceof Error ? err.message : String(err);
                log('FileContent', `Error deleting file: ${errorMessage}`, LogLevel.ERROR);
                console.error('[FileContent] Error deleting file:', err);
                toast.error('Failed to delete file');
              } finally {
                setIsLoading(false);
              }
            }}
            maxSize={100}
            acceptedTypes={['.mp4', '.wmv', '.pdf', '.jpeg', '.jpg', '.png', '.gif', '.webp']}
            activeId={activeId}
          />
        </div>
      </FormContainer>
      <GenericConfirmationModal
      showModal={showContentDeleteModal}
      toggleModal={() => setShowContentDeleteModal(false)}
      onConfirmation={handleContentDelete}
      title="Delete File Content"
      message="Are you sure you want to delete this entire file content? This action cannot be undone."
      confirmText="Delete"
      cancelText="Cancel"
      isWarning={true}
    />
    </PopupComponent>
  );
};

const FormContainer = styled.div`
  padding: 24px 32px;
  overflow-y: auto;
  max-height: calc(90vh - 140px);
  background: #fff;

  .user_form_box {
    margin-bottom: 24px;
    
    label {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      color: #4A4A4A;
      font-size: 14px;
    }

    input[type="text"], textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #E5E7EB;
      border-radius: 8px;
      font-size: 14px;
      color: #374151;
      background: #fff;
      transition: all 0.2s ease;
      
      &::placeholder {
        color: #9CA3AF;
      }
      
      &:focus {
        outline: none;
        border-color: #3FAEFB;
        box-shadow: 0 0 0 2px rgba(63, 174, 251, 0.1);
      }
    }

    textarea {
      resize: vertical;
      min-height: 120px;
      line-height: 1.5;
    }

    .all_tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;

      span {
        padding: 8px 16px;
        border-radius: 6px;
        background: #F3F4F6;
        cursor: pointer;
        font-size: 14px;
        color: #4B5563;
        transition: all 0.2s ease;
        border: 1px solid transparent;
        
        &:hover {
          background: #E5E7EB;
        }
        
        &.active {
          background: #EBF5FF;
          color: #3FAEFB;
          border-color: #3FAEFB;
        }
      }
    }
  }

  .file-section {
    margin-top: 16px;

    .upload-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px 16px;
      background: #F0F9FF;
      border: 1px dashed #3FAEFB;
      border-radius: 6px;
      color: #3FAEFB;
      cursor: pointer;
      transition: all 0.2s ease;

      svg {
        margin-right: 8px;
      }

      &:hover {
        background: #E0F2FE;
      }
    }
  }

  .file_name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    background: #F3F4F6;
    border-radius: 6px;
    margin-top: 8px;
    font-size: 14px;
    
    button {
      background: none;
      border: none;
      color: #EF4444;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
`;

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  background-color: #fee2e2;
  color: #dc2626;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #fecaca;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid #E5E7EB;
`;

const SaveButton = styled.button`
  padding: 10px 24px;
  background-color: #3FAEFB;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  height: 42px;
  min-width: 120px;
  max-width: 240px;
  border: none;
  font-weight: 500;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #2E9FEA;
  }
  
  &:active {
    background-color: #2B96DE;
  }
`;

export default FileContent;