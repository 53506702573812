import React, { useCallback, useMemo } from 'react';
import { Card } from 'antd';
import { X } from 'lucide-react';
import { getStatusColor } from '../QuotationList.types';
import { Logger } from '../../../utils/logger';

// Types
export interface StatusTotal {
  type: string;
  count: number;
  total: number;
}

export interface QuotationStatusManagementProps {
  statusTotals: StatusTotal[];
  onStatusChange: (status: string | null) => void;
  selectedStatus: string | null;
  loading?: boolean;
}

export const QuotationStatusManagement: React.FC<QuotationStatusManagementProps> = ({
  statusTotals,
  onStatusChange,
  selectedStatus,
  loading = false
}) => {
  Logger.log('QuotationStatusManagement: Rendering with', { 
    statusCount: statusTotals.length,
    selectedStatus,
    statusTotals 
  });

  // Get contrast text color for a background
  const getContrastTextColor = useCallback((hexColor: string): string => {
    try {
      // Handle HSL colors
      if (hexColor.startsWith('hsl')) {
        const hslMatch = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/.exec(hexColor);
        if (hslMatch) {
          const lightness = parseInt(hslMatch[3], 10);
          return lightness > 50 ? '#333333' : '#ffffff';
        }
      }
      
      // Handle hex colors
      let colorHex = hexColor.replace('#', '');
      
      if (colorHex.length === 3) {
        colorHex = colorHex.split('').map(c => c + c).join('');
      }
      
      if (colorHex.length !== 6) {
        return '#333333';
      }
    
      const r = parseInt(colorHex.substr(0, 2), 16);
      const g = parseInt(colorHex.substr(2, 2), 16);
      const b = parseInt(colorHex.substr(4, 2), 16);
      
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      
      return yiq >= 128 ? '#333333' : '#ffffff';
    } catch (error) {
      console.error('Error calculating contrast color:', error);
      return '#333333'; // Safe fallback to dark text
    }
  }, []);

  // Get display name for a status
  const getStatusDisplayName = useCallback((status: string): string => {
    if (!status) return '';
    
    // Format the status string
    return status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase());
  }, []);

  // Sort status totals alphabetically
  const sortedStatusTotals = useMemo(() => {
    if (!statusTotals || statusTotals.length === 0) return [];
    
    // Create a copy and sort alphabetically by type
    const sorted = [...statusTotals].sort((a, b) => {
      // Sort alphabetically by the display name (with spaces instead of underscores)
      const displayNameA = a.type.replace('_', ' ').toLowerCase();
      const displayNameB = b.type.replace('_', ' ').toLowerCase();
      
      return displayNameA.localeCompare(displayNameB);
    });
    
    Logger.log('Alphabetically sorted status totals:', sorted.map(s => s.type));
    return sorted;
  }, [statusTotals]);

  // Handle status selection
  const handleStatusSelect = useCallback((type: string) => {
    Logger.log('QuotationStatusManagement: Status selected', { 
      type, 
      currentSelected: selectedStatus 
    });
    onStatusChange(selectedStatus === type ? null : type);
  }, [selectedStatus, onStatusChange]);

  return (
    <div className="status-management-wrapper">
      <div 
        className="d-flex flex-nowrap overflow-auto"
        style={{
          padding: '8px 0 8px 0',  // Reduced padding especially at bottom
          marginBottom: '8px',
          gap: '8px'
        }}
      >
        {sortedStatusTotals.map((status) => {
          Logger.log(`Rendering status card: ${status.type}`); // Log each status as it renders
          const statusColor = getStatusColor(status.type);
          return (
            <div 
              key={status.type}
              style={{ 
                minWidth: '200px',
                maxWidth: '250px',
                marginRight: '16px' // Add consistent spacing between cards
              }}
            >
              <Card
                className="status-card h-100"
                style={{
                    cursor: 'pointer',
                    backgroundColor: selectedStatus === status.type ? 
                      `${statusColor}22` : // Light version of color
                      '#ffffff',
                    borderColor: selectedStatus === status.type ? statusColor : '#dee2e6',
                    borderWidth: selectedStatus === status.type ? '2px' : '1px',
                    borderRadius: '8px'
                  }}
                onClick={() => handleStatusSelect(status.type)}
                bodyStyle={{ 
                  padding: '8px 12px',
                  borderRadius: '8px'
                }}
              >
                {/* Close button for selected card */}
                {selectedStatus === status.type && (
                  <div 
                    className="position-absolute"
                    style={{ 
                      top: '8px', 
                      right: '8px',
                      zIndex: 1
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      Logger.log('Clearing status filter');
                      onStatusChange(null);
                    }}
                  >
                    <X 
                      size={14}
                      className="text-muted" 
                      style={{ 
                        cursor: 'pointer',
                        opacity: 0.6
                      }}
                    />
                  </div>
                )}

                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-1">
                    <span
                      className="rounded-circle d-inline-block me-2"
                      style={{ 
                        width: '8px', 
                        height: '8px',
                        backgroundColor: statusColor
                      }}
                    />
                    <span className="text-capitalize text-secondary" style={{ fontSize: '0.875rem' }}>
                      {status.type.replace('_', ' ')} - {status.count}
                    </span>
                  </div>
                  <div 
                    className="fw-medium"
                    style={{ fontSize: '1.125rem' }}
                  >
                    ₹ {status.total.toLocaleString()}
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      </div>

      {/* Custom scrollbar styles */}
      <style>
        {`
         .status-management-wrapper {
  margin: -8px -16px 8px -16px;
  padding: 8px 16px;
}
          
          .status-management-wrapper .overflow-auto::-webkit-scrollbar {
            height: 6px;
          }
          
          .status-management-wrapper .overflow-auto::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 3px;
          }
          
          .status-management-wrapper .overflow-auto::-webkit-scrollbar-thumb {
            background: #d1d1d1;
            border-radius: 3px;
          }
          
          .status-management-wrapper .overflow-auto::-webkit-scrollbar-thumb:hover {
            background: #b1b1b1;
          }

          .status-card {
            box-shadow: 0 1px 3px rgba(0,0,0,0.1);
            transition: all 0.2s ease-in-out;
          }

          .status-card:hover {
            box-shadow: 0 2px 8px rgba(0,0,0,0.1);
            transform: translateY(-1px);
          }
          
          /* Ensure first and last cards have proper spacing */
          .status-management-wrapper .overflow-auto > div:first-child {
            margin-left: 4px;
          }
          
          .status-management-wrapper .overflow-auto > div:last-child {
            margin-right: 4px;
          }
        `}
      </style>
    </div>
  );
};

export default QuotationStatusManagement;