import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ErrorText from "../../components/errorText";
import { Logger } from '../../utils/logger';

// Basic types for conditions
export interface AutomationCondition {
  id: string;
  fieldId: string;
  operator: string;
  value: string;
  logicOperator?: 'AND' | 'OR';
  customFieldValue?: string; 
}

export interface AutomationConditionBlockProps {
  onChange: (conditions: AutomationCondition[]) => void;
  initialConditions?: AutomationCondition[];
  isVisible: boolean;
  isLoading?: boolean;
  title?: string;
  description?: string;
  stepNumber?: number;
  eventType?: string;
}

// Operator mapping for each field type - only using supported operators
const FIELD_OPERATORS: Record<string, {value: string, label: string}[]> = {
  name: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  email: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  phone: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  amount: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' },
    { value: 'greater_than', label: 'greater than' },
    { value: 'greater_than_equal', label: 'greater than or equal to' },
    { value: 'less_than', label: 'less than' },
    { value: 'less_than_equal', label: 'less than or equal to' }
  ],
  text: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  number: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' },
    { value: 'greater_than', label: 'greater than' },
    { value: 'greater_than_equal', label: 'greater than or equal to' },
    { value: 'less_than', label: 'less than' },
    { value: 'less_than_equal', label: 'less than or equal to' }
  ],
  selection: [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ],
  'multi-selection': [
    { value: 'equals', label: 'equal to' },
    { value: 'not_equals', label: 'not equal to' }
  ]
};

/**
 * AutomationConditionBlock component - Handles field-level conditions for automation
 */
const AutomationConditionBlock: React.FC<AutomationConditionBlockProps> = ({
  onChange,
  initialConditions = [],
  isVisible,
  isLoading = false,
  title = "Field Criteria",
  description = "Define conditions for lead filtering",
  stepNumber = 2,
  eventType
}) => {
    const initializedRef = useRef(false);
  // State for storing conditions
  const [conditions, setConditions] = useState<AutomationCondition[]>([]);
  
  // Ref to track previous conditions for comparison
  const prevConditionsRef = useRef<string>("");

  // Get field data from Redux store and filter out location, date, and time fields
  const customFormFields = useSelector((state: any) => {
    const fields = state?.rootReducers?.user?.userPreferences?.customForm || [];
    // Filter out location, date, and time type fields
    return fields.filter((field: any) => 
      field.type !== 'location' && 
      field.type !== 'date' && 
      field.type !== 'time'
    );
  });

  // Set conditions from props when they change
  useEffect(() => {
    // Skip if we've already initialized and no new initialConditions are provided
    if (initializedRef.current && (!initialConditions || initialConditions.length === 0)) {
      return;
    }
    
    Logger.log('[AutomationConditionBlock] Initializing with eventType:', eventType);
    Logger.log('[AutomationConditionBlock] Initial conditions:', initialConditions);
    
    // If we have initialConditions, use them
    if (initialConditions && initialConditions.length > 0) {
      Logger.log('[AutomationConditionBlock] Using provided initial conditions');
      setConditions([...initialConditions]);
      initializedRef.current = true;
      return;
    }
    
    // Otherwise set defaults based on event type
    if (eventType === 'create_quotation' || eventType === 'create_invoice' || eventType === 'create_order') {
      Logger.log('[AutomationConditionBlock] Setting default total field condition');
      setConditions([{
        id: generateId(),
        fieldId: 'total',
        operator: 'greater_than',
        value: '',
        logicOperator: undefined
      }]);
    } 
    else if (eventType === 'edit_quotation' || eventType === 'edit_invoice' || eventType === 'edit_order') {
      Logger.log('[AutomationConditionBlock] Setting default status field condition');
      setConditions([{
        id: generateId(),
        fieldId: 'status',
        operator: 'equals',
        value: '',
        logicOperator: undefined
      }]);
    }
    // ADD NEW CONDITION HERE FOR CALL_DURATION
    else if (eventType === 'call_duration') {
      Logger.log('[AutomationConditionBlock] Setting default call duration condition');
      setConditions([{
        id: generateId(),
        fieldId: 'extraDetails.duration',
        operator: 'greater_than',
        value: '',
        logicOperator: undefined
      }]);
    }
    // ADD NEW CONDITION HERE FOR CALL_COUNT
    else if (eventType === 'call_count') {
      Logger.log('[AutomationConditionBlock] Setting default call count condition');
      setConditions([{
        id: generateId(),
        fieldId: 'count',
        operator: 'greater_than',
        value: '',
        logicOperator: undefined
      }]);
    }
    
    initializedRef.current = true;
  }, [eventType, initialConditions]);

  // Update parent when conditions change
// Add this before useEffect that updates parent
useEffect(() => {
    const currentConditionsStr = JSON.stringify(conditions);
    
    // Only notify parent if conditions actually changed and all values are filled
    if (currentConditionsStr !== prevConditionsRef.current) {
      // Check if all conditions have values before sending to parent
      const allConditionsFilled = conditions.every(condition => 
        condition.fieldId && condition.operator && condition.value !== '');
      
      if (allConditionsFilled) {
        onChange(conditions);
        prevConditionsRef.current = currentConditionsStr;
      } else {
        // Don't update parent but still update reference to prevent re-renders
        prevConditionsRef.current = currentConditionsStr;
      }
    }
  }, [conditions, onChange]);

  // Generate a unique ID for new conditions
  const generateId = () => `condition-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

// Add this function to the component
const getContextualHelpText = () => {
    if (eventType === 'create_quotation' || eventType === 'create_invoice' || eventType === 'create_order') {
      const documentType = eventType.split('_')[1];
      return (
        <>
          <strong>Example:</strong> Where <i>Total</i> is <i>greater than</i> "5000"
          <br />
          <strong>Purpose:</strong> Automatically identify high-value {documentType}s for special handling. Use this to prioritize important sales or flag big-ticket items.
        </>
      );
    } else if (eventType === 'edit_quotation' || eventType === 'edit_invoice' || eventType === 'edit_order') {
      const documentType = eventType.split('_')[1];
      let statusExample = documentType === 'quotation' ? "Approved" : 
                          documentType === 'invoice' ? "Paid" : "Shipped";
      
      return (
        <>
          <strong>Example:</strong> Where <i>Status</i> is <i>equal to</i> "{statusExample}"
          <br />
          <strong>Purpose:</strong> Trigger actions when a {documentType}'s status changes. Perfect for follow-ups when quotes are approved, orders are shipped, or invoices are paid.
        </>
      );
    } else if (eventType === 'call_duration') {
      return (
        <>
          <strong>Example:</strong> Where <i>Duration</i> is <i>greater than</i> "60" seconds
          <br />
          <strong>Purpose:</strong> Identify when you've successfully connected with a lead. Great for sending follow-up messages after meaningful conversations or moving leads to a different stage after successful calls.
        </>
      );
    } else if (eventType === 'call_count') {
      return (
        <>
          <strong>Example:</strong> Where <i>Count</i> is <i>greater than</i> "5"
          <br />
          <strong>Purpose:</strong> Track engagement based on total number of calls (incoming and outgoing). Use this to identify highly engaged leads who you've called multiple times or who have called you frequently.
        </>
      );
    } else if (eventType === 'new_lead_added' || eventType === 'edit_lead') {
      return (
        <>
          <strong>Example:</strong> Where <i>Field Name</i> is <i>equal to</i> "High Priority"
          <br />
          <strong>Note:</strong> Values are case sensitive. "High Priority" is different from "high priority".
        </>
      );
    }
    
    // Default case
    return (
      <>
        <strong>Example:</strong> Where <i>Field</i> is <i>equal to</i> "Example Value"
        <br />
        <strong>Note:</strong> Specify conditions to make your automation more targeted.
      </>
    );
  };

// Add a new condition
const addCondition = () => {
    // For invoice/quotation/order creation events - use total field
    if (eventType === 'create_quotation' || eventType === 'create_invoice' || eventType === 'create_order') {
      const newCondition: AutomationCondition = {
        id: generateId(),
        fieldId: 'total',
        operator: 'greater_than',
        value: '',
        logicOperator: 'AND'
      };
      setConditions(prevConditions => [...prevConditions, newCondition]);
      return;
    }
  
    // For invoice/quotation/order edit events - use status field
    if (eventType === 'edit_quotation' || eventType === 'edit_invoice' || eventType === 'edit_order') {
      const newCondition: AutomationCondition = {
        id: generateId(),
        fieldId: 'status',
        operator: 'equals',
        value: '',
        logicOperator: 'AND'
      };
      setConditions(prevConditions => [...prevConditions, newCondition]);
      return;
    }
  
    // For call events - keep original logic
    if (eventType === 'call_duration') {
      const newCondition: AutomationCondition = {
        id: generateId(),
        fieldId: 'extraDetails.duration',
        operator: 'greater_than',
        value: '',
        logicOperator: 'AND'
      };
      setConditions(prevConditions => [...prevConditions, newCondition]);
      return;
    }
  
    if (eventType === 'call_count') {
      const newCondition: AutomationCondition = {
        id: generateId(),
        fieldId: 'count',
        operator: 'greater_than',
        value: '',
        logicOperator: 'AND'
      };
      setConditions(prevConditions => [...prevConditions, newCondition]);
      return;
    }
    
    // Default case for other event types
    const defaultField = customFormFields[0]?.value || '';
    const defaultType = customFormFields[0]?.type || 'text';
    const defaultOperator = FIELD_OPERATORS[defaultType]?.[0]?.value || 'equals';
  
    const newCondition: AutomationCondition = {
      id: generateId(),
      fieldId: defaultField,
      operator: defaultOperator,
      value: '',
      logicOperator: 'AND'
    };
  
    setConditions(prevConditions => [...prevConditions, newCondition]);
  };


  const isSingleConditionEvent = (eventType?: string): boolean => {
    // List of events that should have exactly one condition
    const singleConditionEvents = [
      'create_quotation', 
      'create_invoice', 
      'create_order',
      'edit_quotation',
      'edit_invoice', 
      'edit_order',
      'call_duration',
      'call_count'
    ];
    
    return eventType ? singleConditionEvents.includes(eventType) : false;
  };

 // Modify the removeCondition function to prevent removing the last condition for single-condition events
const removeCondition = (id: string) => {
  // For single-condition events, don't allow removing if it's the only condition
  if (isSingleConditionEvent(eventType) && conditions.length <= 1) {
    return; // Prevent removing the last condition
  }
  
  setConditions(prevConditions => prevConditions.filter(condition => condition.id !== id));
};

  // Update a specific field in a condition
  const updateCondition = (id: string, field: keyof AutomationCondition, value: string) => {
    setConditions(prevConditions => prevConditions.map(condition => {
      if (condition.id === id) {
        // Special handling when field changes - reset operator based on new field type
        if (field === 'fieldId') {
          const fieldObj = customFormFields.find((f: any) => f.value === value);
          const fieldType = fieldObj?.type || 'text';
          const defaultOperator = FIELD_OPERATORS[fieldType]?.[0]?.value || 'equals';
          
          return {
            ...condition,
            fieldId: value,
            operator: defaultOperator,
            value: '' // Reset value when field changes
          };
        }
        
        return { ...condition, [field]: value };
      }
      return condition;
    }));
  };

  // Update the logic operator (AND/OR)
  const updateLogicOperator = (id: string, value: 'AND' | 'OR') => {
    setConditions(prevConditions => prevConditions.map(condition => {
      if (condition.id === id) {
        return { ...condition, logicOperator: value };
      }
      return condition;
    }));
  };

  // Get field type from field ID
  const getFieldType = (fieldId: string) => {
    const field = customFormFields.find((f: any) => f.value === fieldId);
    return field?.type || 'text';
  };

  // Render operators dropdown based on field type

  const renderOperators = (fieldId: string) => {
    // For custom field entries, show all operators
    if (fieldId === 'custom_field_entry') {
      // Specify the type explicitly
      const allOperators: {value: string, label: string}[] = [];
      
      Object.values(FIELD_OPERATORS).forEach(operators => {
        operators.forEach(op => {
          if (!allOperators.some(existing => existing.value === op.value)) {
            allOperators.push(op);
          }
        });
      });
      
      return allOperators.map((op, index) => (
        <option key={index} value={op.value}>{op.label}</option>
      ));
    }
    
    // For creation events, only allow greater than operators
    if ((eventType === 'create_quotation' || eventType === 'create_invoice' || 
        eventType === 'create_order') && fieldId === 'total') {
      return [
        { value: 'greater_than', label: 'greater than' },
        { value: 'greater_than_equal', label: 'greater than or equal to' }
      ].map((op, index) => (
        <option key={index} value={op.value}>{op.label}</option>
      ));
    }
    
    // For call events, use number operators
    if ((eventType === 'call_duration' && fieldId === 'extraDetails.duration') || 
        (eventType === 'call_count' && fieldId === 'count')) {
      return FIELD_OPERATORS['number'].map((op, index) => (
        <option key={index} value={op.value}>{op.label}</option>
      ));
    }
    
    // For edit events, only allow equals operator
    if ((eventType === 'edit_quotation' || eventType === 'edit_invoice' || 
        eventType === 'edit_order') && fieldId === 'status') {
      return [
        { value: 'equals', label: 'equal to' }
      ].map((op, index) => (
        <option key={index} value={op.value}>{op.label}</option>
      ));
    }
    
    // Default case - determine by field type
    const fieldType = getFieldType(fieldId);
    const operators = FIELD_OPERATORS[fieldType] || FIELD_OPERATORS.text;
    
    return operators.map((op, index) => (
      <option key={index} value={op.value}>{op.label}</option>
    ));
  };

 // Render appropriate value input based on field type and operator
const renderValueInput = (condition: AutomationCondition) => {
    const { fieldId, operator, value } = condition;
    
    // Handler for value changes
    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      updateCondition(condition.id, 'value', e.target.value);
    };
    
    // For creation events with total field
    if ((eventType === 'create_quotation' || eventType === 'create_invoice' || 
        eventType === 'create_order') && fieldId === 'total') {
      return (
        <input
          type="number"
          className="form-control"
          placeholder="Enter amount..."
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
          min="0"
        />
      );
    }
    
    // For edit events with status field
    if ((eventType === 'edit_quotation' || eventType === 'edit_invoice' || 
        eventType === 'edit_order') && fieldId === 'status') {
      
      // Get status options from localStorage
      let statusOptions: {color: string, name: string, value: string, isDefault?: boolean}[] = [];
      
      try {
        const userPrefs = localStorage.getItem("user_preferences");
        if (userPrefs) {
          const preferences = JSON.parse(userPrefs);
          
          if (eventType === 'edit_quotation' && preferences?.quotationCustomization?.quotationStatus) {
            statusOptions = preferences.quotationCustomization.quotationStatus;
          } else if (eventType === 'edit_invoice' && preferences?.invoiceCustomization?.invoiceStatus) {
            statusOptions = preferences.invoiceCustomization.invoiceStatus;
          } else if (eventType === 'edit_order' && preferences?.orderCustomization?.orderStatus) {
            statusOptions = preferences.orderCustomization.orderStatus;
          }
        }
      } catch (error) {
        console.error('Error loading status options from localStorage:', error);
      }
      
      return (
        <select
          className="form-select"
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
        >
            
          <option value="">Select Status</option>
          {statusOptions && statusOptions.length > 0 ? (
            statusOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.name || option.value}
              </option>
            ))
          ) : (
            <option value="" disabled>No status options available</option>
          )}
        </select>
      );
    }
    
    // For call events
    if ((eventType === 'call_duration' && fieldId === 'extraDetails.duration') ||
        (eventType === 'call_count' && fieldId === 'count')) {
      return (
        <input
          type="number"
          className="form-control"
          placeholder={`Enter ${eventType === 'call_duration' ? 'seconds' : 'value'}...`}
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
          min="0"
        />
      );
    }
    
    // For fields with options (selection, multi-selection)
    const fieldObj = customFormFields.find((f: any) => f.value === fieldId);
    const fieldType = fieldObj?.type || 'text';
    
    if ((fieldType === 'selection' || fieldType === 'multi-selection') && fieldObj?.options) {
      return (
        <select
          className="form-select"
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
        >
          <option value="">Select</option>
          {fieldObj.options.map((option: string, index: number) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }
    
    // For date fields
    if (fieldType === 'date') {
      return (
        <input
          type="date"
          className="form-control"
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
        />
      );
    }
    
    // For number fields
    if (fieldType === 'number' || fieldType === 'amount') {
      return (
        <input
          type="number"
          className="form-control"
          placeholder="Enter value..."
          value={value}
          onChange={handleValueChange}
          disabled={isLoading}
        />
      );
    }
    
    // Default to text input
    return (
      <input
        type="text"
        className="form-control"
        placeholder="Enter value..."
        value={value}
        onChange={handleValueChange}
        disabled={isLoading}
      />
    );
  };
  
  // Only render content if visible
  if (!isVisible) {
    return null;
  }

  return (
    <div className="col-md-12 condition-block">
      <span className="auto_dot">
        <label className="dot_no">{stepNumber}</label>
      </span>
      <label className="when_then_label">{title}</label>
      <span className="when_then_trigger">
        {description}
      </span>
      
      {/* Add contextual condition example and note */}
<div className="mt-2 mb-3 p-2 bg-light rounded">
  <small className="text-muted">
    {getContextualHelpText()}
  </small>
</div>
      
      {/* Condition Rows */}
      {conditions.map((condition, index) => (
        <div key={condition.id} className="col-md-12 mt-3">
          <div className="row align-items-center">
          {/* Field Selector */}
        
{/* Field Selector */}
{/* Field Selector */}
<div className="col-md-3">
  {condition.fieldId === 'custom_field_entry' ? (
    // Show text input when custom field option is selected
    <input
      type="text"
      className="form-control"
      value={condition.customFieldValue || ''}
      onChange={(e) => {
        updateCondition(condition.id, 'customFieldValue', e.target.value);
      }}
      placeholder="Enter custom field name"
      disabled={isLoading}
    />
  ) : (
    <select
      className="form-select"
      value={condition.fieldId}
      onChange={(e) => updateCondition(condition.id, 'fieldId', e.target.value)}
      disabled={isLoading || 
                eventType === 'call_duration' || 
                eventType === 'call_count' || 
                eventType === 'create_quotation' || 
                eventType === 'create_invoice' || 
                eventType === 'create_order' ||
                eventType === 'edit_quotation' ||
                eventType === 'edit_invoice' ||
                eventType === 'edit_order'}
    >
      {/* Existing options */}
      {eventType === 'call_duration' ? (
        <option value="extraDetails.duration">Call Duration</option>
      ) : eventType === 'call_count' ? (
        <option value="count">Call Count</option>
      ) : (eventType === 'create_quotation' || eventType === 'create_invoice' || eventType === 'create_order') ? (
        <option value="total">Total</option>
      ) : (eventType === 'edit_quotation' || eventType === 'edit_invoice' || eventType === 'edit_order') ? (
        <option value="status">Status</option>
      ) : (
        <>
          <option value="">Select Field</option>
          {customFormFields.map((field: any, idx: number) => (
            <option key={idx} value={field.value}>
              {field.name}
            </option>
          ))}
          {/* Add this option for all events that can use custom fields */}
          {(eventType === 'create_lead' || eventType === 'edit_lead' || eventType === 'new_lead_added') && (
            <option value="custom_field_entry">Enter custom field</option>
          )}
        </>
      )}
    </select>
  )}
</div>
            
            {/* Operator Selector */}
            <div className="col-md-3">
              <select
                className="form-select"
                value={condition.operator}
                onChange={(e) => updateCondition(condition.id, 'operator', e.target.value)}
                disabled={isLoading || !condition.fieldId}
              >
                <option value="">Select Operator</option>
                {renderOperators(condition.fieldId)}
              </select>
            </div>
            
            {/* Value Input */}
            <div className="col-md-3">
              {renderValueInput(condition)}
            </div>
            
          {/* Remove Button */}
<div className="col-md-1">
  {/* Only show remove button if it's not a single-condition event OR if there's more than one condition */}
  {(!isSingleConditionEvent(eventType) || conditions.length > 1) && (
    <button
      type="button"
      className="btn btn-outline-danger btn-sm"
      onClick={() => removeCondition(condition.id)}
      disabled={isLoading}
    >
      <i className="fa fa-times"></i>
    </button>
  )}
</div>
            
            {/* Logic Operator (not for last condition) */}
          
{index < conditions.length - 1 && (
  <div className="col-md-2">
    <select
      className="form-select"
      value="AND"  // Force value to always be AND
      disabled={true}  // Disable the dropdown completely
      onChange={(e) => updateLogicOperator(condition.id, e.target.value as 'AND' | 'OR')}
    >
      <option value="AND">AND</option>
      {/* Remove OR option altogether */}
    </select>
  </div>
)}
          </div>
        </div>
      ))}
      
      {/* Add Condition Button */}
  
{/* Add Condition Button - Only show for non-single-condition events */}
{!isSingleConditionEvent(eventType) && (
  <div className="col-md-12 mt-3">
    <button
      type="button"
      className="btn btn-outline-primary btn-sm"
      onClick={addCondition}
      disabled={isLoading}
    >
      <i className="fa fa-plus"></i> Add Condition
    </button>
  </div>
)}
    </div>
  );
};

export default React.memo(AutomationConditionBlock);