import React, { useState } from 'react';
import { message } from 'antd';
import { Share2, Download, Settings } from 'lucide-react';
import { InvoiceViewerProps } from './Invoice';
import PopupComponent from '../../components/PopupComponent';
import styled from 'styled-components';
import { BUCKET_URL } from '../../config/config';
import toast from 'react-hot-toast';
import { Logger } from '../../utils/logger';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 70vh;
  min-height: 600px;
`;

const IframeContainer = styled.div`
  position: relative;
  background: white;
  border-radius: 8px;
  flex: 1;
  overflow: hidden;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  display: block;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const Button = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: ${props => props.primary ? 'none' : '1px solid #e0e0e0'};
  background: ${props => props.primary ? '#1677ff' : 'white'};
  color: ${props => props.primary ? 'white' : '#666'};
  
  &:hover {
    opacity: 0.9;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 24px;
`;

const ErrorMessage = styled.div`
  color: #ff4d4f;
  margin-bottom: 16px;
`;

const LoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InvoiceViewer: React.FC<InvoiceViewerProps> = ({
  visible,
  onClose,
  invoiceId,
  token,
  userId,
  onEdit,
  order,
  bucketUrl
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [iframeError, setIframeError] = useState<boolean>(false);

  const getWebViewUrl = (): string => {
    const cleanToken = token ? token.replace('Bearer ', '') : '';
    return `https://integration.3sigmacrm.com/invoice/${invoiceId}?token=${cleanToken}`;
  };

  const handleDownload = () => {
    if (!order?.invoiceUrl) return;
    const fileUrl = (bucketUrl || BUCKET_URL) + order.invoiceUrl;
    
    const form = document.createElement('form');
    form.method = 'GET';
    form.action = fileUrl;
    form.target = '_blank';
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

 // In your handleCopy function, modify the toast z-index or use a different approach
const handleCopy = () => {
  try {
    if (order?.uniqueLinkId) {
      const shareUrl = `https://integration.3sigmacrm.com/share/invoice/${order.uniqueLinkId}`;
      navigator.clipboard.writeText(shareUrl);
      
      // Option 1: If using react-hot-toast, use a custom style
      toast.success('Invoice link copied to clipboard', {
        style: {
          zIndex: 9999 // Higher than your modal's z-index
        }
      });
      
    
    }
  } catch (error) {
    console.error('Copy error:', error);
  }
};

  const handleIframeLoad = (e: React.SyntheticEvent<HTMLIFrameElement>): void => {
    setLoading(false);
    const iframe = e.target as HTMLIFrameElement;
    if (iframe.contentWindow) {
      try {
        const doc = iframe.contentWindow.document;
        doc.body.style.margin = '0';
        doc.body.style.padding = '0';
        doc.body.style.height = '100%';
        doc.body.style.overflowX = 'hidden';
        doc.documentElement.style.height = '100%';
        doc.documentElement.style.overflowX = 'hidden';
      } catch (error) {
        Logger.log('Could not modify iframe content');
      }
    }
  };

  const renderFooter = () => (
    <ActionButtons>
      <Button onClick={handleCopy}>
        <span style={{ fontSize: '16px', marginRight: '4px' }}>📋</span>
        Copy
      </Button>
      <Button primary onClick={handleDownload}>
        <Download size={16} />
        Download
      </Button>
    </ActionButtons>
  );

  return (
    <PopupComponent
      isOpen={visible}
      onClose={onClose}
      title="Invoice Details"
      size="lg"
      footer={renderFooter()}
    >
      <ContentWrapper>
        <IframeContainer>
        {!iframeError ? (
          <StyledIframe
            src={getWebViewUrl()}
            onLoad={handleIframeLoad}
            onError={() => setIframeError(true)}
            style={{ overflow: 'hidden' }}
          />
        ) : (
          <ErrorContainer>
            <ErrorMessage>Error loading invoice</ErrorMessage>
            <Button onClick={() => setIframeError(false)}>
              Retry
            </Button>
          </ErrorContainer>
        )}
        
        {loading && (
          <LoadingSpinner>
            <div className="spinner" />
          </LoadingSpinner>
        )}
        </IframeContainer>
      </ContentWrapper>
    </PopupComponent>
  );
};

export default InvoiceViewer;