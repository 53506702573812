import React, { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Spin, notification } from 'antd';
import moment from 'moment';
import { Logger } from '../../utils/logger';

interface GoogleMailIntegrationProps {
  token: string;
  userId: string;
  onSuccess: () => void;
  onError: (error: string) => void;
}

const GoogleMailIntegration: React.FC<GoogleMailIntegrationProps> = ({
  token,
  userId,
  onSuccess,
  onError,
}) => {
  const [loading, setLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [connectedDate, setConnectedDate] = useState<Date | null>(null);

  const checkMailStatus = async () => {
    try {
      const timestamp = new Date().getTime();
      const response = await fetch(
        'https://mapi2.3sigmacrm.com/api/v1/user/google-token-and-status?' + timestamp,
        {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          },
        }
      );

      const data = await response.json();
      Logger.log('Gmail Status Response:', data);

      if (data.status && data.data) {
        const hasMailScope = data.data.scope?.includes('https://mail.google.com/');
        const isActive = data.data.status === 'active' && hasMailScope;
        setIsConnected(isActive);
        if (isActive && data.data.connectedAt) {
          setConnectedDate(new Date(data.data.connectedAt));
        }
      }
    } catch (error) {
      console.error('Error checking mail status:', error);
      setIsConnected(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      checkMailStatus();
    } else {
      setLoading(false);
    }
  }, [token]);

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://mail.google.com/',
    onSuccess: async (codeResponse) => {
      try {
        setLoading(true);
        const response = await fetch(
          'https://mapi2.3sigmacrm.com/api/v1/user/get-google-tokens',
          {
            method: 'POST',
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code: codeResponse.code })
          }
        );

        const data = await response.json();
        if (data.status) {
          await checkMailStatus();
          notification.success({
            message: 'Gmail connected successfully!'
          });
          onSuccess();
          setConnectedDate(new Date());
        }
      } catch (error) {
        console.error('Google login failed:', error);
        notification.error({
          message: 'Connection failed'
        });
        onError('Connection failed');
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <div style={{ 
      width: '100%',
      maxWidth: '600px',
      margin: '40px auto',
      padding: '30px',
      background: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      textAlign: 'center'
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '30px'
      }}>
        <img 
  src={require('../../assets/images/integration/gmail.png')}
  alt="Gmail"
  style={{
    width: '28px',
    height: '28px',
    marginRight: '15px'
  }}
/>
        <h2 style={{ 
          margin: 0,
          fontSize: '24px',
          color: '#333'
        }}>
          Gmail
        </h2>
      </div>

      {loading ? (
        <Spin />
      ) : isConnected ? (
        <div>
          <div style={{ 
            marginBottom: '20px', 
            color: '#52c41a',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px'
          }}>
            <span style={{ color: '#52c41a' }}>✓</span>
            Your Gmail account is connected and working properly
          </div>
          <p style={{ 
            color: '#666',
            fontSize: '15px',
            marginBottom: '20px'
          }}>
           
           <ul style={{
  listStyle: 'none',
  padding: 0,
  textAlign: 'left',
  margin: '0 auto',
  maxWidth: '400px'
}}>
  <li style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
    <span style={{ color: '#EA4335', marginRight: '10px' }}>•</span>
    Send personalized email campaigns through Gmail
  </li>
  <li style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
    <span style={{ color: '#EA4335', marginRight: '10px' }}>•</span>
    Auto-sync all your emails with 3sigma CRM
  </li>
  <li style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
    <span style={{ color: '#EA4335', marginRight: '10px' }}>•</span>
    Use Gmail in your automation workflows
  </li>
</ul>
          </p>
          {connectedDate && (
            <p style={{ 
              color: '#666',
              fontSize: '14px',
              marginTop: '20px'
            }}>
              Gmail connected successfully on {moment(connectedDate).format("DD MMM YYYY hh:mm A")}
            </p>
          )}
           <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
        onClick={async () => {
          try {
            setLoading(true);
            const response = await fetch(
              'https://mapi2.3sigmacrm.com/api/v1/integration/user-integration/google-disconnect',
              {
                method: 'PUT',
                headers: {
                  'Authorization': `${token}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                  scope: 'https://mail.google.com/'
                })
              }
            );

            const data = await response.json();
            if (data.status) {
              setIsConnected(false);
              notification.success({
                message: 'Gmail disconnected successfully!'
              });
              onSuccess();
            }
          } catch (error) {
            console.error('Disconnect failed:', error);
            notification.error({
              message: 'Failed to disconnect Gmail'
            });
            onError('Disconnect failed');
          } finally {
            setLoading(false);
          }
        }}
        style={{
          padding: '8px 16px',
          backgroundColor: 'transparent',
          color: '#EA4335',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: '500'
        }}
      >
        Disconnect Gmail
      </button>
      </div>
        </div>
      ) : (
        <div>
          <p style={{ 
            marginBottom: '25px',
            color: '#666',
            fontSize: '15px'
          }}>
            Connect Gmail to 3sigma CRM to sync all your emails, send personalized campaigns, and automate email communications - all from your Gmail account..
          </p>
          <button
            onClick={() => googleLogin()}
            style={{
              padding: '12px 24px',
              backgroundColor: '#EA4335',
              color: 'white',
              border: 'none',
              borderRadius: '6px',
              cursor: 'pointer',
              fontSize: '15px',
              fontWeight: '500',
              transition: 'background-color 0.2s'
            }}
          >
            Connect Gmail
          </button>
        </div>
      )}
    </div>
  );
};

export default GoogleMailIntegration;