// ProductImageUpload.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X } from 'lucide-react';
import toast from 'react-hot-toast';
import { Logger } from '../../utils/logger';

interface ImageType {
  filePath: string;
  fileName: string;
  bucketUrl: string;
}

interface ProductImageUploadProps {
  existingImages: ImageType[];
  onFilesChange: (files: File[]) => void;
  bucketUrl: string;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_FILE_TYPES = {
  'image/*': ['.jpeg', '.jpg', '.png']
};

const ProductImageUpload: React.FC<ProductImageUploadProps> = ({
  existingImages = [],
  onFilesChange,
  bucketUrl
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);

  // Generate full image URL
  const getImageUrl = (filePath: string) => {
    // Remove any leading slash from filePath to prevent double slashes
    const cleanPath = filePath.replace(/^\/+/, '');
    // Remove any trailing slash from bucketUrl
    const cleanBucket = bucketUrl.replace(/\/+$/, '');
    const fullUrl = `${cleanBucket}/${cleanPath}`;
    // Log only in development
    if (process.env.NODE_ENV === 'development') {
      Logger.log('Image URL:', fullUrl);
    }
    return fullUrl;
  };

  useEffect(() => {
    // Cleanup preview URLs on unmount
    return () => {
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, []);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any[]) => {
    // Handle rejected files
    rejectedFiles.forEach(({ file, errors }) => {
      if (errors[0]?.code === 'file-too-large') {
        toast.error(`${file.name} is too large (max: 5MB)`);
      } else if (errors[0]?.code === 'file-invalid-type') {
        toast.error(`${file.name} is not a valid image type`);
      }
    });

    // Process accepted files
    const newFiles = acceptedFiles.filter(file => {
      const isDuplicate = selectedFiles.some(
        existing => existing.name === file.name && existing.size === file.size
      );
      return !isDuplicate;
    });

    if (newFiles.length) {
      setSelectedFiles(prev => [...prev, ...newFiles]);
      const newPreviewUrls = newFiles.map(file => URL.createObjectURL(file));
      setPreviewUrls(prev => [...prev, ...newPreviewUrls]);
      onFilesChange([...selectedFiles, ...newFiles]);
    }
  }, [selectedFiles, onFilesChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    multiple: true
  });

  const handleRemovePreview = (index: number) => {
    URL.revokeObjectURL(previewUrls[index]);
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setPreviewUrls(prev => prev.filter((_, i) => i !== index));
    setSelectedFiles(updatedFiles);
    onFilesChange(updatedFiles);
  };

  return (
    <div className="image-section">
      <div 
        {...getRootProps()} 
        className={`dropzone-area ${isDragActive ? 'active' : ''}`}
      >
        <input {...getInputProps()} />
        <div className="dropzone-content">
          <Upload className="upload-icon" size={20} />
          {isDragActive ? (
            <span className="drop-text">Drop images here ...</span>
          ) : (
            <>
              <span className="upload-text">Drag & drop product images</span>
              <span className="upload-text-sub">or click to select</span>
            </>
          )}
          <small className="file-info">Supports JPG, PNG (up to 5MB)</small>
        </div>
      </div>

      {(existingImages.length > 0 || previewUrls.length > 0) && (
        <div className="preview-grid">
          {existingImages.map((image, index) => (
            <div key={`existing-${image.filePath}`} className="preview-item">
              <img 
                src={getImageUrl(image.filePath)}
                alt={`Product preview ${index + 1}`}
                onError={(e) => {
                  console.error('Image load failed:', getImageUrl(image.filePath));
                  e.currentTarget.src = '/fallback.png';
                }}
              />
            </div>
          ))}
          {previewUrls.map((url, index) => (
            <div key={`preview-${url}`} className="preview-item">
              <img 
                src={url}
                alt={`New preview ${index + 1}`}
                onError={(e) => {
                  console.error('Preview load failed:', url);
                  e.currentTarget.src = '/fallback.png';
                }}
              />
              <button
                type="button"
                className="remove-btn"
                onClick={() => handleRemovePreview(index)}
                title="Remove preview"
              >
                <X size={12} />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductImageUpload;