// TaskFormPopupWrapper.tsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PopupComponent from '../../components/PopupComponent';
import CreateTaskForm from '../../components/createTaskForm';
import LeadDetails from './leadDetails/leadDetails';
import { ExternalLink } from 'lucide-react';
import { styled } from 'styled-components';
import DrawerComponent from '../../components/drawer';
import { Logger } from '../../utils/logger';

interface TaskFormPopupProps {
  isOpen: boolean;
  onClose: () => void;
  action: 'add' | 'edit';
  leadId?: string;
  updateTaskValue?: any;
  fetchTaskStatusFollowUps: (data?: any) => void;
}

const LeadButton = styled.button`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px 8px;
  background-color: #6366F1;
  color: white;
  border-radius: 4px;
  border: none;
  font-size: 13px;
  font-weight: 500;

  &:hover {
    background-color: #4F46E5;
  }
`;

const TaskFormPopupWrapper: React.FC<TaskFormPopupProps> = ({
  isOpen,
  onClose,
  action,
  leadId,
  updateTaskValue,
  fetchTaskStatusFollowUps
}) => {
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [selectedLead, setSelectedLead] = useState<any>(null);
  const StoreData = useSelector((state: any) => state?.rootReducers);

  const handleOpenLeadDetails = () => {
    Logger.log('Opening lead details with:', {
      updateTaskValue,
      leadId,
      action
    });

    // For edit task - use lead object directly from updateTaskValue
    if (updateTaskValue?.lead?.[0]) {
      Logger.log('Edit mode - Using lead from updateTaskValue:', updateTaskValue.lead[0]);
      setSelectedLead(updateTaskValue.lead[0]);
      setOpenWithHeader(true);
      return;
    }
    
    // For new task creation with leadId
    if (leadId) {
      Logger.log('Add mode - Searching for lead with ID:', leadId);
      const leadDetails = StoreData?.leads?.leads?.find(
        (lead: any) => lead._id === leadId
      );
      Logger.log('Found lead details:', leadDetails);
      if (leadDetails) {
        setSelectedLead(leadDetails);
        setOpenWithHeader(true);
      }
    }
  };

  Logger.log('Current state:', {
    selectedLead,
    openWithHeader,
    isOpen
  });

  return (
    <>
      <PopupComponent
        isOpen={isOpen}
        onClose={onClose}
        title={action === 'add' ? "Create Task" : "Edit Task"}
        size="md"
        headerActions={
          (updateTaskValue?.lead?.[0]?._id || leadId) && (
            <LeadButton 
              onClick={handleOpenLeadDetails}
              title="View Lead Details"
            >
              <span>Lead</span>
              <ExternalLink size={16} />
            </LeadButton>
          )
        }
      >
        <CreateTaskForm
          action={action}
          status=""
          updateTaskValue={updateTaskValue}
          leadId={leadId}
          drawerClose={onClose}
          fetchTaskStatusFollowUps={fetchTaskStatusFollowUps}
        />
      </PopupComponent>

      {selectedLead && (
        <DrawerComponent
          openWithHeader={openWithHeader}
          setOpenWithHeader={setOpenWithHeader}
          customHeader={false}
          drawerTitle="Lead Details"
          size="lg"
          className="lead_details"
        >
          <LeadDetails
            _id={selectedLead._id}
            name={selectedLead.name}
            integration={selectedLead.integration}
            customSource={selectedLead.customSource}
            createdAt={selectedLead.createdAt}
            status={selectedLead.status || []}
            saleValue={selectedLead.saleValue}
            website={selectedLead.website}
            label={selectedLead.label || []}
            leadListId={StoreData.leadName.leadName?.id}
            deleteLeadFromDrawer={() => {}}
            copyLeadToList={() => {}}
            moveLeadToList={() => {}}
            onLabelClick={() => {}}
            onStatusClick={() => {}}
            setFrom={() => {}}
            handleEditModal={() => {}}
            handleLeadDetailsModalOpen={() => {}}
            onLeadFileUpload={() => {}}
            editLead={() => {}}
            leadFileDelete={() => {}}
            refreshData={() => {}}
            onDeleteLead={() => {}}
          />
        </DrawerComponent>
      )}
    </>
  );
};

export default TaskFormPopupWrapper;