import React, {
  PropsWithChildren,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as yup from "yup";
import update from "immutability-helper";
import ErrorText from "../../components/errorText";
import {
  createNewLead,
  getLeadDetail,
  updateLead,
  putUserPreferences,
} from "../../services/leadService";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Inputs from "../../components/inputs";
import { setCustomSource } from "../../actions/actions";
import InputMaps from "../../components/InputMaps";
import moment from "moment";
import { Spin } from "antd";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { IoIosCloseCircle } from "react-icons/io";
import { Check, Plus, X } from "lucide-react";
import { Logger } from '../../utils/logger';

export interface CreateLeadDataI {
  name: string;
  email: string;
  phone: string;
  notes: string;
  salesValue: number;
  integration?: string;
  multi_select?: string[];
}

interface PropsT {
  showCreateNewLeadDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  getLeads: (leadId: any) => void;
  isEditMode: boolean;
  isDetailsBox: boolean;
  leadToBeUpdate: string;
  handleDrawerClick: () => void;
  selectedLeadId: string;
  leadList: Array<any>;
  disabled?: boolean;

  ref?: any;
  onClose?: () => void;
}

const CreateLeadForm: React.FC<PropsWithChildren<PropsT>> = forwardRef(
  (
    {
      showCreateNewLeadDrawer,
      getLeads,
      isEditMode,
      isDetailsBox,
      leadToBeUpdate,
      handleDrawerClick,
      selectedLeadId,
      leadList,
      disabled,
      onClose,
    },
    ref
  ) => {
    const StoreData = useSelector((state: any) => {
      return state?.rootReducers;
    });
    const dispatch = useDispatch();
    const [dynamicData, setDynamicData] = useState<any>([]);
    const [leadSourceData, setLeadSourceData] = useState([]);

    const [leadData, setLeadData] = useState<any>({
      name: "",
      email: "",
      phone: "",
      salesValue: 0,
      notes: "",
      integration: "64109561e6c7e737227a6382",
      list: "",
      multi_select: [],
    });

    const [errors, setErrors] = useState({
      name: "",
      // phone: "",
      email: "",
      saleValue: "",
      notes: "",
    });
    const regExp = /\b\d{10}\b/;
    const [newSourceToggle, setNewSourceToggle] = useState(false);
    const [selectedSource, setSelectedSource] = useState("leadForm");
    const [sourceName, setSourceName] = useState("");
    const [selectedList, setSelectedList] = useState("");
    const [addressName, setAddressName] = useState({});
    const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
    const [leadListData, setleadListData] = useState<Array<string>>([]);
    useEffect(() => {
      if (isEditMode || disabled) {
        fetchLeadDetails();
      }
      let form_Data = StoreData.user?.userPreferences?.customForm;
      let source_Data = StoreData.customSource?.sourceList;
      setDynamicData(form_Data);
      setLeadSourceData(source_Data);
    }, []);

    const fetchLeadDetails = async () => {
      const response = await getLeadDetail(leadToBeUpdate);
      if (response && response.status) {
        setLeadData({
          ...leadData,
          ...response?.data?.data.extraDetails,
          name: response?.data?.data?.name || "",
          email: response?.data?.data?.email || "",
          phone: response?.data?.data?.phone
            ? Number(response?.data?.data?.phone)
            : "",
          saleValue: response?.data?.data?.saleValue
            ? Number(response?.data?.data?.saleValue)
            : "",
          notes: response?.data?.data?.note || "",
        });
        // setSourceName(response.data.data.customSource || '')
        setSelectedSource(response.data.data?.customSource);
      }
    };

    let schema = yup.object().shape({
      name: yup.string().required("Name is required"),
      // phone: yup.string().required("Phone Number is Required").matches(regExp, {
      //   message: "Phone Number is not valid",
      //   excludeEmptyString: true,
      // }),
      // email: yup.string().email("Email is invalid").required("Email is required"),
      // saleValue: yup.string().required("Sale value is required"),
      // notes: yup.string().required("Note is required"),
    });

    const handleChange = (name: any, value: string | string[]) => {
      setLeadData({
        ...leadData,
        [name]: value,
      });

      setErrors({
        ...errors,
        [name]: null,
      });
    };
   // In CreateLeadForm.tsx, update the handleMapChange function
// Replace this in CreateLeadForm.tsx
const handleMapChange = (name: any, value: string, coords: any) => {
  const locationObject = {
    address: value,
    coords: {
      latitude: coords.lat,
      longitude: coords.lng,
      latitudeDelta: 0.0922,
      longitudeDelta: 0.0922
    }
  };

  // FIXED: Merge with existing values instead of replacing
  setAddressName(prevState => ({
    ...prevState,
    [name]: locationObject
  }));
  
  setErrors({
    ...errors,
    [name]: null,
  });
};
    const onSourceChange = (tab: string) => {
      setSelectedSource(tab);
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    function removeNullAndBlankValues(obj: any): any {
      const newObj: any = {};
      for (const key in obj) {
        const value = obj[key];
        if (
          value !== null &&
          value !== "" &&
          value !== "0" &&
          !isEmptyObject(value) &&
          !isEmptyArray(value)
        ) {
          newObj[key] = value;
        }
      }
      return newObj;
    }

    function isEmptyObject(obj: any): boolean {
      return obj && typeof obj === "object" && Object.keys(obj).length === 0;
    }

    function isEmptyArray(value: any): boolean {
      if (Array.isArray(value)) {
        return (
          value.length === 0 || (value.length === 1 && isEmptyArray(value[0]))
        );
      }
      return typeof value === "object" && Object.keys(value).length === 0;
    }

    // Handle form submission
    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
      e && e.preventDefault();
      setIsPageLoad(true);
      if (Object.values(addressName)) {
        Object.assign(leadData, addressName);
      }
      const isFormValid = await schema.isValid(leadData, {
        abortEarly: false,
      });

      if (isFormValid) {
        const newLeadData: any = {
          name: leadData?.name,
          phone: leadData?.phone.toString(),
          email: leadData?.email,
          saleValue: leadData.saleValue ? Number(leadData.saleValue) : null,
          customSource: selectedSource,
          isDistributed: leadData.isDistributed || null,
          whatsApp: leadData.whatsApp || null,
          notes: leadData.whatsApp || null,
          files: leadData.files || null,
          integration: leadData.integration || null,
          list: leadData.list || null,
          extraDetails: {},
          customField: [{}],
        };

        // Loop through leadData object and add remaining fields to extraDetails
        for (const key in leadData) {
          if (
            !newLeadData.hasOwnProperty(key) &&
            leadData[key] !== null &&
            leadData[key] !== "" &&
            leadData[key] !== 0
          ) {
            newLeadData.extraDetails[key] = leadData[key];
          }
        }
        Logger.log("newLeadData =====", newLeadData);
        const updateLeadData: any = removeNullAndBlankValues(newLeadData);
        if (isEditMode) {
          try {
            const { integration, ...body } = updateLeadData;
            const editResponse = await updateLead(leadToBeUpdate, body);
            if (editResponse && editResponse.status == 200) {
              toast.success(editResponse?.data?.message);
              handleDrawerClick();
              clearAllLeadForm();
              onClose && onClose();
              leadData.list !== "0"
                ? getLeads(leadData.list)
                : getLeads(undefined);
            }
          } catch (err: any) {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error("Error while creating a new lead!");
            }
          }
        } else {
          try {
            let body: any = updateLeadData;
            const response = await createNewLead(body);
            if (response && response.status == 200) {
              toast.success(response?.data?.message);
              handleDrawerClick();
              clearAllLeadForm();
              leadData.list !== "0"
                ? getLeads(leadData.list)
                : getLeads(undefined);
            }
          } catch (err: any) {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error("Error while creating a new lead!");
            }
          }
        }
      } else {
        schema.validate(leadData, { abortEarly: false }).catch((err) => {
          const validationErrors = err.inner.reduce(
            (acc: any, error: { path: string; message: string }) => {
              return {
                ...acc,
                [error.path]: error.message,
              };
            },
            {}
          );
          setErrors((prevErrors) =>
            update(prevErrors, {
              $set: validationErrors,
            })
          );
        });
      }
      setIsPageLoad(false);
    };

    const clearAllLeadForm = () => {
      setLeadData({
        name: "",
        email: "",
        phone: "",
        salesValue: 0,
        notes: "",
      });
      setAddressName({});
      showCreateNewLeadDrawer(false);
    };

    const NewSourceToggle = () => {
      setNewSourceToggle(!newSourceToggle);
      setSourceName("");
    };

    const addNewSource = () => {
      if (sourceName) {
        let tempData: any = [...leadSourceData];
        let tempObj = {
          name: sourceName,
          value: sourceName?.split(" ")?.join("_"),
        };
        tempData.push(tempObj);
        handleAddLeadSource(tempData);
        dispatch(setCustomSource(tempData));
        setLeadSourceData(tempData);
        NewSourceToggle();
      }
    };
    const handleAddLeadSource = async (data: any) => {
      try {
        const response = await putUserPreferences({ customSource: data });
        if (response && response.status) {
          Logger.log(response.data.message);
        }
      } catch (err: any) {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error("Error while updating Source!");
        }
      }
    };
    useEffect(() => {
      if (selectedLeadId) {
        setSelectedList(selectedLeadId);
        handleChange("list", selectedLeadId);
      }
    }, [selectedLeadId]);

    useEffect(() => {
      if (leadList) {
        let defaultLead = {
          name: "Default lead list",
          _id: "",
        };

        setleadListData([defaultLead, ...leadList]);
      }
    }, [leadList]);
    
    return (
      <Root>
        <Spin size="large" tip="Loading..." spinning={isPageLoad}>
          <form
            id="LeadsFilterForm"
            onSubmit={(e) => handleSubmit(e)}
            className="leadsform"
          >
            <div className="form-container">

            
            <div className="product_group lead_form">
   
    <div className="lead_source_header">
      <h5 className="lead_source">Lead Source</h5>
      
      {!disabled && (
        <div className="new_source_control">
          {!newSourceToggle ? (
            <button
              className="new_source_btn"
              type="button"
              onClick={() => NewSourceToggle()}
            >
              <Plus size={14} className="icon-plus" />
              <span>New Source</span>
            </button>
          ) : (
            <div className="new_source_input">
              <input
                name="name"
                type="text"
                className="form-control"
                autoComplete="off"
                value={sourceName}
                onChange={(e) => setSourceName(e?.target?.value)}
                placeholder="Source name"
              />
              <div className="action_buttons">
                <button className="btn-save" onClick={() => addNewSource()}>
                  <Check size={16} />
                </button>
                <button className="btn-cancel" onClick={() => NewSourceToggle()}>
                  <X size={16} />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
    
    <div className="lead_source_tabs">
      <div className="source_tabs">
        {leadSourceData?.map((item: any, i: number) => {
          if (selectedSource !== item.value && disabled) return null;
          
          return (
            <div
              key={i}
              onClick={() => onSourceChange(item.value)}
              className={`tab_item ${selectedSource === item.value ? 'active' : ''}`}
            >
              {item.name}
            </div>
          );
        })}
     
    
      </div>
    </div>

                <div>
                  <div>
                    <label className="form-label">
                      Select List
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <select
                      disabled={isEditMode || disabled}
                      name="cars"
                      className="form-control"
                      value={selectedList}
                      onChange={(e) => {
                        setSelectedList(e.target.value);
                        handleChange("list", e.target.value);
                      }}
                    >
                      {leadListData.map((item: any, ix: number) => {
                        return (
                          <option value={item._id} key={ix}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {dynamicData?.map((item: any, i: number) => {
  // Fix for handling location data properly
  let finalValue;
  if (item.type === "location") {
    // If it's a location field, extract just the address string
    const locationData = leadData?.[item?.value];
    finalValue = locationData?.address || "";
  } else if (item.type === "multi-selection") {
    finalValue = leadData?.[item?.value] || [];
  } else {
    finalValue = isNaN(leadData?.[item?.value])
      ? leadData?.[item?.value?.toLowerCase()]
      : leadData?.[item?.value];
  }
  return (
    <div className="form-group hide_dt select_icon" key={i}>
      {item.type === "location" ? (
        <InputMaps
          disabled={disabled}
          type={item.type}
          name={item?.value}
          value={finalValue}
          locationData={leadData?.[item?.value]} // Pass the full location object as a separate prop
          onChange={(name: string, value: string, coords: any) => handleMapChange(name, value, coords)}
          className="form-control"
          label={item.label}
          isRequired={item?.isRequired}
        />
      ) : item.type === "date" ? (
                          <div className="form-group">
                            <label className="form-label">{item?.label}</label>
                            <input
                              disabled={disabled}
                              type="date"
                              id="task_date"
                              name="toBePerformAt"
                              className="form-control"
                              value={finalValue}
                              onChange={(e: any) =>
                                handleChange(item?.value, e.target.value)
                              }
                              placeholder="Enter Date"
                            />
                          </div>
                        ) : (
                          <Inputs
                            disabled={disabled}
                            name={item?.value}
                            type={
                              item.type === "phone" || item.type === "amount"
                                ? "number"
                                : item.type
                            }
                            autoComplete="off"
                            className={
                              item.label === "Notes"
                                ? "form-control note_area"
                                : "form-control"
                            }
                            placeholder={`Enter ${item?.name}`}
                            value={finalValue || ""}
                            onChange={(e: any) =>
                              handleChange(item?.value, e.target.value)
                            }
                            label={item.label}
                            isRequired={item.isRequired}
                            options={["selection", "multi-selection"].includes(item.type) ? item.options : undefined}
                            id={
                              item.type === "time" || item.type === "date"
                                ? "lead_date_time"
                                : ""
                            }
                          />
                        )}

                        {/* {errors?.name && item?.name === "Name" && item.isRequired ? (
                    <ErrorText message={errors?.name} />
                  ) : errors?.phone &&
                    item?.name === "Phone" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.phone} />
                  ) : (
                    ""
                  )} */}
                        {/* {errors?.name && item?.name === "Name" && item.isRequired ? (
                    <ErrorText message={errors?.name} />
                  ) : errors?.phone &&
                    item?.name === "Phone" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.phone} />
                  ) : errors?.email &&
                    item?.name === "Email" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.email} />
                  ) : errors?.saleValue &&
                    item?.name === "saleValue" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.saleValue} />
                  ) : errors?.notes &&
                    item?.name === "notes" &&
                    item.isRequired ? (
                    <ErrorText message={errors?.notes} />
                  ) :(
                    ""
                  )} */}
                      </div>
                    );
                  })}
                </div>
              </div>

              {!isDetailsBox || (isDetailsBox && isEditMode) ? (
                <div className="d-flex justify-content-center Leadscreate ">
                  <button type="submit" className="btn btn-dark w-100">
                    {!isEditMode ? "Add" : "Update"} Lead
                  </button>
                </div>
              ) : null}
            </div>
            <Toaster position="top-right" reverseOrder={false} />
          </form>
        </Spin>
      </Root>
    );
  }
);

export default CreateLeadForm;

const Root = styled.section`
  .form-container {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
  }
  
  /* Lead Form General Styling */
  .lead_form {
    margin-bottom: 0px;
      margin-top: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
  
  /* Lead Source Header */
  .lead_source {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  
  /* Lead Source Tab Container */
  .lead_source_tabs {
    margin-bottom: 12px;
  }
  
  .source_tabs_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 12px;
  }
  
  /* Tab Items Container */
   .source_tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 2px;
    flex-grow: 1;
  }
  
  /* Individual Tab Item */
  .tab_item {
    padding: 6px 12px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
    color: #495057;
    cursor: pointer;
    transition: all 0.2s ease;
    user-select: none;
  }
  
  .tab_item:hover {
    background-color: #e9ecef;
  }
  
  .tab_item.active {
    background-color: #0d6efd;
    color: white;
    border-color: #0d6efd;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .new_source_btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 0;
  background-color: transparent;
  border: none;
  color: #0d6efd;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.new_source_btn:hover {
  color: #0a58ca;
  text-decoration: underline;
}

.icon-plus {
  stroke-width: 2.5;
}
  
  /* Input Field for New Source */
  .new_source_input {
    display: flex;
    width: 100%;
    max-width: 300px;
  }
  
  .new_source_input .form-control {
    border-radius: 20px 0 0 20px;
    border-right: none;
    padding: 6px 12px;
    font-size: 13px;
    height: 34px;
    flex-grow: 1;
  }
  
  /* Save/Cancel Buttons */
  .action_buttons {
    display: flex;
  }
  
  .action_buttons button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border: 1px solid #dee2e6;
    background-color: white;
    cursor: pointer;
    padding: 0;
  }
  
  .btn-save {
    color: #28a745;
    border-radius: 0;
    border-left: none;
  }
  
  .btn-cancel {
    color: #dc3545;
    border-radius: 0 20px 20px 0;
    border-left: none;
  }
  
  .btn-save:hover {
    background-color: rgba(40, 167, 69, 0.1);
  }
  
  .btn-cancel:hover {
    background-color: rgba(220, 53, 69, 0.1);
  }
  
  /* Form Field General Styling */
  .form-label {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #212529;
  }
  
  .form-control {
    position: relative;
    width: 100%;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  /* Select Dropdown */
  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }
  
  /* Add Lead Button */
  .Leadscreate {
    position: sticky;
    margin-top: auto;
    bottom: 0px;
    padding-top: 16px;
  }
  
  .Leadscreate .btn {
    border-radius: 8px;
    padding: 12px 20px;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .Leadscreate .btn-dark {
    background-color: #0d6efd;
    border-color: #0d6efd;
    color: white;
  }
  
  .lead_source_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.lead_source {
  margin-bottom: 0;
}
  .Leadscreate .btn-dark:hover {
    background-color: #0b5ed7;
    border-color: #0a58ca;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.15);
  }
  
  .Leadscreate .btn-dark:active {
    transform: translateY(1px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  
  /* Dropdown Menus */
  .dropdown-menu {
    position: absolute;
    width: 100%;
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 6px 0;
  }

  .dropdown-item {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      background-color: #f8f9fa;
    }
  }

  .form-check-input {
    margin-right: 8px;
  }

  .selected-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;