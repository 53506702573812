// ProductForm.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Package, Tag, DollarSign, Info, AlertCircle, Search } from 'lucide-react';
import toast from 'react-hot-toast';
import PopupComponent from '../../components/PopupComponent';
import ProductImageUpload from './ProductImageUpload';
import { createNewProduct, updateProduct } from '../../services/productService';
import { getAllCategories } from '../../services/categoryService';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import './productForm.scss';
import { styled } from 'styled-components';
import { Logger } from '../../utils/logger';


export const StyledFormContainer = styled.div`
  padding: 1.5rem;
  max-height: calc(90vh - 120px);
  overflow-y: auto;
`;

// Missing SearchableSelect component styling
export const StyledSearchableSelect = styled.div`
  position: relative;
  width: 100%;

  .select-input-wrapper {
    position: relative;
  }

  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 0.375rem;
    margin-top: 0.25rem;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
  }

  .option-item {
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    &:hover {
      background-color: #F3F4F6;
    }
    &.selected {
      background-color: #EFF6FF;
    }
  }
`;

// Missing unit-gst-row styling
export const StyledUnitGSTRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
`;

// Price calculation styling
export const StyledPriceCalculation = styled.div`
  margin-top: 0.5rem;
  padding: 0.75rem;
  background: #F9FAFB;
  border: 1px solid #E5E7EB;
  border-radius: 0.375rem;
  font-size: 0.875rem;

  .price-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .label {
      color: #6B7280;
    }

    .value {
      font-weight: 500;
    }
  }
`;

export const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledFieldsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledFieldRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;

  .field-icon {
    color: #6B7280;
  }

  .required {
    color: #DC2626;
    margin-left: 0.25rem;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #E5E7EB;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #2563EB;
    box-shadow: 0 0 0 1px #2563EB;
  }

  &.error {
    border-color: #DC2626;
    background-color: #FEF2F2;
  }
`;

export const StyledSelect = styled(StyledInput).attrs({ as: 'select' })`
  background-color: white;
`;

export const StyledTextArea = styled(StyledInput).attrs({ as: 'textarea' })`
  min-height: 100px;
  resize: vertical;
`;

export const StyledErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #DC2626;
  font-size: 0.75rem;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const StyledFormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #E5E7EB;
`;

export const StyledButton = styled.button<{ variant?: 'primary' | 'secondary' }>`
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;

  ${props => props.variant === 'primary' ? `
    background-color: #2563EB;
    color: white;
    border: none;
    &:hover:not(:disabled) {
      background-color: #1D4ED8;
    }
  ` : `
    background-color: white;
    border: 1px solid #E5E7EB;
    &:hover:not(:disabled) {
      background-color: #F9FAFB;
    }
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const UNIT_OPTIONS = [
    // Common Units
    { value: 'pc', label: 'Piece (Pc)' },
    { value: 'unit', label: 'Unit' },
    { value: 'pack', label: 'Pack' },
    { value: 'set', label: 'Set' },
    { value: 'box', label: 'Box' },
    { value: 'carton', label: 'Carton' },
    { value: 'bundle', label: 'Bundle' },
    { value: 'roll', label: 'Roll' },
    { value: 'dozen', label: 'Dozen (Doz)' },
    { value: 'bag', label: 'Bag' },
    { value: 'case', label: 'Case' },
    { value: 'pair', label: 'Pair' },
    
    // Weight Units
    { value: 'kg', label: 'Kilogram (Kg)' },
    { value: 'g', label: 'Gram (g)' },
    { value: 'mg', label: 'Milligram (mg)' },
    { value: 'ton', label: 'Ton' },
    { value: 'quintal', label: 'Quintal (Q)' },
    { value: 'pound', label: 'Pound (lb)' },
    { value: 'ounce', label: 'Ounce (oz)' },
    
    // Volume Units
    { value: 'l', label: 'Liter (L)' },
    { value: 'ml', label: 'Milliliter (mL)' },
    { value: 'gal', label: 'Gallon (gal)' },
    { value: 'bottle', label: 'Bottle' },
    { value: 'jar', label: 'Jar' },
    { value: 'can', label: 'Can' },
    { value: 'drum', label: 'Drum' },
    { value: 'tank', label: 'Tank' },
    
    // Length Units
    { value: 'm', label: 'Meter (m)' },
    { value: 'cm', label: 'Centimeter (cm)' },
    { value: 'mm', label: 'Millimeter (mm)' },
    { value: 'km', label: 'Kilometer (km)' },
    { value: 'ft', label: 'Feet (ft)' },
    { value: 'inch', label: 'Inch (in)' },
    { value: 'yard', label: 'Yard (yd)' },
    
    // Area Units
    { value: 'sqm', label: 'Square Meter (m²)' },
    { value: 'sqft', label: 'Square Feet (ft²)' },
    { value: 'sqyd', label: 'Square Yard (yd²)' },
    { value: 'acre', label: 'Acre' },
    { value: 'hectare', label: 'Hectare (ha)' },
    
    // Time-based Units
    { value: 'hour', label: 'Hour (hr)' },
    { value: 'day', label: 'Day' },
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'year', label: 'Year' },
    
    // Service Units
    { value: 'service', label: 'Service' },
    { value: 'job', label: 'Job' },
    { value: 'visit', label: 'Visit' },
    { value: 'session', label: 'Session' },
    
    // Digital Units
    { value: 'license', label: 'License' },
    { value: 'user', label: 'User' },
    { value: 'subscription', label: 'Subscription' }
  ];

const GST_OPTIONS = [
    { value: '0', label: 'GST 0%' },
    { value: '5', label: 'GST 5%' },
    { value: '12', label: 'GST 12%' },
    { value: '18', label: 'GST 18%' },
    { value: '28', label: 'GST 28%' }
  ];

interface Category {
  _id: string;
  name: string;
  color?: string;
}

interface ProductFormData {
  name: string;
  category: string;
  description: string;
  price: string;
  unit: string;
  extraDetails: {
    unit: string;
    gst: string;
  };
}

interface ProductFormProps {
  show: boolean;
  onHide: () => void;
  selectedProduct?: any;
  fetchProducts?: () => void;
}

interface SearchableSelectProps {
  options: Array<{ value: string; label: string }>;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  error?: boolean;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options,
  value,
  onChange,
  placeholder,
  className = '',
  error
}) => {
  const [search, setSearch] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');

  useEffect(() => {
    const selected = options.find(opt => opt.value === value);
    setSelectedLabel(selected?.label || '');
  }, [value, options]);

  const filteredOptions = options.filter(opt =>
    opt.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelect = (optValue: string) => {
    const selected = options.find(opt => opt.value === optValue);
    if (selected) {
      onChange(optValue);
      setSelectedLabel(selected.label);
      setSearch('');
    }
    setShowOptions(false);
  };

  return (
    <div className="searchable-select">
      <div className="select-input-wrapper">
        <input
          type="text"
          value={showOptions ? search : selectedLabel}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => {
            setShowOptions(true);
            setSearch('');
          }}
          onBlur={() => {
            setTimeout(() => {
              setShowOptions(false);
              setSearch('');
            }, 200);
          }}
          placeholder={placeholder}
          className={`${className} ${error ? 'error' : ''}`}
        />
        <Search size={16} className="search-icon" />
      </div>
      {showOptions && (
        <div className="options-list">
          {filteredOptions.map(opt => (
            <div
              key={opt.value}
              className={`option-item ${value === opt.value ? 'selected' : ''}`}
              onClick={() => handleSelect(opt.value)}
            >
              {opt.label}
            </div>
          ))}
          {filteredOptions.length === 0 && (
            <div className="option-item no-results">No matches found</div>
          )}
        </div>
      )}
    </div>
  );
};

const ProductForm: React.FC<ProductFormProps> = ({
  show,
  onHide,
  selectedProduct,
  fetchProducts
}) => {
  Logger.log('ProductForm render:', { show, isEdit: Boolean(selectedProduct) });

  const isEditMode = Boolean(selectedProduct);
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [errors, setErrors] = useState<Partial<ProductFormData>>({});
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const bucketUrl = useSelector((state: any) => 
    state.rootReducers?.userData?.userDetails?.bucketUrl || ''
  );

  const initialFormState = useCallback(() => ({
    name: '',
    category: '',
    description: '',
    price: '',
    unit: '',
    extraDetails: {
      unit: '',
      gst: '0'
    }
  }), []);

  const [productData, setProductData] = useState<ProductFormData>({
    name: selectedProduct?.name || '',
    category: selectedProduct?.category?._id || '',
    description: selectedProduct?.description || '',
    price: selectedProduct?.price?.toString() || '',
    unit: selectedProduct?.extraDetails?.unit || '',
    extraDetails: {
      unit: selectedProduct?.extraDetails?.unit || '',
      gst: selectedProduct?.extraDetails?.gst || '0'
    }
  });

  // Effect to initialize/reset form data
  useEffect(() => {
    if (show) {
      if (isEditMode && selectedProduct) {
        setProductData({
          name: selectedProduct?.name || '',
          category: selectedProduct?.category?._id || '',
          description: selectedProduct?.description || '',
          price: selectedProduct?.price?.toString() || '',
          unit: selectedProduct?.extraDetails?.unit || '',
          extraDetails: {
            unit: selectedProduct?.extraDetails?.unit || '',
            gst: selectedProduct?.extraDetails?.gst || '0'
          }
        });
      } else {
        setProductData(initialFormState());
      }
    }
    return () => setProductData(initialFormState());
  }, [show, isEditMode, selectedProduct]);

  // Effect to load categories
  useEffect(() => {
    const loadCategories = async () => {
      if (!show) return;
      
      setCategoryLoading(true);
      try {
        const response = await getAllCategories({
          page: 1,
          perPage: 100,
          orderBy: 'name',
          isAscending: true
        });
        if (response?.data?.data) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error('Error loading categories:', error);
        toast.error('Failed to load categories');
      } finally {
        setCategoryLoading(false);
      }
    };

    loadCategories();
  }, [show]);

  const handleClose = useCallback(() => {
    if (hasUnsavedChanges) {
      if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
        setProductData(initialFormState());
        setErrors({});
        setSelectedFiles([]);
        setHasUnsavedChanges(false);
        onHide();
      }
    } else {
      onHide();
    }
  }, [hasUnsavedChanges, initialFormState, onHide]);

  const handleChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setProductData(prev => {
      const newData = {
        ...prev,
        [name]: name === 'price' ? (value === '' ? '' : Math.max(0, Number(value)).toString()) : value
      };

      if (name === 'gst') {
        newData.extraDetails = {
          ...prev.extraDetails,
          gst: value
        };
      } else if (name === 'unit') {
        newData.unit = value;
        newData.extraDetails = {
          ...prev.extraDetails,
          unit: value
        };
      }

      return newData;
    });

    setHasUnsavedChanges(true);
    
    if (errors[name as keyof ProductFormData]) {
      setErrors(prev => ({ ...prev, [name]: undefined }));
    }
  }, [errors]);

  const getUnitLabel = useCallback((unitValue: string) => {
    const unit = UNIT_OPTIONS.find(opt => opt.value === unitValue);
    return unit ? unit.label.split(' ')[0].toLowerCase() : unitValue;
  }, []);

  const calculateGSTPrice = useCallback((price: string, gst: string) => {
    const basePrice = Number(price);
    const gstRate = Number(gst);
    const gstAmount = basePrice * (gstRate / 100);
    return (basePrice + gstAmount).toFixed(2);
  }, []);

  const validateForm = useCallback((): boolean => {
    const newErrors: Partial<ProductFormData> = {};

    if (!productData.name.trim()) {
      newErrors.name = 'Product name is required';
    }
    if (!productData.category) {
      newErrors.category = 'Please select a category';
    }
    if (!productData.price || Number(productData.price) <= 0) {
      newErrors.price = 'Please enter a valid price';
    }
    if (!productData.unit) {
      newErrors.unit = 'Please select a unit type';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [productData]);

  const generateProductId = useCallback(() => 
    `PRD${Date.now().toString(36).toUpperCase()}`, []
  );

// Replace the handleSubmit function with this fixed version:

const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  
  if (!validateForm()) {
    toast.error('Please fill all required fields correctly');
    return;
  }

  setLoading(true);
  try {
    if (isEditMode) {
      // For update, include productId
      const updateData = {
        productId: selectedProduct._id,
        name: productData.name,
        category: productData.category,
        description: productData.description,
        price: productData.price,
        currency: 'INR',
        // Add extraDetails if needed as a separate property in backend
      };
      
      // @ts-ignore - Temporarily ignore type checking for this call
      const response = await updateProduct(selectedProduct._id, updateData);
      if (response?.data) {
        toast.success('Product updated successfully');
        setHasUnsavedChanges(false);
        fetchProducts?.();
        onHide();
      }
    } else {
      // For create, include required productId field
      const createData = {
        productId: generateProductId(), // Generate a new ID
        name: productData.name,
        category: productData.category,
        description: productData.description,
        price: productData.price,
        currency: 'INR',
        // Add extraDetails if needed as a separate property in backend
      };
      
      // @ts-ignore - Temporarily ignore type checking for this call
      const response = await createNewProduct(createData);
      if (response?.data) {
        toast.success('Product created successfully');
        setHasUnsavedChanges(false);
        fetchProducts?.();
        onHide();
      }
    }
  } catch (error) {
    console.error('Error saving product:', error);
    toast.error('Failed to save product');
  } finally {
    setLoading(false);
  }
};


  return (
    <PopupComponent
      isOpen={show}
      onClose={onHide}
      title={isEditMode ? 'Edit Product' : 'Add New Product'}
      size="lg"
    >
      <StyledFormContainer>
        <form onSubmit={handleSubmit}>
          <StyledFormGrid>
          <ProductImageUpload
  existingImages={selectedProduct?.images || []}
  onFilesChange={setSelectedFiles}
  bucketUrl={bucketUrl}
/>
  
            <StyledFieldsColumn>
              <StyledFieldRow>
                <StyledLabel>
                  <Package size={14} className="field-icon" />
                  <span>Product Name</span>
                  <span className="required">*</span>
                </StyledLabel>
                <StyledInput
                  type="text"
                  name="name"
                  value={productData.name}
                  onChange={handleChange}
                  placeholder="Enter product name"
                  className={errors.name ? 'error' : ''}
                />
                {errors.name && (
                  <StyledErrorMessage>
                    <AlertCircle />
                    <span>{errors.name}</span>
                  </StyledErrorMessage>
                )}
              </StyledFieldRow>
  
              <StyledFieldRow>
                <StyledLabel>
                  <Tag size={14} className="field-icon" />
                  <span>Category</span>
                  <span className="required">*</span>
                </StyledLabel>
                <StyledSelect
                  name="category"
                  value={productData.category}
                  onChange={handleChange}
                  className={errors.category ? 'error' : ''}
                >
                  <option value="">Select category</option>
                  {categories.map(cat => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </StyledSelect>
                {errors.category && (
                  <StyledErrorMessage>
                    <AlertCircle />
                    <span>{errors.category}</span>
                  </StyledErrorMessage>
                )}
              </StyledFieldRow>
  
              <StyledFieldRow>
                <StyledLabel>
                  <DollarSign size={14} className="field-icon" />
                  <span>Price per {getUnitLabel(productData.unit)}</span>
                  <span className="required">*</span>
                </StyledLabel>
                <div style={{ position: 'relative' }}>
                  <span style={{
                    position: 'absolute',
                    left: '0.75rem',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#6B7280'
                  }}>₹</span>
                  <StyledInput
                    type="number"
                    name="price"
                    value={productData.price}
                    onChange={handleChange}
                    placeholder="0.00"
                    className={errors.price ? 'error' : ''}
                    style={{ paddingLeft: '1.75rem' }}
                  />
                </div>
                {errors.price && (
                  <StyledErrorMessage>
                    <AlertCircle />
                    <span>{errors.price}</span>
                  </StyledErrorMessage>
                )}
              </StyledFieldRow>
              <StyledUnitGSTRow>
  <div className={errors.unit ? 'has-error' : ''}>
    <SearchableSelect
      options={UNIT_OPTIONS}
      value={productData.unit}
      onChange={(value) => handleChange({ target: { name: 'unit', value }} as any)}
      placeholder="Search unit type"
      className="field-select"
      error={Boolean(errors.unit)}
    />
    {errors.unit && (
      <StyledErrorMessage>
        <AlertCircle size={12} />
        <span>{errors.unit}</span>
      </StyledErrorMessage>
    )}
  </div>
  <div>
    <StyledSelect
      name="gst"
      value={productData.extraDetails.gst}
      onChange={handleChange}
    >
      {GST_OPTIONS.map(opt => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </StyledSelect>
  </div>
</StyledUnitGSTRow>

{/* Add this price calculation display after the unit-gst row */}
{!errors.price && productData.price && Number(productData.price) > 0 && (
  <StyledPriceCalculation>
    <div className="price-item">
      <span className="label">Base Price:</span>
      <span className="value">₹{Number(productData.price).toFixed(2)} / {getUnitLabel(productData.unit)}</span>
    </div>
    <div className="price-item">
      <span className="label">With GST ({productData.extraDetails.gst}%):</span>
      <span className="value">₹{calculateGSTPrice(productData.price, productData.extraDetails.gst)} / {getUnitLabel(productData.unit)}</span>
    </div>
  </StyledPriceCalculation>
)}
              <StyledFieldRow>
                <StyledLabel>
                  <Info size={14} className="field-icon" />
                  <span>Description</span>
                </StyledLabel>
                <StyledTextArea
                  name="description"
                  value={productData.description}
                  onChange={handleChange}
                  placeholder="Enter product description"
                  rows={3}
                />
              </StyledFieldRow>
            </StyledFieldsColumn>
          </StyledFormGrid>
  
          <StyledFormActions>
            <StyledButton 
              type="button" 
              variant="secondary"
              onClick={onHide}
              disabled={loading}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="submit"
              variant="primary"
              disabled={loading}
            >
              {loading ? (
                <span className="loading-text">
                  <span className="loader" />
                  {isEditMode ? 'Updating...' : 'Creating...'}
                </span>
              ) : (
                isEditMode ? 'Update Product' : 'Create Product'
              )}
            </StyledButton>
          </StyledFormActions>
        </form>
      </StyledFormContainer>
    </PopupComponent>
  );
};

export default ProductForm;