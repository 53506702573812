import React from "react";
import AutomationRuleDrawer from "./automationRuleDrawer";
import { FaTrash } from "react-icons/fa";

/**
 * A simple wrapper component that displays the AutomationRuleDrawer
 * in the main content area instead of as a side drawer
 */
const AutomationRuleWrapper = ({
  autoRuleDrawerToggle,
  onAutomationDelete,
  updateAutomationDetail,
  isUpdateAuto,
  handleGetAutomationList,
  emailTemplate,
  whatsappTemplate,
  onSearch,
  searchValue,
  resetTemplates,
}) => {
  return (
    <div className="automation-rule-wrapper">
      <div className="automation-rule-header">
        <h2>Automation Rules</h2>
        <div>
          {isUpdateAuto && (
            <button 
              className="btn btn-light delete-button" 
              onClick={() => onAutomationDelete(updateAutomationDetail._id)}
              title="Delete Rule"
              style={{ marginRight: '10px' }}
            >
              <FaTrash size={14} />
            </button>
          )}
          <button 
            className="btn btn-light cancel-button" 
            onClick={autoRuleDrawerToggle}
          >
            Cancel
          </button>
        </div>
      </div>
      
      <div className="automation-rule-content">
        <AutomationRuleDrawer
          autoRuleDrawerToggle={autoRuleDrawerToggle}
          onAutomationDelete={onAutomationDelete}
          updateAutomationDetail={updateAutomationDetail}
          isUpdateAuto={isUpdateAuto}
          handleGetAutomationList={handleGetAutomationList}
          emailTemplate={emailTemplate}
          whatsappTemplate={whatsappTemplate}
          onSearch={onSearch}
          searchValue={searchValue}
          resetTemplates={resetTemplates}
        />
      </div>
    </div>
  );
};

export default AutomationRuleWrapper;