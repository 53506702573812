import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import styles from "./leadSelector.module.scss";
import AgGrid, { IFetchRowsParams } from "../../views/common/grid/AgGrid";
import { ColDef, GridApi } from "ag-grid-community";
import { useSelector } from "react-redux";
import { PreferenceI } from "../../views/leads/leadsGrid/leads-grid";
import { useDispatch } from "react-redux";
import {
  assignedToRenderer,
  dateRenderer,
  labelRenderer,
  lastActivityRenderer,
  locationRenderer,
  nameRenderer,
  phoneRenderer,
  sourceRenderer,
  statusRenderer,
} from "../../views/leads/leadsGrid/leads-renderers";
import {
  advanceFilterKeys,
  FilterParams,
} from "../../views/leads/leadsGrid/leads.types";
import {
  setLeadCount,
  setLeadFilter,
  setLeadList,
  setLeadName,
  userPreferences,
} from "../../actions/actions";
import _, { isEqual } from "lodash";
import {
  filterLeads,
  getAllLeads,
  putUserPreferences,
} from "../../services/leadService";
import toast from "react-hot-toast";
import { areAllValuesUndefined } from "../../utils/helpers";
import { FiFilter } from "react-icons/fi";
import LeadsList from "../../views/leads/leadsList";
import DrawerComponent from "../drawer";
import LeadFilter from "../../views/leads/leadFilter";
import { getAllLeadList } from "../../services/leadListService";
import { Logger } from '../../utils/logger';

const customStyles = {
  content: {
    top: "10%",
    left: "18%",
    right: "1%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};
const LeadSelector = ({
  isOpen,
  onClose,
  selectedLeads,
  handleSelectedLeads,
  setSelectedLeadList,
  selectedLeadList,
}: any) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [resetGrid, setResetGrid] = useState<number>(0);

  const { filterParam, leadCount } = useSelector(
    (state: any) => state.rootReducers.leads
  );

  const gridApi = useRef<GridApi | null>(null);
  const preferenceRef = useRef<PreferenceI | null>(null);
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });

  const preferences: PreferenceI = useSelector(
    (state: { rootReducers: { user: { userPreferences: any } } }) =>
      state?.rootReducers?.user?.userPreferences
  );

  const debouncedColumnMove = useRef(
    _.debounce((newColumnOrder, preferences) => {
      onColumnOrderChanged && onColumnOrderChanged(newColumnOrder, preferences);
    }, 500)
  ).current;

  const debouncedColumnWidth = useRef(
    _.debounce((columnId, newWidth, preferences) => {
      onColumnWidthChanged &&
        onColumnWidthChanged(columnId, newWidth, preferences);
    }, 500)
  ).current;

  const defaultColumnDefs: ColDef[] = [
    {
      headerName: "Name",
      sortable: false,
      width: 150,
      field: "name",
      colId: "name",
      cellRenderer: nameRenderer,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: "Actions",
      sortable: false,
      width: 500,
      field: "phone",
      colId: "phone",
      cellRenderer: phoneRenderer,
    },
    {
      headerName: "Status",
      sortable: false,
      width: 150,
      field: "status",
      colId: "status",
      cellRenderer: (params: any) => statusRenderer({ ...params, preferences }),
    },
    {
      headerName: "Source",
      sortable: false,
      width: 180,
      field: "integration",
      colId: "integration",
      cellRenderer: sourceRenderer,
    },
    {
      headerName: "Last Activity",
      sortable: false,
      width: 250,
      field: "lastActivity",
      colId: "lastActivity",
      cellRenderer: lastActivityRenderer,
    },
    {
      headerName: "Assigned To",
      sortable: false,
      width: 200,
      field: "assignedTo",
      colId: "assignedTo",
      cellRenderer: assignedToRenderer,
    },
    {
      headerName: "Label",
      sortable: false,
      width: 220,
      field: "label",
      colId: "label",
      cellRenderer: (params: any) => labelRenderer({ ...params, preferences }),
    },
  ];

  const [columnDefs, setColumnDefs] = useState<ColDef[]>(defaultColumnDefs);
  const [searchValue, setSearchValue] = useState("");
  const [leadListData, setLeadListData] = useState<Array<any>>([]);
  const [showFilterDrawer, setShowFilterDrawer] = useState<boolean>(false);

  // useEffect(() => {
  //   if (
  //     isOpen &&
  //     selectedLeads?.length &&
  //     leadListData?.length &&
  //     gridApi?.current
  //   ) {
  //     gridApi?.current?.forEachNode((node) => {
  //       if (selectedLeads?.includes(node?.data?._id)) {
  //         node.setSelected(true);
  //       }
  //     });
  //   }
  // }, [selectedLeads, leadListData, gridApi?.current, isOpen]);

  const fetchLeadListOnScroll = (
    params: IFetchRowsParams,
    leadName?: string,
    search?: string
  ) => {
    let updatedFilterParams: Partial<FilterParams> = {
      sort: { orderBy: params.orderBy, isAscending: params.isAscending },
      ...filterParam,
      paginationParams: { perPage: params.perPage, page: params.page },
    };

    dispatch(setLeadFilter(updatedFilterParams));

    let lead_name = leadName || StoreData.leadName.leadName.id;
    return fetchLeads(
      updatedFilterParams,
      lead_name !== "0" ? lead_name : undefined
    );
  };

  const handleRowClick = (rowData: any) => {
    // handleLeadClick(rowData);
  };

  const comparePreferencesAreEqual = (newPref: any, oldPref: any) => {
    return isEqual(newPref, oldPref);
  };

  const onColumnOrderChanged = (orders: string[], preferences: PreferenceI) => {
    const uniqueOrders = [...new Set(orders)];
    const leadGridPreferences = {
      ...preferences.leadGridPreferences,
      columnOrders: uniqueOrders,
    };
    if (
      !comparePreferencesAreEqual(
        leadGridPreferences,
        preferences.leadGridPreferences
      )
    ) {
      dispatch(
        userPreferences({
          ...preferences,
          leadGridPreferences,
        })
      );
      handleAddGridPreferences(leadGridPreferences);
    }
  };

  const onColumnWidthChanged = (
    colId: string,
    newWidth: number,
    preferences: PreferenceI
  ) => {
    const updatedColumnWidths = {
      ...(preferences.leadGridPreferences?.columnWidths || {}),
      [colId]: newWidth,
    };

    const leadGridPreferences = {
      ...preferences.leadGridPreferences,
      columnWidths: updatedColumnWidths,
    };

    if (
      !comparePreferencesAreEqual(
        leadGridPreferences,
        preferences.leadGridPreferences
      )
    ) {
      dispatch(
        userPreferences({
          ...preferences,
          leadGridPreferences,
        })
      );
      handleAddGridPreferences(leadGridPreferences);
    }
  };

  const handleAddGridPreferences = async (data: any) => {
    try {
      const response = await putUserPreferences({
        leadGridPreferences: data,
      });
      if (response && response.status) {
        Logger.log(response.data.message);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Error while updating Source!");
      }
    }
  };

  const fetchLeadsWrapper = async () => {
    setResetGrid((prev) => prev + 1);
  };

  const fetchLeads = async (filterParam: FilterParams, leadName: string) => {
    try {
      setIsLoading(true);
      let response;
      if (
        Object.keys(filterParam).some((key) =>
          advanceFilterKeys.includes(key)
        ) &&
        !areAllValuesUndefined(filterParam, advanceFilterKeys)
      ) {
        response = await filterLeads({
          ...filterParam,
          list: leadName,
        });
      } else {
        response = await getAllLeads({
          isAscending: false,
          page: filterParam.paginationParams?.page,
          perPage: filterParam.paginationParams?.perPage,
          list: leadName,
        });
      }
      if (response && response.status) {
        let responseData = response?.data;
        setLeadListData(responseData.data);
        dispatch(setLeadCount(responseData.total));
        return { data: responseData.data, totalRows: responseData.total };
      }
    } catch (err) {
      Logger.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onGridReady = (params: any) => {
    gridApi.current = params.api;
  };

  useEffect(() => {
    if (!preferences) return;
    preferenceRef.current = preferences;
    const columnOrders = preferences.leadGridPreferences?.columnOrders || [];
    let columnWidths = preferences.leadGridPreferences?.columnWidths || {};

    // columnWidths["phone"] = 250;

    let orderedColumnDefs = [];
    if (columnOrders.length === 0) {
      orderedColumnDefs = defaultColumnDefs.map((def) => {
        const width = columnWidths[def.field || ""];

        return {
          ...def,
          width: width || def.width,
        };
      });
    } else {
      orderedColumnDefs = columnOrders.map((colId) => {
        let columnDef = defaultColumnDefs.find((def) => def.field === colId);
        if (
          !columnDef &&
          preferences.customForm &&
          preferences.customForm.length
        ) {
          const form = preferences.customForm.find(
            (form) => form.value === colId
          );
          if (form) {
            let cellRenderer;
            if (form.type === "location") {
              cellRenderer = locationRenderer;
            } else if (form.type === "date") {
              cellRenderer = dateRenderer;
            }
            let field = `extraDetails.${form.value}`;
            if (form.value === "saleValue") {
              field = "saleValue";
            } else if (form.value === "email") {
              field = "email";
            }
            columnDef = {
              headerName: form.label,
              sortable: false,
              width: 150,
              field: field,
              colId: form.value,
              cellRenderer,
            };
          }
        }
        if (columnDef) {
          const width = columnWidths[colId];
          return {
            ...columnDef,
            width: width || columnDef.width,
          };
        }

        return null;
      });
    }

    setColumnDefs(
      orderedColumnDefs.filter((colDef: any) => colDef !== null) as ColDef[]
    );
  }, [preferences]);

  useEffect(() => {
    if (isOpen) {
      let lead_name = StoreData.leadName.leadName;
      if (!("id" in lead_name)) {
        dispatch(setLeadName({ id: "0", name: "Lead list" }));
      }
      lead_name = StoreData.leadName.leadName;
      setSelectedLeadList(
        Object.keys(lead_name).length > 0
          ? lead_name
          : { id: "0", name: "Lead list" }
      );
      getLeadList();
    }
  }, [isOpen]);

  const reloadLeads = () => {
    gridApi.current?.refreshCells({ force: true });
    fetchLeadsWrapper();
  };

  const debouncedSearch = _.debounce((e: any) => {
    onSearch(e);
  }, 500);

  const onSearch = async (search?: string) => {
    dispatch(setLeadFilter({ ...filterParam, search: search || undefined }));

    reloadLeads();
  };

  const renderSearch = () => {
    return (
      <div
        className={`d-flex justify-content-center align-items-center leads-search-bar ${styles.lead_search}`}
      >
        <i
          className="fa fa-search cursor-pointer"
          aria-hidden="true"
          style={{ fontSize: "14px" }}
        ></i>
        <input
          type="text"
          placeholder="Type to search..."
          className="form-control background-transparent"
          value={searchValue}
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "transparent",
          }}
          onChange={(e) => {
            setSearchValue(e.target.value);
            debouncedSearch(e.target.value);
          }}
        />
      </div>
    );
  };

  const toggleLeadFilterDrawer = () => {
    setShowFilterDrawer(true);
  };

  const LeadFilterDataList = async (
    objectFilterData: Partial<FilterParams>
  ) => {
    dispatch(setLeadFilter({ ...filterParam, ...objectFilterData }));
    setShowFilterDrawer(false);
    reloadLeads();
  };

  const getLeadList = useCallback(async () => {
    try {
      const resp = await getAllLeadList({
        isAscending: false,
        page: 1,
        perPage: 50,
      });
      if (resp && resp.status) {
        dispatch(setLeadList(resp?.data?.data));
      }
    } catch (err) {}
  }, []);

  const handleLeadNameChange = (id: any, name: string) => {
    let tempObj = {
      id: id,
      name: name,
    };
    dispatch(setLeadName(tempObj));
    setSelectedLeadList(tempObj);
    reloadLeads();
  };

  const onRowSelected = (event: any) => {
    const selectedRows = event.api.getSelectedRows();
    handleSelectedLeads(selectedRows);
  };

  return (
    <Modal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
      <div className={styles.lead_selector}>
        <div className={styles.lead_heaader}>
          <section
            className="leads-section-1"
            style={{ padding: 0, margin: "5px 0" }}
          >
            <div className="align-items-center leads-section-1-sub-1">
              <div
                style={{
                  backgroundColor: "#EBF0F4",
                  borderRadius: "6px",
                  width: "27px",
                  height: "26px",
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <FiFilter
                  size={15}
                  onClick={toggleLeadFilterDrawer}
                  id="filter-img"
                  color="#000"
                  className="cursor-pointer"
                />
              </div>

              <LeadsList
                isLeadSelection
                onDeleteClick={() => {}}
                leadLists={StoreData.leadList.leadList}
                getLeadList={getLeadList}
                onEditList={() => {}}
                onAddList={() => {}}
                leadData={leadCount}
                LeadNameChange={handleLeadNameChange}
                selectedLeadList={selectedLeadList}
              />

              {selectedLeads?.length > 0 ? (
                <span className={styles.selected_count}>
                  {selectedLeads?.length} leads selected
                </span>
              ) : null}
            </div>
          </section>
          {renderSearch()}
          <button onClick={onClose} className={styles.close_button}>
            Save
          </button>
        </div>

        <AgGrid
          columnDefs={columnDefs}
          fetchData={fetchLeadListOnScroll}
          onRowClick={handleRowClick}
          defaultData={leadListData}
          totalRows={leadCount}
          isLoading={isLoading}
          onColumnOrderChanged={(newColumnOrder) =>
            debouncedColumnMove(newColumnOrder, preferenceRef.current)
          }
          onColumnWidthChanged={(columnId, newWidth) =>
            debouncedColumnWidth(columnId, newWidth, preferenceRef.current)
          }
          key={resetGrid}
          onGridReady={onGridReady}
          onRowSelected={onRowSelected}
        />
      </div>

      <DrawerComponent
        openWithHeader={showFilterDrawer}
        setOpenWithHeader={setShowFilterDrawer}
        drawerTitle="Leads Filter"
        customHeader={false}
        size="xs"
      >
        <LeadFilter
          setShowFilterDrawer={setShowFilterDrawer}
          LeadFilterDataList={LeadFilterDataList}
        />
      </DrawerComponent>
    </Modal>
  );
};

export default LeadSelector;
