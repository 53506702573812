import React, { useState, useEffect, ChangeEvent } from "react";
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import styled from 'styled-components';
import {
  FaFileUpload,
  FaGoogle,
  FaCheckCircle,
  FaSpinner,
  FaGoogleDrive,
  FaFileAlt,
  FaSignOutAlt,
  FaInfoCircle,
} from "react-icons/fa";
import DrawerComponent from "./components/drawer";
import DynamicConfirmationModal from "./components/DynamicConfirmationModal";
import { setLeads } from "./actions/actions";
import { getAllLeads } from "./services/leadService";
import {
  getGoogleSheets,
  getGoogleSheetsInfo,
  getGoogleTokens,
  importGoogleSheet,
} from "./services/authServices";
import { useGoogleLogin, CodeResponse, TokenResponse } from '@react-oauth/google';
import { GoogleAuthService } from './services/GoogleAuthService';
import {
  DrawerContent,
  StepIndicatorContainer,
  StepIndicator,
  StepDot,
  StepConnector,
  StepContainer,
  StepTitle,
  StepDescription,
  GoogleButton,
  SheetList,
  SheetItem,
  SheetIcon,
  SheetDetails,
  SheetName,
  SheetDate,
  MappingContainer,
  MappingItem,
  MappingLabel,
  MappingSelect,
  ImportButton,
  SuccessIcon,
  DropdownItem,
  CloseButton,
  StepIndicatorSection,
  DisconnectButton,
} from './UploadSheetFile.styles';

const ImportDialogContainer = styled(StepContainer)`
  text-align: center;
  padding: 24px;
`;

const SheetRequirementsNote = styled.div`
  background: #f0f9ff;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  border-left: 4px solid #3b82f6;
  display: flex;
  gap: 12px;
  align-items: flex-start;

  svg {
    flex-shrink: 0;
    margin-top: 2px;
    color: #3b82f6;
  }
`;

const HelpLink = styled.a`
  color: #3b82f6;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 8px;
  display: block;

  &:hover {
    text-decoration: none;
  }
`;

const DialogButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
  justify-content: center;
`;

interface ImportDialogProps {
  onRegularImport: () => void;
  onSyncImport: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

const ImportDialog: React.FC<ImportDialogProps> = ({
  onRegularImport,
  onSyncImport,
  onCancel,
  isLoading
}) => (
  <ImportDialogContainer>
    <StepTitle>Enable Auto-Sync?</StepTitle>
    <StepDescription>
      <div style={{ marginBottom: '16px' }}>
        <strong>One-time Import:</strong> Import all leads from the sheet once.
      </div>
      <div style={{ marginBottom: '16px' }}>
        <strong>Keep Synced:</strong> Automatically import new leads every few minutes. Perfect if:
        <ul style={{ textAlign: 'left', marginTop: '8px', marginBottom: '8px' }}>
          • Your sheet is connected to Google Forms<br/>
          • You frequently add new leads to this sheet<br/>
          • You have a team updating this sheet
        </ul>
      </div>
    </StepDescription>
    <DialogButtons>
      <ImportButton 
        onClick={onSyncImport}
        disabled={isLoading}
        style={{ backgroundColor: '#10B981' }}
      >
        {isLoading ? <FaSpinner className="spinner" /> : "Yes, Keep Synced"}
      </ImportButton>
      <ImportButton
        onClick={onRegularImport}
        disabled={isLoading}
      >
        Just Import Once
      </ImportButton>
    </DialogButtons>
  </ImportDialogContainer>
);




interface Props {
  selectedList: {
    id: string;
    name: string;
  };
}

interface AuthError extends Error {
  code: 'TOKEN_INVALID' | 'NETWORK_ERROR' | 'AUTH_REQUIRED' | 'RATE_LIMIT' | 'SERVER_ERROR';
}



interface GoogleSheetsInfoResponse {
  statusCode: number;
  status: boolean;
  data: string[];
}

interface Sheet {
  id: string;
  name: string;
}

interface GoogleTokens {
  access_token: string;
  scope: string;
  token_type: string;
  expiry_date: number;
}

interface RootState {
  rootReducers: {
    user?: {
      userPreferences?: {
        customForm?: Array<{ name: string; [key: string]: any }>;
      };
    };
  };
}

const UploadSheetFile: React.FC<Props> = ({ selectedList }) => {
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sheetsList, setSheetsList] = useState<Sheet[]>([]);
  const [selectedSheet, setSelectedSheet] = useState<string>("");
  const [sheetHeaders, setSheetHeaders] = useState<string[]>([]);
  const [mappedFields, setMappedFields] = useState<{ [key: string]: string }>({});
  const [formCloseModel, setFormCloseModel] = useState<boolean>(false);
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);
  const [showingImportDialog, setShowingImportDialog] = useState(false);
  const dispatch = useDispatch();


  const handleGoogleLoginSuccess = async (codeResponse: CodeResponse) => {
    try {
      setIsLoading(true);
      await GoogleAuthService.sendGoogleCode(codeResponse.code);
  
      const { isValid, hasRequiredScopes } = await GoogleAuthService.checkExistingTokens();
      
      if (isValid && hasRequiredScopes) {
        toast.success('Successfully connected to Google Sheets');
        await fetchGoogleSheets();
        setCurrentStep(1);
      } else {
        throw new Error('Failed to obtain required permissions');
      }
    } catch (error) {
      console.error('Auth error:', error);
      toast.error('Failed to connect to Google Sheets. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: handleGoogleLoginSuccess,
    onError: (error) => {
      console.error('Google Login Error:', error);
      toast.error('Failed to authenticate with Google');
      setIsLoading(false);
    },
    scope: GoogleAuthService.SHEETS_SCOPE
  });

  const handleFieldMapping = (crmField: string, sheetHeader: string): void => {
    setMappedFields((prev) => ({
      ...prev,
      [crmField]: sheetHeader
    }));
  };
  useEffect(() => {
    const checkTokenAndProceed = async () => {
      if (currentStep === 0) {
        try {
          setIsLoading(true);
          const { isValid, hasRequiredScopes } = await GoogleAuthService.checkExistingTokens();
          
          // Only proceed if we have valid token with sheets scope
          if (isValid && hasRequiredScopes) {
            // Valid token with sheets scope - proceed to sheets list
            await fetchGoogleSheets();
            setCurrentStep(1);
          }
          // If not valid or missing scopes, stay on auth screen (step 0)
        } catch (error) {
          console.error('Token check failed:', error);
          toast.error('Failed to verify Google authentication status');
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    checkTokenAndProceed();
  }, [currentStep]);

  const AUTH_CONFIG = {
    MAX_RETRIES: 2,
    RETRY_DELAY: 1000,
    AUTH_TIMEOUT: 30000, // 30s
    REQUEST_TIMEOUT: 15000 // 15s
  } as const;

  const handleSheetError = (error: { response: { data: { message: any; }; }; }) => {
    if (axios.isAxiosError(error) && error.response?.data?.message) {
      const errorMessage = error.response.data.message;
      
      // Check for the specific sheet naming error
      if (errorMessage.includes("Unable to parse range") && 
          errorMessage.includes("Sheet1!A1:Z1")) {
        
        return toast.error(
          "We can only read from the first tab in your spreadsheet, and it must be named 'Sheet1'. " +
          "Please rename your first sheet tab to 'Sheet1' and try again.",
          { duration: 6000 }
        );
      }
    }
    
    // Default error handling
    toast.error("Failed to import sheet. Please check your sheet structure and try again.");
  }

  const handleSheetSelect = async (sheetId: string): Promise<void> => {
    try {
      setIsLoading(true);
      setSelectedSheet(sheetId);
      
      const response = await getGoogleSheetsInfo(sheetId);
      const responseData = response.data as GoogleSheetsInfoResponse; // Access the data from axios response
      
      // Check the response structure from the API
      if (responseData.statusCode === 200 && responseData.status && Array.isArray(responseData.data)) {
        // Clean the headers (remove extra spaces) and filter out empty strings
        const cleanedHeaders = responseData.data
          .map(header => header.trim())
          .filter(header => header.length > 0);
        
        setSheetHeaders(cleanedHeaders);
        setCurrentStep(2);
      } else {
        throw new Error('Invalid header data received');
      }
    } catch (error) {
      console.error('Header fetch error:', error);
      toast.error('Failed to fetch sheet headers');
      setSheetHeaders([]); // Reset to empty array on error
    } finally {
      setIsLoading(false);
    }
  };
  
  const getFilteredMappings = () => {
    return Object.entries(mappedFields)
      .filter(([_, value]) => value !== "")
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as { [key: string]: string });
  };

  
  
 

  const fetchGoogleSheets = async (): Promise<void> => {
  try {
    setIsLoading(true);
    const response = await getGoogleSheets();
    
    if (response?.data?.data) {
      setSheetsList(response.data.data);
    } else {
      throw new Error('No sheets data available');
    }
  } catch (error: unknown) {
    console.error('Fetch sheets error:', error);
    
    // Type guard for axios error
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      toast.error('Session expired. Please reconnect to Google Sheets');
      setCurrentStep(0);  // Go back to auth step
    } else {
      toast.error('Failed to fetch Google Sheets');
      setSheetsList([]);
    }
  } finally {
    setIsLoading(false);
  }
};

   const handleGoogleAuth = async (): Promise<void> => {
    try {
      setIsLoading(true);
      login(); // Directly initiate login flow
    } catch (error) {
      console.error('Google auth error:', error);
      toast.error('Failed to authenticate with Google');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnect = async () => {
    try {
      setIsDisconnecting(true);
      await GoogleAuthService.disconnect();
      toast.success('Successfully disconnected from Google');
      setCurrentStep(-1); // Close the drawer
    } catch (error) {
      console.error('Disconnect error:', error);
      toast.error('Failed to disconnect from Google');
    } finally {
      setIsDisconnecting(false);
    }
  };

  const importLeads = () => {
    if (!selectedSheet) {
      toast.error("Please select a sheet first");
      return;
    }
    setShowingImportDialog(true);
  };
  
  const handleRegularImport = async () => {
    try {
      setIsLoading(true);
      const filteredMappings = getFilteredMappings();
      
      const response = await importGoogleSheet({
        spreadsheetId: selectedSheet,
        mapping: filteredMappings,
        list: selectedList?.id && selectedList.id !== "0" ? selectedList.id : undefined,
      });

      const leadsResponse = await getAllLeads({
        isAscending: false,
        page: 1,
        perPage: 10,
      });

      if (leadsResponse && leadsResponse.status) {
        dispatch(setLeads(leadsResponse.data.data));
      }

      toast.success("Leads imported successfully");
      setCurrentStep(3);
    } catch (error) {
      console.error("Error importing leads:", error);
      toast.error("Failed to import leads");
    } finally {
      setIsLoading(false);
      setShowingImportDialog(false);
    }
  };

  const handleSyncImport = async () => {
    try {
      setIsLoading(true);
      const filteredMappings = getFilteredMappings();
      
      // Setup sync first
      const authToken = localStorage.getItem('auth_token');
      await fetch('https://mapi2.3sigmacrm.com/api/v1/integration/user-integration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken || ''
        },
        body: JSON.stringify({
          integrationKey: "google_spread_sheets",
          details: [{
            spreadsheetId: selectedSheet,
            spreadsheetName: sheetsList.find(s => s.id === selectedSheet)?.name || '',
            range: "A1:Z10000",
            isFirstTimeCompleted: false,
            isRecursive: true,
            mapping: filteredMappings
          }]
        })
      });

      // Do the regular import after setting up sync
      await handleRegularImport();
      toast.success("Import completed and sync setup successfully");
    } catch (error) {
      console.error("Error setting up sync:", error);
      toast.error("Failed to setup sync");
    } finally {
      setIsLoading(false);
      setShowingImportDialog(false);
    }
  };

  const StoreData = useSelector((state: RootState) => state.rootReducers);
  useEffect(() => {
    if (StoreData.user?.userPreferences?.customForm) {
      const initialMapping = StoreData.user.userPreferences.customForm.reduce<{ [key: string]: string }>(
        (acc, field) => {
          acc[field.name] = "";
          return acc;
        },
        {}
      );
      setMappedFields(initialMapping);
    }
  }, [StoreData]);

  

  
 
  

  

  

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepContainer>
            <StepTitle>Connect to Google Sheets</StepTitle>
            <StepDescription>
              Streamline your lead import process by connecting directly to your
              Google Sheets. This secure connection allows you to easily select
              and import lead data.
            </StepDescription>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <GoogleButton onClick={handleGoogleAuth} disabled={isLoading}>
                {isLoading ? (
                  <>
                    <FaSpinner className="spinner" />
                    {/* Show different text based on whether we're checking or connecting */}
                    {currentStep === 0 ? "Checking connection..." : "Connecting..."}
                  </>
                ) : (
                  <>
                    <FaGoogle />
                    Connect Google Sheets
                  </>
                )}
              </GoogleButton>
            </div>
          </StepContainer>
        );
  
      case 1:
        return (
          <StepContainer>
            <StepTitle>Select Google Sheet</StepTitle>
            <StepDescription>
              Choose the Google Sheet containing your lead data. Only sheets you
              have access to will be displayed.
            </StepDescription>

           
            <SheetList>
              {Array.isArray(sheetsList) && sheetsList.length > 0 ? (
                sheetsList.map((sheet) => (
                  <SheetItem
                    key={sheet.id}
                    onClick={() => handleSheetSelect(sheet.id)}
                  >
                    <SheetIcon>
                      <FaFileAlt />
                    </SheetIcon>
                    <SheetName>{sheet.name}</SheetName>
                  </SheetItem>
                ))
              ) : (
                <StepDescription>No sheets available</StepDescription>
              )}
            </SheetList>
            <SheetRequirementsNote>
  <FaInfoCircle size={18} />
  <div>
    <strong>Sheet Requirements:</strong>
    <ul style={{ margin: '8px 0', paddingLeft: '20px' }}>
      <li>First tab must be named <code>Sheet1</code></li>
      <li>First row should contain headers</li>
     
    </ul>
    <HelpLink 
      href="https://help.3sigmacrm.com/welcome/adding-or-importing-leads/import-from-google-sheets" 
      target="_blank"
      rel="noopener noreferrer"
    >
      View our step-by-step import guide
    </HelpLink>
  </div>
</SheetRequirementsNote>
          </StepContainer>
        );
      

      case 2:
        if (showingImportDialog) {
          return (
            <ImportDialog
              onRegularImport={handleRegularImport}
              onSyncImport={handleSyncImport}
              onCancel={() => setShowingImportDialog(false)}
              isLoading={isLoading}
            />
          );
        }

        return (
          <StepContainer>
            <StepTitle>Map Fields</StepTitle>
            <StepDescription>
              Match your Google Sheet columns to the corresponding fields in
              your CRM. This ensures your lead data is imported correctly.
            </StepDescription>
            <MappingContainer>
              {Object.entries(mappedFields).map(([crmField, value]) => (
                <MappingItem key={crmField}>
                  <MappingLabel>{crmField}</MappingLabel>
                  <MappingSelect
                    value={value}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      handleFieldMapping(crmField, e.target.value)
                    }
                  >
                    <option value="">Select column</option>
                    {sheetHeaders.length > 0 ? (
                      sheetHeaders.map((header) => (
                        <option key={header} value={header}>
                          {header}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>No headers available</option>
                    )}
                  </MappingSelect>
                </MappingItem>
              ))}
            </MappingContainer>
            <ImportButton 
              onClick={importLeads} 
              disabled={isLoading || sheetHeaders.length === 0 || Object.values(getFilteredMappings()).length === 0}
            >
              {isLoading ? <FaSpinner className="spinner" /> : "Import Leads"}
            </ImportButton>
          </StepContainer>
        );
  
      case 3:
        return (
          <StepContainer>
            <StepTitle>Import Successful</StepTitle>
            <SuccessIcon>
              <FaCheckCircle />
            </SuccessIcon>
            <StepDescription>
              Your leads have been successfully imported. They are now available
              in your CRM for follow-up and management.
            </StepDescription>
            <CloseButton onClick={() => setCurrentStep(-1)}>Close</CloseButton>
          </StepContainer>
        );
  
      default:
        return null;
    }
  };

  return (
    <>
      <DropdownItem 
        onClick={() => {
          setCurrentStep(0);
          setIsLoading(true); // Set loading while we check token status
        }}
      >
        <FaGoogleDrive />
        Import from Google Sheets
      </DropdownItem>

      <DrawerComponent
  openWithHeader={currentStep !== -1}
  setOpenWithHeader={() => setFormCloseModel(true)}
  drawerTitle="Google Sheets Import"
  size="sm"
>
  <DrawerContent>
    <StepIndicatorSection>
      <StepIndicatorContainer>
        <StepIndicator>
          {["Connect", "Select", "Map", "Import"].map((step, index) => (
            <React.Fragment key={index}>
              <StepDot
                active={index === currentStep}
                completed={index < currentStep}
              >
                {index + 1}
              </StepDot>
              {index < 3 && (
                <StepConnector completed={index < currentStep} />
              )}
            </React.Fragment>
          ))}
        </StepIndicator>
      </StepIndicatorContainer>
      
      {currentStep > 0 && (
  <DisconnectButton
    onClick={handleDisconnect}
    disabled={isDisconnecting}
  >
    {isDisconnecting ? (
      <FaSpinner className="spinner" />
    ) : (
      <FaSignOutAlt />
    )}
    Disconnect Google
  </DisconnectButton>
)}
    </StepIndicatorSection>

    {renderStep()}
  </DrawerContent>
</DrawerComponent>

      <DynamicConfirmationModal
        onConfirmation={() => {
          setCurrentStep(-1);
          setFormCloseModel(false);
        }}
        showModal={formCloseModel}
        toggleModal={() => setFormCloseModel(false)}
        title="Are you sure you want to cancel the import process?"
      />
    </>
  );
};

export default UploadSheetFile;