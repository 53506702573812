import axios from "axios";
import { API_URL } from "../config/config";
import { Invoice } from "../views/Invoice/Invoice"; 
import { getHeaderOptions } from "./getHeaderOptions";
import { Logger } from '../utils/logger';

interface InvoiceIdResponse {
    statusCode: number;
    status: boolean;
    data: {
      quotationId: string;
    }
  }

  interface InvoiceRequestParams {
    orderBy: string;
    page: number;
    perPage: number;
    status?: string;
    fromDate?: string;
    toDate?: string;
    search?: string;
  }
  
  
const API_ENDPOINT = API_URL + 'api/v1';

export const getAllInvoices = (params: InvoiceRequestParams) => {
    Logger.log('InvoiceService: Making request with params:', params);
    const headers = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
    };
    return axios.get(`${API_ENDPOINT}/Invoice`, { headers, params });
  };

export const createInvoices = (data: Invoice) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    return axios.post(`${API_ENDPOINT}/Invoice`, data, { headers });
};

export const getInvoiceId = () => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    Logger.log('[InvoiceService] Fetching invoice ID');
    return axios.get<InvoiceIdResponse>(`${API_URL}api/v1/invoice/id`, { headers });
};

export const updateInvoices = (data: Invoice) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };

    // Extract ID for the API endpoint
    const id = data._id;
    
    // Remove _id from request body to prevent issues
    const { _id, ...updatePayload } = data;
    
    Logger.log('Service: Updating invoice with ID:', id);
    Logger.log('Service: Update payload (without _id):', updatePayload);
    
    return axios.put(`${API_ENDPOINT}/Invoice/${id}`, updatePayload, { headers });
};

export const deleteInvoices = (id: string) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    return axios.delete(`${API_ENDPOINT}/Invoice/${id}`, { headers });
};

export const getInvoiceDetails = (id: string) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    return axios.get(`${API_ENDPOINT}/Invoice/${id}`, { headers });
};