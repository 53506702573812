import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input, Card, message, Modal, Pagination } from 'antd';
import { Plus, Search, Edit2, Trash2, Package } from 'lucide-react';
import Header from '../../components/header';
import ProductForm from './ProductForm';
import { getAllProducts, deleteProduct } from '../../services/productService';

interface ProductImage {
  filePath: string;
  fileName: string;
  uploadedBy: string;
  uploadedAt: string;
  fileSize: string;
}

interface Product {
  _id: string;
  name: string;
  description: string | null;
  images: ProductImage[];
  currency: string;
  price: number;
  category: {
    _id: string;
    name: string;
  };
  extraDetails: {
    unit?: string;
    [key: string]: any;
  };
  isActive: boolean;
  createdAt: string;
}

interface ApiResponse {
  data: Product[];
  total: number;
}

// Styled Components preserving class names
const StyledMainContainer = styled.div.attrs({ className: 'main-container' })`
  padding: 16px;
  background-color: #f5f7f9;
  min-height: calc(100vh - 64px);
`;

const ContentHeader = styled.div.attrs({ className: 'content-header' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0;

  .title-section {
    display: flex;
    align-items: center;
    gap: 8px;

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #1e293b;
      margin: 0;
    }
  }

  .actions-section {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const SearchContainer = styled.div.attrs({ className: 'search-wrapper' })`
  position: relative;
  width: 450px;
  margin-right: 8px;

  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #8392ab;
    pointer-events: none;
    z-index: 1;
  }

  .ant-input {
    width: 100%;
    padding-left: 36px;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    height: 40px;
    
    &::placeholder {
      color: #94a3b8;
    }
    
    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
    }
  }
`;

const StyledInput = styled(Input)`
  padding: 8px 12px 8px 36px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 0.875rem;
  height: 38px;

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
  padding: 16px;
`;

const ProductCard = styled(Card)`
  border-radius: 8px;
  background: white;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .action-buttons {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const ActionButtons = styled.div.attrs({ className: 'action-buttons' })`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 4px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  background: white;
  padding: 4px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const Badge = styled.span`
  background: #f1f5f9;
  color: #64748b;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  &.btn-primary {
    background: #1890ff;
    border-color: #1890ff;
    color: white;
    height: 40px;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 6px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      background: #40a9ff;
      border-color: #40a9ff;
    }

    &:active {
      background: #096dd9;
      border-color: #096dd9;
    }
  }
`;

interface StatusBadgeProps {
  status?: string;
}

const StatusBadge = styled.div<StatusBadgeProps>`
  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  color: white;
  background: ${props => {
    switch (props.status?.toLowerCase()) {
      case 'active': return '#4CB050';
      case 'inactive': return '#E32C2C';
      default: return '#8392ab';
    }
  }};
`;

const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [showProductForm, setShowProductForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const pageSize = 12;

  useEffect(() => {
    fetchProducts();
  }, [currentPage, searchTerm]);

  const handleEdit = (product: Product) => {
    setSelectedProduct(product);
    setShowProductForm(true);
  };

  const handleDelete = async (confirm: boolean) => {
    if (confirm && selectedProduct) {
      try {
        await deleteProduct(selectedProduct._id);
        message.success('Product deleted successfully');
        fetchProducts();
      } catch (error) {
        message.error('Error deleting product');
      }
    }
    setDeleteModalVisible(false);
    setSelectedProduct(null);
  };

  const fetchProducts = async (page: number = currentPage) => {
    try {
      setLoading(true);
      const params = {
        page,
        perPage: pageSize,
        ...(searchTerm && { search: searchTerm.trim() })
      };
      
      const response = await getAllProducts(params);
      if (response?.data) {
        const apiResponse = response.data as ApiResponse;
        setProducts(apiResponse.data);
        setTotalProducts(apiResponse.total);
      }
    } catch (error) {
      message.error('Failed to load products');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="main" className="main">
      <Header />
      <StyledMainContainer>
        <Card bodyStyle={{ padding: '16px' }}>
          <ContentHeader>
            <div className="title-section">
              <h1>Products ({totalProducts} items)</h1>
            </div>
            
            <div className="actions-section">
              <SearchContainer>
                <Search className="search-icon" size={16} />
                <Input
                  placeholder="Search products..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchContainer>
              
              <StyledButton 
                className="btn-primary"
                onClick={() => setShowProductForm(true)}
              >
                <Plus size={16} />
                Add Product
              </StyledButton>
            </div>
          </ContentHeader>

          <ProductsGrid>
            {products.map((product) => (
              <ProductCard key={product._id}>
                <ActionButtons>
                  <Button
                    type="text"
                    className="btn-icon"
                    onClick={() => handleEdit(product)}
                    title="Edit product"
                  >
                    <Edit2 size={14} />
                  </Button>
                  <Button
                    type="text"
                    className="btn-icon text-danger"
                    onClick={() => {
                      setSelectedProduct(product);
                      setDeleteModalVisible(true);
                    }}
                    title="Delete product"
                  >
                    <Trash2 size={14} />
                  </Button>
                </ActionButtons>

                <div style={{ marginBottom: '12px' }}>
                  {product.images?.[0]?.filePath ? (
                    <img 
                      src={product.images[0].filePath}
                      alt={product.name}
                      style={{ 
                        width: '100%', 
                        height: '140px',
                        objectFit: 'cover',
                        borderRadius: '6px'
                      }}
                    />
                  ) : (
                    <div style={{ 
                      width: '100%', 
                      height: '140px', 
                      background: '#f8f9fa',
                      borderRadius: '6px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center' 
                    }}>
                      <Package size={24} color="#8392ab" />
                    </div>
                  )}
                </div>

                <div style={{ padding: '8px' }}>
                  <h3 style={{ 
                    fontSize: '14px',
                    fontWeight: 600,
                    marginBottom: '4px',
                    color: '#1e293b'
                  }}>
                    {product.name}
                  </h3>
                  
                  <div style={{ 
                    fontSize: '12px',
                    color: '#64748b',
                    marginBottom: '8px' 
                  }}>
                    {product.category?.name || 'No Category'}
                  </div>
                  
                  <div style={{ 
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <div style={{ 
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#0284c7'
                    }}>
                      ₹{product.price.toLocaleString()}
                    </div>
                    {product.extraDetails?.unit && (
                      <div style={{ 
                        fontSize: '12px',
                        color: '#64748b'
                      }}>
                        {product.extraDetails.unit}
                      </div>
                    )}
                  </div>
                </div>
              </ProductCard>
            ))}
          </ProductsGrid>

          {totalProducts > pageSize && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
              <Pagination
                current={currentPage}
                total={totalProducts}
                pageSize={pageSize}
                onChange={setCurrentPage}
                showSizeChanger={false}
              />
            </div>
          )}
        </Card>

        <ProductForm
          show={showProductForm}
          onHide={() => setShowProductForm(false)}
          selectedProduct={selectedProduct}
          fetchProducts={fetchProducts}
        />

        <Modal
          title="Delete Product"
          open={deleteModalVisible}
          onOk={() => handleDelete(true)}
          onCancel={() => handleDelete(false)}
          className="custom-modal"
        >
          <p>Are you sure you want to delete this product?</p>
        </Modal>
      </StyledMainContainer>
    </div>
  );
};

export default ProductList;