import styled from 'styled-components';
import { Input, InputNumber, Select, Button, Form } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';

// ====== Common Colors and Variables ======
export const COLORS = {
  border: '#e2e8f0',
  primary: '#3b82f6',
  primaryLight: 'rgba(59, 130, 246, 0.1)',
  danger: '#ef4444',
  dangerHover: '#dc2626',
  background: '#f8fafc',
  text: '#1f2937',
  textSecondary: '#6b7280',
  inputBg: '#ffffff',
  divider: '#e5e7eb',
};

// ====== Common Container Components ======
export const SectionContainer = styled.div`
  margin-bottom: 1.5rem;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SectionTitle = styled.span`
  font-weight: 500;
  color: ${COLORS.text};
  font-size: 0.9rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${COLORS.divider};
  margin: 1rem 0;
`;

// ====== Common Form Elements ======
export const StyledInput = styled(Input)`
  height: 40px;
  border-radius: 8px;
  border: 1px solid ${COLORS.border};
  
  &:hover, &:focus {
    border-color: ${COLORS.primary};
    box-shadow: 0 0 0 2px ${COLORS.primaryLight};
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  height: 40px;
  width: 100%;
  border-radius: 8px;
  
  .ant-input-number-input {
    height: 38px;
  }
  
  &:hover, &:focus {
    border-color: ${COLORS.primary};
    box-shadow: 0 0 0 2px ${COLORS.primaryLight};
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 40px !important;
    border-radius: 8px !important;
    border: 1px solid ${COLORS.border} !important;
    padding: 4px 11px !important;
    
    &:hover {
      border-color: ${COLORS.primary} !important;
    }
  }
  
  .ant-select-selection-item {
    line-height: 30px !important;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 12px;
  
  .ant-form-item-label > label {
    color: ${COLORS.textSecondary};
    font-size: 0.8rem;
  }
`;

// ====== Button Styles ======
export const ActionButton = styled(Button)`
  &.ant-btn-link {
    padding: 0;
    color: ${COLORS.primary};
    
    &:hover {
      color: ${props => props.danger ? COLORS.danger : COLORS.primary};
      opacity: 0.8;
    }
  }
`;

export const DeleteButton = styled(DeleteOutlined)`
  color: ${COLORS.danger};
  font-size: 16px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    color: ${COLORS.dangerHover};
    background: rgba(239, 68, 68, 0.1);
    border-radius: 6px;
  }
`;

export const AddButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  height: 40px;
  
  &.ant-btn-dashed {
    border-color: ${COLORS.primary};
    color: ${COLORS.primary};
    
    &:hover {
      border-color: ${COLORS.primary};
      color: ${COLORS.primary};
      opacity: 0.8;
    }
  }
`;

export const StyledIconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  color: ${COLORS.textSecondary};
  cursor: help;
`;

// ====== Grid Layouts ======
export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 48px;
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 1rem;
  
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const ChargeGrid = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1.5fr 1.5fr 40px;
  gap: 12px;
  margin-bottom: 16px;
  align-items: flex-start;
`;

// ====== Specific Components for Additional Charges ======
export const ChargesContainer = styled(SectionContainer)`
  .additional-charges-list {
    margin-bottom: 1rem;
  }
`;

// ====== Specific Components for Discounts ======
export const DiscountContainer = styled(SectionContainer)`
  padding-top: 1rem;
  border-top: 1px solid ${COLORS.divider};
`;

export const DiscountControls = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
`;

export const FixedWidthSelect = styled(StyledSelect)`
  width: 150px !important;
`;

export const FixedWidthInputNumber = styled(StyledInputNumber)`
  width: 150px !important;
`;

// ====== Specific Components for Taxes ======
export const TaxContainer = styled(SectionContainer)`
  padding-top: 1rem;
  border-top: 1px solid ${COLORS.divider};
`;

export const TaxFieldsContainer = styled(ThreeColumnGrid)`
  .ant-btn {
    margin-top: 29px; /* Aligns with inputs - accounts for label height */
  }
`;

// ====== Main Adjustments Card ======
export const AdjustmentsCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid ${COLORS.divider};
  overflow: hidden;
`;

export const CardHeader = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${COLORS.divider};
`;

export const CardBody = styled.div`
  padding: 1rem;
`;

export const CardTitle = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: ${COLORS.text};
`;