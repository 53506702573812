import axios from "axios";
import { API_URL } from "../config/config";
import { Quotation } from "../views/Quotation/Quotation"; 
import { getHeaderOptions } from "./getHeaderOptions";
import { Logger } from '../utils/logger';

interface QuotationIdResponse {
    statusCode: number;
    status: boolean;
    data: {
      quotationId: string;
    }
}

interface QuotationRequestParams {
  orderBy: string;
  page: number;
  perPage: number;
  status?: string;
  fromDate?: string;
  toDate?: string;
  search?: string;
}

const API_ENDPOINT_V1 = API_URL + 'api/v1';
const API_ENDPOINT_V2 = API_URL + 'api/v2';

export const getAllQuotations = (params: QuotationRequestParams) => {
    Logger.log('QuotationService: Making request with params:', params);
    const headers = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
    };
    // Keep using v1 as in the original
    return axios.get(`${API_ENDPOINT_V1}/Quotation`, { headers, params });
};

export const createQuotations = (data: Quotation) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    // Update to v2 like mobile
    return axios.post(`${API_ENDPOINT_V2}/quotation`, data, { headers });
};

export const getQuotationId = () => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    Logger.log('[QuotationService] Fetching quotation ID');
    // Keep v1 as mobile uses /quotation/last-id without v2
    return axios.get<QuotationIdResponse>(`${API_URL}api/v1/quotation/last-id`, { headers });
};

export const updateQuotations = (data: Quotation) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };

    // Extract ID for the API endpoint
    const id = data._id;
    
    // Remove _id from request body to prevent issues
    const { _id, ...updatePayload } = data;
    
    Logger.log('Service: Updating quotation with ID:', id);
    Logger.log('Service: Update payload (without _id):', updatePayload);
    
    // Update to v2 like mobile
    return axios.put(`${API_ENDPOINT_V2}/quotation/${id}`, updatePayload, { headers });
};

export const deleteQuotations = (id: string) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    // Keep using v1 as in the original since mobile doesn't use v2 for delete
    return axios.delete(`${API_ENDPOINT_V1}/Quotation/${id}`, { headers });
};

export const getQuotationDetails = (id: string) => {
    const headers = {
        ...getHeaderOptions,
        Authorization: localStorage.getItem('auth_token') || ''
    };
    // Update to v2 like mobile
    return axios.get(`${API_ENDPOINT_V2}/quotation/${id}`, { headers });
};