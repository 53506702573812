import DayGroup from "./DayGroup";
import React, { useCallback } from "react";
import DelayControl from "./DelayControl";
import { Logger } from '../../utils/logger';

interface ActionData {
  type: string;
  data: any;
}

interface IActionSection {
  isUpdateAuto: boolean;
  taskData: any;
  dayActions: Record<string, ActionData[]>;
  setDayActions: any;
  ShowInfoAction: any;
  setDayDelays: any;
  statusData: any;
  userListData: any;
  labelData: any;
  leadListData: any;
  whatsappTemplate: any;
  emailTemplate: any;
  dayErrors: any;
  dayDelays: any;
  dayMetrics?: Record<string, any>;
}

const ActionSection = ({
  isUpdateAuto,
  taskData,
  dayActions,
  setDayActions,
  ShowInfoAction,
  setDayDelays,
  statusData,
  userListData,
  labelData,
  leadListData,
  whatsappTemplate,
  emailTemplate,
  dayErrors,
  dayDelays,
  dayMetrics, 
}: IActionSection) => {
  // NEW: Add this function
  const handleAddAction = (dayKey: string) => {
    setDayActions((prev: any) => ({
      ...prev,
      [dayKey]: [...(prev[dayKey] || []), { type: "", data: {} }],
    }));
  };

  const handleActionChange = (
    dayKey: string,
    actionIndex: number,
    value: string
  ) => {
    setDayActions((prev: any) => {
      const newActions = { ...prev };
      if (newActions[dayKey]) {
        newActions[dayKey] = newActions[dayKey].map(
          (action: any, idx: number) =>
            idx === actionIndex ? { ...action, type: value, data: {} } : action
        );
      }

      return newActions;
    });
  };

  const handleActionDelete = (dayKey: string, actionIndex: number) => {
    setDayActions((prev: any) => ({
      ...prev,
      [dayKey]: prev[dayKey].filter(
        (_: any, idx: number) => idx !== actionIndex
      ),
    }));
  };

  const handleDeleteDay = useCallback((dayKey: string) => {
    setDayActions((prev: any) => {
      const newActions = { ...prev };
      delete newActions[dayKey];
      return newActions;
    });
    setDayDelays((prev: any) => {
      const newDelays = { ...prev };
      delete newDelays[dayKey];
      return newDelays;
    });
  }, []);

  // NEW: Add this function
  const handleAddDay = () => {
    const days = Object.keys(dayActions);

    let lastDayNumber = 0;

    if (days?.length) {
      lastDayNumber = Math.max(
        ...days.map((day) => parseInt(day.split("_")[1]))
      );
    }

    const newDayKey = `day_${lastDayNumber + 1}`;

    // Logger.log("newDayKey", newDayKey);

    setDayActions((prev: any) => ({
      ...prev,
      [newDayKey]: [],
    }));

    // CHANGED: Initialize with zero delay
    setDayDelays((prev: any) => ({
      ...prev,
      [newDayKey]: 1,
    }));
  };

  // Update the day handling logic

  const handleDelayChange = (
    dayKey: string,
    newDelay: number,
    index: number
  ) => {
    if (index < Object.keys(dayActions)?.length - 2) {
      return;
    }

    // 1. Set delay exactly as provided
    setDayDelays((prev: any) => ({
      ...prev,
      [dayKey]: newDelay,
    }));

    let tempDelays = { ...dayDelays, [dayKey]: newDelay };

    const sortedDays = Object.keys(dayActions).sort(
      (a, b) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1])
    );

    if (sortedDays.length === 0) return;

    let currentDayNumber = 1;

    const newDayActions: Record<string, any> = {};

    sortedDays.forEach((dayKey, index) => {
      newDayActions[`day_${currentDayNumber}`] = dayActions[dayKey];

      if (index < sortedDays.length - 1) {
        let delayKey = tempDelays[dayKey] || 1;
        currentDayNumber += delayKey;
      }
    });

    setDayActions(newDayActions);
  };

  return (
    <div className="col-md-12 when_then">
      <span className="auto_dot">
        <label className="dot_no">2</label>
      </span>
      <label className="when_then_label">Then do this...</label>
      <span className="when_then_trigger">
      Add actions to execute automatically
      </span>

     {/* Day Groups */}
{Object.entries(dayActions)
  .sort(
    ([a], [b]) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1])
  )
  .map(([dayKey, actions], index, arr) => {
    return (
      <React.Fragment key={dayKey}>
        <DayGroup
          key={dayKey}
          dayKey={dayKey}
          actions={dayActions[dayKey]} // Use dayActions[dayKey] to get the correct actions
          onAddAction={handleAddAction}
          onActionChange={handleActionChange}
          onActionDelete={handleActionDelete}
          onShowActionInfo={(value) => {
            ShowInfoAction(value, dayKey);
          }}
          onDeleteDay={handleDeleteDay}
          isUpdateAuto={isUpdateAuto}
          statusData={statusData}
          userListData={userListData}
          labelData={labelData}
          leadListData={leadListData}
          whatsappTemplate={whatsappTemplate}
          emailTemplate={emailTemplate}
          taskData={taskData}
          errors={dayErrors[dayKey]}
          isDelete={Object.keys(dayActions).length > 1}
          metrics={dayMetrics && dayMetrics[dayKey]} // Keeping metrics key as is
        />

        {index < Object.keys(dayActions).length - 1 && (
          <DelayControl
            value={dayDelays[dayKey] || 1} // Use nullish coalescing
            onChange={(value) => handleDelayChange(dayKey, value, index)}
            error={dayErrors[dayKey]?.delay}
          />
        )}
      </React.Fragment>
    );
  })}

      {/* Add Day Button */}
      <div className="add_new_plus mt-4" onClick={handleAddDay}>
        <span className="auto_dot_plus">
          <img alt="plus" src="assets/img/plus.png" />
        </span>
        <label>ADD DAY</label>
      </div>
    </div>
  );
};

export default ActionSection;
