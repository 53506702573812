import axios from "axios";
import { API_URL, client_secret } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";
import { Logger } from '../utils/logger';

const API_ENDPOINT = API_URL + "api/v1";

const CLIENT_ID = "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com";
const GOOGLE_TOKEN_URL = "https://oauth2.googleapis.com/token";
const REDIRECT_URI = window.location.origin;

export const generateOtp = (data: object) => {
  return axios.post(`${API_ENDPOINT}/auth/generate-otp`, data, {
    headers: getHeaderOptions(),
  });
};
export const signIn = (data: object) => {
  // return axios.post(`${API_ENDPOINT}/auth`, data, {});
  return axios.post(`${API_ENDPOINT}/auth`, data, {
    headers: getHeaderOptions(),
  });
};
// in services/authServices.ts
export const sendGoogleCode = async (code: string) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/user/profile`, {
      code
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getUserProfile = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/profile`, {
    headers: obj,
  });
};

export const updateProfile = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/profile`, { headers: obj });
};

export const getGoogleTokens = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/user/get-google-tokens`, data, {
    headers: obj,
  });
};

export const getGoogleSheets = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/get-sheet-list`, {
    headers: obj,
  });
};

export const getGoogleSheetsInfo = (sheetId: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(
    `${API_ENDPOINT}/user/get-sheet-header?spreadsheetId=${sheetId}`,
    {
      headers: obj,
    }
  );
};

export const importGoogleSheet = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/google-sheet`, data, {
    headers: obj,
  });
};

export const switchBusinessAccount = (targetUserId: string) => {
  const currentToken = localStorage.getItem("auth_token") || "";
  
  let obj = {
    ...getHeaderOptions,
    Authorization: currentToken,
  };

  return axios.post(
    `${API_ENDPOINT}/auth/switch-account`,
    { 
      user: targetUserId,                        // Using "user" instead of "userId"
      accessToken: currentToken.replace("Bearer ", "")  // Removing Bearer prefix
    },
    { headers: obj }
  );
};

export const exchangeCodeForTokens = async (code: any) => {
  try {
    Logger.log("Exchanging code for tokens...");
    
    const response = await axios.post(
      "https://oauth2.googleapis.com/token",
      {
        code,
        client_id: CLIENT_ID,
        client_secret: client_secret, // Using the client secret from config
        redirect_uri: REDIRECT_URI,
        grant_type: "authorization_code",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    Logger.log("Token exchange response:", response.data);
    return response.data; // Contains access_token, id_token, refresh_token
  } catch (error) {
    console.error("Error exchanging code for tokens:", error);
    throw new Error("Failed to exchange code for tokens");
  }
};

export const exchangeGoogleAuthCode = async (code: string) => {
  try {
    Logger.log("Exchanging Google auth code for tokens...");
    const response = await axios.post(
      GOOGLE_TOKEN_URL,
      {
        code,
        client_id: CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        grant_type: "authorization_code",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    Logger.log("Google token exchange response:", response.data);
    localStorage.setItem("google_tokens", JSON.stringify(response.data));

    return response.data;
  } catch (error) {
    console.error("Error exchanging Google auth code:", error);
    throw new Error("Failed to exchange Google auth code");
  }
};

const createHeaders = () => {
  const authToken = localStorage.getItem("auth_token") || "";
  return {
    ...getHeaderOptions(),
    Authorization: authToken,
  };
};