import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Card, Button, Spinner, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { message } from 'antd';
import { Activity } from './types';
import './ActivityFeed.css';
import { getAllActivity } from '../../services/activityService';
import activityTypes, { getActivityTypeConfig } from './activityTypes';
import FilterComponent from '../../components/FilterBar/FilterBar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import LeadDetails from '../leads/leadDetails/leadDetails';
import DrawerComponent from '../../components/drawer';

interface Lead {
    _id: string;
    name: string;
    integration: string;
    integrationId: string;
    customSource: string;
    createdAt: Date;
    status: string[];
    saleValue: string;
    website: string;
    label: string[];
    phone: string;
}

interface ActivityFeedProps {
    initialStartDate?: string;
    initialEndDate?: string;
}

interface ActivityFilterState {
    type: string;
    teams: string[];
    userIds: string[];
    dateRange: string;
}

const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return 'Today';
    if (date.toDateString() === yesterday.toDateString()) return 'Yesterday';

    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
};

const ActivityFeed: React.FC<ActivityFeedProps> = ({ initialStartDate, initialEndDate }) => {
    // Original states
    const [displayedActivities, setDisplayedActivities] = useState<Activity[]>([]);
    const [floatingDate, setFloatingDate] = useState<string>('Today');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [filters, setFilters] = useState<ActivityFilterState>(() => ({
        type: '',
        teams: [],
        userIds: [],
        dateRange: initialStartDate && initialEndDate 
            ? `${initialStartDate},${initialEndDate}`
            : 'Last 7 Days'
    }));
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [customDateRange, setCustomDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [hasMore, setHasMore] = useState(true);
    const [total, setTotal] = useState(0);

    // New states for lead details drawer
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [selectedLead, setSelectedLead] = useState<Lead | null>(null);

    const itemsPerPage = 15;
    const containerRef = useRef<HTMLDivElement>(null);

    // Lead details handler
    const handleLeadClick = useCallback((activity: Activity) => {
        if (activity.lead) {
            // Map available lead data to required format
            setSelectedLead({
                _id: activity.lead._id,
                name: activity.lead.name,
                integration: activity.lead.integrationName || '',  // Using integrationName instead
                integrationId: activity.lead.integrationId || '',
                customSource: '',  // Default value since it's not in the original data
                createdAt: new Date(),  // Default to current date since it's not in the original data
                status: [],  // Default empty array since it's not in the original data
                saleValue: '',  // Default value since it's not in the original data
                website: '',  // Default value since it's not in the original data
                label: [],  // Default empty array since it's not in the original data
                phone: activity.lead.phone || ''  // Adding phone from the original data
            });
            setOpenWithHeader(true);
        } else {
            message.error('No lead details available');
        }
    }, []);

    // Original loadActivities function
    const loadActivities = useCallback(async (page: number, reset = false) => {
        if (!reset && (isLoadingMore || !hasMore)) return;
    
        if (reset) {
            setIsLoading(true);
        } else {
            setIsLoadingMore(true);
        }
    
        try {
            let dateParams = {};
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            
            // ... (rest of the date logic remains the same)
    
            const params = {
                page,
                perPage: itemsPerPage,
                type: filters.type,
                teams: filters.teams.join(','),
                userIds: filters.userIds.join(','),
                orderBy: '_id',
                isAscending: false,
                byOrganization: false,
                ...dateParams
            };
    
            const filteredParams = Object.fromEntries(
                Object.entries(params).filter(([_, value]) => value !== '')
            );
            
            const response = await getAllActivity(filteredParams);
            const activities = response.data.data;
            setTotal(response.data.total);
    
            if (activities.length === 0) {
                setHasMore(false);
            } else {
                setDisplayedActivities(prev => reset ? activities : [...prev, ...activities]);
                setCurrentPage(page + 1);
            }
        } catch (error) {
            console.error("Error fetching activities:", error);
            setHasMore(false);
        } finally {
            setIsLoading(false);
            setIsLoadingMore(false);
        }
    }, [filters, isLoadingMore, hasMore]);

    // Reset function
    const resetActivityFeed = useCallback(() => {
        setCurrentPage(1);
        setDisplayedActivities([]);
        setHasMore(true);
        loadActivities(1, true);
    }, [loadActivities]);

    // Initial load and filter changes
    useEffect(() => {
        resetActivityFeed();
    }, [filters]);

    // Scroll handler
    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (!container || isLoading || isLoadingMore || !hasMore) return;

        const bottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 5;
        if (bottom) {
            loadActivities(currentPage, false);
        }
    }, [currentPage, isLoading, isLoadingMore, hasMore, loadActivities]);

    // Date filter handlers
    const applyDateFilter = useCallback((range: string) => {
        setFilters((prev: ActivityFilterState) => ({ ...prev, dateRange: range }));
        if (range === 'Custom') setShowDatePicker(true);
    }, []);
    
    // Update the handleCustomDateApply function to use ActivityFilterState
    const handleCustomDateApply = useCallback(() => {
        const { startDate, endDate } = customDateRange;
        if (!startDate || !endDate) return;
        
        setShowDatePicker(false);
        setFilters((prev: ActivityFilterState) => ({
            ...prev,
            dateRange: `${startDate.toISOString()},${endDate.toISOString()}`,
        }));
    }, [customDateRange]);

    const dateOptions = [
        { label: 'Today', value: 'Today' },
        { label: 'Yesterday', value: 'Yesterday' },
        { label: 'Last 7 Days', value: 'Last 7 Days' },
        { label: 'Last 30 Days', value: 'Last 30 Days' },
        { label: 'This Month', value: 'This Month' },
        { label: 'Last Month', value: 'Last Month' },
        { label: 'This Year', value: 'This Year' },
        { label: 'Custom', value: 'Custom' }
    ];

    return (
        <Container className="activity-feed-container" fluid>
            <Modal show={showDatePicker} onHide={() => setShowDatePicker(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Custom Date Range</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePicker
                        selected={customDateRange.startDate ?? new Date()}
                        onChange={(date) => setCustomDateRange({ ...customDateRange, startDate: date ?? new Date() })}
                        selectsStart
                        startDate={customDateRange.startDate}
                        endDate={customDateRange.endDate}
                    />
                    <DatePicker
                        selected={customDateRange.endDate ?? new Date()}
                        onChange={(date) => setCustomDateRange({ ...customDateRange, endDate: date ?? new Date() })}
                        selectsEnd
                        startDate={customDateRange.startDate}
                        endDate={customDateRange.endDate}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDatePicker(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleCustomDateApply}>Apply</Button>
                </Modal.Footer>
            </Modal>

            <FilterComponent
                dateOptions={dateOptions}
                activityTypes={activityTypes}
                applyDateFilter={applyDateFilter}
                setFilters={setFilters as any}
                filters={filters as any}
                className="filter-bar"
            />

            {isLoading ? (
                <div className="loading-indicator d-flex justify-content-center my-4">
                    <Spinner animation="border" variant="primary" />
                </div>
            ) : (
                <div className="activity-list" onScroll={handleScroll} ref={containerRef}>
                    <div className="floating-date-bar">{floatingDate}</div>
                    <div className="timeline-line"></div>
                    {displayedActivities.map((activity) => {
                        const { label, icon } = getActivityTypeConfig(activity.type);
                        return (
                            <Card key={activity._id} className="activity-card" data-date={activity.performedAt}>
                                <Card.Body className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={icon} flip="horizontal" />
                                    <div className="activity-info ms-3">
                                        <h6>{label}</h6>
                                        <p>{activity.lead?.name || 'N/A'}</p>
                                        <p>Created by {activity.createdBy} on {formatDate(activity.performedAt)}</p>
                                    </div>
                                    <Button 
                                        variant="link" 
                                        onClick={() => handleLeadClick(activity)}
                                        disabled={!activity.lead}
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </Button>
                                </Card.Body>
                            </Card>
                        );
                    })}
                </div>
            )}

            {/* Lead Details Drawer */}
            {selectedLead && (
                <DrawerComponent
                    openWithHeader={openWithHeader}
                    setOpenWithHeader={setOpenWithHeader}
                    customHeader={false}
                    drawerTitle="Lead Details"
                    size="lg"
                    className="lead_details"
                >
                    <LeadDetails
                        _id={selectedLead._id}
                        name={selectedLead.name}
                        integration={selectedLead.integration}
                        customSource={selectedLead.customSource}
                        createdAt={selectedLead.createdAt}
                        status={selectedLead.status}
                        saleValue={selectedLead.saleValue}
                        website={selectedLead.website}
                        label={selectedLead.label}
                        deleteLeadFromDrawer={() => {}}
                        copyLeadToList={() => {}}
                        moveLeadToList={() => {}}
                        onLabelClick={() => {}}
                        onStatusClick={() => {}}
                        setFrom={() => {}}
                        handleEditModal={() => {}}
                        handleLeadDetailsModalOpen={() => {}}
                        onLeadFileUpload={() => {}}
                        editLead={() => {}}
                        leadFileDelete={() => {}}
                        refreshData={() => {}}
                        onDeleteLead={() => {
                            setOpenWithHeader(false);
                        }}
                    />
                </DrawerComponent>
            )}
        </Container>
    );
};

export default ActivityFeed;