import { Logger } from '../utils/logger';

// Replace the entire content of CallLogsDrawerManager.ts

type OpenDrawerFunction = (filters?: { searchText?: string }) => void;

class CallLogsDrawerManager {
  private openDrawerFn: OpenDrawerFunction | null = null;

  registerOpenDrawer(fn: OpenDrawerFunction) {
    this.openDrawerFn = fn;
  }

  unregisterOpenDrawer() {
    this.openDrawerFn = null;
  }

  openDrawer(filters?: { searchText?: string }) {
    if (this.openDrawerFn) {
      this.openDrawerFn(filters);
    } else {
      Logger.warn('CallLogsDrawerManager: openDrawer function is not registered yet.');
    }
  }
}

const callLogsDrawerManager = new CallLogsDrawerManager();
export default callLogsDrawerManager;