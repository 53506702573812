import React, { useMemo } from 'react';
import moment from 'moment';
import { 
  Pencil, MessageSquare, CheckSquare, Eye,
  Clock, ListPlus, Bot, CheckCircle2,
  MessageCircle, Send, Check, CheckCheck, 
  Calendar, CalendarPlus, UserCheck, Bell, 
  Tags, AlertCircle, Phone, PhoneIncoming,
  PhoneOutgoing, PhoneOff, ArrowRight, 
  MoveRight, Copy, FileText, Reply
} from 'lucide-react';
import { LucideIcon } from 'lucide-react';
import { getActivityMeta } from './activityConstants';
import { ActivityItemProps } from './types';
import './ActivityItem.css';

const ActivityItem: React.FC<ActivityItemProps> = React.memo(({ 
  activity, 
  handleEditModal,
  name 
}) => {
  // Get specific icon based on activity type and status
const getSpecificIcon = (type: string, details?: any) => {
  interface IconWithMeta {
    icon: LucideIcon;
    iconColor?: string;
    bgColor?: string;
  }

  // Handle WhatsApp message states
  if (type.includes('whatsapp')) {
    if (type.includes('failed')) return { 
      icon: AlertCircle,
      bgColor: '#EF4444'
    };
    if (type.includes('sending')) return { 
      icon: Send,
      bgColor: '#22C55E'
    };
    if (type.includes('sent')) return { 
      icon: Check,
      bgColor: '#22C55E'
    };
    if (type.includes('delivered')) return { 
      icon: CheckCheck,
      bgColor: '#22C55E'
    };
    if (type.includes('read')) return {
      icon: MessageCircle,
      bgColor: '#22C55E',
      iconColor: '#FFFFFF'
    };
  }

  // Handle calls with better icons
  if (type === 'OUTGOING') return {
    icon: PhoneOutgoing,
    bgColor: '#3B82F6'
  };
  if (type === 'INCOMING') return {
    icon: PhoneIncoming,
    bgColor: '#10B981'
  };
  if (type === 'MISSED') return {
    icon: PhoneOff,
    bgColor: '#EF4444'
  };
  
  // Handle status and list updates
  if (type === 'status_updated' || type.includes('Status updated')) return {
    icon: ArrowRight,
    bgColor: '#F59E0B'
  };
  if (type.includes('lead_moved') || type.includes('moved to')) return {
    icon: MoveRight,
    bgColor: '#6366F1'
  };

  // Handle task states
  if (type === 'task_created' || type.includes('follow-up task created')) return {
    icon: CalendarPlus,
    bgColor: '#8B5CF6'
  };
  if (type === 'due_date_updated') return {
    icon: Calendar,
    bgColor: '#8B5CF6'
  };
  
  // Default icon for unhandled types
  return {
    icon: Clock,
    bgColor: '#64748B'
  };

  // Handle status updates
  if (type === 'status_updated') {
    return { icon: ArrowRight };
  }

  // Handle calls
  if (type.includes('call')) {
    if (type.includes('missed')) return { icon: PhoneOff };
    if (type.includes('incoming')) return { icon: PhoneIncoming };
    if (type.includes('outgoing')) return { icon: PhoneOutgoing };
    return { icon: Phone };
  }

  // Handle different lead states
  if (type === 'lead_moved') return { icon: MoveRight };
  if (type === 'lead_assigned') return { icon: UserCheck };
  if (type === 'lead_copied') return { icon: Copy };

  // Handle automation and tasks
  if (type.includes('automation')) return { icon: Bot };
  if (type === 'task_created') return { icon: CalendarPlus };
  if (type === 'task_completed') return { icon: CheckSquare };
  if (type === 'task_note_added') return { icon: MessageSquare };
  if (type === 'follow_up_task') return { icon: Bell };

  // Handle notes and messages
  if (type === 'note_created') return { icon: FileText };
  if (type.includes('replied')) return { icon: Reply };
  
  return { icon: Clock };
};

// Get metadata with custom icon handling


const iconMeta = getSpecificIcon(activity.type);
const { bgColor } = getActivityMeta(activity.type);
const Icon = iconMeta.icon;
  
  const time = useMemo(() => {
    const utcDate = moment.utc(activity.performedAt || activity.createdAt);
    const istDate = utcDate.utcOffset(330);
    const currentMoment = moment().utcOffset(330);
    
    if (istDate.isSame(currentMoment, 'day')) {
      return `Today ${istDate.format('hh:mm a')}`;
    } else if (istDate.isSame(currentMoment.clone().subtract(1, 'day'), 'day')) {
      return `Yesterday ${istDate.format('hh:mm a')}`;
    } else if (istDate.isSame(currentMoment, 'year')) {
      return istDate.format('DD MMM, hh:mm a');
    } else {
      return istDate.format('DD MMM YYYY, hh:mm a');
    }
  }, [activity.performedAt, activity.createdAt]);

  const creatorName = useMemo(() => {
    if (!activity.createdBy) return '';
    if (typeof activity.createdBy === 'string') return activity.createdBy;
    return `${activity.createdBy.firstName || ''} ${activity.createdBy.lastName || ''}`.trim();
  }, [activity.createdBy]);

  const getActivityBackground = (type: string) => {
    switch (type) {
      case 'task_created':
      case 'task_completed':
      case 'automation':
      case 'task_note_added':
        return 'bg-activity-purple';
      case 'lead_moved':
      case 'lead_copied':
      case 'status_updated':
        return 'bg-activity-blue';
      default:
        return '';
    }
  };

  return (
    <div className={`activity-item ${getActivityBackground(activity.type)}`}>
      <div className="activity-line"></div>
      
      <div 
        className="activity-icon" 
        style={{ 
          backgroundColor: iconMeta.bgColor || bgColor,
          color: iconMeta.iconColor || '#FFFFFF'
        }}
        data-type={activity.type}
      >
        <Icon 
          size={24} 
          strokeWidth={activity.type.includes('whatsapp') ? 2.5 : 2}
        />
      </div>

      <div className="activity-content">
        <div className="activity-header">
          <div className="activity-time">{time}</div>
          {activity.type !== 'automation' && (
            <button
              className="edit-button"
              onClick={() => handleEditModal(name, activity._id, 'edit')}
            >
              <Pencil size={14} />
            </button>
          )}
        </div>

        <div className="activity-description">
          <div className="activity-label">{activity.label}</div>
          
          {activity.notes && (
            <div className="note-content">
              {activity.notes}
            </div>
          )}

          {activity.extraDetails && (
            <div className="extra-details">
              {activity.extraDetails.templateName && (
                <span className="template-info">
                  Template: {activity.extraDetails.templateName}
                </span>
              )}
              {activity.extraDetails.campaignName && (
                <span className="campaign-info">
                  Campaign: {activity.extraDetails.campaignName}
                </span>
              )}
            </div>
          )}

          {activity.task?.toBePerformAt && (
            <div className="due-date">
              Due: {moment(activity.task.toBePerformAt).format('DD MMM, hh:mm a')}
            </div>
          )}
        </div>

        {creatorName && (
          <div className="creator-info">
            <div className="creator-avatar">
              {creatorName.charAt(0)}
            </div>
            <span className="creator-name">{creatorName}</span>
          </div>
        )}
      </div>
    </div>
  );
});

ActivityItem.displayName = 'ActivityItem';

export default ActivityItem;