import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import axios from 'axios';
import { API_URL } from '../config/config';
import ErrorText from './errorText';
import toast from 'react-hot-toast';
import { Search } from 'lucide-react';
import PopupComponent from './PopupComponent';
import { Logger } from '../utils/logger';

interface TaskLeadSelectProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (leadId: string, leadName: string) => void;
    leadList: any[];
    error?: string;
  }

interface Lead {
  _id: string;
  name: string;
  phone?: string;
}

const TaskLeadSelect: React.FC<TaskLeadSelectProps> = ({
  isOpen,
  onClose,
  onSelect,
  error
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Lead[]>([]);

  useEffect(() => {
    if (isOpen) {
      setSearchTerm('');
      setSearchResults([]);
    }
  }, [isOpen]);

  const debouncedSearch = _.debounce(async (value: string) => {
    if (!value?.trim()) {
      setSearchResults([]);
      return;
    }

    setLoading(true);
    try {
      Logger.log('Calling API with search term:', value);
      const response = await axios.get(`${API_URL}api/v1/lead/search`, {
        headers: {
          Authorization: localStorage.getItem("auth_token") || "",
        },
        params: {
          search: value,
          orderBy: "_id",
          isAscending: false,
          page: 1,
          perPage: 50,
        },
      });

      Logger.log('API Response:', response.data);
      if (response.data?.data) {
        setSearchResults(response.data.data);
      }
    } catch (err) {
      console.error('Search failed:', err);
      toast.error("Search failed");
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleSelect = (lead: Lead) => {
    onSelect(lead._id, lead.name);
    onClose();
  };

  return (
    <PopupComponent
      isOpen={isOpen}
      onClose={onClose}
      title="Select Lead"
      size="sm"
      loading={loading}
    >
      <StyledContent>
        <div className="search-container">
          <Search size={20} className="search-icon" />
          <input
            type="text"
            className="search-input"
            placeholder="Search leads by name or phone..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        
        <div className="leads-container">
          {searchResults.length > 0 ? (
            <div className="lead-list">
              {searchResults.map((lead: Lead) => (
                <div
                  key={lead._id}
                  className="lead-item"
                  onClick={() => handleSelect(lead)}
                >
                  <div className="lead-info">
                    <span className="lead-name">{lead.name || 'Unknown'}</span>
                    {lead.phone && <span className="lead-phone">{lead.phone}</span>}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty-state">
              <p>{searchTerm ? 'No leads found' : 'Start typing to search leads'}</p>
            </div>
          )}
        </div>
      </StyledContent>
    </PopupComponent>
  );
};

const StyledContent = styled.div`
  .search-container {
    position: relative;
    margin-bottom: 16px;
  }

  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }

  .search-input {
    width: 100%;
    padding: 10px 12px 10px 40px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    font-size: 14px;
    
    &:focus {
      outline: none;
      border-color: #2563eb;
      box-shadow: 0 0 0 2px rgba(37,99,235,0.1);
    }

    &::placeholder {
      color: #999;
    }
  }

  .leads-container {
    max-height: 400px;
    overflow-y: auto;
  }

  .lead-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .lead-item {
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    background: white;

    &:hover {
      background-color: #f8f9fa;
      border-color: #2563eb;
      transform: translateY(-1px);
    }
  }

  .lead-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .lead-name {
    font-weight: 500;
    color: #1a1a1a;
    font-size: 14px;
  }

  .lead-phone {
    color: #666;
    font-size: 13px;
  }

  .empty-state {
    text-align: center;
    color: #666;
    padding: 20px;
  }
`;

export default TaskLeadSelect;